
import { IoReload } from 'react-icons/io5'

import Search from './Search';

const KurseSearchFilter = ({ allCourses, setFilterSponsor, setFilterProjektleitung, setSearchResults, filterSponsor, allCustomers, filterProjektleitung, allProjektleiter, allIndications, filterIndication, setFilterIndication }) => {

    return (
        <div className='form-container'>

            {/* filter */}
            <div>
                <div className='filter-header-container' onClick={() => { setFilterSponsor("Alle"); setFilterProjektleitung("Alle"); setFilterIndication("Alle") }}>
                    <h5>Filter</h5>
                    <IoReload />
                </div>

                {/* select which customer */}
                <div className='input-container third'>
                    <label htmlFor='customer-filter-list'>Kunde: </label>
                    <select name="customer-filter-list" id="customer-filter-list" className='filter-by-customer-select' value={filterSponsor} onChange={(e) => setFilterSponsor(e.currentTarget.value)}>
                        <option value="Alle">Alle</option>
                        {allCustomers.map((customer, i) =>
                            <option key={`customer-select-item-${i}`} value={customer}>{customer ? customer : "-"}</option>
                        )}
                    </select>
                </div>
                {/* select which projektleiter */}
                <div className='input-container third'>
                    <label htmlFor='projektleiter-filter-list'>Projektleiter<sup>*</sup>in: </label>
                    <select name="projektleiter-filter-list" id="projektleiter-filter-list" className='filter-by-projektleiter-select' value={filterProjektleitung} onChange={(e) => setFilterProjektleitung(e.currentTarget.value)}>
                        <option value="Alle">Alle</option>
                        {allProjektleiter.map((projektleiter, i) =>
                            <option key={`projektleiter-select-item-${i}`} value={projektleiter}>{projektleiter ? projektleiter : "-"}</option>
                        )}
                    </select>
                </div>
                {/* select which Indikation */}
                <div className='input-container third'>
                    <label htmlFor='indication-filter-list'>Indikation:</label>
                    <select name="indication-filter-list" id="indication-filter-list" className='filter-by-indication-select' value={filterIndication} onChange={(e) => setFilterIndication(e.currentTarget.value)}>
                        <option value="Alle">Alle</option>
                        {allIndications.map((indication, i) =>
                            <option key={`indication-select-item-${i}`} value={indication}>{indication ? indication : "-"}</option>
                        )}
                    </select>
                </div>
            </div>


            {/* search */}
            <div className='input-container large'>
                <label>Suche: </label>
                <Search input={allCourses} callback={setSearchResults} searchKeys={["AerztlicherLeiter", "Sponsor_Name", "KursKey", "VNR", "KursTitel", "KursUntertitel", "Referenten"]} />
            </div>

        </div >
    )

}

export default KurseSearchFilter
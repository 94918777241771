import { useState, useEffect, useContext } from 'react';
import { TokenContext } from '../Main.js';

import { AiOutlineWarning, AiOutlineLock, AiOutlineDownload } from 'react-icons/ai'
import { BiExit } from 'react-icons/bi'
import { HiCheckCircle } from 'react-icons/hi'
import { BiWorld } from 'react-icons/bi'
import { FiTrash2 } from 'react-icons/fi'

import { filterArray, formatDateToString } from '../../functions/helper.js'
import { downloadJson, downloadKursLinkJson } from '../../functions/fileHandler.js';

import KurseSearchFilter from '../KurseSearchFilter';


// COURSE TABLE FOR EACH KIND OF COURSE (KURS, WEBINAR, PODCAST, ...)
const KursTable = ({ title, courses, setSelectedCourse, showIcons = false, removeCourseFromDatabase = undefined, validation = true, allRelations = false }) => {

    // access-token
    const [tokenObject, setTokenObject] = useContext(TokenContext);

    // -- state for search-result
    const [displayedCourses, setDisplayedCourses] = useState(courses);
    // -- states for filtering
    const [allCustomers, setAllCustomers] = useState([]);
    const [allProjektleiter, setAllProjektleiter] = useState([]);
    const [allIndications, setAllIndications] = useState([]);
    const [filterSponsor, setFilterSponsor] = useState("Alle");
    const [filterProjektleitung, setFilterProjektleitung] = useState("Alle");
    const [filterIndication, setFilterIndication] = useState("Alle");


    // -- init
    useEffect(() => {
        initTable();
    }, [])
    const initTable = async () => {
        // set all sponsors, indications and projektleiter
        let sponsors = [];
        let projektleiter = [];
        var indications = [];
        courses.forEach(thisCourse => {
            // sponsors
            if (!sponsors.includes(thisCourse.Sponsor_Name)) sponsors.push(thisCourse.Sponsor_Name);
            // projektleiter
            if (!projektleiter.includes(thisCourse.Projektleiter)) projektleiter.push(thisCourse.Projektleiter);
            // indications
            thisCourse.Indikationen.forEach(thisIndication => { if (!indications.includes(thisIndication)) indications.push(thisIndication); })
        });

        // set states
        setAllCustomers(sponsors.sort());
        setAllProjektleiter(projektleiter.sort());
        setAllIndications(indications.sort());
    }

    // -- update filter
    useEffect(() => {
        setDisplayedCourses(filterArray(courses, [{ key: 'Sponsor_Name', value: filterSponsor }, { key: 'Projektleiter', value: filterProjektleitung }, { key: 'Indikationen', value: filterIndication }]));
    }, [filterSponsor, filterProjektleitung, filterIndication]);



    // -- row component
    const TableRow = ({ index, course }) => {

        // -- download offline_info.json and kurs_link.json for this course
        const downloadJsonFiles = async () => {
            await downloadJson(course, tokenObject, setTokenObject);
            downloadKursLinkJson(course);
        }

        // determin if course is active, expired or upcoming
        let state;
        if (!course.Laufzeit_Start || !course.Laufzeit_Ende) state = "undefined";
        else {
            const startDate = new Date(course.Laufzeit_Start);
            const endDate = new Date(course.Laufzeit_Ende);
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(0, 0, 0, 0);
            if (startDate > today || isNaN(startDate) || isNaN(endDate)) state = "upcoming";
            else if (today > endDate) state = "expired";
            else state = "active";
        }

        // get amount of teilnehmer
        let teilnehmerCount = 0;
        if (allRelations) {
            allRelations.forEach(relation => { if (relation.KursKey === course.KursKey) teilnehmerCount++; })
        }

        // each row is a table in this case
        return (
            <div className='table-container course-table'>
                <table>
                    <tbody>
                        <tr key={`available-courses-key-${index}`} className={`course-row no-bottom-padding ${course.AktiverNutzer ? "blocked" : ""}`}>

                            {/* validation icons */}
                            {validation ?
                                <>
                                    {/* warnings */}
                                    <td className='column-icon' ispublic={course.Valid === 1 ? "true" : "false"}>
                                        {course.Valid === 1 ?
                                            <>
                                                <HiCheckCircle />
                                                <span className='explanation'>Kurs validiert</span>
                                            </>
                                            :
                                            <>
                                                <AiOutlineWarning />
                                                <span className='explanation'>Warnungen vorhanden</span>
                                            </>
                                        }
                                    </td>

                                    {/* public in cme shuttle */}
                                    {!course.GCP && course.Art !== "Live" ?
                                        // only display if not a gcp-module
                                        <td className='column-icon' ispublic={course.Public === 1 ? "true" : "false"}>
                                            {course.Public === 1 ?
                                                <>
                                                    <BiWorld />
                                                    <span className='explanation'>Kurs veröffentlicht in CME Shuttle</span>
                                                </>
                                                :
                                                <>
                                                    <AiOutlineLock />
                                                    <span className='explanation'>Kurs nicht in CME Shuttle</span>
                                                </>
                                            }
                                        </td> : <></>
                                    }
                                </>

                                : <td className='column-icon'></td>
                            }

                            {/* normal row */}
                            <td className={`${validation ? 'column-width-30' : 'column-width-40'} underline`} style={{ fontSize: "1.2rem" }} onClick={() => setSelectedCourse(course)}>{course.KursKey}</td>
                            <td className={`${validation ? 'column-width-50' : 'column-width-40'} column-list`}>
                                <ul className='course-table-list'>

                                    {/* course-title */}
                                    <li>
                                        <div className='course-title'>{course.KursTitel ? course.KursTitel : <span className='lightred-info'>Keine Angabe</span>}</div>
                                    </li>

                                    {/* vnr/id */}
                                    <li>
                                        <span>{course.VNR ? "VNR:" : "ID:"}</span>
                                        <span>{course.VNR ? course.VNR : (course.ID ? course.ID : <span className='lightred-info'>Keine Angabe</span>)}</span>
                                    </li>


                                    {/* sponsor */}
                                    <li>
                                        <span>Sponsor:</span>
                                        <span>{course.Sponsor_Name ? course.Sponsor_Name : <span className='lightred-info'>Keine Angabe</span>}</span>
                                    </li>

                                    {/* cme points */}
                                    {course.CME_Punkte || course.CME_Punkte === 0 ?
                                        <li>
                                            <span>CME Punkte:</span>
                                            <span>{course.CME_Punkte}</span>
                                        </li>
                                        : <></>
                                    }

                                    {/* answerkey */}
                                    {course.Art === "Kurs" ?
                                        <li>
                                            <span>Antwortschlüssel:</span>
                                            <span>{course.CorrectAnswerKey ? course.CorrectAnswerKey : <span className='lightred-info'>Keine Angabe</span>}</span>
                                        </li>
                                        : <></>
                                    }

                                    {/* aerzt-leiter */}
                                    <li>
                                        <span>Leitung:</span>
                                        <span>{course.AerztlicherLeiter[0] && course.AerztlicherLeiter[0].Nachname ? course.AerztlicherLeiter[0].Nachname : <span className='lightred-info'>Keine Angabe</span>}{course.AerztlicherLeiter.length > 1 ? ", et al." : ""}</span>
                                    </li>

                                    {/* referenten */}
                                    <li>
                                        <span>Referenten:</span>
                                        <span>{course.Referenten[0] && course.Referenten[0].Nachname ? course.Referenten[0].Nachname : <span className='lightred-info'>Keine Angabe</span>}{course.Referenten.length > 1 ? ", et al." : ""}</span>
                                    </li>

                                    {/* amount teilnehmer */}
                                    {allRelations ?
                                        <li>
                                            <span>Teilnehmer:</span>
                                            <span>{teilnehmerCount}</span>
                                        </li>
                                        : <></>
                                    }
                                </ul>
                            </td>
                            {showIcons ?
                                <td className='icon-column column-icon'>
                                    {course.urlKurs ? <div className='column-icon black-icon'><a target='_blank' href={course.urlKurs}><BiExit /></a></div> : <></>}
                                    {course.Valid ? <div className='column-icon black-icon' onClick={downloadJsonFiles}><AiOutlineDownload /></div> : <></>}
                                    {removeCourseFromDatabase ? <div className='column-icon delete underline' onClick={() => removeCourseFromDatabase(course)}><FiTrash2 /></div> : <></>}
                                </td>
                                : <></>
                            }


                        </tr>
                    </tbody>
                </table>
                <span state={state} className='bottom-row white-info'>{formatDateToString(course.Laufzeit_Start)} - {formatDateToString(course.Laufzeit_Ende)}</span>
            </div>
        )
    }



    return (
        <div>

            {/* filter and search */}
            <KurseSearchFilter
                allCourses={courses}

                setSearchResults={(searchResult) => setDisplayedCourses(filterArray(searchResult, [{ key: 'Sponsor_Name', value: filterSponsor }, { key: 'Projektleiter', value: filterProjektleitung }, { key: 'Indikationen', value: filterIndication }]))}

                allCustomers={allCustomers}
                allProjektleiter={allProjektleiter}
                allIndications={allIndications}

                filterSponsor={filterSponsor} setFilterSponsor={setFilterSponsor}
                filterProjektleitung={filterProjektleitung} setFilterProjektleitung={setFilterProjektleitung}
                filterIndication={filterIndication} setFilterIndication={setFilterIndication}
            />

            {/* rows */}
            {courses.length !== 0 ?
                displayedCourses.map((course, index) =>
                    <TableRow
                        key={`table-row-${course.Art}-key-${index}`}
                        index={index}
                        course={course}
                    />
                )
                :
                <div className='table-container'>
                    <table><tbody><tr className='course-row'><td>Keine {title === "Alle" ? "Ergebnisse" : title} verfügbar</td></tr></tbody></table>
                </div>}
        </div>
    )
}

export default KursTable
import { useState, useEffect, useContext } from "react";
import { TokenContext } from "../Main";

import { sendPatchRequest, sendRequest } from "../../rest/requests";
import { printInput } from "../InputComponents";

import Loader from "../Loader";
import BackButton from "../BackButton";


const SponsorenForm = ({ selectedSponsor, goBack }) => {

    // access-token
    const [tokenObject, setTokenObject] = useContext(TokenContext);

    // init
    const [initialized, setInitialized] = useState(false);

    // this sponsor
    const [thisSponsor, setThisSponsor] = useState()


    // initialize component
    useEffect(() => { init() }, []);
    const init = async () => {
        // GET THIS SPONSOR FROM DATABASE
        const response = await sendRequest(tokenObject, setTokenObject, 'GET', `/sponsoren/${selectedSponsor.SPONSOR_ID}`, 'Lade Sponsoren', 'Sponsoren geladen');
        if (response.success) {
            setThisSponsor(response.data[0]);
            setInitialized(true);
        }
    }


    // state thisSponsor on change
    useEffect(() => {
        if (thisSponsor && initialized) {
            // UPDATE THIS SPONSOR IN DATABASE
            sendPatchRequest(tokenObject, setTokenObject, `/sponsoren`, thisSponsor, `Speichere Sponsor`, `Sponsor gespeichert`);
        }
    }, [thisSponsor])


    // update thisSponsor
    const updateThisSponsor = async (key, value) => {
        setThisSponsor((prev) => ({
            ...prev,
            [key]: value
        }))
    }



    if (initialized && thisSponsor) {
        return (
            <div className="basic-container">

                <BackButton onClick={goBack} />

                <div className="form-container">
                    <h3>{thisSponsor.Name}</h3>

                    {/* id */}
                    {printInput(undefined, 'sponsor-id-input', 'ID', thisSponsor.SPONSOR_ID, true, undefined, 'text', '', true)}
                    {/* kuerzel */}
                    {printInput((e) => updateThisSponsor("Kuerzel", e.currentTarget.value), 'sponsor-kuerzel-input', 'Kürzel', thisSponsor.Kuerzel)}
                    {/* name */}
                    {printInput((e) => updateThisSponsor("Name", e.currentTarget.value), 'sponsor-name-input', 'Name', thisSponsor.Name, true, undefined, 'text', '', false, undefined, true)}
                    {/* logo */}
                    {printInput((e) => updateThisSponsor("Logo", e.currentTarget.value), 'sponsor-logo-input', 'Logo', thisSponsor.Logo, true, undefined, 'text', '', false, undefined, true)}

                </div>
                <div className="seperator"></div>
            </div >
        )
    }
    else {
        return <Loader />
    }
}

export default SponsorenForm
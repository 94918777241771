import { useState, useContext } from 'react';
import { TokenContext } from '../Main';
import { VscDiscard, VscArrowRight } from 'react-icons/vsc'
import { AiOutlineArrowRight } from 'react-icons/ai'

import { sendRequest } from '../../rest/requests';

import Popup from "../Popup";


// -- popup for overwriting existing teilnehmer
const OverwritePopup = ({ databaseTeilnehmer, importedTeilnehmer }) => {

    // access-token
    const [tokenObject, setTokenObject] = useContext(TokenContext);

    // -- old teilnehmer from database
    const [oldTeilnehmer, setOldTeilnehmer] = useState({ ...databaseTeilnehmer });
    // -- new teilnehmer for database
    const [newTeilnehmer, setNewTeilnehmer] = useState({ ...importedTeilnehmer });



    // -- use old value for this entry - no need for database-access
    const discardChanges = (key) => {
        setNewTeilnehmer((prev) => ({
            ...prev,
            [key]: oldTeilnehmer[key]
        }))
    }


    // -- use new value for this entry
    const saveChanges = (key) => {
        // copy old teilnehmer
        const updatedTeilnehmer = { ...oldTeilnehmer };
        // set new value
        updatedTeilnehmer[key] = newTeilnehmer[key];
        setOldTeilnehmer(updatedTeilnehmer)

        // update teilnehmer in database
        updateTeilnehmer(updatedTeilnehmer);
    }

    // --update this teilnehmer
    const updateTeilnehmer = async (teilnehmer) => {
        console.log('update in db', teilnehmer);
        // UPDATE TEILNEHMER IN DATABASE
        sendRequest(tokenObject, setTokenObject, 'PATCH', `/teilnehmer/${teilnehmer.EFN}`, `Speichere Teilnehmer "${teilnehmer.Nachname}"`, `Teilnehmer "${teilnehmer.Nachname}" gespeichert`, teilnehmer);
    }


    return (
        <Popup
            id={5}
            content={
                <div id='overwrite-changes'>

                    <h2>Teilnehmer ist bereits in Datenbank</h2>

                    <h5>Welche Teilnehmer-Felder sollen überschrieben werden?</h5>
                    <small>Wurde nichts ausgewählt, bleiben die roten Felder bestehen</small>

                    <div className="changes-container">
                        {Object.keys(newTeilnehmer).map((key, i) => {

                            // compare new and old values
                            const oldValue = oldTeilnehmer[key];
                            const newValue = newTeilnehmer[key];
                            const valuesEqual = oldValue === newValue ? true : false;

                            // overwrite row
                            return (
                                <div className="overwrite-entry" key={`overwrite-change-entry-${i}`}>

                                    {/* key */}
                                    <span className="overwrite-key">{key}: </span>

                                    {/* value */}
                                    <span className="overwrite-value">
                                        {valuesEqual ?
                                            // old and new are the same
                                            newValue
                                            :
                                            // display both, new and old
                                            <span>
                                                <span style={{ color: 'red' }}>{oldValue}</span><br />
                                                <span style={{ color: 'green' }}>{newValue}</span>
                                            </span>
                                        }
                                    </span>

                                    {/* discard/overwrite buttons */}
                                    {!valuesEqual ?
                                        <span className='overwrite-btn-container'>
                                            <span className="pointer" style={{ color: "red" }} onClick={() => discardChanges(key)}><VscDiscard /><span className='info'>Verwerfen</span></span>
                                            <span className="pointer" style={{ color: "green" }} onClick={() => saveChanges(key)}><VscArrowRight /><span className='info'>Übernehmen</span></span>
                                        </span>
                                        :
                                        <></>
                                    }

                                </div>
                            )
                        })}
                    </div>

                    <div className="seperator"></div>

                    <div className="multiple-btn-container">
                        <div id='cancel-btn' className="basic-small-btn">Abbrechen</div>
                        <div id='next-teilnehmer-btn' className="basic-small-btn">Nächster Teilnehmer <AiOutlineArrowRight /></div>
                    </div>
                </div>
            }
            button={<div className="basic-btn left">Öffne Änderungen</div>}
            initialeActive={'true'}
        />
    )
}

export default OverwritePopup
import { useState, useEffect, useContext } from 'react'
import { TokenContext } from '../Main.js';

import { printInput, printInputWithPreview, printTextArea } from '../InputComponents';
import { sendPatchRequest, sendRequest } from '../../rest/requests';
import { getReferentenFromDatabase } from '../../rest/requests_get_all.js';

import Loader from '../Loader';


const ReferentenForm = ({ selectedReferent, setAllReferenten }) => {

    // access-token
    const [tokenObject, setTokenObject] = useContext(TokenContext);

    const [initialized, setInitialized] = useState(false);

    // -- this referent
    const [thisReferent, setThisReferent] = useState();


    // -- init
    useEffect(() => {
        // scroll to top when starting this form
        document.querySelector('#sections-container').scrollTop = 0;
        initReferentenForm();
    }, []);

    const initReferentenForm = async () => {
        // GET REFERENT FROM DB
        const response = await sendRequest(tokenObject, setTokenObject, 'GET', `/referenten/${selectedReferent.REFERENT_ID}`, `Lade "${selectedReferent.REFERENT_ID}" aus Datenbank`, `Referent "${selectedReferent.REFERENT_ID}" geladen`);

        if (response.success) {
            // set state
            setThisReferent(response.data[0]);
            setInitialized(true);
        }
    }




    // UPDATE THIS REFERENT IN DATABASE
    const updateThisReferent = async (e, key) => {
        var value;
        if (e.currentTarget.type === "checkbox") value = e.currentTarget.checked ? 1 : 0;
        else value = e.currentTarget.value;

        setThisReferent((prevState) => ({
            ...prevState,
            [key]: value,
        }))
    }

    useEffect(() => {
        if (initialized) {
            // update referent in database
            sendPatchRequest(
                tokenObject, setTokenObject, `/referenten/${thisReferent.REFERENT_ID}`, thisReferent, `Speichere Referent "${thisReferent.REFERENT_ID}"`, `Referent "${thisReferent.REFERENT_ID}" gespeichert`,
                async () => setAllReferenten(await getReferentenFromDatabase(tokenObject, setTokenObject))
            )
        }
    }, [thisReferent])



    if (thisReferent && initialized) {
        return (

            <div className='basic-container' >
                <div className='form-container'>
                    <h3>{thisReferent.Vorname} {thisReferent.Nachname}</h3>
                    <form>
                        {printInput((e) => updateThisReferent(e, "Titel"), "Titel", "Titel: ", thisReferent.Titel)}

                        {printInput((e) => updateThisReferent(e, "Foto"), "Foto", "Foto-Name: ", thisReferent.Foto, true, "<strong>Datei-Namen angeben!</strong><br>Referenten-Fotos müssen in <i style='color:gray'>assets/images/</i> liegen.<br>Üblich ist Nachname, alles klein, im jpg-Format.<br><i style='color:gray'>nachname.jpg</i>")}

                        {printInput((e) => updateThisReferent(e, "Vorname"), "Vorname", "Vorname: ", thisReferent.Vorname)}

                        {printInput((e) => updateThisReferent(e, "Nachname"), "Nachname", "Nachname: ", thisReferent.Nachname, true, undefined, "text", "", true)}

                        {printInputWithPreview(true, (e) => updateThisReferent(e, "Foto_small"), "Foto_small", "Foto-Thumbnail-URL: ", thisReferent.Foto_small, true, "<strong>URL angeben!</strong><br>Kleines Referenten-Foto für CME Shuttle App.")}

                        {printTextArea((e) => updateThisReferent(e, "Allgemein"), "Allgemein", "Allgemein: ", thisReferent.Allgemein, true, "Allgemeine Informationen des Referenten. Üblicherweiße Adresse.", "large-text")}

                        {printTextArea((e) => updateThisReferent(e, "Lebenslauf"), "Lebenslauf", "Lebenslauf: ", thisReferent.Lebenslauf, true, "Muss nicht angegeben werden.", "large-text")}

                        {printTextArea((e) => updateThisReferent(e, "Sonstiges"), "Sonstiges", "Sonstiges: ", thisReferent.Sonstiges, true, "Muss nicht angegeben werden.", "large-text")}
                    </form>
                </div>
            </div>
        )
    }
    else {
        return <Loader />
    }
}

export default ReferentenForm
import { useEffect, useState, useContext } from "react"
import { TokenContext } from "./Main";
import { FiTrash2 } from 'react-icons/fi'

import { sendPatchRequest, sendRequest } from "../rest/requests";
import { printInput, printTextArea } from "./InputComponents";

import Loader from "./Loader";



export default function ShuttleInformation({ }) {

    // access-token
    const [tokenObject, setTokenObject] = useContext(TokenContext);

    const [initialized, setInitialized] = useState(false);
    const [shuttleInfos, setShuttleInfos] = useState();


    // -- init
    useEffect(() => {
        init();
    }, [])
    const init = async () => {
        // GET SHUTTLE-INFOS FROM DATABASE
        const response = await sendRequest(tokenObject, setTokenObject, 'GET', '/shuttle', 'Lade Shuttle-Infos', 'Shuttle-Infos geladen');
        if (response.success) {
            setShuttleInfos(response.data[0]);
            setInitialized(true);
        }
    }


    // -- update
    const updateInfos = (key, value) => {
        setShuttleInfos((prevState) => ({
            ...prevState,
            [key]: value
        }))
    }
    // finished updating -> update in database
    useEffect(() => {
        if (initialized && shuttleInfos) {
            // UPDATE SHUTTLE INFOS IN DATABASE
            sendPatchRequest(tokenObject, setTokenObject, `/shuttle`, shuttleInfos, `Speichere Shuttle-Infos`, `Shuttle-Infos gespeichert`)
        }
    }, [shuttleInfos])


    // -- delete login
    const deleteLogin = (key) => {
        const confirmation = window.confirm(`Sicher, dass du das Login "${key}" löschen möchtest`);
        if (confirmation) {
            const newInfos = { ...shuttleInfos };
            delete newInfos.loginData[key];
            setShuttleInfos(newInfos);
        }
    }
    // -- add login
    const addLogin = () => {
        const newValue = window.prompt("Bitte trage Kürzel für den neuen Kunden ein");
        if (newValue) {
            // random password
            const newPassword = () => {
                let newPw = "";
                for (let i = 0; i < 10; i++) { newPw += Math.floor(Math.random() * 99); }
                return newPw;
            }
            setShuttleInfos((prev) => ({
                ...prev,
                loginData: {
                    ...shuttleInfos.loginData,
                    [newValue]: newPassword()
                }
            }))
        }
    }
    // -- update login
    const updateLogin = (key, value) => {
        setShuttleInfos(prev => ({
            ...prev,
            loginData: {
                ...shuttleInfos.loginData,
                [key]: value
            }
        }))
    }


    if (initialized && shuttleInfos) {
        return (
            <div className="basic-container">
                <div className="form-container">
                    <h1>{shuttleInfos.Name}</h1>

                    {/* version */}
                    {printInput((e) => updateInfos("version", e.currentTarget.value), 'shuttle-version', 'Version', shuttleInfos.version)}

                    {/* app-version */}
                    {printInput((e) => updateInfos("AppVersion", e.currentTarget.value), 'shuttle-app-version', 'React-Native App-Version', shuttleInfos.AppVersion)}

                    {/* message */}
                    {printTextArea((e) => updateInfos("message", e.currentTarget.value), 'shuttle-message', 'Nachricht in App', shuttleInfos.message)}

                    {/* app-info */}
                    {printTextArea((e) => updateInfos("AppInfo", e.currentTarget.value), 'shuttle-app-info', 'Neu in dieser Version', shuttleInfos.AppInfo)}


                    <h3>Login-Daten</h3>
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Passwort</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* existing logins */}
                                {Object.keys(shuttleInfos.loginData).map((login, index) => {
                                    const key = login;
                                    const value = shuttleInfos.loginData[key];

                                    return (
                                        <tr key={`login-list-container-${index}`}>
                                            <td className="column-input column-width-20">{printInput(undefined, `shuttle-login-data-name-${index}`, '', key, true, undefined, 'text', '', true)}</td>
                                            <td className="column-input">{printInput((e) => updateLogin(key, e.currentTarget.value), `shuttle-login-data-pw-${index}`, '', value)}</td>
                                            <td className="column-icon delete pointer" onClick={() => deleteLogin(key)}><FiTrash2 /></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>

                    {/* new logins */}
                    <div className="basic-btn" onClick={addLogin}>Login hinzufügen</div>
                </div>
            </div >
        )
    }
    else {
        return (
            <Loader />
        )
    }
}
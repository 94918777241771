import { useEffect, useState, useContext } from "react"
import { TokenContext } from "../Main"
import { AiOutlineArrowRight } from "react-icons/ai"

import { months } from "../../functions/helper"
import { getAllCoursesInOneArray } from '../../rest/requests_get_all'
import { sendRequest } from '../../rest/requests'
import { exportXlsx } from '../../functions/fileHandler'
import { printInput, printSelect } from "../InputComponents"

import Loader from "../Loader"
import StatisticForm from "./StatisticForm"
import Search from "../Search"


const StatisticManager = ({ }) => {

    // access-token
    const [tokenObject, setTokenObject] = useContext(TokenContext);

    // init
    const [initialized, setInitialized] = useState(false);
    // array with all courses
    const [coursesArray, setCoursesArray] = useState();
    // array with all courses
    const [filteredCourseArray, setFilteredCourseArray] = useState([]);
    // selected course for specific statistic
    const [selectedCourse, setSelectedCourse] = useState();

    // export month and year
    const [exportMonth, setExportMonth] = useState(months[0]);
    const [exportYear, setExportYear] = useState((new Date()).getFullYear());


    // -- init
    useEffect(() => init(), []);
    const init = async () => {
        // GET COURSES FROM DATABASE
        let receivedCourses = await getAllCoursesInOneArray(tokenObject, setTokenObject);
        // filter out gcp,webinar,etc.
        receivedCourses = receivedCourses.filter(elem => elem.Art === "Kurs" || elem.Art === "Live");
        // sort by alphabet
        receivedCourses = receivedCourses.sort((a, b) => a.KursKey.toLowerCase().localeCompare(b.KursKey.toLowerCase()));
        // set state
        setCoursesArray(receivedCourses);

        setInitialized(true);
    }


    // -- export statistic for all courses (additionally sorted by month/date)
    const exportAllCourseStatistic = async (e, tokenObject, setTokenObject, exportAll = false) => {
        e.preventDefault();

        // change button until finished
        const button = e.currentTarget.querySelector('input[type="submit"');
        button.style.display = 'none';


        // GET ALL COURSES FROM DATABASE
        const allCourses = await getAllCoursesInOneArray(tokenObject, setTokenObject);

        // GET KURS-TEILNAHME-RELATIONS FROM DATABASE
        const response = await sendRequest(tokenObject, setTokenObject, 'GET', '/kursteilnahme/allKursTeilnehmerRelations', 'Lade alle Kurs-Teilnehmer-Relations', 'Alle Kurs-Teilnehmer-Relations geladen');

        if (response.success) {
            const allRelations = response.data;

            var result = [];

            // -- run through courses
            for (let i = 0; i < allCourses.length; i++) {
                const thisCourse = allCourses[i];
                const vnr = thisCourse.VNR;

                // dont use course if expired or upcoming in a different month than selected one
                if (!exportAll) {

                    const start = new Date(thisCourse.Laufzeit_Start);
                    const end = new Date(thisCourse.Laufzeit_Ende);
                    const current = new Date(`01 ${exportMonth} ${exportYear}`);

                    // set time and day of month all to the same
                    current.setHours(0, 0, 0, 0);
                    current.setDate(1);
                    start.setHours(0, 0, 0, 0);
                    start.setDate(1);
                    end.setHours(0, 0, 0, 0);
                    end.setDate(1);

                    // expired - ignore this course and continue for-loop
                    if (current < start || current > end) continue;
                }

                // dont use this course it has no vnr
                if (vnr) {
                    // filter allRelations for this course
                    var kursTeilnahme = allRelations.filter(elem => elem.VNR === vnr);

                    // sort kursteilnahme by date
                    kursTeilnahme = kursTeilnahme.sort((a, b) => (new Date(a.Teilnahmedatum) - new Date(b.Teilnahmedatum)));

                    // filter
                    if (!exportAll) {
                        // filter kursteilnahme by month
                        kursTeilnahme = kursTeilnahme.filter(elem => {
                            const monthIndex = (new Date(elem.Teilnahmedatum)).getMonth();
                            return months[monthIndex] === exportMonth;
                        })
                        // filter kursteilnahme by year
                        kursTeilnahme = kursTeilnahme.filter(elem => {
                            const thisYear = (new Date(elem.Teilnahmedatum)).getFullYear();
                            return thisYear === exportYear;
                        })
                    }

                    // row template
                    const sponsor = thisCourse.Sponsor_Name;
                    const sponsorShort = thisCourse.Sponsor_Kuerzel ? thisCourse.Sponsor_Kuerzel : "";
                    const title = thisCourse.KursTitel;

                    const referent =
                        (thisCourse.AerztlicherLeiter[0] && thisCourse.AerztlicherLeiter[0].Nachname) ?
                            thisCourse.AerztlicherLeiter[0].Nachname
                            :
                            thisCourse.Referenten[0] ?
                                thisCourse.Referenten[0].Nachname : undefined;

                    const body = { "Sponsor": sponsor, "Kuerzel": sponsorShort, "Datum": "", "Referent": referent, "Titel": title, "VNR": vnr, "QR-Code": 0, "Antwortbogen": 0, "Online": 0 };

                    if (kursTeilnahme.length === 0) {
                        // -- if no kursteilnahme exists
                        result.push({ ...body })
                    }
                    else {
                        // -- run through kursteilnahmen
                        for (let j = 0; j < kursTeilnahme.length; j++) {
                            const thisTeilnahme = kursTeilnahme[j];

                            // date
                            const date = new Date(thisTeilnahme.Teilnahmedatum);
                            const month = months[date.getMonth()];
                            const dateString = `${month} ${date.getFullYear()}`;

                            // kind of participation
                            const participationKind = (thisTeilnahme.AD_Email) ? "QR-Code" : (thisTeilnahme.Antwortbogen ? "Antwortbogen" : "Online");

                            // -- create a new row
                            const row = { ...body };
                            row.Datum = dateString;
                            row[participationKind] = 1;


                            // -- compare new row with existing rows
                            if (result.length === 0) {
                                // first element
                                result.push(row);
                            }
                            else {

                                // -- run through result
                                for (let resultIndex = 0; resultIndex < result.length; resultIndex++) {

                                    const resultElem = result[resultIndex];

                                    // sort by Datum
                                    if (row.Datum === resultElem.Datum && row.VNR === resultElem.VNR) {
                                        // row found -> increase this rows teilnahme
                                        result[resultIndex][participationKind]++;
                                        break;
                                    }
                                    else if (resultIndex === result.length - 1) {
                                        // row not found -> add new row and break loop
                                        result.push(row);
                                        break;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            // no kursteilnahmen in this month/year
            if (result.length === 0) alert('Keine Kursteilnahmen vorhanden');
            // export xlsx-file
            else exportXlsx(result, 'kurs-teilnahme-statistik');
            // show export-btn again
            button.style.display = 'block';
        }
    }


    if (initialized && coursesArray) {
        return (
            <div className='statistic-container basic-container'>

                <h1>Statistik</h1>

                <div>
                    {/* export kursteilnahmen as xlsx */}
                    <h3>Kursteilnahmen nach Sponsor, Art und Datum</h3>

                    {/* export by month/year */}
                    <form className='form-container' onSubmit={(e) => exportAllCourseStatistic(e, tokenObject, setTokenObject)}>
                        <h5>Export nach Monat/Jahr</h5>

                        {/* month */}
                        {printSelect((e) => setExportMonth(e.currentTarget.value), 'export-kurs-teiln-month', 'Monat', exportMonth, months)}

                        {/* year */}
                        {printInput((e) => setExportYear(parseInt(e.currentTarget.value)), 'export-kurs-teiln-year', 'Jahr', exportYear, true, undefined, 'number')}

                        {/* export-button */}
                        <input className='basic-btn left' type="submit" value="Export" />
                    </form><br />


                    {/* export all */}
                    <h5>Alles Exportieren</h5>
                    <form className='form-container' onSubmit={(e) => exportAllCourseStatistic(e, tokenObject, setTokenObject, true)}>
                        {/* export button */}
                        <input className='basic-btn left' type="submit" value="Alles Exportieren" />
                    </form>
                </div>
                <div className='seperator'></div>


                {/* export specific course statistics */}
                <h3>Kursteilnahmen nach Kurs</h3>
                <div className='form-container'>

                    {/* search form */}
                    <Search
                        input={coursesArray}
                        callback={(results) => setFilteredCourseArray(results)}
                        searchKeys={["AerztlicherLeiter", "Sponsor_Name", "KursKey", "VNR", "KursTitel", "KursUntertitel", "Referenten"]}
                        showAll={false}
                    />

                    {/* table of search-results */}
                    {filteredCourseArray.length > 0 ?
                        <div className='table-container'>

                            <table>
                                {/* headline */}
                                <thead>
                                    <tr>
                                        <th>KursKey</th>
                                        <th>Titel</th>
                                        <th>VNR</th>
                                        <th></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {/* teilnehmer-list */}
                                    {filteredCourseArray.map((course, index) =>
                                        <tr key={`stats-course-row-${index}`} className='pointer' onClick={() => { setSelectedCourse(course.KursKey); setFilteredCourseArray([]) }}>
                                            <td>{course.KursKey}</td>
                                            <td>{course.KursTitel}</td>
                                            <td>{course.VNR}</td>
                                            <td className="column-icon add"><AiOutlineArrowRight /></td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        :
                        <></>
                    }
                </div>
                {selectedCourse ?
                    <StatisticForm selectedCourse={selectedCourse} />
                    :
                    <></>
                }

                <div className="seperator"></div>
            </div>
        )
    }
    else {
        return <Loader />
    }
}

export default StatisticManager
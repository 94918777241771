import { useState } from "react"


export default function BurgerMenu({ burgerMenu, activeMenu, userRole, reloadMain }) {

    const isAdmin = userRole === "Admin";

    const [verlaufVisible, setVerlaufVisible] = useState(false);

    return (
        <div id="burger-menu" style={burgerMenu ? { display: 'block' } : { display: 'none' }}>

            {/* list of menus */}
            <ul>
                <div className='basic-small-btn' onClick={() => reloadMain(0)}><li active={activeMenu === 0 ? "true" : "false"}>Kurse</li></div>
                <div className='basic-small-btn' onClick={() => reloadMain(1)}><li active={activeMenu === 1 ? "true" : "false"}>Referenten</li></div>
                {isAdmin ? <div className='basic-small-btn' onClick={() => reloadMain(2)}><li active={activeMenu === 2 ? "true" : "false"}>Teilnehmer</li></div> : <></>}
                <div className='basic-small-btn' onClick={() => reloadMain(3)}><li active={activeMenu === 3 ? "true" : "false"}>Sponsoren</li></div>
                {isAdmin ? <div className='basic-small-btn' onClick={() => reloadMain(4)}><li active={activeMenu === 4 ? "true" : "false"}>Fachgebiete</li></div> : <></>}
                {isAdmin ? <div className='basic-small-btn' onClick={() => reloadMain(5)}><li active={activeMenu === 5 ? "true" : "false"}>Kursteilnahme</li></div> : <></>}
                <div className='basic-small-btn' onClick={() => reloadMain(6)}><li active={activeMenu === 6 ? "true" : "false"}>Statistik</li></div>
                <div className='basic-small-btn' onClick={() => reloadMain(7)}><li active={activeMenu === 7 ? "true" : "false"}>Links</li></div>
                {isAdmin ? <div className='basic-small-btn' onClick={() => reloadMain(8)}><li active={activeMenu === 8 ? "true" : "false"}>GCP-Kurse</li></div> : <></>}
                {isAdmin ? <div className='basic-small-btn' onClick={() => reloadMain(9)}><li active={activeMenu === 9 ? "true" : "false"}>Zertifikate</li></div> : <></>}
                {isAdmin ? <div className='basic-small-btn' onClick={() => reloadMain(10)}><li active={activeMenu === 10 ? "true" : "false"}>Podcast-Serien</li></div> : <></>}
                {isAdmin ? <div className='basic-small-btn' onClick={() => reloadMain(11)}><li active={activeMenu === 11 ? "true" : "false"}>CME Shuttle</li></div> : <></>}
                {isAdmin ? <div className='basic-small-btn' onClick={() => reloadMain(12)}><li active={activeMenu === 12 ? "true" : "false"}>A2-Nutzer</li></div> : <></>}
                <div className='basic-small-btn' onClick={() => reloadMain(13)}><li active={activeMenu === 13 ? "true" : "false"}>Werkzeuge</li></div>
            </ul>


            {/* status-history */}
            <div id='status-history-container'>
                <h4>Verlauf</h4>
                <div className="pointer" onClick={() => setVerlaufVisible(!verlaufVisible)}><u>{verlaufVisible ? "Verlauf verstecken" : "Zeige Verlauf"}</u></div>
                <div id='status-history' style={verlaufVisible ? {} : { height: 0, overflow: 'hidden', padding: 0 }}></div>
            </div>

        </div >
    )
}
import { noReservedChars } from '../functions/validation.js';


const AddNewForm = ({ id = 0, title = "Neu hinzufügen", onSubmit, inputName, specialValidation = true }) => {

    const validate = (e) => {
        e.preventDefault();

        const form = e.currentTarget;
        const input = form[`add-new-input-${id}`].value;
        const errorMessage = document.querySelector(`#add-new-error-message-${id}`);
        errorMessage.innerHTML = "";

        if (!input) {
            errorMessage.innerHTML = "Eingabe darf nicht leer sein!"
        }
        else if (!noReservedChars(input)) {
            errorMessage.innerHTML = "Keine Sonderzeichen erlaubt!";
        }
        else if (!specialValidation) {
            errorMessage.innerHTML = "Validierung fehlgeschlagen"
        }
        else {
            // valid
            onSubmit(input, errorMessage);
        }

    }

    return (
        <div className="form-container">

            <form className="add-new-form" onSubmit={validate}>

                <h5>{title}</h5>

                <div className='input-container large'>

                    <label htmlFor={`add-new-input-${id}`}>{inputName}</label>
                    <input id={`add-new-input-${id}`} name={`add-new-input-${id}`} type="text" />

                </div>

                <div id={`add-new-error-message-${id}`} style={{ color: "red", margin: "1.5rem 0" }}></div>

                <input className="basic-btn" type="submit" value='Erstellen' />
            </form>
        </div>
    )
}

export default AddNewForm
// --- validating all kinds of stuff ---

const podcastStructur = [
    "login",
    "AerztlicherLeiter",
    "Referenten",
    "KursTitel",
    "KursUntertitel",
    "KursTyp",
    "KursTypOptionen",
    "Indikationen",
    "KursBeschreibung",
    "KursTileImage",
    "Laufzeit_Start",
    "Laufzeit_Ende",
    "PodcastCover",
    "SPONSOR_ID",
    "urlKurs",
    "Podcast_Serie"
]
const webinarStructur = [
    "login",
    "AerztlicherLeiter",
    "Referenten",
    "KursTitel",
    "KursTyp",
    "KursTypOptionen",
    "WebinarInfo",
    "Fortbildungskategorie",
    "Fortbildungsart",
    "Indikationen",
    "Kosten",
    "KursBeschreibung",
    "KursTileImage",
    "KursBannerImage",
    "Laufzeit_Start",
    "Laufzeit_Ende",
    "SPONSOR_ID",
    "urlKurs"
]
const liveStructure = [
    "VNR",
    "EIV_Passwort",
    "Zertifikat_Vorlage",
    "Laufzeit_Start",
    "Laufzeit_Ende",
    "KursTitel",
    "Fortbildungsart",
    "CME_Punkte",
    "Fortbildungskategorie",
    "Fortbildungsart",
    "AerztlicherLeiter",
    "Referenten",
]

function validateKursInfo(kursInfo, errorMessage) {
    // -- error message
    if (!errorMessage) errorMessage = document.createElement('div');
    errorMessage.style.textAlign = "left";
    errorMessage.innerHTML = "";

    // -- init result
    var result = true;

    // -- validate entrys
    const validateEntries = kursInfo.Art === "Kurs" ? true : (kursInfo.Art === "Podcast" ? podcastStructur : (kursInfo.Art === "Webinar" ? webinarStructur : liveStructure));

    // -- run through kursInfo.json
    Object.entries(kursInfo).forEach((elem) => {

        // return if not needed to be validated
        if (validateEntries !== true && !validateEntries.includes(elem[0])) return;

        // special handling for specific json-entrys
        switch (elem[0]) {

            // -- standard course-inputs
            //#region 
            case "QRCodeURL":
                if (!validateUrl(elem[1]) && kursInfo.QRCodeGenerator) {
                    errorMessage.innerHTML += `<div>QRCodeURL ist fehlerhaft!</div>`
                    result = false;
                }
                break;

            case "Video":
                elem[1].forEach((thisObject) => {
                    if (!thisObject.src && (kursInfo.KursTyp === "Standard" || kursInfo.KursTyp === "BigVideo")) {
                        errorMessage.innerHTML += `<div>Video-Source nicht angegeben!</div>`
                        result = false;
                    }
                })
                break;

            case "AerztlicherLeiter":
                if (!validateReferenten(kursInfo, elem[1])) {
                    errorMessage.innerHTML += `<div>Ärztlicher Leiter nicht vollständig!</div>`
                    result = false;
                }
                break;

            case "Referenten":
                if (!validateReferenten(kursInfo, elem[1])) {
                    errorMessage.innerHTML += `<div>Referenten nicht vollständig!</div>`
                    result = false;
                }
                break;

            case "Info":
                if (kursInfo.Zertifiziert) {
                    if (!elem[1].Allgemein.Titel || !elem[1].Allgemein.Zielgruppe || !elem[1].Allgemein.Lernziele || !elem[1].Allgemein.Zertifizierung || !elem[1].Allgemein.Hinweis || !elem[1].Allgemein.Funktionsweise || !elem[1].Allgemein.NachsterSchritt || !elem[1].Haftungsausschluss.Titel || !elem[1].Haftungsausschluss.Text || !elem[1].TutorielleUnterstuetzung.Titel || !elem[1].TutorielleUnterstuetzung.Text || !elem[1].Impressum.Titel || !elem[1].Impressum.Herausgeber || !elem[1].Impressum.Vertrieb) {
                        errorMessage.innerHTML += `<div>Allgemeine Kursinformationen nicht vollständig ausgefüllt!</div>`
                        result = false;
                    }
                }
                break;

            case "Laufzeit_Start":
                if (isNaN(Date.parse(elem[1]))) {
                    errorMessage.innerHTML += `<div>Laufzeit-Start nicht ausgefüllt!</div>`;
                    result = false;
                }
                break;
            case "Laufzeit_Ende":
                if (isNaN(Date.parse(elem[1]))) {
                    errorMessage.innerHTML += `<div>Laufzeit-Ende nicht ausgefüllt!</div>`;
                    result = false;
                }
                break;

            case "slides":
                kursInfo.slides.forEach((currentPart, partIndex) => {
                    if (currentPart.length === 0) {
                        errorMessage.innerHTML += `<div>Es fehlen Charts!</div>`;
                        result = false;
                    }

                    var allTimes = [];

                    // single chart validation
                    currentPart.forEach((currentSlide, index) => {
                        if (!currentSlide.chartNr || !currentSlide.title || !currentSlide.time) {
                            errorMessage.innerHTML += `<div>Chartinformationen fehlen bei Teil ${partIndex + 1} Chart ${index + 1}!</div>`;
                            result = false;
                        }

                        if (!validateSlideTimeFormat(currentSlide.time)) {
                            errorMessage.innerHTML += `<div>Falsches Zeitformat bei Chart ${index + 1}!</div>`;
                            result = false;
                        }
                        else {
                            var timeArray = currentSlide.time.split(':');
                            var min = parseInt(timeArray[0]);
                            var sec = parseInt(timeArray[1]);

                            var slideTime = (min * 60) + sec;
                            allTimes.push(slideTime);

                            if (sec > 59) {
                                errorMessage.innerHTML += `<div>Chart-Sekunden größer 59 bei Chart ${index + 1}!</div>`;
                                result = false;
                            }
                        }

                    });

                    // all chart-times validation
                    if (allTimes[0] !== 0) {
                        errorMessage.innerHTML += `<div>Chart 1 beginnt nicht mit 0:00!</div>`;
                        result = false;
                    }
                    for (let i = 1; i < allTimes.length - 1; i++) {
                        const element = allTimes[i];
                        if (element > allTimes[i + 1]) {
                            errorMessage.innerHTML += `<div>Zeit von Chart ${i + 1} ist größer als nächster Chart ${i + 2}!</div>`;
                            result = false;
                        }
                    }

                })
                break;

            case "PDF":
                if (kursInfo.KursTyp === "PDF" && !kursInfo.PDF) {
                    // pdf-name is empty
                    errorMessage.innerHTML += `<div>${elem[0]} ist nicht ausgefüllt!</div>`;
                    result = false;
                }
                break;


            case "CorrectAnswerKey":
                if (!kursInfo.CorrectAnswerKey) {
                    // correctanswerkey empty
                    errorMessage.innerHTML += `<div>${elem[0]} ist nicht ausgefüllt!</div>`;
                    result = false;
                }
                else if (kursInfo.CorrectAnswerKey.length !== 10) {
                    // correctanswerkey-length is not 10
                    errorMessage.innerHTML += `<div>Der Antwortschlüssel muss 10-stellig sein!</div>`;
                    result = false;
                }
                break;
            //#endregion


            // -- shuttle entries
            //#region             
            case "WebinarInfo":
                if (kursInfo.Art === "Webinar") {
                    kursInfo.WebinarInfo.forEach((thisWebinar, index) => {
                        if (!validateDateFormat(thisWebinar.Datum) || !thisWebinar.Beginn || !thisWebinar.Ende) {
                            errorMessage.innerHTML += `<div style='color: orange'>Webinar-Infos ${index + 1} sind fehlerhaft!</div>`;
                            result = false;
                        }
                        if (!validateTimeFormat(thisWebinar.Beginn) || !validateTimeFormat(thisWebinar.Ende)) {
                            errorMessage.innerHTML += `<div style='color: orange'>Zeit-Format bei Webinar ${index + 1} falsch!</div>`;
                            result = false;
                        }
                    })
                }
                break;
            case "Indikationen":
                if (!kursInfo.GCP) {
                    kursInfo.Indikationen.forEach((thisIndication, index) => {
                        if (!thisIndication) {
                            errorMessage.innerHTML += `<div style='color: orange'>Indikation ${index + 1} nicht ausgefüllt!</div>`;
                            result = false;
                        }
                    })
                }
                break;
            case "login":
                if (!kursInfo.GCP && kursInfo.Zertifiziert) {
                    kursInfo.login.forEach((thisLogin, index) => {
                        if (!thisLogin) {
                            errorMessage.innerHTML += `<div style='color: orange'>Login ${index + 1} nicht ausgefüllt!</div>`;
                            result = false;
                        }
                    })
                }
                break;
            case "PodcastCover":
                if (kursInfo.Art === "Podcast" && !validateUrl(elem[1])) {
                    errorMessage.innerHTML += `<div style='color: orange'>URL für PodcastCover fehlerhaft!</div>`;
                    result = false;
                }
                break;
            case "Podcast_Serie":
                if (kursInfo.Art === "Podcast" && !elem[1]) {
                    errorMessage.innerHTML += `<div style='color: orange'>Keine Podcast_Serie ausgewählt</div>`;
                    result = false;
                }
                break;
            case "urlZip":
                if (kursInfo.Art === "Kurs" && !validateUrl(elem[1]) && !kursInfo.GCP && kursInfo.Zertifiziert) {
                    errorMessage.innerHTML += `<div style='color: orange'>URL für die Download-Zip-Datei fehlerhaft!</div>`;
                    result = false;
                }
                break;
            case "KursBannerImage":
                if (!validateUrl(elem[1]) && !kursInfo.GCP && kursInfo.Zertifiziert) {
                    errorMessage.innerHTML += "<div style='color: orange'>URL Banner fehlerhaft!</div>"
                    result = false;
                }
                break;
            case "KursTileImage":
                if (!validateUrl(elem[1]) && !kursInfo.GCP && kursInfo.Zertifiziert) {
                    errorMessage.innerHTML += "<div style='color: orange'>URL Kachel fehlerhaft!</div>"
                    result = false;
                }
                break;
            case "urlKurs":
                if (!validateUrl(elem[1]) && !kursInfo.GCP && kursInfo.Zertifiziert) {
                    errorMessage.innerHTML += "<div style='color: orange'>URL Kurs fehlerhaft!</div>"
                    result = false;
                }
                break;
            //#endregion


            // -- standard validation-handling 
            //#region 
            default:
                if (!elem[1]) {
                    if (
                        // These Elements are allowed to be empty
                        elem[0] === "KursUntertitel" ||
                        elem[0] === "Public" ||
                        elem[0] === "Valid" ||
                        elem[0] === "Qr_Pdf_Generator" ||
                        elem[0] === "KursbeschreibungWebsite" ||
                        elem[0] === "MetaBeschreibung" ||
                        elem[0] === "Keyphrase" ||
                        elem[0] === "Keywords" ||
                        elem[0] === "Notizen" ||
                        elem[0] === "AktiverNutzer" ||
                        elem[0] === "id" ||
                        elem[0] === "Sponsor_Kuerzel" ||
                        elem[0] === "Sponsor_Name" ||
                        elem[0] === "Sponsor_Logo" ||
                        elem[0] === "Sponsor_Info" ||
                        elem[0] === "GCP" ||
                        elem[0] === "Zertifiziert" ||
                        elem[0] === "WissensfragenSichtbar" ||
                        elem[0] === "QRCodeGenerator"
                    ) { /* do nothing */ }
                    else if (
                        (!kursInfo.Zertifiziert) &&
                        (
                            // These Elements are allowed to be empty IF course is not certified
                            elem[0] === "VNR" ||
                            elem[0] === "FragenTitel" ||
                            elem[0] === "FragenVorgehen" ||
                            elem[0] === "EFNHinweis" ||
                            elem[0] === "EFNHinweisLegende" ||
                            elem[0] === "AppOfflineHinweis"
                        )
                    ) { /* do nothing */ }
                    else {
                        // field is empty
                        errorMessage.innerHTML += `<div>${elem[0]} ist nicht ausgefüllt!</div>`;
                        result = false;
                    }
                }
                break;
            //#endregion
        }
    })
    // -- additional handling of video and chart-assets
    if (kursInfo.Video.length !== kursInfo.slides.length) {
        // different amount of video sources than courseparts
        errorMessage.innerHTML += `<div>Anzahl Videos stimmt nicht mit Anzahl der Kursteile überein.<br>Anzahl Videos: ${kursInfo.Video.length}<br>Anzahl Kursteile: ${kursInfo.slides.length}</div>`;
        result = false;
    }
    return result;
}

const validateDateFormat = date => {
    var date_regex = /^(0[1-9]|1\d|2\d|3[01])\.(0[1-9]|1[0-2])\.(19|20)\d{2}$/;
    return date_regex.test(date)
}
const validateTimeFormat = time => {
    return /^\d{2}\:\d{2}$/.test(time)
}
const validateSlideTimeFormat = time => {
    return /^\d{1,2}\:\d{2}$/.test(time);
}
const validateUrl = string => {
    if (string) {
        // only use link, no params
        var url = string.split('?')[0];
        var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return pattern.test(url);
    }
    else return false;
}
const validateReferenten = (kursInfo, refArray) => {
    for (let i = 0; i < refArray.length; i++) {
        const thisRef = refArray[i];

        // webinar/podcast
        if (kursInfo.Art !== "Kurs" && (!thisRef.Nachname || !thisRef.Vorname)) return false;

        // kurs
        else if (kursInfo.Art === "Kurs" && (!thisRef.Nachname || !thisRef.Vorname || !thisRef.Foto || !thisRef.Allgemein || !thisRef.Interessenskonflikte)) return false;

        // referent-thumbnail
        else if (kursInfo.Art !== "Live" && !validateUrl(thisRef.Foto_small) && !kursInfo.GCP) return false;
    }
    // all good
    return true;
}


// -- check for special chars
const noReservedChars = (input) => {
    const reservedChars = ['~', '#', '!', '@', '$', '%', '^', '&', '*', '=', '+', '[', '{', ']', '}', '\\', '|', ';', '/', '?', "'"]
    var valid = true;
    reservedChars.forEach(char => {
        if (input.includes(char)) valid = false;
    })
    return valid;
}

export { validateKursInfo, noReservedChars }

import { useState, useEffect, useContext } from 'react'
import { TokenContext } from '../Main'
import { FiTrash2 } from 'react-icons/fi'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { GoPrimitiveDot } from 'react-icons/go'

import { sendRequest } from '../../rest/requests'
import { formatDateToString } from '../../functions/helper'
import { getGcpFromDatabase } from '../../rest/requests_get_all'

import AddNewForm from '../AddNewForm'
import GcpForm from './GcpForm'
import Popup from '../Popup'
import Loader from './../Loader'


const GcpManager = ({ setHeaderTitle, allGcp, setAllGcp, allGcpModules, allTeilnehmer, zertifikatVorlagen }) => {

    // access-token
    const [tokenObject, setTokenObject] = useContext(TokenContext);

    const [initialized, setInitialized] = useState(false);
    const [selectedGcpKey, setSelectedGcpKey] = useState();

    useEffect(() => {
        initGcpManager();
    }, []);
    const initGcpManager = async () => {
        setInitialized(true);
    }


    // ADD GCP TO DATABASE
    const addThisGcp = async (gcpKey, errorMessage) => {

        const response = await sendRequest(tokenObject, setTokenObject, 'POST', '/gcp', `Erstelle GCP-Kurs "${gcpKey}"`, `GCP-Kurs "${gcpKey}" hinzugefügt`, { "GCPKey": gcpKey });

        // success
        if (response.success) {
            setSelectedGcpKey(gcpKey);
            // update locally
            setAllGcp(await getGcpFromDatabase(tokenObject, setTokenObject));
        }
        // error
        else errorMessage.innerHTML = "Datenbank-Fehler. Möglicherweise ist dieser GCP-Key bereits in Verwendung.";
    }

    // DELETE GCP FROM DATABASE
    const deleteThisGcp = async (e, gcpKey) => {
        e.stopPropagation();

        const confirm = window.confirm(`Sicher, dass du den GCP-Kurs "${gcpKey}" löschen möchtest?`);

        if (confirm) {
            // delete in db
            const response = await sendRequest(tokenObject, setTokenObject, 'DELETE', `/gcp/${gcpKey}`, `Lösche GCP-Kurs "${gcpKey}"`, `GCP-Kurs "${gcpKey}" gelöscht`);
            if (response.success) {
                // update locally
                setAllGcp(await getGcpFromDatabase(tokenObject, setTokenObject));
            }
        }
    }


    if (initialized && allGcp && !selectedGcpKey)
        return (
            <div className='basic-container'>

                <span className='headline-add-btn'>
                    {/* headline */}
                    <h1>GCP-Kurse</h1>

                    {/* add-new popup */}
                    <Popup
                        content={
                            // ADD NEW GCP
                            <AddNewForm onSubmit={addThisGcp} inputName={"GCP-Key: "} />
                        }
                        button={<AiOutlinePlusCircle />}
                    />
                </span>

                <div className='table-container'>
                    <table>

                        {/* headline */}
                        <thead>
                            <tr>
                                <th className='column-icon'></th>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Typ</th>
                                <th>Laufzeit</th>
                                <th>Zertifikat</th>
                                <th className='column-icon'></th>
                            </tr>
                        </thead>

                        <tbody>
                            {allGcp.map((gcp, index) =>
                                <tr key={`gcp-row-${index}`} className='pointer' onClick={() => setSelectedGcpKey(gcp.GCPKey)}>
                                    <td className='column-icon' style={{ color: (gcp.Status ? "green" : "crimson") }}><GoPrimitiveDot /></td>
                                    <td>{gcp.GCP_ID}</td>
                                    <td>{gcp.GCPKey}</td>
                                    <td>{gcp.Typ}</td>
                                    <td>{formatDateToString(gcp.Laufzeit_Start)} - {formatDateToString(gcp.Laufzeit_Ende)}</td>
                                    <td>{gcp.Zertifikat_Vorlage}</td>
                                    <td className='column-icon underline delete' onClick={(e) => deleteThisGcp(e, gcp.GCPKey)}><FiTrash2 /></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className='seperator'></div>

            </div>
        )
    else if (selectedGcpKey)
        return (
            <GcpForm
                selectedGcpKey={selectedGcpKey}

                allGcpModules={allGcpModules}
                setAllGcp={setAllGcp}
                allTeilnehmer={allTeilnehmer}

                zertifikatVorlagen={zertifikatVorlagen}
                setHeaderTitle={setHeaderTitle}
            />
        )
    else
        return (
            <Loader />
        )
}

export default GcpManager
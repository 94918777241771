import TextEditor from './TextEditor';
import InfoPopup from './InfoPopup';


// -- print input
/**
 * 
 * @param {Function} onChangeFunc callback-function when component changes
 * @param {string} id unique id for identifying
 * @param {string} name name for label
 * @param {Object} value entry in thisCourse
 * @param {boolean} dependency under which circumstances is this input displayed -> default = true  
 * @param {string} popupText info-popup text -> default = undefined
 * @param {string} type input-type -> default = text
 * @param {string} className classes for input
 * @param {boolean} large determins if input spans accross whole width
 * @returns basic <input>
 */
const printInput = (onChangeFunc, id, name, value, dependency = true, popupText = undefined, type = "text", className = '', isReadOnly = false, placeholder = undefined, large = false) => {
    if (!value && value !== 0) value = "";
    return (
        <div className={`input-container ${large ? 'large' : ''}`} style={!dependency ? { display: "none" } : {}}>
            {popupText ? <InfoPopup text={popupText} /> : <></>}

            <label htmlFor={id}>{name}</label>
            <input
                id={id}
                name={id}
                type={type}
                value={value}
                className={`${className} ${isReadOnly ? 'read-only' : ''}`}
                placeholder={placeholder}

                onChange={isReadOnly ? () => { return false } : onChangeFunc}
                readOnly={isReadOnly}

                onWheel={(e) => e.target.blur()} // prevent value-change by scrolling
            />
        </div>
    )
}

// -- print input with a photo preview
/**
 * 
 * @param {Boolean} previewIsImage decide if preview is a follow-up-link or a small thumbnail-image
 * @param {Function} onChangeFunc callback-function when component changes
 * @param {string} id unique id for identifying
 * @param {string} name name for label
 * @param {Object} value entry in thisCourse
 * @param {boolean} dependency under which circumstances is this input displayed -> default = true  
 * @param {string} popupText info-popup text -> default = undefined
 * @param {string} type input-type -> default = text
 * @param {string} className classes for input
 * @param {boolean} large determins if input spans accross whole width
 * @returns basic <input> with a follow-link or a small thumnail image on the right-hand-side of the input-field
 */
const printInputWithPreview = (previewIsImage, onChangeFunc, id, name, value, dependency = true, popupText = undefined, type = "text", className = '', isReadOnly = false, placeholder = undefined, large = false) => {

    var Preview;
    if (previewIsImage) {
        Preview = () => <img className='preview-value' src={value} alt="Vorschaubild" />
    }
    else {
        Preview = () => <a className='preview-value' href={value} target='_blank'>Vorschau</a>
    }

    return (
        <div className='preview-container' style={!dependency ? { display: "none" } : {}}>
            {printInput(onChangeFunc, id, name, value, dependency, popupText, type, className, isReadOnly, placeholder, large)}
            <Preview />
        </div>
    )
}

// -- print input textarea
/**
 * 
 * @param {Function} onChangeFunc callback-function when component changes
 * @param {string} id unique id for identifying
 * @param {string} name name for label
 * @param {Object} value entry in thisCourse
 * @param {boolean} dependency under which circumstances is this input displayed -> default = true  
 * @param {string} popupText info-popup text -> default = undefined
 * @param {string} className classes for input
 * @param {int} maxLetters maximum of letters in textarea -> default = null
 * @param {int} maxWords maximum of words in textarea -> default = null
 * @param {boolean} large determins if input spans accross whole width
 * @returns basic <textarea>
 */
const printTextArea = (onChangeFunc, id, name, value, dependency = true, popupText = undefined, className = '', maxLetters = null, maxWords = null, isReadOnly = false, large = true) => {
    if (!value) value = "";

    // set max-letters
    var letters = null;
    if (maxLetters) letters = value.length;

    // set maxWords
    var words = null;
    if (maxWords) {
        // both is not possible
        letters = null;
        maxLetters = null;
        // determin max words
        words = value.split(' ').length;
    }

    return (
        <div className={`input-container ${large ? 'large' : ''}`} style={!dependency ? { display: "none" } : {}}>
            {popupText ? <InfoPopup text={popupText} /> : <></>}

            <label htmlFor={id}>{name}</label>

            {/* display letters/words */}
            <PrintWordsOrLetters words={words} maxWords={maxWords} letters={letters} maxLetters={maxLetters} />

            <textarea
                id={id}
                name={id}
                type="text"
                value={value.replaceAll('<br>', '\n')}
                className={`${className} ${isReadOnly ? 'read-only' : ''}`}

                onChange={isReadOnly ? () => { return false } : onChangeFunc}
                readOnly={isReadOnly}
            />
        </div>
    )
}

// -- print select-input
/**
 * 
 * @param {Function} onChangeFunc callback-function when component changes
 * @param {string} id unique id for identifying
 * @param {string} name name for label
 * @param {Object} value entry in thisCourse
 * @param {Array} selectArray Array of string which are used for dropdown => Syntax eiter ["a","b",...] or [{value: "1", name: "a"}, {value: "2", name: "b"}]
 * @param {boolean} dependency under which circumstances is this input displayed -> default = true  
 * @param {string} popupText info-popup text -> default = undefined
 * @param {string} className classes for input
 * @param {boolean} large determins if input spans accross whole width
 * @param {string} defaultOption string displayed when value = ""
 * @returns <select> dropdown menu
 */
const printSelect = (onChangeFunc, id, name, value, selectArray, dependency = true, popupText = undefined, className = '', large = false, defaultOption = "Bitte wählen") => {
    if (!value) value = "";
    return (
        <div className={`input-container ${large ? 'large' : ''}`} style={!dependency ? { display: "none" } : {}}>
            {popupText ? <InfoPopup text={popupText} /> : <></>}

            <label htmlFor={id}>{name}</label>
            <select
                id={id}
                name={id}
                className={`form-dropdown-input ${className}`}
                value={value}
                onChange={onChangeFunc}
            >
                <option value="" disabled hidden>{defaultOption}</option>
                {selectArray.map((elem, i) => {
                    return (
                        <option key={`${id}-key-${i}`} value={elem.value ? elem.value : elem}>{elem.name ? elem.name : elem}</option>
                    )
                })}
            </select>
            <br />
        </div>
    )
}

// -- print input-textarea with Texteditor
/**
 * 
 * @param {string} id unique id for identifying
 * @param {string} name name for label
 * @param {Object} value entry in thisCourse
 * @param {boolean} dependency under which circumstances is this input displayed -> default = true  
 * @param {string} popupText info-popup text -> default = undefined
 * @param {string} className classes for input
 * @param {int} maxLetters maximum of letters in textarea -> default = null
 * @param {int} maxWords maximum of words in textarea -> default = null
 * @param {boolean} large determins if input spans accross whole width
 * @returns <textarea> with <TextEditor />
 */
const printTextEditor = (onChangeFunc, id, name, value, dependency = true, popupText = undefined, className = '', maxLetters = null, maxWords = null, isReadOnly = false, large = true) => {
    if (!value) value = "";

    // set max-letters
    var letters = null;
    if (maxLetters) letters = value.length;

    // set maxWords
    var words = null;
    if (maxWords) {
        // both is not possible
        letters = null;
        maxLetters = null;
        // determin max words
        words = value.split(' ').length;
    }

    return (
        <div className={`input-container ${large ? 'large' : ''}`} style={!dependency ? { display: "none" } : {}}>
            {popupText ? <InfoPopup text={popupText} /> : <></>}

            <label htmlFor={id}>{name}</label>
            <div className="textarea-editor">

                {/* display letters/words */}
                <PrintWordsOrLetters words={words} maxWords={maxWords} letters={letters} maxLetters={maxLetters} />

                <textarea
                    id={id}
                    name={id}
                    value={value.replaceAll('<br>', '\n')}
                    className={`${className} ${isReadOnly ? 'read-only' : ''}`}
                    type="text"

                    onChange={isReadOnly ? () => { return false } : onChangeFunc}
                    readOnly={isReadOnly}
                />

                <TextEditor />
            </div>
        </div>
    )
}

// -- print checkbox
/**
 * 
 * @param {Function} onChangeFunc callback-function when component changes -> can be undefined if defaultChecked = true
 * @param {string} id unique id for identifying
 * @param {string} name name for label
 * @param {Object} value entry in thisCourse
 * @param {Boolean} defaultChecked uncontrolled input
 * @param {boolean} dependency under which circumstances is this input displayed -> default = true  
 * @param {string} popupText info-popup text -> default = undefined
 * @param {string} className classes for input
 * @returns basic <input>
 */
const printCheckbox = (onChangeFunc, id, name, value, defaultChecked = false, dependency = true, popupText = undefined, className = '', large = false) => {
    var Input;

    // differenciate between defaultChecked inputs and checked inputs
    if (defaultChecked) {
        Input = () =>
            <input
                id={id}
                name={id}
                type="checkbox"
                defaultChecked={value}
                className={className}

                onChange={onChangeFunc}
            />
    }
    else {
        Input = () =>
            <input
                id={id}
                name={id}
                type="checkbox"
                value={value}
                checked={value}
                className={className}

                onChange={onChangeFunc}
            />
    }

    return (
        <div className={`input-container input-checkbox-container ${large ? 'large' : ''}`} style={!dependency ? { display: "none" } : {}}>
            {popupText ? <InfoPopup text={popupText} /> : <></>}

            <label htmlFor={id}>{name}</label>
            <Input />
        </div>
    )
}

// -- print file-upload input
/**
 * 
 * @param {Function} onChangeFunc callback-function when component changes
 * @param {string} id unique id for identifying
 * @param {string} name name for label
 * @param {boolean} dependency under which circumstances is this input displayed -> default = true  
 * @param {string} popupText info-popup text -> default = undefined
 * @param {string} className classes for input
 * @returns basic <input>
 */
const printFileInput = (onChangeFunc, id, name, dependency = true, popupText = undefined, className = '') => {
    return (
        <div className="input-container" style={!dependency ? { display: "none" } : {}}>
            {popupText ? <InfoPopup text={popupText} /> : <></>}

            <label htmlFor={id}>{name}</label>

            <input
                type="file"
                name={id}
                id={id}
                className={className}

                onChange={onChangeFunc}
                onChangeCapture={(e) => e.currentTarget.setAttribute('file-dropped', '')}

                // on file drag-over
                onDragEnter={(e) => e.currentTarget.setAttribute('file-over', '')}
                onDragLeave={(e) => e.currentTarget.removeAttribute('file-over')}
            />
        </div>
    )
}



// -- helper-functions
// display words out of maxwords or letters/maxletters
const PrintWordsOrLetters = ({ words, maxWords, letters, maxLetters }) => {
    if (letters !== null && maxLetters !== null) return <div className="max-letters">{letters}/{maxLetters}</div>
    else if (words !== null && maxWords !== null) return <div className="max-letters">{words}/{maxWords} Wörter</div>
    else return null
}
// onchange for max-words and max-letters
const validateLength = (fieldValue, maxLetters, maxWords) => {
    var valid = true;

    if (maxLetters !== null && maxLetters <= fieldValue.length) valid = false;
    if (maxWords !== null && maxWords <= fieldValue.split(' ').length) valid = false;

    return valid;
}

export { printInput, printInputWithPreview, printTextArea, printSelect, printTextEditor, printCheckbox, printFileInput }
import { useContext, useState, useEffect } from 'react';
import { TokenContext } from '../Main.js';

import { addToState } from '../../functions/localStateUpdates.js';
import { noReservedChars } from '../../functions/validation.js';
import { sendRequest } from '../../rest/requests.js';
import { getTeilnehmerFromDatabase } from '../../rest/requests_get_all.js';
import { exportXlsx } from '../../functions/fileHandler.js';

import Search from '../Search.js';

const TeilnehmerSearchAndCreate = ({ allTeilnehmer, setAllTeilnehmer, filteredTeilnehmer, setFilteredTeilnehmer, createFallback = null, andOrConditions = false }) => {

    // access-token
    const [tokenObject, setTokenObject] = useContext(TokenContext);

    // id for <Search /> -> needs to be unique
    const searchComponentIndex = 'teilnehmer-search-create';

    // interessens- and fachgebiete
    const [interessensgebiete, setInteressensgebiete] = useState();
    const [fachgebiete, setFachgebiete] = useState()

    // -- init
    useEffect(() => { init() }, []);
    const init = async () => {

        if (andOrConditions) {
            // GET FACHGEBIETE FROM DATABASE
            const fachgebiete_response = await sendRequest(tokenObject, setTokenObject, 'GET', '/fachgebiete', 'Lade Interessensgebiete', 'Interessensgebiete geladen');

            // GET INTERESSENSGEBIETE FROM DATABASE
            const interessensgebiete_response = await sendRequest(tokenObject, setTokenObject, 'GET', '/interessensgebiete', 'Lade Interessensgebiete', 'Interessensgebiete geladen');

            // set states
            if (fachgebiete_response.success && interessensgebiete_response.success) {
                setFachgebiete(fachgebiete_response.data);
                setInteressensgebiete(interessensgebiete_response.data);
            }
        }
    }


    // -- add teilnehmer to database
    const addNewTeilnehmer = async () => {

        // -- get form-information
        const form = document.querySelector(`#search-component-${searchComponentIndex}`).querySelector('.search-form');
        const efn = form["search-input"].value;

        // -- confirmation window
        if (window.confirm(`Teilnehmer mit EFN: "${efn}" erstellen?`)) {

            // -- empty error-message
            const errorMessage = document.querySelector('#new-teilnehmer-error-message');
            errorMessage.innerHTML = "";

            // -- validate
            if (!efn) {
                errorMessage.innerHTML = "EFN darf nicht leer sein!"
            }
            else if (!noReservedChars(efn) || efn.includes(' ')) {
                errorMessage.innerHTML = "Keine Sonderzeichen/Leerstellen erlaubt!";
            }
            else {
                // valid 

                // ADD TEILNEHMER TO DATABASE
                const addResponse = await sendRequest(tokenObject, setTokenObject, 'POST', '/teilnehmer/erstellen', `Erstelle "${efn}"`, `Teilnehmer mit EFN: "${efn}" erfolgreich erstellt`, { "EFN": efn });
                if (addResponse.success) {

                    // GET TEILNEHMER FROM DATABASE
                    const getResponse = await sendRequest(tokenObject, setTokenObject, 'GET', `/teilnehmer/${efn}`, `Lade Teilnehmer mit EFN: "${efn}"`, `Teilnehmer mit EFN: "${efn}" geladen`);

                    if (getResponse.success) {
                        const thisTeilnehmer = getResponse.data;

                        // GET ALL TEILNEHMERFROM DATABASE - update allTeilnehmer-state
                        setAllTeilnehmer(await getTeilnehmerFromDatabase(tokenObject, setTokenObject));

                        // display new teilnehmer in search results
                        addToState(filteredTeilnehmer, setFilteredTeilnehmer, thisTeilnehmer[0]);

                        // pass this teilnehmer and execute fallback 
                        if (createFallback) createFallback(thisTeilnehmer[0]);
                    }
                    else {
                        // -- error
                        errorMessage.innerHTML = "Datenbank-Fehler.";
                    }
                }
                else {
                    // -- error
                    errorMessage.innerHTML = "Datenbank-Fehler. Möglicherweiße ist diese EFN schon vergeben.";
                }
            }
        }
    }




    return (
        <>
            {/* search-component */}
            <Search
                id={searchComponentIndex}
                input={allTeilnehmer}
                searchKeys={["EFN", "Vorname", "Nachname", "Mail", "Fachgebiet", "Interessensgebiete"]}

                callback={setFilteredTeilnehmer}
                showAll={false}
                focus={false}

                conditionKeys={fachgebiete && interessensgebiete ?
                    [
                        {
                            name: "Arbeitsstätte", data: [
                                { name: "Praxis", key: "Ambulant", value: 1 },
                                { name: "Klinik", key: "Stationaer", value: 1 },
                                { name: "Andere", key: "Andere", value: 1 },
                            ]
                        },
                        { name: "Fachgebiete", data: fachgebiete.map(elem => ({ key: "Fachgebiet", name: elem.Name, value: elem.Name })) },
                        { name: "Interessensgebiete", data: interessensgebiete.map(elem => ({ key: "Interessensgebiete", name: elem.Name, value: elem.Name })) }
                    ]
                    :
                    null
                }
            />
            <div className='form-container search-create-teilnehmer-container'>


                {/* error-message */}
                <div id='new-teilnehmer-error-message' style={{ color: "red", margin: "1.5rem 0" }}></div>


                <div className='search-status-btn-container'>
                    {/* export teilnehmer */}
                    <div className='basic-small-btn' onClick={() => exportXlsx(filteredTeilnehmer.map(elem => ({ ...elem, Interessensgebiete: elem.Interessensgebiete.join(', ') })), "teilnehmer-liste", true)}>
                        Suchergebnisse exportieren
                    </div>
                    {/* add teilnehmer */}
                    <div className='basic-small-btn' onClick={addNewTeilnehmer}>
                        Teilnehmer mit dieser EFN erstellen
                    </div>
                </div>

            </div>
        </>
    )
}

export default TeilnehmerSearchAndCreate
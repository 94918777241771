import { useState, useEffect, useContext } from 'react';
import { TokenContext } from '../Main';
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { HiOutlineExternalLink } from 'react-icons/hi'

// base templates
import templateStandard from '../../assets/course-templates/template-standard.json'

import { noReservedChars } from '../../functions/validation.js'
import { uploadNewCourseFile } from '../../functions/fileHandler.js'
import { sendRequest } from '../../rest/requests';
import { getCoursesFromDatabase } from '../../rest/requests_get_all';
import { addCourseToLocalstorage, sortByDate } from '../../functions/helper';
import { overwriteCourseWithTemplates } from '../../functions/course-template-handler.js';

import { printCheckbox, printFileInput, printSelect } from '../InputComponents';

import KursTable from './KurseTable';

import Loader from '../Loader';
import KursForm from './KursForm';
import Popup from '../Popup';


export const KurseManager = ({ setHeaderTitle, userLastname, allCourses, setAllCourses, allReferenten, allPodcastSerien, zertifikatVorlagen, userRole }) => {

    // access-token
    const [tokenObject, setTokenObject] = useContext(TokenContext);

    // -- fill object with all course types
    var allCourseTypes = [];
    for (let i = 0; i < allCourses.length; i++) {
        const element = allCourses[i];
        allCourseTypes.push(...element.data);
    }
    allCourseTypes = sortByDate(allCourseTypes);

    // -- specific course-info
    const [selectedCourse, setSelectedCourse] = useState(null);

    // -- all course-types
    const [activeTable, setActiveTable] = useState("Alle");

    // -- states for new course
    // how to initialize new course (blank,course,file)
    const [kindOfTemplate, setKindOfTemplate] = useState("Vorlage");
    // if template is a file or course-copy -> this state will be used
    const [newFile, setNewFile] = useState();
    // state only for dropdown-menu
    const [copyThisCourseKey, setCopyThisCourseKey] = useState();


    // -- init
    useEffect(() => {
        // unmount
        return () => {
            setSelectedCourse(null)
            setNewFile()
        }
    }, []);


    // -- clear if template-type changes
    useEffect(() => {
        setNewFile();
        setCopyThisCourseKey();
    }, [kindOfTemplate])


    // -- add course to database
    const addCourseToDatabase = async (e) => {

        e.preventDefault();

        // -- init error-message
        var errorMessage = document.querySelector('#select-new-json-file-message');
        errorMessage.innerHTML = "";
        errorMessage.style.color = "red";

        try {

            // -- new KursKey
            const newCourseKey = e.currentTarget["new-course-text"].value;

            // -- validation
            // validate course-key
            if (!noReservedChars(newCourseKey)) {
                errorMessage.innerHTML = "KursKey enhält unerlaubtes Sonderzeichen.";
                return;
            }
            // validation empty kurs key
            else if (newCourseKey === "") {
                errorMessage.innerHTML = "Kursname darf nicht leer sein.";
                return;
            }
            else {
                // validation double entrys
                for (let i = 0; i < allCourseTypes.length; i++) {
                    const course = allCourseTypes[i];

                    // check if course exists already
                    if (course.KursKey === newCourseKey) {
                        errorMessage.innerHTML = "Kurs existiert bereits.";
                        return;
                    };
                }
            }


            // new course-info - use template json-file
            let newCourse = { ...templateStandard };


            // use a file or a existing course as template
            if (kindOfTemplate === "Datei" || kindOfTemplate === "Kurs") {
                if (newFile) {
                    // use selected file
                    newCourse = { ...newCourse, ...newFile };
                }
                else {
                    errorMessage.innerHTML = "Keine Datei ausgewählt";
                    return false;
                }
            }
            // use template files in course-templates
            else if (kindOfTemplate === "Vorlage") {
                // which template to use
                const newCourseTemplate = e.currentTarget["new-course-template"].value;
                // use english templates
                const newCourseIsEnglish = e.currentTarget["new-course-english"].checked;
                // overwrite entries from templates
                newCourse = overwriteCourseWithTemplates(newCourse, newCourseTemplate, newCourseIsEnglish);
            }
            else {
                errorMessage.innerHTML = "Unbekannter Fehler";
                console.log('Fehler bei Template Auswahl');
                return;
            }


            // reset some values
            newCourse.KursKey = newCourseKey;
            newCourse.Public = 0;
            newCourse.Valid = 0;
            delete newCourse.VNR;
            delete newCourse.ID;
            delete newCourse.EIV_Passwort;
            delete newCourse.Laufzeit_Start;
            delete newCourse.Laufzeit_Ende;


            // ADD COURSE TO DATABASE
            const response = await sendRequest(tokenObject, setTokenObject, 'POST', '/kurse', `Erstelle Kurs "${newCourse.KursKey}"`, `Kurs "${newCourse.KursKey}" hinzugefügt`, newCourse);

            if (response.success) {
                // GET ALL COURSES FROM DATABASE - set allCourses-State
                setAllCourses(await getCoursesFromDatabase(tokenObject, setTokenObject));

                // init course-form
                setSelectedCourse(newCourse);
            }

        }
        catch (e) {
            errorMessage.innerHTML = "Unbekannter Fehler";
            console.log(e);
        }
    }

    // -- remove course from database
    const removeCourseFromDatabase = async (course) => {
        const courseKey = course.KursKey;

        let input = prompt(`Sicher, dass du den Kurs "${courseKey}" löschen möchtest?\n\nGib "${courseKey}" in das Feld ein, um sicherzustellen, dass du den richtigen Kurs löscht.`);

        if (input) {
            if (input === courseKey) {
                // -- delete course

                // DELETE THIS COURSE FROM DATABASE
                const response = await sendRequest(tokenObject, setTokenObject, 'DELETE', `/kurse/${courseKey}`, `Lösche Kurs "${courseKey}"`, `Kurs "${courseKey}" gelöscht`);

                if (response.success) {
                    // GET ALL COURSES FROM DATABASE - set allCourses-State
                    setAllCourses(await getCoursesFromDatabase(tokenObject, setTokenObject));

                    // refresh-timeout
                    const currentTable = activeTable;
                    setActiveTable();
                    setTimeout(() => setActiveTable(currentTable), 500);
                }

            }
            else {
                // do nothing
                alert("Falsche Eingabe!");
            }
        }

        // -- save a backup in localstorage
        addCourseToLocalstorage(course);
    }



    // -- main component
    if (selectedCourse === null) {
        return (
            <div className='basic-container'>

                <span className='headline-add-btn'>

                    {/* headline */}
                    <h1>Kurs-Verwaltung</h1>

                    {/* add-new popup */}
                    <Popup
                        content={
                            // ADD NEW COURSE
                            <div className='form-container'>
                                <form className="new-item-form" onSubmit={addCourseToDatabase}>
                                    <h5>Neuen Kurs erstellen</h5>

                                    {/* KursKey input */}
                                    <div className='input-container'>
                                        <label htmlFor="new-course-text">KursKey: </label>
                                        <input id="new-course-text" name="new-course-text" placeholder='CME Mustermann-M BK 2022' type="text" />
                                    </div>


                                    {/* choose with which course-info to start with */}
                                    {kindOfTemplate === "Vorlage" ?
                                        <>
                                            {/* use blank template */}
                                            <div className="input-container">
                                                <label htmlFor="new-course-template">Vorlage wählen: </label>
                                                <select id="new-course-template" className="form-dropdown-input" name="new-course-template" type="text" >
                                                    {allCourses.map((entry, i) => {
                                                        return (
                                                            entry.types.map((elem, j) => {
                                                                if (elem) return <option key={`course-type-selection-${i}-${j}`} value={elem}>{elem}</option>
                                                            })
                                                        )
                                                    })}
                                                </select><br />
                                            </div>

                                            {/* checkbox if course is in english */}
                                            {printCheckbox(undefined, 'new-course-english', 'Kurs in Englisch')}
                                        </>
                                        :

                                        kindOfTemplate === "Datei" ?
                                            // -- use a uploaded file
                                            printFileInput((e) => uploadNewCourseFile(e, setNewFile), 'select-new-json-file', 'Vorhandene Json laden')
                                            :

                                            kindOfTemplate === "Kurs" ?
                                                // -- use existing course as template
                                                printSelect(
                                                    (e) => {
                                                        const kursKey = e.currentTarget.value;
                                                        const course = allCourseTypes.filter(elem => elem.KursKey === kursKey)[0];
                                                        setCopyThisCourseKey(kursKey);
                                                        setNewFile(course);
                                                    },
                                                    'select-course-copy-template',
                                                    "Kurs als Kopie verwenden", copyThisCourseKey,
                                                    allCourseTypes.sort((a, b) => a.KursKey.localeCompare(b.KursKey)).map(course => course.KursKey)
                                                )

                                                : <></>
                                    }


                                    {/* additional information */}
                                    <div>
                                        <a style={{ display: "flex" }} className="gray-info" href="https://academy2.diskstation.me/cme/anleitung_cmed_6.pdf" target="_blank"> <HiOutlineExternalLink />Anleitung cMed 6 </a>
                                    </div>
                                    <div className='seperator'></div>



                                    {/* choose template */}
                                    <div>
                                        <h5>Vorlagen wählen</h5>
                                        <p>
                                            <span className='gray-info'>Vorlage:</span> Leere Vorlage<br />
                                            <span className='gray-info'>Datei:</span> Upload JSON-Datei<br />
                                            <span className='gray-info'>Kurs:</span> Bereits vorhanden Kurs kopieren
                                        </p>
                                        {printSelect((e) => setKindOfTemplate(e.currentTarget.value), 'select-course-template', "Vorlage wählen", kindOfTemplate, ["Vorlage", "Datei", "Kurs"])}
                                    </div>

                                    <br />
                                    <div id="select-new-json-file-message"></div>

                                    <input className="basic-btn" type="submit" value='Erstellen' />
                                </form>
                            </div>
                        }
                        button={<AiOutlinePlusCircle />}
                    />
                </span>



                {/* SELECT COURSE */}
                <div id='course-list'>

                    {/* select which table to be displayed */}
                    <div>
                        <div className='checkbox-inline-container'>
                            <input type="radio" id="list-selection-alle" name="list-kind-selection" value="Alle" checked={activeTable === "Alle"} onChange={(e) => setActiveTable(e.currentTarget.value)} className="square-checkbox" />
                            <label htmlFor="list-selection-alle">Alle</label>
                        </div>
                        {allCourses.map((entry, index) => (
                            <div key={`course-type-selection-${index}`} className='checkbox-inline-container'>
                                <input type="radio" id={`list-selection-${entry.id}`} name="list-kind-selection" value={entry.name} checked={activeTable === entry.name} onChange={(e) => setActiveTable(e.currentTarget.value)} className="square-checkbox" />
                                <label htmlFor={`list-selection-${entry.id}`}>{entry.name}</label>
                            </div>
                        ))}
                    </div>
                    <div className='seperator'></div>


                    {/* course-table */}
                    {activeTable === "Alle" ?
                        <KursTable
                            title={"Alle"} courses={allCourseTypes}
                            setSelectedCourse={setSelectedCourse}
                            showIcons={true}
                            removeCourseFromDatabase={removeCourseFromDatabase}
                        />
                        :
                        allCourses.map((entry, index) => {
                            if (activeTable === entry.name)
                                return (
                                    <div key={`kursmanager-table-key-${index}`}>
                                        <KursTable
                                            title={entry.name} courses={entry.data}
                                            setSelectedCourse={setSelectedCourse}
                                            showIcons={true}
                                            removeCourseFromDatabase={removeCourseFromDatabase}
                                        />
                                    </div>
                                )
                        })
                    }

                </div >

                <div className="seperator"></div>

            </div>
        )
    }
    else if (selectedCourse) {
        return (
            <KursForm
                userRole={userRole}
                setHeaderTitle={setHeaderTitle}
                userLastname={userLastname}
                courseKey={selectedCourse.KursKey}
                allReferenten={allReferenten}
                allPodcastSerien={allPodcastSerien}

                zertifikatVorlagen={zertifikatVorlagen}
                goBack={() => { setSelectedCourse(null) }}
            />
        )
    }
    else {
        return <Loader />
    }
}

export default KurseManager
import { useState, useEffect, useContext } from 'react'
import { TokenContext } from '../Main.js'

import { FiTrash2 } from 'react-icons/fi'
import { AiOutlineFilePdf, AiOutlineDown, AiOutlineDownload } from 'react-icons/ai'
import { BiCheckboxMinus, BiCheckbox, BiMailSend } from "react-icons/bi"
import { SiWorldhealthorganization } from 'react-icons/si'

import { backendUrl } from '../../rest/config.js'
import { formatDateToString, checkedRelationsChange, sendCertificateToCheckedRelations, downloadCertificateFromCheckedRelations, uncheckAll, checkAll, toggleTable } from '../../functions/helper.js';
import { sendRequest } from '../../rest/requests.js';
import { printInput } from '../InputComponents.js'
import { sendToEiv } from '../../rest/eiv.js'
import { defaultHeaderTitle } from '../../functions/global_variables.js'

import Loader from './../Loader'
import RelationForm from './RelationForm';
import TeilnehmerSearchAndCreate from './../Teilnehmer/TeilnehmerSearchAndCreate'
import BackButton from '../BackButton.js'
import TeilnehmerCopyButton from '../Teilnehmer/TeilnehmerCopyButton.js'


const TeilnehmerList = ({ setHeaderTitle, selectedCourse, restartApp, allTeilnehmer, setAllTeilnehmer, goBack }) => {

    // access-token
    const [tokenObject, setTokenObject] = useContext(TokenContext);

    const [initialized, setInitialized] = useState(false);


    // -- kurs-teilnehmer-relations
    const [kursTeilnehmerRelations, setKursTeilnehmerRelations] = useState();

    // -- filtered teilnehmer
    const [filteredTeilnehmer, setFilteredTeilnehmer] = useState([]);

    // -- jump to RelationForm if relation is selected
    const [selectedRelationId, setSelectedRelationId] = useState();

    // -- array with all efn-numbers which participated
    const [efnArray, setEfnArray] = useState([]);

    // -- array containing checked teilnehmer
    const [checkedRelations, setCheckedRelations] = useState([]);


    // -- init
    useEffect(() => {
        // init set header-title
        setHeaderTitle(selectedCourse.KursKey);

        // init
        initTeilnehmerList();

        // reset set header-title
        return () => setHeaderTitle(defaultHeaderTitle)
    }, []);

    const initTeilnehmerList = async () => {
        uncheckAll(setCheckedRelations);

        // -- get and set teilnehmer of this course
        getAndSetRelations();

        setInitialized(true);
    }


    // -- get and set kurs-teiln-relation
    const getAndSetRelations = async () => {
        // GET ALL TEILNEHMER OF THIS COURSE
        const response = await sendRequest(tokenObject, setTokenObject, 'POST', '/kursteilnahme/kursTeilnehmer', `Lade Teilnehmer des Kurses "${selectedCourse.KursKey}"`, `Teilnehmer für Kurs "${selectedCourse.KursKey}" geladen`, { "KursKey": selectedCourse.KursKey });

        if (response.success) {
            var receivedTeilnehmerOfThisCourse = response.data;

            // -- sort receivedTeilnehmerOfThisCourse by date
            receivedTeilnehmerOfThisCourse = receivedTeilnehmerOfThisCourse.sort((a, b) => {
                const firstElem = a.Teilnahmedatum;
                const secondElem = b.Teilnahmedatum;

                if (firstElem <= secondElem) return 1;
                else return -1
            });

            // -- create efn array of teilnehmer of this course (to filter none participants)
            var newEfnArray = [];
            receivedTeilnehmerOfThisCourse.forEach(relation => {
                newEfnArray.push(relation.EFN);
            });
            setEfnArray(newEfnArray);

            // -- set relation state
            setKursTeilnehmerRelations(receivedTeilnehmerOfThisCourse);
        }
    }


    // -- go to RelationForm.js
    const goToRelationForm = (id) => {
        uncheckAll(setCheckedRelations);
        setSelectedRelationId(id)
    }

    // -- go to RelationForm.js when Teilnehmer was just created
    const addTeilnehmerAndGoToRelationForm = async (teilnehmer) => {
        try {
            // ADD RELATION TO DATABASE
            const response = await sendRequest(tokenObject, setTokenObject, 'POST', '/kursteilnahme/createRelation', ``, ``, { "KursKey": selectedCourse.KursKey, "VNR": selectedCourse.VNR, "EFN": teilnehmer.EFN, "AnswerKey": selectedCourse.CorrectAnswerKey });
            if (response.success) {
                // init updated list
                initTeilnehmerList();
                // use inserted AI-ID from response and jump to relation-form
                goToRelationForm(response.data.insertId);
            }
            else throw "Fehler bei Request an '/kursteilnahme/createRelation'";
        }
        catch (e) {
            alert("Fehler bei Kursteilnahme.\n\nMöglicherweise hat Kurs noch keine VNR.");
            console.log('Fehler in addTeilnehmerAndGoToRelationForm', e);
        }
    }


    // -- delete this teilnehmer from course
    const deleteFromCourse = async (e, relation) => {
        e.stopPropagation();

        const confirmation = window.confirm(`Sicher, dass du den Teilnehmer "${relation.Nachname}" von Kurs "${relation.KursTitel}" löschen möchtest`);

        if (confirmation) {
            // DELETE TEILNEHMER FROM DATABASE
            const response = await sendRequest(tokenObject, setTokenObject, 'DELETE', '/kursteilnahme/deleteRelation', `Lösche Teilnehmer`, `Teilnehmer erfolgreich gelöscht`, { "KURS_TEILNEHMER_ID": relation.KURS_TEILNEHMER_ID })
            if (response.success) initTeilnehmerList();
        }
    }


    // -- send to eiv
    const sendTeilnehmerToEiv = async () => {

        const response = await sendToEiv(
            tokenObject, setTokenObject,
            selectedCourse.VNR,
            selectedCourse.KursKey,
            checkedRelations.length > 0 ? checkedRelations : kursTeilnehmerRelations,
            checkedRelations.length > 0 ? false : true
        )

        // restart app
        if (response) restartApp();
    }


    if (initialized && kursTeilnehmerRelations && !selectedRelationId)
        return (
            <div className='basic-container'>

                <BackButton onClick={goBack} />

                {/* general course information */}
                <div>
                    <h3>{selectedCourse.KursTitel}</h3>
                    <span>

                        <div className='vertical-margin simple-form-container form-container'>

                            {/* vnr */}
                            {printInput(undefined, 'vnr-input', <span className='gray-info'>VNR: </span>, selectedCourse.VNR, true, undefined, '', 'basic-input', true)}
                            {/* eiv-password */}
                            {printInput(undefined, 'eiv-password-input', <span className='gray-info'>EIV-Passwort: </span>, selectedCourse.EIV_Passwort, true, undefined, '', 'basic-input', true)}
                            {/* zertifikat-vorlage */}
                            {printInput(undefined, 'zertifikat-vorlage-input', <span className='gray-info'>Zertifikat Vorlage:</span>, selectedCourse.Zertifikat_Vorlage, true, undefined, '', 'basic-input', true)}
                            {/* answerkey */}
                            {printInput(undefined, 'correct-answer-key-input', <span className='gray-info'>Antwortschlüssel: </span>, selectedCourse.CorrectAnswerKey, true, undefined, '', 'basic-input', true)}
                            {/* referenten */}
                            {printInput(
                                undefined,
                                'referenten-input',
                                <span className='gray-info'>
                                    Referent{selectedCourse.Referenten.length > 1 ? "en" : ""}:
                                </span>,
                                selectedCourse.Referenten.map((referent, index) =>
                                    `${referent.Vorname} ${referent.Nachname}`
                                ).join(', '),
                                true, undefined, '', 'basic-input', true)
                            }
                            {/* sponsor */}
                            {printInput(undefined, 'sponsor-input', <span className='gray-info'>Sponsor: </span>, selectedCourse.Sponsor_Name, true, undefined, '', 'basic-input', true)}
                            {/* amount teilnehmer */}
                            {printInput(undefined, 'teilnehmer-amount-input', <span className='gray-info'>Anzahl Teilnehmer: </span>, kursTeilnehmerRelations.length, true, undefined, '', 'basic-input', true)}

                        </div>


                    </span>
                </div>


                {/* add new teilnehmer */}
                <div>
                    <h6>Teilnehmer hinzufügen</h6>

                    <TeilnehmerSearchAndCreate
                        allTeilnehmer={allTeilnehmer} setAllTeilnehmer={setAllTeilnehmer}
                        filteredTeilnehmer={filteredTeilnehmer}
                        setFilteredTeilnehmer={setFilteredTeilnehmer}

                        // after creating teilnehmer -> add to course and open relation
                        createFallback={(thisTeilnehmer) => addTeilnehmerAndGoToRelationForm(thisTeilnehmer)}
                    />

                    {filteredTeilnehmer.length > 0 ?
                        <div className='table-container' active="true">

                            <div className='table-header' onClick={toggleTable}>
                                <AiOutlineDown />
                                {filteredTeilnehmer.length} Teilnehmer gefunden
                            </div>

                            <table>
                                <thead>
                                    <tr>
                                        <th>Nachname</th>
                                        <th>Vorname</th>
                                        <th>EFN</th>
                                        <th></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {filteredTeilnehmer.map((teilnehmer, index) => {
                                        const teilnehmerPassedCourse = efnArray.includes(teilnehmer.EFN);
                                        return (
                                            <tr key={`teilnehmer-row-${index}`}>
                                                <td>{teilnehmer.Nachname}</td>
                                                <td>{teilnehmer.Vorname}</td>
                                                <td>{teilnehmer.EFN}</td>
                                                {teilnehmerPassedCourse ?
                                                    <td>Kurs absolviert</td>
                                                    :
                                                    <td className='underline' style={{ color: "green" }} onClick={() => addTeilnehmerAndGoToRelationForm(teilnehmer)}>Zu Kurs hinzufügen</td>
                                                }
                                                <TeilnehmerCopyButton teilnehmer={teilnehmer} callback={(newTeilnehmer) => addTeilnehmerAndGoToRelationForm(newTeilnehmer)} />
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        : <></>}

                </div>
                <div className='seperator'></div>


                {/* list of teilnehmer */}
                <div>
                    <h3>Teilnehmer "{selectedCourse.KursTitel}"</h3>


                    <div className='table-btn-container'>
                        {/* buttons for opening/sending/downloading */}
                        {kursTeilnehmerRelations.length > 0 ? <div className='basic-small-btn width-150 inline' onClick={sendTeilnehmerToEiv}><SiWorldhealthorganization /> An EIV melden</div> : <></>}
                        {checkedRelations.length > 0 ?
                            <>
                                <div
                                    className='basic-small-btn inline width-150'
                                    onClick={() => {
                                        checkedRelations.forEach(teilnehmer => {
                                            window.open(`${backendUrl}/zertifikate/kurs/${selectedCourse.VNR}/${teilnehmer.EFN}`);
                                        })
                                    }}
                                    target='_blank'>
                                    <AiOutlineFilePdf />Öffne Zertifikate
                                </div>
                                <div className='basic-small-btn width-150 inline' onClick={() => downloadCertificateFromCheckedRelations(tokenObject, setTokenObject, checkedRelations)}><AiOutlineDownload />Download Zertifikate</div>
                                <div className='basic-small-btn width-150 inline' onClick={() => sendCertificateToCheckedRelations(tokenObject, setTokenObject, checkedRelations)}><BiMailSend />Sende Zertifikat</div>
                            </>
                            :
                            <></>
                        }
                    </div>


                    {/* main table */}
                    <div className='table-container' active="false">

                        <div className='table-header' onClick={toggleTable}>
                            <AiOutlineDown />{kursTeilnehmerRelations.length} Teilnehmer
                        </div>

                        {kursTeilnehmerRelations.length > 0 ?
                            <table>
                                {/* headline */}
                                <thead>
                                    <tr>
                                        <th className='column-icon underline'
                                            onClick={checkedRelations.length > 0 ? () => uncheckAll(setCheckedRelations) : () => checkAll(kursTeilnehmerRelations, setCheckedRelations)}>
                                            {checkedRelations.length > 0 ? <BiCheckboxMinus /> : <BiCheckbox />}
                                        </th>
                                        <th>Nachname</th>
                                        <th>Vorname</th>
                                        <th>EFN</th>
                                        <th>Teilnahmeart</th>
                                        <th>Teilnahmedatum</th>
                                        <th>EIV-Meldedatum</th>
                                        <th className='column-icon'></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {kursTeilnehmerRelations.map((relation, index) =>
                                        <tr key={`relation-row-${index}`} className='pointer' onClick={() => goToRelationForm(relation.KURS_TEILNEHMER_ID)}>
                                            <td className='column-icon' onClick={(e) => e.stopPropagation()}>
                                                <input type="checkbox" className="relation-checkbox" onClick={(e) => checkedRelationsChange(e, relation, checkedRelations, setCheckedRelations, "KURS_TEILNEHMER_ID")} />
                                            </td>
                                            <td>{relation.Nachname}</td>
                                            <td>{relation.Vorname}</td>
                                            <td>{relation.EFN}</td>
                                            <td>{relation.AD_Email ? "QR-Code" : (relation.Antwortbogen ? "Antwortbogen" : "Online")}</td>
                                            <td>{formatDateToString(relation.Teilnahmedatum)}</td>
                                            <td>{relation.EIV_Datum ? formatDateToString(relation.EIV_Datum) : "-"}</td>
                                            <td className='column-icon delete' onClick={(e) => deleteFromCourse(e, relation)}><FiTrash2 /></td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            :
                            <table>
                                <tbody>
                                    <tr><td>Noch keine Teilnehmer vorhanden</td></tr>
                                </tbody>
                            </table>
                        }
                    </div>
                </div>
                <div className='seperator'></div>
            </div >
        )
    else if (selectedRelationId)
        return (
            <RelationForm
                relationId={selectedRelationId} setRelationId={setSelectedRelationId}
                allTeilnehmer={allTeilnehmer} setAllTeilnehmer={setAllTeilnehmer}

                getAndSetRelations={getAndSetRelations}
            />
        )
    else
        return (
            <Loader />
        )
}

export default TeilnehmerList
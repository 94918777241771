import { AiOutlineInfoCircle } from 'react-icons/ai'
import './../assets/css/info-popup.css'

import parse from 'html-react-parser';


export const InfoPopup = ({ text }) => {
    return (
        <div className="info-icon-container">
            <AiOutlineInfoCircle />
            <div className="info-popup-container" hidden>
                {parse(text)}
            </div>
        </div>
    )
}

export default InfoPopup
import { downloadBackup } from './../../functions/fileHandler.js';

import Import from '../ImportExport/Import.js'
import Export from '../ImportExport/Export.js'

export const Werkzeuge = ({ userRole }) => {


    return (
        <div className='basic-container'>
            <h1>Werkzeuge</h1>

            {userRole === "Admin" ?
                <>
                    {/* Import */}
                    <Import />
                    <div className='seperator'></div>


                    {/* Export */}
                    <Export />
                    <div className='seperator'></div>
                </>
                : <></>}


            {/* localstorage kurs-backups */}
            <div>
                <h3>Backups</h3>
                <button className='basic-btn left' onClick={() => { localStorage.removeItem('course_creator_infos') }} >Backup löschen</button>
                <button className='basic-btn left' onClick={downloadBackup} >Backup herunterladen</button>
            </div>
            <div className='seperator'></div>

        </div>
    )
}

export default Werkzeuge
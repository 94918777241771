// Functions to update local states containing e.g. Kurse,Teilnehmer,Referenten, etc.
// -> Prevent to many database-accesses -> speed improvement

/**
 * Update a state without database-access
 * @param {Object} state React-State
 * @param {Function} setState React-Set-State
 * @param {Object} newObject Object in state to be updated
 * @param {String} key Entry-Key to be compared
 * @returns Copy of updated State
 */
const updateState = (state, setState, newObject, key) => {
    var newArray = [...state];
    newArray.forEach((item, index) => {
        if (item[key] === newObject[key]) {
            newArray[index] = newObject;
        }
    });
    setState(newArray);
    return newArray;
}

/**
 * Add something to state without database-access
 * @param {Object} state React-State
 * @param {Function} setState React-Set-State
 * @param {Object} newObject Object to be added to state
 * @returns Copy of updated State
 */
const addToState = (state, setState, newObject) => {
    var newArray = [...state];
    newArray.unshift(newObject);
    setState(newArray);
    return newArray;
}

/**
 * Delete something from state without database access
 * @param {Object} state React-State
 * @param {Function} setState React-Set-State
 * @param {String} key Entry-Key
 * @param {String} value Value to be compared with key
 * @returns Copy of updated State
 */
const deleteFromState = (state, setState = undefined, key, value) => {
    const newArray = state.filter(item => item[key] !== value);
    if (setState) setState(newArray);
    return newArray;
}

export { updateState, addToState, deleteFromState }
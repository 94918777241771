import { Spinner } from "react-activity"
import { AiOutlineCheckCircle, AiOutlineWarning } from 'react-icons/ai'


const StatusBar = () => {

    return (
        <footer>
            {/* warnings */}
            <div id="warning-message" onClick={(e) => e.currentTarget.querySelector('#warning-message-popup').toggleAttribute('active')}>
                <AiOutlineWarning />
                <div id="warning-message-popup">
                    <div id="validation-info">
                        <p style={{ backgroundColor: "red" }}>Standard</p>
                        <p style={{ backgroundColor: "orange" }}>CME Shuttle</p>
                    </div>
                    <div id='warning-message-text'></div>
                </div>
            </div>

            <div id="status-message"></div>
            <div className="status-icon">
                <div id="status-spinner-container">
                    <Spinner color="#eb4260" />
                </div>
                <div id="status-check-container">
                    <AiOutlineCheckCircle />
                </div>
            </div>
        </footer>
    )
}

export default StatusBar
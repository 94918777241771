import { useState, useEffect, useContext } from 'react'
import { TokenContext } from '../Main'

import { FiTrash2 } from 'react-icons/fi'
import { AiOutlineDown } from 'react-icons/ai'

import Loader from './../Loader'
import TeilnehmerForm from './TeilnehmerForm';
import TeilnehmerSearchAndCreate from './TeilnehmerSearchAndCreate';
import TeilnehmerMergeOrDelete from './TeilnehmerMergeOrDelete';
import TeilnehmerCopyButton from './TeilnehmerCopyButton';

import { toggleTable, confirmTeilnehmerDeletion } from '../../functions/helper.js';
import { sendRequest } from '../../rest/requests';
import { getTeilnehmerFromDatabase } from '../../rest/requests_get_all';


const TeilnehmerManager = ({ allTeilnehmer, setAllTeilnehmer, allRelations, restartApp }) => {

    // access-token
    const [tokenObject, setTokenObject] = useContext(TokenContext);

    const [initialized, setInitialized] = useState(false);

    // if teilnehmer selected -> go to TeilnehmerForm.js
    const [selectedTeilnehmer, setSelectedTeilnehmer] = useState();

    // basic search results
    const [filteredTeilnehmer, setFilteredTeilnehmer] = useState([]);

    // search results for merge/delete table
    const [searchResults, setSearchResults] = useState([]);


    // -- init
    useEffect(() => {
        initTeilnehmerManager();
    }, []);
    const initTeilnehmerManager = async () => {
        setInitialized(true);
    }


    // -- delete teilnehmer
    const deleteThisTeilnehmer = async (e, teilnehmer) => {
        e.stopPropagation();

        const confirm = confirmTeilnehmerDeletion(teilnehmer);

        if (confirm) {
            // DELETE TEILNEHMER FROM DATABASE
            const response = await sendRequest(tokenObject, setTokenObject, 'DELETE', `/teilnehmer/delete/${teilnehmer.EFN}`, `Lösche Teilnehmer "${teilnehmer.EFN}"`, `Teilnehmer mit EFN: "${teilnehmer.EFN}" gelöscht`);

            // success
            if (response.success) {
                setAllTeilnehmer(await getTeilnehmerFromDatabase(tokenObject, setTokenObject));
                setFilteredTeilnehmer([]);
            }
            // error
            else alert("Beim löschen eines Teilnehmers ging etwas schief");
        }
    }


    if (initialized && allTeilnehmer && !selectedTeilnehmer)
        return (
            <div className='basic-container'>

                {/* Teilnehmer-List */}
                <h3>Teilnehmer</h3>
                <TeilnehmerSearchAndCreate
                    allTeilnehmer={allTeilnehmer} setAllTeilnehmer={setAllTeilnehmer}
                    filteredTeilnehmer={filteredTeilnehmer}
                    setFilteredTeilnehmer={setFilteredTeilnehmer}

                    createFallback={(thisTeilnehmer) => setSelectedTeilnehmer(thisTeilnehmer)}
                    andOrConditions={true}
                />

                {/* table of search-results for teilnehmer */}
                <div className='table-container' active='true'>

                    {/* header */}
                    <div className='table-header' onClick={toggleTable}>
                        <AiOutlineDown />{filteredTeilnehmer.length} Teilnehmer gefunden
                    </div>

                    <table>

                        {/* headline */}
                        <thead>
                            <tr>
                                <th>Nachname</th>
                                <th>Vorname</th>
                                <th>EFN</th>
                                <th>Kurse</th>
                                <th className='column-icon'></th>
                            </tr>
                        </thead>

                        <tbody>
                            {filteredTeilnehmer.map((teilnehmer, index) => {

                                // amount of courses
                                var courseCount = 0;
                                allRelations.map(elem => { if (elem.EFN === teilnehmer.EFN) courseCount++ })

                                return (
                                    <tr key={`teilnehmer-row-${index}`} className='pointer' onClick={() => setSelectedTeilnehmer(teilnehmer)}>
                                        <td>{teilnehmer.Nachname}</td>
                                        <td>{teilnehmer.Vorname}</td>
                                        <td>{teilnehmer.EFN}</td>
                                        <td>{courseCount}</td>
                                        <TeilnehmerCopyButton teilnehmer={teilnehmer} callback={(newTeilnehmer) => setSelectedTeilnehmer(newTeilnehmer)} />
                                        <td className='column-icon delete' onClick={(e) => deleteThisTeilnehmer(e, teilnehmer)}><FiTrash2 /></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                <div className='seperator'></div>


                {/* Merge multiple Teilnehmer */}
                <TeilnehmerMergeOrDelete
                    restartApp={restartApp}
                    setSelectedTeilnehmer={setSelectedTeilnehmer}

                    allTeilnehmer={allTeilnehmer} setAllTeilnehmer={setAllTeilnehmer}
                    allRelations={allRelations}
                    searchResults={searchResults} setSearchResults={setSearchResults}
                />
            </div>
        )
    else if (selectedTeilnehmer)
        return (
            <TeilnehmerForm
                selectedTeilnehmer={selectedTeilnehmer}

                allTeilnehmer={allTeilnehmer} setAllTeilnehmer={setAllTeilnehmer}
                setSelectedTeilnehmer={setSelectedTeilnehmer}
            />
        )
    else
        return (
            <Loader />
        )
}

export default TeilnehmerManager
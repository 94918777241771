import { useState, useEffect, useContext } from 'react'
import { TokenContext } from '../Main.js'

import { FiTrash2 } from 'react-icons/fi'

import { printSelect } from './../InputComponents.js'
import { sendRequest } from './../../rest/requests.js'

import Loader from './../Loader'

export const UserManager = ({ }) => {

    // access-token
    const [tokenObject, setTokenObject] = useContext(TokenContext);

    // -- init
    const [initialized, setInitialized] = useState(false);
    const [users, setUsers] = useState([])


    // -- init
    useEffect(() => {
        initUserManager();
    }, []);

    const initUserManager = async () => {

        // GET ALL USERS FROM DATABASE
        const response = await sendRequest(tokenObject, setTokenObject, 'GET', '/nutzer', 'Lade alle Nutzer', 'Nutzer geladen');

        if (response.success) {
            setUsers(response.data);
            setInitialized(true);
        }
    }

    // -- reload
    const reload = () => {
        setInitialized(false);
        setUsers([]);
        setTimeout(() => {
            initUserManager();
        }, 1000);
    }


    // -- delete a user
    const deleteThisUser = async (user) => {
        var confirm = window.confirm(`Nutzer ${user.Nachname} wirklich löschen?`);
        if (confirm) {
            // DELETE USER FROM DATABASE
            const response = await sendRequest(tokenObject, setTokenObject, 'DELETE', `/nutzer/${user.Mail}`, `Lösche Nutzer ${user.Nachname}`, `Nutzer ${user.Nachname} gelöscht`);

            // successfully deleted user
            if (response.success) reload();
        }
    }


    // -- rolle on change
    const rolleOnChange = async (e, user) => {
        // Rolle changed to value
        const value = e.currentTarget.value;
        user.Rolle = value;

        // UPDATE ROLLE IN DATABASE
        const response = await sendRequest(tokenObject, setTokenObject, 'PATCH', '/nutzer', 'Speichere Nutzer-Rolle', 'Nutzer-Rolle gespeichert', user);

        // success
        if (response.success) reload();
    }


    if (initialized)
        return (
            <div className='basic-container'>

                <span className='headline-add-btn'>
                    {/* headline */}
                    <h1>Academy2 Nutzer<sup>*</sup>innen</h1>
                </span>

                <div className='table-container'>
                    <table>
                        <tbody>
                            {users.map((user, index) =>
                                <tr key={`user-row-${index}`}>
                                    <td>{user.Vorname} {user.Nachname}</td>
                                    <td>{user.Mail}</td>
                                    <td className='column-input'>{printSelect((e) => rolleOnChange(e, user), 'user-role-input', '', user.Rolle, ["Nutzer", "Projektleiter", "Admin"])}</td>
                                    <td className='column-icon underline delete' onClick={() => deleteThisUser(user)}><FiTrash2 /></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className='seperator'></div>

            </div>
        )
    else
        return (<Loader />)
}

export default UserManager
import { useContext, useState } from "react";
import { TokenContext } from "../Main";
import { Spinner } from "react-activity";
import "react-activity/dist/library.css";
import parse from 'html-react-parser';

import OverwritePopup from "./OverwritePopup";

import { sendRequest } from "../../rest/requests";
import { importJson, importXlsx } from "../../functions/fileHandler";
import { printCheckbox, printFileInput } from "../InputComponents";


// creating one row for teilnehmer - considering different column-names (e.g. old cmeassist)
const getTeilnehmerBody = (teilnehmer) => {

    // -- fill teilnehmer-object
    const vorname = teilnehmer.Vorname ? teilnehmer.Vorname : "";
    const nachname = teilnehmer.Nachname ? teilnehmer.Nachname : (teilnehmer.Name ? teilnehmer.Name : ""); // "Name" is old cmeassist-format
    const efn = teilnehmer.EFN ? teilnehmer.EFN : `undefined-${vorname}-${nachname}`; // efn is primaryKey -> set it to something if undefined

    const land = teilnehmer.Land ? teilnehmer.Land : "";
    const titel = teilnehmer.Titel ? teilnehmer.Titel : "";
    const strasse = teilnehmer.Strasse ? teilnehmer.Strasse : (teilnehmer['Straße'] ? teilnehmer['Straße'] : "");
    const postleitzahl = teilnehmer.Postleitzahl ? teilnehmer.Postleitzahl : (teilnehmer.PLZ ? teilnehmer.PLZ : ""); // "PLZ" is old cmeassist-format
    const stadt = teilnehmer.Stadt ? teilnehmer.Stadt : (teilnehmer.Ort ? teilnehmer.Ort : ""); // "Ort" is old cmeassist-format
    const mail = teilnehmer.Mail ? teilnehmer.Mail : (teilnehmer["Email-Adresse"] ? teilnehmer["Email-Adresse"] : (teilnehmer.Email ? teilnehmer.Email : "")); // "Email-Adresse" is old cmeassist-format
    const fachgebiet = teilnehmer.Fachgebiet ? teilnehmer.Fachgebiet : "";
    const zustimmung = teilnehmer.Zustimmung ? teilnehmer.Zustimmung : (teilnehmer.Newsletter ? teilnehmer.Newsletter : 0); // "Newsletter" is old cmeassist-format
    const datenspeicherung = teilnehmer.Datenspeicherung ? teilnehmer.Datenspeicherung : 1;


    // create body for post-request
    const teilnehmerBody = {
        "EFN": efn,
        "Land": land,
        "Titel": titel,
        "Vorname": vorname,
        "Nachname": nachname,
        "Strasse": strasse,
        "Postleitzahl": postleitzahl,
        "Stadt": stadt,
        "Mail": mail,
        "Fachgebiet": fachgebiet,
        "Zustimmung": zustimmung,
        "Datenspeicherung": datenspeicherung
    }

    return teilnehmerBody;
}

// creating one course for one teilnehmer-row - considering different column-names (e.g. old cmeassist)
const getRelationBody = (relation, index) => {

    // -- fill relation object and save in database
    const vorname = relation.Vorname ? relation.Vorname : "";
    const nachname = relation.Nachname ? relation.Nachname : (relation.Name ? relation.Name : ""); // "Name" is old cmeassist-format
    const efn = relation.EFN ? relation.EFN : `undefined-${vorname}-${nachname}`; // efn is primaryKey -> set it to something if undefined

    const vnr = relation[`Kurs ${index} VNR`];
    var teilnahmedatum = relation[`Kurs ${index} Teilnahmedatum`] ? relation[`Kurs ${index} Teilnahmedatum`] : (relation[`Kurs ${index} Datum Teilnahme`] ? relation[`Kurs ${index} Datum Teilnahme`] : ""); // "Datum Teilnahme" is old cmeassist-format
    const answerkey = relation[`Kurs ${index} AnswerKey`] ? relation[`Kurs ${index} AnswerKey`] : "";
    const kurskey = relation[`Kurs ${index} KursKey`] ? relation[`Kurs ${index} KursKey`] : "";
    const eiv_datum = relation[`Kurs ${index} EIV_Datum`] ? relation[`Kurs ${index} EIV_Datum`] : "";
    const antwortbogen = relation[`Kurs ${index} Antwortbogen`] ? relation[`Kurs ${index} Antwortbogen`] : 0;
    const ad_vorname = relation[`Kurs ${index} AD_Vorname`] ? relation[`Kurs ${index} AD_Vorname`] : "";
    const ad_nachname = relation[`Kurs ${index} AD_Nachname`] ? relation[`Kurs ${index} AD_Nachname`] : "";
    const ad_email = relation[`Kurs ${index} AD_Email`] ? relation[`Kurs ${index} AD_Email`] : "";


    // -- format teilnahmedatum

    // make sure month and day have two digits
    const ensureTwoDigits = (number) => {
        return ("0" + number).slice(-2);
    }
    // make sure year has four digits
    const ensureFourDigits = (number) => {
        return ("20" + number).slice(-4);
    }


    var dateString;

    if (!teilnahmedatum) {
        dateString = false;
    }
    else if (teilnahmedatum.match(/^\d{1,2}\/\d{1,2}\/\d{4}$/)) {
        // special handling for old cmeassist -> old cmeassist entrys are format dd/mm/yyyy
        var newDateArray = teilnahmedatum.split('/');

        dateString = `${ensureFourDigits(newDateArray[2])}-${ensureTwoDigits(newDateArray[1])}-${ensureTwoDigits(newDateArray[0])}` // format to yyyy-mm-dd
    }
    else if (teilnahmedatum.match(/^\d{1,2}.\d{1,2}.\d{4}$/)) {
        // special handling for dd.mm.yyyy format
        var newDateArray = teilnahmedatum.split('.');

        dateString = `${ensureFourDigits(newDateArray[2])}-${ensureTwoDigits(newDateArray[1])}-${ensureTwoDigits(newDateArray[0])}` // format to yyyy-mm-dd
    }
    else if (teilnahmedatum.includes('T')) {
        // special handling for wrong format yyyy-mm-ddT00:00:00:0000Z
        dateString = teilnahmedatum.split('T')[0];
    }
    else {
        const date = new Date(teilnahmedatum);

        if (!isNaN(date)) {
            const month = date.getMonth() + 1;
            const day = date.getDate();
            const year = date.getFullYear();

            dateString = `${ensureFourDigits(year)}-${ensureTwoDigits(month)}-${ensureTwoDigits(day)}`;
        }
        else {
            dateString = false;
        }
    }


    const relationBody = {
        "EFN": efn,
        "VNR": vnr,
        "Teilnahmedatum": dateString,
        "AnswerKey": answerkey,
        "KursKey": kurskey,
        "EIV_Datum": eiv_datum,
        "Antwortbogen": antwortbogen,
        "AD_Vorname": ad_vorname,
        "AD_Nachname": ad_nachname,
        "AD_Email": ad_email
    }

    return relationBody;
}


export const Import = ({ }) => {

    // access-token
    const [tokenObject, setTokenObject] = useContext(TokenContext);

    // -- when loading files, sending requests, etc
    const [loading, setLoading] = useState();

    // -- on error
    const [errorMessage, setErrorMessage] = useState("");

    // -- overwrite a teilnehmer in a popup
    const [overwritePopupActive, setOverwritePopupActive] = useState(false);
    const [databaseTeilnehmer, setDatabaseTeilnehmer] = useState();
    const [importedTeilnehmer, setImportedTeilnehmer] = useState();


    // -- import a json file type "Kurse", "Teilnehmer" or "Kursteilnahmen"
    const importJsonFile = (e, type = "Kurse") => {
        e.preventDefault();
        setLoading(true);

        // clear error-message        
        setErrorMessage("");

        // import file
        importJson(e, async (json) => {
            try {
                if (json) {
                    // type in which index to start and end
                    const startIndex = prompt('Start-Index', 0);
                    const endIndex = prompt('Ende-Index', json.length - 1);

                    if (window.confirm(`Sicher, dass du ${endIndex - startIndex + 1} Einträge importieren möchtest.`)) {

                        // run through array
                        for (let i = startIndex; i <= endIndex; i++) {
                            const body = json[i];

                            let response;

                            // types -> "Kurse", "Teilnehmer", "Kursteilnahmen"
                            switch (type) {
                                case "Kurse":
                                    // ADD COURSE TO DATABASE                            
                                    response = await sendRequest(tokenObject, setTokenObject, 'POST', '/kurse', `Erstelle Kurs - Index ${i}`, `Kurs erstellt - Index ${i}`, body);
                                    break;
                                case "Teilnehmer":
                                    // ADD TEILNEHMER TO DATABASE                            
                                    response = await sendRequest(tokenObject, setTokenObject, 'POST', '/teilnehmer/erstellen', `Erstelle Teilnehmer - Index ${i}`, `Teilnehmer erstellt - Index ${i}`, body);
                                    break;
                                case "Kursteilnahmen":
                                    // ADD KURS-TEILNEHMER-RELATION TO DATABASE                            
                                    response = await sendRequest(tokenObject, setTokenObject, 'POST', '/kursteilnahme/createRelation', `Erstelle Kursteilnahme - index ${i}`, `Kursteilnahme erstellt - index ${i}`, body);
                                    break;

                                default:
                                    response.success = false;
                                    break;
                            }

                            // error
                            if (!response.success) setErrorMessage(errorMessage + `<br>Fehler bei Kurserstellung. Index ${i}`);
                        }
                    }
                }
                // error
                else setErrorMessage(errorMessage + "Fehler bei Datei-Import. Es muss sich um eine json-Datei handeln!<br>");

                setLoading(false);
            }
            catch (e) { setErrorMessage(errorMessage + e + '<br>') }
        })
    }



    // -- import xlsx-file and overwrite if needed - containing Teilnehmer and Kursteilnahmen
    const importTeilnehmerAndKursteilnahmen = async (e) => {
        e.preventDefault();

        // -- checkboxes define what to import
        const uploadTeilnehmer = document.querySelector('#upload-teilnehmer').checked;
        const uploadRelation = document.querySelector('#upload-relation').checked;
        const uploadEvent = document.querySelector('#upload-event').checked;

        setLoading(true);

        // -- message-divs
        const successMessage = document.querySelector('#xlsx-upload-success-message');
        const errorMessage = document.querySelector('#xlsx-upload-error-message');
        successMessage.innerHTML = "";
        errorMessage.innerHTML = "";

        // -- read xlsx file and return json-object
        importXlsx(e, async (json) => {
            try {
                if (json) {
                    // file output as json-format
                    console.log(json);

                    successMessage.innerHTML = "Datei erfolgreich geladen<br>";

                    // type in which index to start and end
                    const startIndex = parseInt(prompt('Start-Index', 0));
                    const endIndex = parseInt(prompt('Ende-Index', json.length - 1));


                    if (window.confirm(`Sicher, dass du ${endIndex - startIndex + 1} Einträge importieren möchtest.`)) {

                        console.log("Start: ", startIndex);
                        console.log('Ende: ', endIndex);

                        // go through every teilnehmer
                        for (let i = startIndex; i <= endIndex; i++) {

                            // -- this teilnehmer
                            const teilnehmer = json[i];
                            // get requey-body for this teilnehmer
                            const teilnehmerBody = getTeilnehmerBody(teilnehmer);


                            // -- create teilnehmer if checkbox is checked
                            if (uploadTeilnehmer) {
                                // CREATE TEILNEHMER DATABASE
                                const addResponse = await sendRequest(tokenObject, setTokenObject, 'POST', '/teilnehmer/erstellen', `Erstelle Teilnehmer - index ${i}`, `Teilnehmer geladen - index ${i}`, teilnehmerBody);

                                if (!addResponse.success) {
                                    // -- create-request failed 
                                    // -> possible reason: teilnehmer already in database 
                                    // -> try to update instead of creating

                                    // GET TEILNEHMER
                                    const getResponse = await sendRequest(tokenObject, setTokenObject, 'GET', `/teilnehmer/${teilnehmerBody.EFN}`);
                                    const receivedTeilnehmer = getResponse.data;


                                    // -- teilnehmer available
                                    if (receivedTeilnehmer[0]) {

                                        // set old and new one
                                        const oldTeilnehmer = receivedTeilnehmer[0];
                                        const newTeilnehmer = teilnehmerBody;

                                        // determin if imported teilnehmer is the same like the one in database
                                        let teilnehmerEqual = true;
                                        for (let key in newTeilnehmer) {
                                            // compare keys in teilnehmer-objects
                                            let newEntry = newTeilnehmer[key];
                                            let oldEntry = oldTeilnehmer[key];
                                            // strings to lowercase
                                            if (typeof newEntry === "string") newEntry = newEntry.toLowerCase();
                                            if (typeof oldEntry === "string") oldEntry = oldEntry.toLowerCase();
                                            // compare
                                            if (newEntry !== oldEntry) teilnehmerEqual = false;
                                        }

                                        // overwrite teilnehmer only if new one is unequal
                                        if (!teilnehmerEqual) {
                                            // set new, old teilnehmer and activate popup                                           
                                            setOverwritePopupActive(true);
                                            setDatabaseTeilnehmer(oldTeilnehmer);
                                            setImportedTeilnehmer(newTeilnehmer);

                                            // add eventlistender to next and cancel button
                                            const cancelBtn = document.querySelector('#cancel-btn');
                                            const nextBtn = document.querySelector('#next-teilnehmer-btn');

                                            // create click-promise
                                            const overwrite = new Promise(resolve => {
                                                cancelBtn.addEventListener('click', () => resolve(false))
                                                nextBtn.addEventListener('click', () => resolve(true))
                                            })

                                            // await button-click
                                            await overwrite;

                                            // reset popup
                                            setOverwritePopupActive(false);
                                            setDatabaseTeilnehmer();
                                            setImportedTeilnehmer();

                                            // cancel import if cancel-button got clicked
                                            if (!await overwrite) {
                                                setLoading(false);
                                                return;
                                            }
                                        }
                                        else {
                                            // just for information
                                            console.log('Teilnehmer equal - do nothing');
                                        }
                                    }
                                    // -- teilnehmer NOT available
                                    else {
                                        errorMessage.innerHTML += `•<strong>FEHLER BEI TEILNEHMERERSTELLUNG: </strong> Nachname: ${teilnehmerBody.Nachname} – EFN: ${teilnehmerBody.EFN} - Index: ${i}<br>`;
                                        console.log("Fehler bei Teilnehmer-Erstellung: ", teilnehmerBody);
                                        console.log("------------------------------------------");
                                    }
                                }
                            }



                            // -- try creating relation even though teilnehmer-creation was not successfull
                            if (uploadRelation) {

                                // run through "Kurs {index}" in xlsx-table
                                for (let courseIndex = 1; courseIndex <= 200; courseIndex++) {

                                    const vnr = teilnehmer[`Kurs ${courseIndex} VNR`];

                                    if (vnr) {
                                        // get request-body for this relation
                                        const relationBody = getRelationBody(teilnehmer, courseIndex);

                                        // check for right Teilnahnedatum-format
                                        const regex = /^\d{4}-\d{2}-\d{2}$/;
                                        if (!relationBody.Teilnahmedatum || relationBody.Teilnahmedatum.match(regex) === null) {
                                            console.log('hier stimmt was nicht: ', relationBody);
                                            errorMessage.innerHTML += `Fehler bei Datum-Format. Siehe Konsole. (Index: ${i})<br>`;
                                        }
                                        else {
                                            // CREATE RELATION IN DATABASE
                                            const response = await sendRequest(tokenObject, setTokenObject, 'POST', '/kursteilnahme/createRelation', `Erstelle Kursteilnahme - index ${i}`, `Kursteilnahme erstellt - index ${i}`, relationBody);

                                            // -- error
                                            if (!response.success) {
                                                errorMessage.innerHTML += `- <strong>FEHLER BEI KURSTEILNAHME: </strong>VNR: ${relationBody.VNR} – efn: ${relationBody.EFN} - Index: ${i} - Kursindex: ${courseIndex}<br>`;
                                                console.log("Fehler bei Kursteilnahme-Erstellung: ", relationBody);
                                                console.log("------------------------------------------");
                                            }
                                        }
                                    }
                                    else {
                                        // stop loop when course-array in excel-table reached end
                                        break;
                                    }
                                }
                            }


                            // -- add teilnehmer to a event
                            if (uploadEvent) {
                                const event_id = teilnehmer.Veranstaltung;

                                const body = { EVENT_ID: event_id, EFN: teilnehmer.EFN }
                                const response = await sendRequest(tokenObject, setTokenObject, 'POST', '/veranstaltung-teilnehmer', 'Füge Teilnehmer hinzu', 'Teilnehmer hinzugefügt', body);

                                if (!response.success) alert("Fehler beim Hinzufügen zu einem Event");
                            }

                        }
                        // end of teilnehmer-loop


                        // restart app info
                        successMessage.innerHTML += "Import abgeschlossen.<br><i>Aktualisiere die Seite, um die neuen Einträge zu sehen.</i><br>"
                    }
                }
                else {
                    errorMessage.innerHTML += "Fehler bei Datei-Import. Es muss sich um eine xlsx-Datei handeln!<br>";
                }

                setLoading(false);
            }
            catch (e) {
                console.log(e);
                errorMessage.innerHTML += e + '<br>';
            }
        })
    }


    return (
        <div className='basic-container import-container'>

            {/* standard json- and xlsx-import */}
            <div>
                <h3>Import</h3>

                {/* JSON */}
                <div>
                    {/* import kurse */}
                    <form className="form-container">
                        <h5>Kurse, Webinare, Podcasts, Live-Veranstaltungen</h5>
                        {/* upload json-file */}
                        {printFileInput((e) => importJsonFile(e, "Kurse"), 'upload-kurse', 'json-Datei')}
                        {/* error message */}
                        <div style={{ color: "red" }}>{parse(errorMessage)}</div>
                    </form>
                    <br />

                    {/* import teilnehmer */}
                    <form className="form-container">
                        <h5>Teilnehmer</h5>
                        {/* upload json-file */}
                        {printFileInput((e) => importJsonFile(e, "Teilnehmer"), 'upload-kurse', 'json-Datei')}
                        {/* error message */}
                        <div style={{ color: "red" }}>{parse(errorMessage)}</div>
                    </form>
                    <br />

                    {/* import kursteilnahmen */}
                    <form className="form-container">
                        <h5>Kursteilnahmen</h5>
                        {/* upload json-file */}
                        {printFileInput((e) => importJsonFile(e, "Kursteilnahmen"), 'upload-kurse', 'json-Datei')}
                        {/* error message */}
                        <div style={{ color: "red" }}>{parse(errorMessage)}</div>
                    </form>
                </div>
                <div className="seperator"></div>


                {/* XLSX */}
                <div>
                    {/* import teilnehmer and kursteilnahme */}
                    <form className="form-container">

                        <h5>Teilnehmer und Kursteilnahmen importieren/überschreiben</h5>

                        {/* decide what to import */}
                        {printCheckbox(undefined, 'upload-teilnehmer', 'Teilnehmer importieren', 1, true)}<br />
                        {printCheckbox(undefined, 'upload-relation', 'Kursteilnahmen importieren', 1, true)}<br />
                        {printCheckbox(undefined, 'upload-event', 'Veranstaltungen importieren', 0, true)}<br />


                        {/* upload xlsx-file */}
                        {printFileInput(importTeilnehmerAndKursteilnahmen, "upload-teilnehmer-kursteilnahme", 'xlsx-Datei')}

                        <div id='xlsx-upload-success-message' style={{ color: "green" }}></div>
                        <div id='xlsx-upload-error-message' style={{ color: "red" }}></div>
                    </form>


                    {/* popup to overwrite existing teilnehmer */}
                    {overwritePopupActive && databaseTeilnehmer && importedTeilnehmer ?
                        <OverwritePopup
                            databaseTeilnehmer={databaseTeilnehmer}
                            importedTeilnehmer={importedTeilnehmer}
                        />
                        : <></>
                    }
                </div>
            </div>


            {/* loading-information when importing */}
            <div id='fullscreen-loader' style={loading ? { display: "flex", backgroundColor: "rgba(255,255,255,0.9)" } : { display: "none" }}>
                <div className="import-loader">
                    <Spinner />
                    <div>Lädt ...</div>
                </div>
            </div>
        </div>
    )
}

export default Import
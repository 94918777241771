import { sendRequest } from "./requests";
import { getCoursesFromDatabase } from "./requests_get_all";

// -- send ALL remaining teilnehmer (no EIV_Datum) from ALL courses to eiv
const sendAllToEiv = async (tokenObject, setTokenObject) => {

    // GET ALL COURSES FROM DATABASE
    const allCourses = await getCoursesFromDatabase(tokenObject, setTokenObject);

    // create one array with all courses to be sent to eiv
    const filteredCourses = allCourses.filter(course => course.id === "CME_Kurse" || course.id === "GCP" || course.id === "Live")
    var courses = [];
    for (let i = 0; i < filteredCourses.length; i++) {
        courses = courses.concat(filteredCourses[i].data)
    }

    // run through courses
    for (let i = 0; i < courses.length; i++) {
        const thisCourse = courses[i];
        const vnr = thisCourse.VNR;
        const kursKey = thisCourse.KursKey;

        // GET TEILNEHMER OF THIS COURSE
        const response = await sendRequest(tokenObject, setTokenObject, 'POST', '/kursteilnahme/kursTeilnehmer', `Lade Teilnehmer aus Kurs "${kursKey}"`, `Teilnehmer aus Kurs "${kursKey}" geladen`, { KursKey: kursKey });

        if (response.success) {
            const kursTeilnRelation = response.data;

            // use teilnehmer without eiv-date
            const teilnehmerWithoutEivDate = kursTeilnRelation.filter(elem => !elem.EIV_Datum);

            // send this to eiv
            await sendToEiv(tokenObject, setTokenObject, vnr, kursKey, teilnehmerWithoutEivDate);
        }
    }
}

// -- send teilnehmer to EIV
const sendToEiv = async (tokenObject, setTokenObject, vnr, kursKey, allRelations, filter = true) => {
    try {
        // copy allRelations
        var allRelationsCopy = [...allRelations];

        // filter=true -> use array of teilnehmer without eiv-date
        if (filter && allRelationsCopy) allRelationsCopy = allRelationsCopy.filter(teilnehmer => !teilnehmer.EIV_Datum);

        // only use teilnehmer with efn containing 80276 and length 15
        if (allRelationsCopy) allRelationsCopy = allRelationsCopy.filter(teilnehmer => (teilnehmer.EFN.includes('80276') && teilnehmer.EFN.length === 15));


        if (allRelationsCopy && allRelationsCopy.length > 0 && vnr) {

            if (window.confirm(`KursKey: "${kursKey}"\n\nVNR: "${vnr}"\n${allRelationsCopy.length} Teilnehmer an EIV melden?`)) {

                // fill a cleaned-up teilnehmer-array otherwise payload large
                var cleanedTeilnehmerArray = [];
                for (let i = 0; i < allRelationsCopy.length; i++) {
                    const relation = allRelationsCopy[i];

                    const cleanedTeilnehmer = {
                        EFN: relation.EFN,
                        // set Lernkontrolle for each Teilnehmer (1 -> all answers are correct)
                        Lernkontrolle: (relation.AnswerKey === relation.CorrectAnswerKey) ? 1 : 0
                    }

                    cleanedTeilnehmerArray.push(cleanedTeilnehmer);
                }

                // request-body
                const body = {
                    "VNR": vnr,
                    "Meldungsart": "N",
                    "Teilnehmer": cleanedTeilnehmerArray,
                    "Mail": "eiv@cmeducation.de",
                }

                // -- send it
                console.log("send to eiv: ", cleanedTeilnehmerArray);

                // SEND TEILNEHMER TO EIV
                const response = await sendRequest(tokenObject, setTokenObject, 'POST', '/eiv/sendToEiv', `Sende an EIV`, `An EIV gesendet`, body);
                if (response.success) {
                    // open response in a window
                    var myWindow = window.open("", "EIV Response Fenster", "width=800,height=400");
                    myWindow.document.write(response.data.html);

                    return true;
                }
                else return false;
            }
        }
        else return false;
    }
    catch (e) {
        console.log(e);
        return false;
    }
}

export { sendAllToEiv, sendToEiv }
import { useEffect, useState, useContext } from "react";
import { TokenContext } from "../Main.js";

import { formatDateToString } from "../../functions/helper.js";
import { exportXlsx } from "../../functions/fileHandler.js";
import { months } from "../../functions/helper.js";

import Loader from "../Loader.js";
import { sendRequest } from "../../rest/requests.js";


// -- statistic-component inside Kursteilnahme
const StatisticForm = ({ selectedCourse, id = 0 }) => {

    // access-token
    const [tokenObject, setTokenObject] = useContext(TokenContext);

    // -- states
    const [statisticObject, setStatisticObject] = useState();
    const [initialized, setInitialized] = useState(false);
    const [relations, setRelations] = useState();


    // -- on course update
    useEffect(() => { onCourseUpdate() }, [selectedCourse])
    const onCourseUpdate = async () => {

        setInitialized(false);

        if (selectedCourse) {
            // GET TEILNEHMER OF THIS COURSE FROM DB
            const response = await sendRequest(tokenObject, setTokenObject, 'POST', '/kursteilnahme/kursTeilnehmer', 'Lade Teilnehmer', 'Teilnehmer geladen', { KursKey: selectedCourse });

            // success
            if (response.success) setRelations(response.data);
        }
    }

    // -- on relations update
    useEffect(() => {
        if (relations && selectedCourse) {
            createStatistic();
            setInitialized(true);
        }
    }, [relations])


    // -- create statistic object which gets displayed as a table
    const createStatistic = () => {
        const copy = [...relations];

        // sort relations by Teilnahmedatum
        const sorted = copy.sort((a, b) => (new Date(a.Teilnahmedatum) - new Date(b.Teilnahmedatum)));

        const statistic = {};

        // -- run through sorted relations
        for (let i = 0; i < sorted.length; i++) {
            const relation = sorted[i];

            // kind of participation
            const teilnahmeKind = (relation.AD_Email) ? "QR-Code" : (relation.Antwortbogen ? "Antwortbogen" : "Online");

            // date
            const date = new Date(relation.Teilnahmedatum);
            const year = date.getFullYear();
            const monthInteger = date.getMonth();
            var month = months[monthInteger];

            if (!statistic[year]) {
                const obj = { "QR-Code": 0, Online: 0, Antwortbogen: 0 };
                // first elem
                statistic[year] = {};
                for (let j = 0; j < months.length; j++) {
                    const thisMonth = months[j];
                    statistic[year][thisMonth] = { ...obj };
                }

                statistic[year][month][teilnahmeKind]++;
            }
            else statistic[year][month][teilnahmeKind]++;
        }

        setStatisticObject(statistic);
    }


    // -- export statistic for one specific course
    const exportSingleCourseStatistic = (relations) => {

        // result
        const content = [];

        // run through kurs-teiln-relations from this course
        for (let i = 0; i < relations.length; i++) {
            const thisRelation = relations[i];

            // correct course answers
            const correctAnswers = thisRelation.CorrectAnswerKey ? thisRelation.CorrectAnswerKey : "";
            // answers the user gave
            const userAnswers = thisRelation.AnswerKey ? thisRelation.AnswerKey : "";

            // count how many answers are incorrect
            let incorrectCount = 0;
            for (let j = 0; j < correctAnswers.length; j++) {
                if (correctAnswers[j] !== userAnswers[j]) incorrectCount++;
            }


            // define one excel row
            content.push({
                VNR: thisRelation.VNR,
                EFN: thisRelation.EFN,
                Titel: thisRelation.Titel,
                Vorname: thisRelation.Vorname,
                Nachname: thisRelation.Nachname,
                Strasse: thisRelation.Strasse,
                Postleitzahl: thisRelation.Postleitzahl,
                Stadt: thisRelation.Stadt,
                Land: thisRelation.Land,
                Mail: thisRelation.Mail,
                Fachgebiet: thisRelation.Fachgebiet,
                Teilnahmedatum: formatDateToString(thisRelation.Teilnahmedatum),
                Teilnahmeart: thisRelation.AD_Email ? 'QR-Code' : (thisRelation.Antwortbogen ? 'Antwortbogen' : 'Online'),
                AD_Email: thisRelation.AD_Email,

                Antwortschlüssel: correctAnswers,

                'Frage 1': userAnswers[0],
                'Frage 2': userAnswers[1],
                'Frage 3': userAnswers[2],
                'Frage 4': userAnswers[3],
                'Frage 5': userAnswers[4],
                'Frage 6': userAnswers[5],
                'Frage 7': userAnswers[6],
                'Frage 8': userAnswers[7],
                'Frage 9': userAnswers[8],
                'Frage 10': userAnswers[9],

                Auswertung: userAnswers.length === 10 ? `${10 - incorrectCount} von 10 richtig` : 'Keine Antworten abgegeben'
            });
        }

        // download xlsx-file
        exportXlsx(content, `statistik_kurs_${relations[0].VNR}`);
    }



    if (initialized && relations && statisticObject) {
        return (
            <div id={`statistic-container-table-${id}`} className='statistic-container-table'>

                <h2>Statistik {selectedCourse}</h2>

                {/* statistic table */}
                {Object.keys(statisticObject).map((year, i) =>
                    <div key={`statistic-map-year-${i}`} className='table-container'>

                        <table>

                            {/* head */}
                            <thead>
                                <tr>
                                    <th>{year}</th>
                                    {Object.keys(statisticObject[year]).map((month, i) =>
                                        <th key={`thead-key-${i}`}>{month}</th>
                                    )}
                                </tr>
                            </thead>

                            {/* body */}
                            <tbody>
                                {/* map kind of QR-Code */}
                                <tr>
                                    <td>QR-Code</td>
                                    {Object.keys(statisticObject[year]).map((month, i) =>
                                        <td key={`tbody-0-key-${i}`}>{statisticObject[year][month]["QR-Code"] ? <div>{statisticObject[year][month]["QR-Code"]}</div> : <></>}</td>
                                    )}
                                </tr>
                                {/* map kind of Antwortbogen */}
                                <tr>
                                    <td>Antwortbogen</td>
                                    {Object.keys(statisticObject[year]).map((month, i) =>
                                        <td key={`tbody-1-key-${i}`}>{statisticObject[year][month].Antwortbogen ? <div>{statisticObject[year][month].Antwortbogen}</div> : <></>}</td>
                                    )}
                                </tr>
                                {/* map kind of Online */}
                                <tr>
                                    <td>Online</td>
                                    {Object.keys(statisticObject[year]).map((month, i) =>
                                        <td key={`tbody-2-key-${i}`}>{statisticObject[year][month].Online ? <div>{statisticObject[year][month].Online}</div> : <></>}</td>
                                    )}
                                </tr>
                            </tbody>
                        </table>

                    </div>
                )}

                {/* export button */}
                <div className="basic-btn" onClick={() => exportSingleCourseStatistic(relations)}>Exportiere Kursstatistik {selectedCourse}</div>
            </div>

        )
    }
    else {
        return <Loader />
    }
}

export default StatisticForm
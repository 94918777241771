import { useState, useEffect, useContext } from 'react'
import { TokenContext } from '../Main'

import { AiOutlineDown, AiOutlinePlusCircle } from 'react-icons/ai'
import { FiTrash2 } from 'react-icons/fi'

import Loader from './../Loader'
import ReferentenForm from './ReferentenForm';
import Search from '../Search';
import AddNewForm from '../AddNewForm';
import Popup from '../Popup';

import { toggleTable } from './../../functions/helper.js'
import { sendRequest } from '../../rest/requests';
import { getReferentenFromDatabase } from '../../rest/requests_get_all';


const ReferentenManager = ({ allReferenten, setAllReferenten }) => {

    // access-token
    const [tokenObject, setTokenObject] = useContext(TokenContext);

    const [initialized, setInitialized] = useState(false);
    const [selectedReferent, setSelectedReferent] = useState();
    const [searchResults, setSearchResults] = useState(allReferenten);

    useEffect(() => {
        initReferentManager();
    }, []);
    const initReferentManager = async () => {
        setInitialized(true);
    }


    // -- add referent 
    const addNewReferent = async (nachname, errorMessage) => {

        // ADD REFERENT TO DATABASE
        const response = await sendRequest(tokenObject, setTokenObject, 'POST', '/referenten/erstellen', `Erstelle "${nachname}"`, `Referent "${nachname}" erstellt`, { "Nachname": nachname });

        // success
        if (response.success) refresh();
        // error
        else errorMessage.innerHTML = "Datenbank-Fehler. Möglicherweiße ist dieser Name schon vergeben";
    }


    // -- delete this referent
    const deleteThisReferent = async (e, thisReferent) => {
        e.stopPropagation();

        const confirm = window.confirm(`Sicher, dass du den Referenten "${thisReferent.REFERENT_ID}" löschen möchtest?`);

        if (confirm) {

            // DELETE REFERENT FROM DATABASE
            const response = await sendRequest(tokenObject, setTokenObject, 'DELETE', `/referenten/delete/${thisReferent.REFERENT_ID}`, `Lösche Referent "${thisReferent.REFERENT_ID}"`, `Referent "${thisReferent.REFERENT_ID}" gelöscht`);

            // success
            if (response.success) refresh();
            // error
            else alert("Beim löschen eines Referenten ging etwas schief");
        }
    }

    // -- refresh ReferentenManager
    const refresh = async () => {
        // update locally
        const newAllReferenten = await getReferentenFromDatabase(tokenObject, setTokenObject);
        setSearchResults(newAllReferenten);
        setAllReferenten(newAllReferenten);

        // refresh timeout
        setInitialized(false);
        setTimeout(() => setInitialized(true), 500);
    }


    if (initialized && allReferenten && !selectedReferent)
        return (
            <div className='basic-container'>

                <span className='headline-add-btn'>
                    {/* headline */}
                    <h1>Referenten</h1>

                    {/* add-new popup */}
                    <Popup
                        content={
                            // ADD NEW REFERENT
                            <AddNewForm onSubmit={addNewReferent} inputName={"Nachname: "} />
                        }
                        button={<AiOutlinePlusCircle />}
                    />
                </span>

                <Search input={allReferenten} callback={setSearchResults} searchKeys={["Vorname", "Nachname", "Allgemein"]} />

                {/* referenten-table */}
                <div className='table-container' active='true'>

                    {/* table-header */}
                    <div className='table-header' onClick={toggleTable}>
                        <AiOutlineDown />{searchResults.length} Referent<sup>*</sup>innen
                    </div>

                    {/* table */}
                    <table>
                        {/* headline */}
                        <thead>
                            <tr>
                                <th>Nachname</th>
                                <th>Vorname</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            {/* teilnehmer-list */}
                            {searchResults.map((referent, index) =>
                                <tr key={`referent-row-${index}`} className='pointer' onClick={() => setSelectedReferent(referent)}>
                                    <td>{referent.Nachname}</td>
                                    <td>{referent.Vorname}</td>
                                    <td className='column-icon delete' onClick={(e) => deleteThisReferent(e, referent)}><FiTrash2 /></td>
                                </tr>
                            )}
                        </tbody>
                    </table>

                </div>

            </div>
        )
    else if (selectedReferent)
        return (
            <ReferentenForm selectedReferent={selectedReferent} setAllReferenten={setAllReferenten} />
        )
    else
        return (
            <Loader />
        )
}

export default ReferentenManager
import { useEffect, useState, useContext } from "react"
import { TokenContext } from "../Main"

import { printInput, printInputWithPreview, printTextArea } from "../InputComponents"
import { getAllPodcastSerien } from "../../rest/requests_get_all"
import { sendPatchRequest, sendRequest } from "../../rest/requests"
import Loader from "../Loader"

const PodcastSerieForm = ({ serienId, setAllPodcastSerien }) => {

    // access-token
    const [tokenObject, setTokenObject] = useContext(TokenContext);

    const [initialized, setInitialized] = useState(false);
    const [thisSerie, setThisSerie] = useState();

    // -- init
    useEffect(() => {
        initSerieForm();
    }, [])
    const initSerieForm = async () => {

        // GET THIS SERIE FROM DATABASE
        const response = await sendRequest(tokenObject, setTokenObject, 'GET', `/podcast-serien/${serienId}`, `Lade Serie mit ID ${serienId}`, `Serie mit ID ${serienId} geladen`);

        if (response.success) {
            setThisSerie(response.data[0]);
            setInitialized(true);
        }
    }


    // -- update serie
    useEffect(async () => {
        if (initialized) {
            // UPDATE SERIE IN DATABASE
            await sendPatchRequest(tokenObject, setTokenObject, `/podcast-serien/${thisSerie.PODCAST_SERIE_ID}`, thisSerie, `Speichere Serie "${thisSerie.Name}"`, `Serie "${thisSerie.Name}" gespeichert`);

            // GET ALL SERIEN AND UPDATE LOCAL-STATE
            setAllPodcastSerien(await getAllPodcastSerien(tokenObject, setTokenObject))

        }
    }, [thisSerie])

    const updateSerie = (key, value) => {
        setThisSerie((prevState) => ({
            ...prevState,
            [key]: value
        }))
    }


    if (initialized) {
        return (
            <div className="basic-container">
                <div className='form-container'>

                    <h3>{thisSerie.Name}</h3>

                    {/* id */}
                    {printInput(undefined, `serie-id-input-${thisSerie.PODCAST_SERIE_ID}`, 'Serie ID', thisSerie.PODCAST_SERIE_ID, true, undefined, 'text', '', true)}

                    {/* subtitle */}
                    {printInput((e) => updateSerie("Untertitel", e.currentTarget.value), `serie-subtitle-input-${thisSerie.PODCAST_SERIE_ID}`, 'Untertitel', thisSerie.Untertitel, true, undefined, 'text', 'large', false, "", true)}

                    {/* description */}
                    {printTextArea((e) => updateSerie("Beschreibung", e.currentTarget.value), `serie-description-input-${thisSerie.PODCAST_SERIE_ID}`, 'Beschreibung', thisSerie.Beschreibung)}

                    {/* Banner-url */}
                    {printInputWithPreview(true, (e) => updateSerie("Banner_url", e.currentTarget.value), `serie-banner-input-${thisSerie.PODCAST_SERIE_ID}`, 'Banner-URL', thisSerie.Banner_url)}

                </div>
            </div>
        )
    }
    else {
        return <Loader />
    }
}

export default PodcastSerieForm
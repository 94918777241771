import { addSpecificElement, removeSpecificElement } from './../../functions/helper.js'


// ::::::::::::::::::::::::::::::::::
// ::::::::: CHANGE-HANDLER :::::::::
// ::::::::::::::::::::::::::::::::::
//#region 
// -- simple onchange function
export function onChange(key, value, setThisCourse, isObject = false, oldEntry = null, firstKey = null, secondKey = null) {

    // if date - check format
    if (key === "Laufzeit_Start" || key === "Laufzeit_Ende") {
        const regex = /^\d{4}-\d{2}-\d{2}$/;
        if (value.match(regex) === null) return false;
    }

    // entry in course is an object or array
    if (isObject && oldEntry !== null && firstKey !== null) {
        // new value is array/object-elem
        if (firstKey !== null && secondKey === null) oldEntry[firstKey] = value;
        // new value is an object/array inside another array
        else if (firstKey !== null && secondKey !== null) oldEntry[firstKey][secondKey] = value;
        // set new state
        setThisCourse(prev => ({
            ...prev,
            [key]: oldEntry
        }))
    }
    // basic key/value pair in course
    else {
        setThisCourse((prevState) => ({
            ...prevState,
            [key]: value,
        }))
    }
}


export function slidesChange(chartIndex, partIndex, thisCourse, setThisCourse) {

    // get values of this html input-elements
    const allParts = document.querySelectorAll('.single-part-container');

    const thisChartElement = allParts[partIndex].querySelectorAll('.chart-input-container')[chartIndex];

    const chartNumber = chartIndex + 1;
    const chartTitle = thisChartElement.querySelector('.chart-title-input').value;
    const chartTime = thisChartElement.querySelector('.chart-time-input').value;

    const isVideoPoster = thisChartElement.querySelector('.chart-is-video-input').checked;

    // create new entry for this chart
    const newChart = {
        "chartNr": chartNumber,
        "title": chartTitle,
        "time": chartTime
    }

    // check if this chart is a videoposter
    if (isVideoPoster) {
        const videoPoster = thisChartElement.querySelector('.chart-videoposter-input').value;
        newChart.videoPoster = videoPoster;
    }

    var newSlides = [...thisCourse.slides];

    newSlides[partIndex][chartIndex] = newChart;


    setThisCourse((prevState) => ({
        ...prevState,
        slides: newSlides
    }));
}
export function wissensfragenChange(questionIndex, thisCourse, setThisCourse) {

    const thisQuestionElement = document.querySelectorAll('.wissensfragen-input-container')[questionIndex];

    // input-values
    const questionNr = questionIndex + 1;
    const headline = thisQuestionElement.querySelector('.headline-input').value;
    const subheadline = thisQuestionElement.querySelector('.subheadline-input').value;
    const question = thisQuestionElement.querySelector('.question-input').value;
    const legende = thisQuestionElement.querySelector('.legende-input').value;

    // answers
    var answerArray = [];
    const answers = thisQuestionElement.querySelectorAll(".wissensfragen-answers-input-container");
    answers.forEach((currentAnswer, index) => {
        // set answer-name
        let answerName;
        switch (index) {
            case 0: answerName = 'A'; break;
            case 1: answerName = 'B'; break;
            case 2: answerName = 'C'; break;
            case 3: answerName = 'D'; break;
            case 4: answerName = 'E'; break;
        }
        // single answer
        answerArray.push({
            "name": answerName,
            "answertext": currentAnswer.querySelector('.answer-answertext-input').value
        })
    })

    const newQuestion = {
        "questionNr": questionNr,
        "headline": headline,
        "subheadline": subheadline,
        "question": question,
        "legende": legende,
        "answers": answerArray
    }

    const newElement = [...thisCourse.Wissensfragen];
    newElement[questionIndex] = newQuestion;

    setThisCourse((prevState) => ({
        ...prevState,
        Wissensfragen: newElement
    }));
}
export function correctAnswerKeyChange(e, setThisCourse, oldAnswerKey, questionIndex) {

    // input answer
    const key = e.currentTarget.value;

    var newCorrectAnswerKey = "";

    for (let i = 0; i < 10; i++) {
        if (i === questionIndex) {
            // new key at i
            newCorrectAnswerKey += key;
        }
        else if (oldAnswerKey && oldAnswerKey[i]) {
            // keep key at
            newCorrectAnswerKey += oldAnswerKey[i];
        }
        else {
            // nothing set
            newCorrectAnswerKey += 'A';
        }
    }


    setThisCourse((prevState) => ({
        ...prevState,
        CorrectAnswerKey: newCorrectAnswerKey
    }));

}

// referenten-change excluding Interessenskonflite
export function referentenChange(targetId, sourceIndex, courseReferenten, allAvailableReferenten, setThisCourse, key = "Referenten") {

    // get index of selected ref in allAvailableReferenten
    let targetIndex;
    allAvailableReferenten.forEach((thisRef, i) => {
        if (thisRef.REFERENT_ID === parseInt(targetId)) targetIndex = i;
    });

    // replace ref at sourceIndex of previous array
    if (targetIndex !== undefined) {
        // copy referenten of course
        let newArray = [...courseReferenten];

        // change a specific referent in array
        newArray[sourceIndex] = { ...newArray[sourceIndex], ...allAvailableReferenten[targetIndex] };

        // set referenten-state
        setThisCourse((prevState) => ({
            ...prevState,
            [key]: newArray
        }));
    }
}
// interessenskonflikte-change
export function interessenskonflikteChange(e, sourceIndex, thisCourse, setThisCourse, key = "Referenten") {
    const value = e.currentTarget.value;

    var newArray = [...thisCourse[key]];
    newArray[sourceIndex].Interessenskonflikte = value;

    setThisCourse((prevState) => ({
        ...prevState,
        [key]: newArray
    }));
}
//#endregion


// ::::::::::::::::::::::::::::::::::::::::::::
// ::::::::: ADD AND REMOVE FUNCTIONS :::::::::
// ::::::::::::::::::::::::::::::::::::::::::::
//#region 
// -- charts - special handling because array within array
export function removeChart(partIndex, setThisCourse, thisCourse) {
    var chartArray = thisCourse.slides;

    if (chartArray[partIndex].length > 1) {
        chartArray[partIndex].pop();

        setThisCourse((prevState) => ({
            ...prevState,
            slides: chartArray
        }));
    }
}
export function addChart(partIndex, setThisCourse, thisCourse) {
    var chartArray = thisCourse.slides;

    chartArray[partIndex].splice(chartArray[partIndex].length, 0, {
        "chartNr": (chartArray[partIndex].length + 1),
        "title": "NEU",
        "time": "0:00"
    });

    setThisCourse((prevState) => ({
        ...prevState,
        slides: chartArray
    }));
}
export function removeSpecificChart(elemIndex, partIndex, setThisCourse, thisCourse) {
    if (window.confirm(`Chart ${elemIndex + 1} löschen?\n"${thisCourse.slides[partIndex][elemIndex].title}"`)) {
        var chartArray = thisCourse.slides;

        if (chartArray[partIndex].length > 1) {

            chartArray[partIndex] = removeSpecificElement(chartArray[partIndex], elemIndex);

            // set ChartNr new
            chartArray[partIndex].forEach((currentChart, index) => {
                currentChart.chartNr = (index + 1);
            });

            setThisCourse((prevState) => ({
                ...prevState,
                slides: chartArray
            }));
        }
    }
}
export function addSpecificChart(elemIndex, partIndex, setThisCourse, thisCourse) {
    var chartArray = thisCourse.slides;
    chartArray[partIndex] = addSpecificElement(chartArray[partIndex], elemIndex, {
        "chartNr": "",
        "title": "NEU",
        "time": "0:00"
    });

    // set ChartNr new
    chartArray[partIndex].forEach((currentChart, index) => {
        currentChart.chartNr = (index + 1);
    });

    setThisCourse((prevState) => ({
        ...prevState,
        slides: chartArray
    }));
}



// -- general
export function addEntry(key, value, thisCourse, setThisCourse, insertIndex = undefined) {
    // copy old entry
    const newArray = [...thisCourse[key]];
    // add value to array at index insertIndex
    addSpecificElement(newArray, insertIndex !== undefined ? insertIndex : newArray.length, value);
    // set course
    setThisCourse(prev => ({
        ...prev,
        [key]: newArray
    }));
}
export function removeEntry(key, removeIndex, thisCourse, setThisCourse) {
    const newArray = [...thisCourse[key]];
    if (window.confirm(`Sicher, dass du den Eintrag Nummer ${removeIndex + 1} löschen möchtest?`)) {
        // remove elem with removeIndex
        newArray.splice(removeIndex, 1);
        setThisCourse(prev => ({
            ...prev,
            [key]: newArray
        }))
    }
}
//#endregion
import { AiOutlineHome } from 'react-icons/ai'
import { BiRefresh, BiLogOut } from 'react-icons/bi'
import { GiHamburgerMenu } from 'react-icons/gi'


const Header = ({ headerTitle, goToHome, toggleMenu, logoutUser, refresh }) => {

    return (
        <header>
            {/* burger-menu */}
            <div className='toggle-burger-btn-container' onClick={toggleMenu}>
                <GiHamburgerMenu />
            </div>

            {/* go home */}
            <div className='home-btn-container' onClick={goToHome}>
                <AiOutlineHome />
            </div>


            {/* title */}
            <h2>{headerTitle}</h2>


            {/* refresh app */}
            <div className='refresh-btn-container' onClick={refresh}>
                <BiRefresh />
            </div>

            {/* logout */}
            <div className='logout-container' onClick={logoutUser}><BiLogOut /></div>
        </header>
    )
}
export default Header
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from 'react-icons/ai'

const AddRemoveButton = ({ removeText, removeFunction, addText, addFunction, textColor = undefined, title = undefined, small = false }) => {
    return (
        <div className={`multiple-btn-container ${small ? 'small' : ''}`}>
            {/* title */}
            {title ? <div className='title'>{title}</div> : <></>}
            {/* add-btn */}
            <div className={`remove ${small ? '' : 'basic-btn'}`} style={textColor ? { color: textColor } : {}} onClick={removeFunction}><AiOutlineMinusCircle /> {removeText}</div>
            {/* remove-btn */}
            <div className={`add ${small ? '' : 'basic-btn'}`} style={textColor ? { color: textColor } : {}} onClick={addFunction}><AiOutlinePlusCircle /> {addText}</div>
        </div>
    )
}

export default AddRemoveButton
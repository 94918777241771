import { useContext } from 'react';

import { TokenContext } from './Main.js';
import { sendRequest } from '../rest/requests.js';

import Loader from './Loader';
import Register from './Register.js';

const Login = ({ setLoggedInUser, userFirstname, userLastname, setUserFirstname, setUserLastname, setUserRole, userMail, setUserMail }) => {

    // access-token
    const [tokenObject, setTokenObject] = useContext(TokenContext);

    // -- validate login input
    const validateLogin = async (e) => {
        e.preventDefault();

        var submitBtn = document.querySelector("#login-form-submit-btn");
        var loader = document.querySelector("#login-form-loader");
        submitBtn.style.display = "none";
        loader.style.display = "block";

        var loginMessage = document.querySelector('#login-message');
        loginMessage.innerHTML = "";

        const form = e.currentTarget;
        const user = { Mail: userMail, Passwort: form.loginPassword.value };

        // login
        await loginUser(user, loginMessage);

        submitBtn.style.display = "block";
        loader.style.display = "none";
    }



    // -- try login
    const loginUser = async (user, loginMessage) => {
        try {

            // SEND LOGINDATA TO BACKEND
            const response = await sendRequest(tokenObject, setTokenObject, 'POST', '/nutzer/login', undefined, undefined, user);
            const userData = response.data;

            if (response.success) {
                // login successfull

                // set token-object
                setTokenObject({
                    'accessToken': userData.accessToken,
                    'refreshToken': userData.refreshToken,
                    'userMail': user.Mail
                });

                // set user
                setLoggedInUser(true);
                setUserFirstname(userData.Vorname);
                setUserLastname(userData.Nachname);
                setUserRole(userData.Rolle);

                loginMessage.innerHTML = "";
            }
            else {
                // login failed
                loginMessage.innerHTML = response.message ? response.message : "Undefinierter Fehler aufgetreten";
            }
        }
        catch (e) {
            loginMessage.innerHTML = 'Undefinierter Fehler aufgetreten';
        }
    }



    return (
        <div className='gray-background'>
            <div className='box-container'>
                <div className="form-container login-container">
                    <h3>Anmelden</h3>

                    <form onSubmit={validateLogin}>
                        <div active="true">

                            {/* Mail */}
                            <div className="input-container large">
                                <label htmlFor="login-mail">E-Mail</label>
                                <input id="login-mail" name="loginMail" type="email" value={userMail}
                                    onChange={(e) => setUserMail(e.currentTarget.value)}
                                    autoComplete='email'
                                />
                            </div>

                            {/* Passwort */}
                            <div className="input-container large">
                                <label htmlFor="login-password">Passwort</label>
                                <input id="login-password" name="loginPassword" type="password" autoComplete='current-password' />
                            </div>

                            {/* message */}
                            <div id='login-message'></div>

                            <div className="input-container large">
                                <input id="login-form-submit-btn" type="submit" value={"Anmelden"} />
                            </div>

                            {/* loader */}
                            <div id='login-form-loader'>
                                <Loader />
                            </div>
                        </div>
                    </form>

                    {/* register new user */}
                    <div className='input-container large'>
                        <Register
                            userFirstname={userFirstname}
                            userLastname={userLastname}
                            setUserFirstname={setUserFirstname}
                            setUserLastname={setUserLastname}
                            userMail={userMail}
                            setUserMail={setUserMail}
                        />
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Login
import Loader from "./Loader"

//  session expired overlay 
const SessionExpired = () => {
    return (
        <div id='session-expired-overlay' className='gray-background'>
            <div className='box-container'>
                <Loader />
                <p>Session ist abgelaufen. Lade erneut.</p>
            </div>
        </div>
    )
}

export default SessionExpired
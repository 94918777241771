import { useEffect, useState, createContext } from 'react'

import './../assets/css/main.css'
import './../assets/css/form.css'
import './../assets/css/header.css'
import './../assets/css/footer.css'
import './../assets/css/sessionExpired.css'
import './../assets/css/kurse.css'
import './../assets/css/table.css'
import './../assets/css/login.css'
import './../assets/css/teilnehmer.css'
import './../assets/css/zertifikate_vorlagen.css'
import './../assets/css/werkzeuge.css'
import './../assets/css/search.css'
import './../assets/css/popup.css'
import './../assets/css/import.css'
import './../assets/css/statistic.css'
import './../assets/css/importExport.css'

import Login from './Login'
import Overview from './Overview'
import SessionExpired from './SessionExpired'

import { sendRequest } from '../rest/requests'


// create a context for tokenobject - providing tokenobject across multiple components
const TokenContext = createContext();


export const Main = () => {

    // -- session- and user-states
    const [userFirstname, setUserFirstname] = useState("");
    const [userLastname, setUserLastname] = useState("");
    const [userMail, setUserMail] = useState("");
    const [userRole, setUserRole] = useState("Nutzer");
    const [loggedInUser, setLoggedInUser] = useState(false);

    // -- token-object containing neccesarry information
    const [tokenObject, setTokenObject] = useState();

    // -- set page title
    useEffect(() => {
        document.title = "CMEassist " + process.env.REACT_APP_VERSION;
    }, []);


    // -- logout user
    const logoutUser = async () => {
        setUserLastname("");
        setLoggedInUser(false);
        setTokenObject();

        const response = await sendRequest(tokenObject, setTokenObject, 'POST', `/nutzer/logout/${userMail}`);
        if (response.success) console.log("Logout successful");
        else console.log("Failed to logout");
    }


    return (
        <TokenContext.Provider value={[tokenObject, setTokenObject]}>

            {loggedInUser ?
                <>
                    <Overview
                        userLastname={userLastname}
                        userRole={userRole}
                        logoutUser={logoutUser}
                    />

                    <SessionExpired />
                </>
                :
                <Login
                    userFirstname={userFirstname}
                    userLastname={userLastname}
                    userMail={userMail}
                    setUserFirstname={setUserFirstname}
                    setUserLastname={setUserLastname}
                    setUserRole={setUserRole}
                    setUserMail={setUserMail}

                    setLoggedInUser={setLoggedInUser}
                />
            }
        </TokenContext.Provider>
    )
}


export default Main

export { TokenContext }
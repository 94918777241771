import { useState } from 'react';
import { sendRequest } from '../rest/requests.js';
import Loader from './Loader';
import Popup from './Popup.js';

const Register = ({ userFirstname, userLastname, setUserFirstname, setUserLastname, userMail, setUserMail }) => {

    // to be set when registered
    const [registeredSuccessfull, setRegisteredSuccessfull] = useState(false);

    // -- validate registration
    const validateRegistration = async (e) => {
        e.preventDefault();

        const loader = document.querySelector('#register-form-loader');
        const submitBtn = document.querySelector('#register-form-submit-btn');
        var registerMessage = document.querySelector('#register-message');

        submitBtn.style.display = "none";
        loader.style.display = "block";
        registerMessage.innerHTML = "";
        registerMessage.style.color = "red";


        const form = e.currentTarget;
        const user = {
            Vorname: userFirstname,
            Nachname: userLastname,
            Mail: userMail,
            Passwort: form.registerPassword.value
        };

        // validate
        if (!user.Vorname || !user.Nachname || !user.Passwort) {
            // empty inputs
            registerMessage.innerHTML = "Angaben dürfen nicht leer sein!";
        }
        else if (form.registerPassword.value !== form.registerRepeatPassword.value) {
            // repeated password does not match
            registerMessage.innerHTML = "Passwörter müssen übereinstimmen!";
        }
        else {
            // SUCCESS -> login
            await createThisUser(user, registerMessage);
        }
        submitBtn.style.display = "block";
        loader.style.display = "none";
    }

    // -- create a user
    const createThisUser = async (user, message) => {
        const response = await sendRequest(undefined, undefined, 'POST', '/nutzer', undefined, undefined, user);

        // successfully registered
        if (response.success) setRegisteredSuccessfull(true);
        // error
        else message.innerHTML = response.message ? response.message : 'Undefinierter Fehler aufgetreten.<br>Möglicherweise ist Nutzer bereits in Datenbank vorhanden.';
    }

    if (!registeredSuccessfull) {

        return (
            <div>

                {/* add-new popup */}
                <Popup
                    content={
                        <div>
                            <h5>Erstelle Nutzer</h5>
                            <div className='form-container login-container' style={{ padding: "1rem 0" }}>
                                <form onSubmit={validateRegistration}>
                                    <div active="true">

                                        {/* Vorname */}
                                        <div className="input-container">
                                            <label htmlFor="register-firstname">Vorname</label>
                                            <input id="register-firstname" name="registerFirstname" type="text" value={userFirstname} onChange={(e) => setUserFirstname(e.currentTarget.value)} />
                                        </div>

                                        {/* Nachname */}
                                        <div className="input-container">
                                            <label htmlFor="register-lastname">Nachname</label>
                                            <input id="register-lastname" name="registerLastname" type="text" value={userLastname} onChange={(e) => setUserLastname(e.currentTarget.value)} />
                                        </div>

                                        {/* Mail */}
                                        <div className="input-container large">
                                            <label htmlFor="register-mail">Mail</label>
                                            <input id="register-mail" name="registerMail" type="email" value={userMail} onChange={(e) => setUserMail(e.currentTarget.value)} />
                                        </div>

                                        {/* Passwort */}
                                        <div className="input-container">
                                            <label htmlFor="register-password">Passwort</label>
                                            <input id="register-password" name="registerPassword" type="password" autoComplete='none' />
                                        </div>

                                        {/* Repeat-Passwort */}
                                        <div className="input-container">
                                            <label htmlFor="register-repeat-password">Passwort wiederholen</label>
                                            <input id="register-repeat-password" name="registerRepeatPassword" type="password" autoComplete='none' />
                                        </div>

                                        {/* message */}
                                        <div id='register-message'></div>

                                        <input id="register-form-submit-btn" className='basic-btn left' type="submit" value={"Registrieren"} />

                                        {/* loader */}
                                        <div id='register-form-loader'>
                                            <Loader />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    }
                    button={<a style={{ textDecoration: 'underline' }}>Registrieren</a>}
                />
            </div>
        )
    }
    else {
        return (
            <div>Erfolgreich registriert. Bitte lass dir von einem Adminstrator oder Projektleiter die entsprechenden Rechte geben.</div>
        )
    }
}

export default Register

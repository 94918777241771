
/**
 *  TEXTEDITOR-HANDLER
 */
function modifyTextarea(e, modifyType) {
    let thisTextarea = e.currentTarget.parentNode.parentNode.querySelector('textarea');

    // get selected text
    var start = thisTextarea.selectionStart;
    var end = thisTextarea.selectionEnd;

    // define new textarea-input
    var modifiedTextarea = thisTextarea.value;
    modifiedTextarea = modifiedTextarea.slice(0, end) + `</${modifyType}>` + modifiedTextarea.slice(end)
    modifiedTextarea = modifiedTextarea.slice(0, start) + `<${modifyType}>` + modifiedTextarea.slice(start)

    // fire onchange event to change state
    const lastValue = thisTextarea.value;
    thisTextarea.value = modifiedTextarea;

    const event = new Event("change", { bubbles: true });
    const tracker = thisTextarea._valueTracker;
    if (tracker) {
        tracker.setValue(lastValue);
    }
    thisTextarea.dispatchEvent(event);

    // focus selected
    thisTextarea.select();
    thisTextarea.selectionStart = start + (modifyType.length + 2); // plus length of inserted html tag
    thisTextarea.selectionEnd = end + (modifyType.length + 2);
}


export const Texteditor = ({ text }) => {
    return (
        <div className="editor-btns">
            <div onClick={(e) => modifyTextarea(e, "u")}><u>U</u></div>
            <div onClick={(e) => modifyTextarea(e, "strong")}><strong>B</strong></div>
            <div onClick={(e) => modifyTextarea(e, "i")}><i>I</i></div>

            <div style={{ "border": "none", backgroundColor: "transparent" }}></div>
            <div onClick={(e) => modifyTextarea(e, "ul")}>Liste</div>
            <div onClick={(e) => modifyTextarea(e, "li")}>Listenelement</div>

            <div style={{ "border": "none", backgroundColor: "transparent" }}></div>
            <div onClick={(e) => modifyTextarea(e, "sub")}>Tiefstellen</div>
            <div onClick={(e) => modifyTextarea(e, "sup")}>Hochstellen</div>

        </div>
    )
}

export default Texteditor
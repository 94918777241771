import { useState, useEffect, useContext } from 'react'
import { TokenContext } from '../Main';

import { FiTrash2 } from 'react-icons/fi'
import { AiOutlinePlusCircle } from 'react-icons/ai'

import { sendRequest } from '../../rest/requests';
import { printSelect } from '../InputComponents.js';

import Popup from '../Popup.js';
import Loader from './../Loader'
import AddNewForm from '../AddNewForm'
import ZertifikatForm from './ZertifikatForm.js'


const ZertifikateManager = ({ setHeaderTitle, zertifikatVorlagen }) => {

    // access-token
    const [tokenObject, setTokenObject] = useContext(TokenContext);

    const [initialized, setInitialized] = useState(false);
    const [selectedZertifikatVorlage, setSelectedZertifikatVorlage] = useState();

    // -- name of vorlage for position-template
    const [nameOfTemplate, setNameOfTemplate] = useState();

    useEffect(() => {
        initZertifikateManager();
    }, []);
    const initZertifikateManager = async () => {
        setInitialized(true);
    }


    // -- create new Zertifikat-Vorlage
    const createNewVorlage = async (newVorlageName, errorMessage) => {

        // select template
        var positionTemplate;
        if (nameOfTemplate) {
            var template = zertifikatVorlagen.filter(elem => elem.Name === nameOfTemplate);
            positionTemplate = template[0].Positionen;
        }
        else positionTemplate = [];

        // SAVE NEW VORLAGE IN DATABASE
        const response = await sendRequest(tokenObject, setTokenObject, 'POST', '/zertifikate/vorlagen/create', `Erstelle Vorlage "${newVorlageName}"`, `Vorlage "${newVorlageName}" erstellt`, { Name: newVorlageName, Positionen: positionTemplate });

        // success go to form
        if (response.success) setSelectedZertifikatVorlage({ "Name": newVorlageName });
        // error
        else errorMessage.innerHTML = "Datenbank-Fehler. Möglicherweise ist dieser Name bereits vergeben"
    }

    // -- delete a zertifikat-vorlage
    const deleteThisVorlage = async (vorlage) => {
        const confirm = window.confirm(`Sicher, dass du die Vorlage "${vorlage.Name}" löschen möchtest?`);

        if (confirm) {
            // DELETE THIS VORLAGE FROM DATABASE
            const response = await sendRequest(tokenObject, setTokenObject, 'DELETE', '/zertifikate/vorlagen/delete', `Lösche "${vorlage.Name}"`, `"${vorlage.Name}" gelöscht`, { Name: vorlage.Name });
            if (!response.success) alert('Fehler beim Löschen der Zertifikat-Vorlage');
        }
    }


    if (initialized && !selectedZertifikatVorlage)
        return (
            <div className='basic-container'>

                <span className='headline-add-btn'>
                    {/* headline */}
                    <h1>Zertifikate Vorlagen</h1>

                    {/* add-new popup */}
                    <Popup
                        content={
                            <div className='form-container'>
                                {/* use template */}
                                <h5>Neues Zertifikat erstellen</h5>
                                {printSelect((e) => { setNameOfTemplate(e.currentTarget.value) }, 'select-position-template', 'Vorlage verwenden: ', nameOfTemplate, zertifikatVorlagen.map(elem => elem.Name))}
                                {/* Create Vorlage */}
                                <AddNewForm onSubmit={createNewVorlage} inputName={"Name: "} title={""} />
                            </div>
                        }
                        button={<AiOutlinePlusCircle />}
                    />
                </span>

                <h4>Alle Vorlagen</h4>
                <div className='table-container'>
                    <table>
                        <tbody>
                            {zertifikatVorlagen.map((vorlage, index) =>
                                <tr key={`zertifikat-vorlage-row-${index}`}>
                                    <td className='underline' onClick={() => setSelectedZertifikatVorlage(vorlage)}>{vorlage.Name}</td>
                                    <td className='column-icon underline delete' onClick={() => { deleteThisVorlage(vorlage) }}><FiTrash2 /></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className='seperator'></div>

            </div>
        )
    else if (selectedZertifikatVorlage)
        return (
            <ZertifikatForm selectedZertifikatVorlage={selectedZertifikatVorlage} zertifikatVorlagen={zertifikatVorlagen} setHeaderTitle={setHeaderTitle} />
        )
    else
        return (
            <Loader />
        )
}

export default ZertifikateManager
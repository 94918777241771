import { ImCross } from 'react-icons/im'

const Popup = ({ id = 0, content, button, initialeActive = 'false', contentIsFunction = false, large = false }) => {

    // get popup-element
    const getThisPopup = () => {
        return document.querySelector(`#popup-component-${id}`).querySelector('.popup');
    }

    // open
    const openPopup = () => getThisPopup().setAttribute('active', 'true');
    // close
    const closePopup = () => getThisPopup().setAttribute('active', 'false');

    return (
        <div id={`popup-component-${id}`} className={`popup-container ${large ? 'large-popup' : ''}`}>

            <div className="popup" onClick={closePopup} active={initialeActive}>
                <div className="popup-content" onClick={(e) => e.stopPropagation() /* prevent closepopup */}>
                    {contentIsFunction ? content(closePopup) : content}

                    <div className="exit" onClick={closePopup}><ImCross /></div>
                </div>
            </div>

            <span className='open-popup-btn' onClick={openPopup}>{button}</span>
        </div>
    )
}

export default Popup
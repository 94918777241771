import { useState, useEffect, useContext } from "react";
import { TokenContext } from "../Main";
import { AiOutlineDown } from "react-icons/ai";
import { FiTrash2 } from 'react-icons/fi'

import { sendRequest } from "../../rest/requests";
import { toggleTable } from "../../functions/helper";

import Loader from "../Loader";
import SponsorenForm from "./SponsorForm";

const SponsorenManager = ({ }) => {

    // access-token
    const [tokenObject, setTokenObject] = useContext(TokenContext);

    // init
    const [initialized, setInitialized] = useState(false);

    // selected sponsro
    const [selectedSponsor, setSelectedSponsor] = useState();

    // sponsoren
    const [sponsoren, setSponsoren] = useState();


    // initialize component
    useEffect(() => { init() }, []);
    const init = async () => {
        // GET ALL SPONSORS FROM DATABASE
        const response = await sendRequest(tokenObject, setTokenObject, 'GET', '/sponsoren', 'Lade Sponsoren', 'Sponsoren geladen');
        if (response.success) {
            setSponsoren(response.data);
            setInitialized(true);
        }
    }

    // add
    const add = async () => {
        const newValue = window.prompt("Bitte trage den Name des Sponsoren ein");

        if (newValue) {
            setInitialized(false);

            // ADD SPONSOR TO DATABASE
            const response = await sendRequest(tokenObject, setTokenObject, 'POST', '/sponsoren', 'Erstelle Sponsor', 'Sponsor erstellt', { "Name": newValue });
            if (response.success) init();
        }
    }

    // delete
    const deleteSponsor = async (e, thisSponsor) => {
        e.stopPropagation();

        const confirm = window.confirm(`Sponsor "${thisSponsor.Name}" wirklich löschen?`);

        if (confirm) {
            setInitialized(false);

            // DELETE SPONSOR FROM DATABASE
            const response = await sendRequest(tokenObject, setTokenObject, 'DELETE', '/sponsoren', `Lösche ${thisSponsor.Name}`, `${thisSponsor.Name} gelöscht`, thisSponsor);
            if (response.success) init();
        }
    }


    if (initialized && !selectedSponsor) {
        return (
            <div className="basic-container">
                <div>
                    <h3>Sponsoren</h3>

                    <div className="table-container form-container" active="true">
                        <div className="table-header" onClick={toggleTable}>
                            <AiOutlineDown />
                            {sponsoren.length} Sponsoren
                        </div>
                        <table>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>Kürzel</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* map sponsoren */}
                                {sponsoren.map((thisSponsor, index) => {

                                    {/* name */ }
                                    return (
                                        <tr key={`sponsoren-list-key-${index}`} className="pointer" onClick={() => setSelectedSponsor(thisSponsor)}>
                                            <td className="column-icon">{thisSponsor.SPONSOR_ID}</td>
                                            <td>{thisSponsor.Name}</td>
                                            <td>{thisSponsor.Kuerzel}</td>
                                            <td className="column-icon delete pointer" onClick={(e) => deleteSponsor(e, thisSponsor)}><FiTrash2 /></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>

                    {/* add */}
                    <div className="basic-btn" onClick={add}>Sponsor hinzufügen</div>
                </div>
                <div className="seperator"></div>
            </div >
        )
    }
    else if (selectedSponsor) {
        return <SponsorenForm selectedSponsor={selectedSponsor} goBack={() => setSelectedSponsor()} />
    }
    else {
        return <Loader />
    }
}

export default SponsorenManager


// -- component for and AND/OR condition
const SearchConditionHandler = ({ andConditions, setAndConditions, orConditions, setOrConditions, conditionHandlerActive, conditionKeys, searchValue }) => {

    // -- manage conditions
    const toggleConditions = (key, value, conditionName) => {
        const thisCondition = { key: key, value: value };
        const newCondition = conditionName === "AND" ? [...andConditions] : [...orConditions];

        // run through condition-state
        for (let i = 0; i < newCondition.length; i++) {

            // this condition
            const elem = newCondition[i];

            if (elem.key === thisCondition.key && elem.value === thisCondition.value) {
                // -- remove
                newCondition.splice(i, 1);
                if (conditionName === "AND") setAndConditions(newCondition);
                else if (conditionName === "OR") setOrConditions(newCondition);
                return;
            }
        }

        // -- add
        newCondition.push(thisCondition);
        if (conditionName === "AND") setAndConditions(newCondition);
        else if (conditionName === "OR") setOrConditions(newCondition);
    }


    // -- select all data from one condition
    const selectAllConditions = (thisCondition, conditionName) => {

        // copy or/andconditions
        const oldConditions = conditionName === "AND" ? [...andConditions] : [...orConditions];
        const newConditions = thisCondition.data;

        const result = [...oldConditions];

        // fill result array with new conditions
        for (let i = 0; i < newConditions.length; i++) {
            const newCondition = newConditions[i];

            // check for duplicates
            let isDuplicate = false;
            for (let j = 0; j < oldConditions.length; j++) {
                const oldCondition = oldConditions[j];
                // duplicate found
                if (newCondition.key === oldCondition.key && newCondition.value === oldCondition.value) isDuplicate = true;
            }

            // push to result
            if (!isDuplicate) result.push(newCondition);
        }

        if (conditionName === "AND") setAndConditions(result);
        if (conditionName === "OR") setOrConditions(result);
    }


    //  show current condition 
    const ConditionString = ({ conditionName }) => {

        const keys = conditionName === "AND" ? [... new Set(andConditions.map(elem => elem.key))] : [... new Set(orConditions.map(elem => elem.key))];
        const conditions = conditionName === "AND" ? [...andConditions] : [...orConditions];

        if (conditions.length) {
            return (
                <div className={`condition-string-container ${conditionName === "AND" ? "and-condition" : "or-condition"}`}>

                    {/* map keys */}
                    {keys.map((thisKey, index, arr) =>
                        <div key={`condition-string-and-key-${index}`} className="condition-string">

                            {/* this key (e.g. Fachgebiet) */}
                            <span className="condition-string-key">
                                {index !== 0 ?
                                    conditionName === "AND" ?
                                        <span className="condition-string-and">UND</span>
                                        :
                                        <span className="condition-string-or">ODER</span>
                                    :
                                    <></>
                                }
                                {thisKey}:&nbsp;
                            </span>

                            {/* this keys values (e.g. Allgemeinmedizin) */}
                            {conditions.filter(elem => elem.key === thisKey).map((elem, i, array) =>

                                // one value
                                <span className="condition-string-values" key={`condition-string-value-key-${i}`}>
                                    {elem.value}{array[i + 1] ? <>,&nbsp;</> : ""}
                                </span>

                            )}
                        </div>
                    )}
                </div>
            )
        }
        else { return <></> }
    }



    return (
        <div className="condition-container">

            <div className="condition-list no-scrollbar" active={conditionHandlerActive ? "true" : "false"}>

                {/* map different conditions */}
                {conditionKeys.map((thisCondition, index) =>
                    <div key={`condition-container-key-${index}`} className="condition-key-container">

                        {/* name */}
                        <div className="condition-name-container">
                            <h5>{thisCondition.name}</h5>
                            <input type="button" className="condition-select-all-btn" value="Alle UND" onClick={() => selectAllConditions(thisCondition, "AND")} />
                            <input type="button" className="condition-select-all-btn" value="Alle ODER" onClick={() => selectAllConditions(thisCondition, "OR")} />
                        </div>

                        {/* data */}
                        <div className="condition-value-container">
                            {thisCondition.data.map((thisData, index) => {

                                // vars
                                const thisKey = thisData.key;
                                const thisValue = thisData.value;
                                const thisName = thisData.name;

                                // this value is active 
                                const andActive = andConditions.filter(elem => (elem.key === thisKey && elem.value === thisValue)).length;
                                const orActive = orConditions.filter(elem => (elem.key === thisKey && elem.value === thisValue)).length;

                                // return single condition-value
                                return (
                                    <div key={`condition-single-container-key-${index}`} className="condition-single-container">
                                        <div className="condition-single-name">{thisName}</div>
                                        <div className="condition-single-button" onClick={() => toggleConditions(thisKey, thisValue, "AND")} active={andActive ? "true" : "false"}>
                                            U
                                        </div>
                                        <div className="condition-single-button" onClick={() => toggleConditions(thisKey, thisValue, "OR")} active={orActive ? "true" : "false"}>
                                            O
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )}
            </div>


            {/* container for a string which conditions are set */}
            <div className="search-condition-string-container">

                {/* if search-input is defined -> additional AND-Condition */}
                {searchValue && (andConditions.length || orConditions.length) ?
                    <>
                        <div className="condition-string-container">
                            <div className="condition-string">
                                <span className="condition-string-key">Suche: </span>
                                <span className="condition-string-value">{searchValue}</span>
                            </div>
                        </div>
                        <div className="condition-combination condition-string-and">UND</div>
                    </>
                    : <></>
                }

                {/* AND-Condition */}
                <ConditionString conditionName="AND" />

                {/* AND */}
                {andConditions.length && orConditions.length ? <div className="condition-combination condition-string-and">UND</div> : ""}

                {/* OR-CONDITION */}
                <ConditionString conditionName="OR" />

            </div>

        </div>
    )
}

export default SearchConditionHandler
import { useEffect, useState, useContext } from 'react';
import { TokenContext } from '../Main';
import parse from 'html-react-parser';

import { AiOutlineFile, AiOutlineVideoCamera } from 'react-icons/ai'
import { AiOutlineRight, AiOutlineLeft } from 'react-icons/ai'

import { overwriteFile, downloadJson, downloadKursLinkJson } from '../../functions/fileHandler.js'
import { addCourseToLocalstorage, formatDateToString, toggleNextSibling } from './../../functions/helper.js'
import { validateKursInfo } from '../../functions/validation.js';
import { sendPatchRequest, sendRequest } from '../../rest/requests';
import { printInput, printInputWithPreview, printTextArea, printSelect, printTextEditor, printFileInput, printCheckbox } from './../InputComponents'
import { defaultHeaderTitle } from '../../functions/global_variables.js';
import { aerztekammerChange } from '../../functions/course-template-handler.js';

import InfoPopup from './../InfoPopup';
import PdfCreator from './PdfCreator';
import Loader from '../Loader';
import AddRemoveButton from './AddRemoveButton';
import BackButton from '../BackButton';

import * as OnChange from './onChangeFunctions.js'

import templateStandard from '../../assets/course-templates/template-standard.json'


// intervall for validation course-information
var validationIntervall;



export const Form = ({ courseKey, setHeaderTitle, userLastname, allReferenten, allPodcastSerien, goBack, zertifikatVorlagen, userRole }) => {

    // access-token
    const [tokenObject, setTokenObject] = useContext(TokenContext);

    // -- STATES --

    // -- infos
    const [thisCourse, setThisCourse] = useState();
    const [loginData, setLoginData] = useState();
    const [sponsors, setSponsors] = useState();
    const [a2users, setA2users] = useState();

    // -- activestep 
    const [activeStep, setActiveStep] = useState(0);

    // -- init
    const [formInitialized, setFormInitialized] = useState(false);
    const [courseBlocked, setCourseBlocked] = useState(false);


    // -- INITIALIZING AND UPDATING --
    //#region 
    // -- init
    useEffect(() => {
        initKursForm();

        // -- on unmount
        return () => {
            // remove warning-message
            var warningMessage = document.querySelector('#warning-message');
            if (warningMessage) warningMessage.removeAttribute('active');
            if (validationIntervall) clearInterval(validationIntervall);
            // reset header-title
            setHeaderTitle(defaultHeaderTitle);
        }
    }, []);
    const initKursForm = async () => {

        // GET COURSE FROM DATABASE
        const response = await sendRequest(tokenObject, setTokenObject, 'GET', `/kurse/${courseKey}/${userLastname}`, `Lade Kurs "${courseKey}"`, `Kurs "${courseKey}" geladen`);

        // GET SHUTTLE INFOS FROM DATABASE
        const shuttle_response = await sendRequest(tokenObject, setTokenObject, 'GET', '/shuttle');

        // GET SPONSORS FROM DATABASE
        const sponsors_response = await sendRequest(tokenObject, setTokenObject, 'GET', '/sponsoren');

        // GET A2-USER FROM DATABASE
        const user_response = await sendRequest(tokenObject, setTokenObject, 'GET', '/nutzer');

        // -- someone is editing this course
        if (response.status === 423) {
            setCourseBlocked(true);
            return;
        }
        // -- course is accessible
        else if (response.success && shuttle_response.success && sponsors_response.success && user_response.success) {
            const receivedCourse = response.data

            // save a backup into localstorage
            addCourseToLocalstorage(receivedCourse);

            // start validation intervall
            setValidationIntervall(receivedCourse);

            // update aerzt-leiter/referenten
            const updateRef = async (key) => {
                // run through aerzt-leiter/referenten
                for (let i = 0; i < receivedCourse[key].length; i++) {
                    const ref_id = receivedCourse[key][i].REFERENT_ID ? receivedCourse[key][i].REFERENT_ID : receivedCourse[key][i].id; // .id is a fallback for older versions
                    if (ref_id) {
                        // GET REFERENT FROM DATABASE
                        const response = await sendRequest(tokenObject, setTokenObject, 'GET', `/referenten/${ref_id}`);
                        if (response.success && response.data[0]) {
                            const thisReferent = response.data[0];
                            // update -> use old entry and concat with new one - to keep Interessenskonflikte
                            receivedCourse[key][i] = { ...receivedCourse[key][i], ...thisReferent };
                            // delete old .id
                            delete receivedCourse[key][i].id;
                        }
                    }
                }
            }
            await updateRef("Referenten");
            await updateRef("AerztlicherLeiter");


            // -- set states
            setThisCourse(receivedCourse); // course
            setLoginData(shuttle_response.data[0].loginData); // shuttle-b2b login-data
            setSponsors(sponsors_response.data);
            setA2users(user_response.data);
            setHeaderTitle(courseKey); // header-title
            setFormInitialized(true); // initialized
        }
    }


    // -- update
    // course-infos changed
    useEffect(() => updateOnCourseInfoChange(), [thisCourse]);
    const updateOnCourseInfoChange = async () => {
        if (thisCourse) {

            // set Letzte Bearbeitung automaticly
            var today = new Date();
            today.setHours(0, 0, 0, 0);
            var todayString = formatDateToString(today);
            if (thisCourse.Datum !== todayString) {
                setThisCourse((prevState) => ({
                    ...prevState,
                    Datum: todayString,
                }))
            }

            // UPDATE COURSE IN DATABASE
            sendPatchRequest(tokenObject, setTokenObject, `/kurse/${thisCourse.KursKey}/${userLastname}`, thisCourse, 'Speichere Kurs', 'Kurs gespeichert');

            // start validation-intervall
            setValidationIntervall(thisCourse);
        }
    }


    // -- validation intervall
    const setValidationIntervall = (thisCourse) => {
        // validate course every 4 seconds to display Warnings in bottom-right corner
        if (validationIntervall) clearInterval(validationIntervall);
        validationIntervall = setInterval(() => {
            if (thisCourse !== null) {
                var valid = validateKursInfo(thisCourse, document.querySelector('#warning-message-text'));
                var notValidWarning = document.querySelector('#warning-message');
                if (!valid) {
                    // course is not valid
                    notValidWarning.setAttribute('active', '');
                    if (thisCourse.Valid !== 0) {
                        setThisCourse((prevState) => ({
                            ...prevState,
                            Valid: 0
                        }))
                    }
                }
                else {
                    // course is valid
                    notValidWarning.removeAttribute('active');
                    if (thisCourse.Valid !== 1) {
                        setThisCourse((prevState) => ({
                            ...prevState,
                            Valid: 1
                        }))
                    }
                }
            };
        }, 4000);
    }
    //#endregion


    // -- NAVIGATION --
    //#region
    useEffect(() => {
        // scroll to top when changing steps
        scrollToTop();
    }, [activeStep])
    function scrollToTop() {
        document.querySelector('#sections-container').scrollTop = 0;
    }
    //#endregion



    // -- aktiven Nutzer löschen
    const unblockThisCourse = async () => {
        if (window.confirm('Gleichzeitige Bearbeitung kann zu Problemen beim Speichern führen.\nTrotzdem bearbeiten?')) {
            const response = await sendRequest(tokenObject, setTokenObject, 'POST', '/kurse/unblock', `Kurs "${courseKey}" freigeben`, `"${courseKey}"`, { KursKey: courseKey });
            if (response.success) {
                initKursForm();
                setCourseBlocked(false);
            }
        }
    }



    if (courseBlocked) {
        return (
            <div>
                <h5 id="course-blocked-container">Kurs wird bereits von jemanden bearbeitet.</h5>

                <div className='basic-btn' onClick={unblockThisCourse}>Trotzdem bearbeiten</div>
            </div>
        )
    }
    else if (formInitialized && thisCourse && loginData && sponsors) {

        return (

            <div className='form-container'>

                {/* back button */}
                <BackButton onClick={goBack} />


                {/* header */}
                <div id="form-header">

                    <div className="steps-container">
                        <div onClick={() => setActiveStep(0)} active={activeStep === 0 ? "true" : "false"} className="basic-small-btn">Allgemein</div>
                        <div onClick={() => setActiveStep(1)} active={activeStep === 1 ? "true" : "false"} className="basic-small-btn">Info 1</div>
                        <div onClick={() => setActiveStep(2)} active={activeStep === 2 ? "true" : "false"} className="basic-small-btn">Info 2</div>
                        <div onClick={() => setActiveStep(3)} active={activeStep === 3 ? "true" : "false"} className="basic-small-btn">Charts</div>
                        <div onClick={() => setActiveStep(4)} active={activeStep === 4 ? "true" : "false"} className="basic-small-btn">Fragen</div>
                        <div onClick={() => setActiveStep(5)} active={activeStep === 5 ? "true" : "false"} className="basic-small-btn">cmeducation</div>
                        <div onClick={() => setActiveStep(6)} active={activeStep === 6 ? "true" : "false"} className="basic-small-btn">Shuttle</div>
                        <div onClick={() => setActiveStep(7)} active={activeStep === 7 ? "true" : "false"} className="basic-small-btn"><AiOutlineFile /></div>
                    </div>

                    <div className='seperator'></div>
                </div>


                {/* main */}
                <form id="main-form" onSubmit={(e) => { e.preventDefault(); return false; }}>

                    {/* projekt informationen */}
                    {activeStep === 0 ?
                        <div>

                            {/* kind of course and identification */}
                            <div>
                                <h3>Art und Identifikation</h3>


                                {/* ID */}
                                {printInput(
                                    () => { return false }, "kurs-id-input", "ID", thisCourse.ID,
                                    true, undefined, "text", "", true, undefined, true
                                )}

                                {/* KursKey */}
                                {printInput(
                                    () => { return false }, "kurs-key-input", "KursKey", thisCourse.KursKey,
                                    true,
                                    "Eindeutiger Kursschlüssel zur Identifikation in cmed 6.<br><br>Wird in <i style='color:gray'>src/json/kurs_link.json</i> eingetragen",
                                    "text", "", true, undefined, true
                                )}


                                {/* display read-only vnr/id if course is public in cme shuttle to prevent false settings */}
                                {thisCourse.Public ?
                                    <>
                                        {/* Art */}
                                        {printInput(
                                            undefined, "readonly-kursart", "Art", thisCourse.Art, true,
                                            "Kursart darf nicht bearbeitet werden wenn Kurs in CME Shuttle öffentlich ist.", "text", "",
                                            true, undefined, true
                                        )}

                                        {/* VNR */}
                                        {printInput(
                                            undefined, "readonly-vnr", "VNR", thisCourse.VNR,
                                            thisCourse.Art === "Kurs" || thisCourse.Art === "Live",
                                            "VNR darf nicht bearbeitet werden wenn Kurs in CME Shuttle öffentlich ist.", "text", "",
                                            true, undefined, true
                                        )}
                                    </>
                                    :
                                    <>
                                        {/* Art */}
                                        {printSelect(
                                            (e) => { OnChange.onChange("Art", e.currentTarget.value, setThisCourse) }, "art-input", "Art", thisCourse.Art, ["Kurs", "Webinar", "Podcast", "Live"],
                                            true,
                                            undefined, '', true
                                        )}

                                        {/* VNR */}
                                        {printInput(
                                            (e) => { OnChange.onChange("VNR", e.currentTarget.value, setThisCourse) }, "vnr-input", "VNR", thisCourse.VNR,
                                            thisCourse.Art === "Kurs" || thisCourse.Art === "Live",
                                            "Kein anderer Kurs darf diese VNR/ID besitzen.", "number", '', false, undefined, true
                                        )}
                                    </>
                                }

                                {/* nicht zertifiziert */}
                                {printCheckbox(
                                    () => OnChange.onChange("Zertifiziert", thisCourse.Zertifiziert ? 0 : 1, setThisCourse), 'zertifiziert-input', 'Kurs ist zertifiziert', thisCourse.Zertifiziert, false,
                                    thisCourse.Art === "Kurs"
                                )}


                                {/* GCP */}
                                {printCheckbox(
                                    () => OnChange.onChange("GCP", thisCourse.GCP ? 0 : 1, setThisCourse), "gcp-input", "Kurs ist ein GCP-Modul", thisCourse.GCP, false,
                                    thisCourse.Art === "Kurs" || thisCourse.GCP
                                )}

                                {/* Ärztekammer */}
                                {printSelect(
                                    (e) => { aerztekammerChange(e, thisCourse, setThisCourse); }, 'aerztekammer-input', 'Ärztekammer', thisCourse.Aerztekammer,
                                    ['Bayern', 'Berlin'],
                                    thisCourse.Art === "Kurs"
                                )}


                                {/* EIV-Passwort */}
                                {printInput(
                                    (e) => { OnChange.onChange("EIV_Passwort", e.currentTarget.value, setThisCourse) }, "eiv-password-input", "EIV Passwort", thisCourse.EIV_Passwort,
                                    thisCourse.Art === "Kurs" || thisCourse.Art === "Live"
                                )}


                                {/* Zertifikat Vorlage */}
                                {printSelect(
                                    (e) => { OnChange.onChange("Zertifikat_Vorlage", e.currentTarget.value, setThisCourse) }, 'zertifikat-vorlage-input', 'Zertifikat Vorlage', thisCourse.Zertifikat_Vorlage, zertifikatVorlagen.map(elem => elem.Name),
                                    thisCourse.Art === "Kurs" || thisCourse.Art === "Live"
                                )}

                            </div>
                            <div className='seperator'></div>


                            {/* project-information */}
                            <div>
                                <h3>Projekt Informationen</h3>

                                {/* Datum (Letzte Bearbeitung)*/}
                                {printInput(
                                    () => { return false }, "date-input", "Letzte Bearbeitung", thisCourse.Datum,
                                    true, "Wird automatisch ausgefüllt", "text", "", true
                                )}


                                {/* Projektleiter */}
                                {printSelect(
                                    (e) => { OnChange.onChange("Projektleiter", e.currentTarget.value, setThisCourse) }, "projektleiter-input", <>Projektleiter<sup>*</sup>in</>, thisCourse.Projektleiter,
                                    // use a2-user from database
                                    a2users.map(elem => elem.Vorname)
                                )}


                                {/* Laufzeit_Start */}
                                {printInput(
                                    (e) => { OnChange.onChange("Laufzeit_Start", e.currentTarget.value, setThisCourse) }, "laufzeit-start-input", "Laufzeit Start", thisCourse.Laufzeit_Start,
                                    true,
                                    "Laufzeit bestimmt ob Kurse in CME Shuttle angezeigt werden.", 'date'
                                )}
                                {/* Laufzeit_Ende */}
                                {printInput(
                                    (e) => { OnChange.onChange("Laufzeit_Ende", e.currentTarget.value, setThisCourse) }, "laufzeit-ende-input", "Laufzeit Ende", thisCourse.Laufzeit_Ende,
                                    true,
                                    undefined, 'date'
                                )}


                                {/* cmeducation-URL */}
                                {printInputWithPreview(
                                    false, (e) => { OnChange.onChange("cmeducationURL", e.currentTarget.value, setThisCourse) }, "cmeducation-url-input", "CMEducation-URL", thisCourse.cmeducationURL,
                                    thisCourse.Art !== "Live",
                                    "Für Verlinkung in PDF-Generator", 'text', '', false, undefined, true
                                )}

                            </div>
                            <div className='seperator'></div>


                            {/* qr-code */}
                            {thisCourse.Art === "Kurs" ?
                                <div>
                                    <h3>QR-Code Generator</h3>

                                    {/* QR-Code-Generator */}
                                    {printCheckbox(
                                        () => OnChange.onChange("QRCodeGenerator", thisCourse.QRCodeGenerator ? 0 : 1, setThisCourse), "qr-code-generate-input", "QR-Code-Generator sichtbar", thisCourse.QRCodeGenerator
                                    )}

                                    {thisCourse.QRCodeGenerator ?
                                        <>
                                            {/* QR-Code-Titel */}
                                            {printInput(
                                                (e) => { OnChange.onChange("QRCodeTitel", e.currentTarget.value, setThisCourse) }, "qr-titel-input", <>QR-Code Generator Titel</>, thisCourse.QRCodeTitel,
                                                true,
                                                "Titel für QR-Code Reiter.", 'text', '', false, undefined, true
                                            )}

                                            {/* QR-Code-URL */}
                                            {printInputWithPreview(
                                                false, (e) => { OnChange.onChange("QRCodeURL", e.currentTarget.value, setThisCourse) }, "qr-url-input", "QR-URL", thisCourse.QRCodeURL,
                                                true,
                                                "Für den QR-Code Generator wird die URL benötigt auf der der Kurs später liegen wird."
                                            )}

                                            {/* QR-Code-Beschreibung */}
                                            {printTextArea(
                                                (e) => { OnChange.onChange("QRCodeBeschreibung", e.currentTarget.value, setThisCourse) }, "qr-beschreibung-input", <>QR-Code Generator Beschreibung</>, thisCourse.QRCodeBeschreibung
                                            )}
                                        </>
                                        :
                                        <></>
                                    }
                                </div>
                                : <></>
                            }
                            <div className='seperator'></div>


                            {/* qr-pdf-generator */}
                            {thisCourse.Art === "Kurs" ?
                                <div>
                                    <h3>QR-PDF-Generator</h3>
                                    {printCheckbox((e) => OnChange.onChange("Qr_Pdf_Generator", thisCourse.Qr_Pdf_Generator ? 0 : 1, setThisCourse), 'qr-pdf-input', 'Soll dieser Kurs im QR-PDF-Generator erscheinen', thisCourse.Qr_Pdf_Generator, false, true, "Bestimmt, ob dieser Kurs im CMEducation QR-PDF-Generator erscheinen soll.", '', true)}
                                    <div className='seperator'></div>
                                </div>
                                : <></>
                            }


                            {/* mail-text */}
                            {(thisCourse.Art === "Kurs" || thisCourse.Art === "Live" || thisCourse.Art === "GCP") && (userRole === "Admin") ?
                                <div>
                                    <h3>Mail Versand Betreff</h3>
                                    {/* betreff success */}
                                    {printInput((e) => { OnChange.onChange("Mail_Betreff", e.currentTarget.value, setThisCourse) }, 'mail-success-subject', 'Betreff Bestanden', thisCourse.Mail_Betreff, true, undefined, 'text', '', false, undefined, true)}
                                    {/* betreff failure */}
                                    {printInput((e) => { OnChange.onChange("Mail_Betreff_Fail", e.currentTarget.value, setThisCourse) }, 'mail-failure-subject', 'Betreff Nicht-Bestanden', thisCourse.Mail_Betreff_Fail, true, undefined, 'text', '', false, undefined, true)}


                                    <h3>Mail Versand Text</h3>

                                    {/* success mail */}
                                    <div className="input-container large">
                                        <label htmlFor='mail-success'>Erfolg Mail-Text</label>
                                        <div className="textarea-editor">

                                            <textarea
                                                id='mail-success'
                                                name='mail-success'
                                                value={thisCourse.Mail_Erfolg}
                                                className='large-text'

                                                onChange={(e) => { OnChange.onChange("Mail_Erfolg", e.currentTarget.value, setThisCourse) }}
                                            />

                                            {/* optional modifyTextarea */}
                                        </div>
                                    </div>


                                    {/* success mail ad */}
                                    <div className="input-container large">
                                        <label htmlFor='mail-success'>Erfolg Außendienst Mail-Text</label>
                                        <div className="textarea-editor">

                                            <textarea
                                                id='mail-ad-success'
                                                name='mail-ad-success'
                                                value={thisCourse.Mail_AD_Erfolg}
                                                className='large-text'

                                                onChange={(e) => { OnChange.onChange("Mail_AD_Erfolg", e.currentTarget.value, setThisCourse) }}
                                            />

                                            {/* optional modifyTextarea */}
                                        </div>
                                    </div>


                                    {/* failure mail */}
                                    <div className="input-container large">
                                        <label htmlFor='mail-failure'>Nicht-Bestanden Mail-Text</label>
                                        <div className="textarea-editor">

                                            <textarea
                                                id='mail-failure'
                                                name='mail-failure'
                                                value={thisCourse.Mail_Fail}
                                                className='large-text'

                                                onChange={(e) => { OnChange.onChange("Mail_Fail", e.currentTarget.value, setThisCourse) }}
                                            />

                                            {/* optional modifyTextarea */}
                                        </div>
                                    </div>


                                    {/* failure mail ad */}
                                    <div className="input-container large">
                                        <label htmlFor='mail-ad-failure'>Nicht-Bestanden Außendienst Mail-Text</label>
                                        <div className="textarea-editor">

                                            <textarea
                                                id='mail-ad-failure'
                                                name='mail-ad-failure'
                                                value={thisCourse.Mail_AD_Fail}
                                                className='large-text'

                                                onChange={(e) => { OnChange.onChange("Mail_AD_Fail", e.currentTarget.value, setThisCourse) }}
                                            />

                                            {/* optional modifyTextarea */}
                                        </div>
                                    </div>

                                </div>
                                : <></>
                            }

                        </div>
                        : <></>
                    }


                    {/* Kursspezifisch */}
                    {activeStep === 1 ?
                        <div>

                            <h3>Kursspezifische Informationen</h3>

                            {/* KursTitel */}
                            {printInput(
                                (e) => { OnChange.onChange("KursTitel", e.currentTarget.value, setThisCourse) }, "kurstitel-input", "Kurstitel", thisCourse.KursTitel,
                                true,
                                "Der Kurstitel kann mittels des Kürzels {TITEL} in den restlichen Feldern verwendet werden und wird dann automatisch eingesetzt.", 'text', '', false, undefined, true
                            )}

                            {/* KursSubTitel */}
                            {printInput((e) => { OnChange.onChange("KursUntertitel", e.currentTarget.value, setThisCourse) }, "kurssubtitel-input", "Kurs Unterüberschrift", thisCourse.KursUntertitel, true, undefined, 'text', '', false, undefined, true)}

                            {/* Kursbeschreibung */}
                            {printTextArea(
                                (e) => { OnChange.onChange("KursBeschreibung", e.currentTarget.value, setThisCourse) }, 'kursbeschreibung-input', 'Kursbeschreibung', thisCourse.KursBeschreibung,
                                thisCourse.Art !== "Live",
                                undefined, 'large-text'
                            )}
                            <div className="seperator"></div>


                            {/* type and content */}
                            {thisCourse.Art === "Kurs" ?
                                <div>
                                    <h3>Kurstyp und Content</h3>

                                    {/* KursTyp */}
                                    {printSelect(
                                        (e) => { OnChange.onChange("KursTyp", e.currentTarget.value, setThisCourse) }, "kursTyp-input", "Kurstyp", thisCourse.KursTyp, ["Standard", "BigVideo", "PDF", "PowerPoint"]
                                    )}

                                    {/* PDF-Filename */}
                                    {printInput(
                                        (e) => { OnChange.onChange("PDF", e.currentTarget.value, setThisCourse) }, "pdf-input", "PDF Name", thisCourse.PDF,
                                        thisCourse.KursTyp === "PDF",
                                        "<strong>Datei-Namen angeben!</strong><br>Das PDF muss in <i style='color:gray'>assets/pdf/</i> liegen."
                                    )}

                                    <div className="seperator"></div>
                                </div>
                                : <></>
                            }


                            {/* Video */}
                            {((thisCourse.Art === "Kurs") && (thisCourse.KursTyp === "Standard" || thisCourse.KursTyp === "BigVideo")) ?
                                <div className="video-container">
                                    <h3>Videos</h3>
                                    {thisCourse.Video.map((elem, index) => (
                                        <span key={`video-key-${index}`}>
                                            {/* print single video-source */}
                                            {printInput(
                                                (e) => { OnChange.onChange("Video", e.currentTarget.value, setThisCourse, true, thisCourse.Video, index, 'src') }, `video-src-input-${index}`, `Video ${index + 1} Name`, thisCourse.Video[index].src,
                                                true,
                                                "<strong>Datei-Namen angeben!</strong><br>Kurs-Videos müssen in <i style='color:gray'>assets/videos/</i> liegen.", "text", "video-src-input", false, undefined, true
                                            )}
                                        </span>
                                    ))}

                                    {/* how many parts  */}
                                    <AddRemoveButton
                                        removeText='Video entfernen'
                                        removeFunction={() => OnChange.removeEntry('Video', thisCourse.Video.length - 1, thisCourse, setThisCourse)}
                                        addText='Video hinzufügen'
                                        addFunction={() => OnChange.addEntry('Video', {}, thisCourse, setThisCourse)}
                                    />
                                </div>
                                : <></>
                            }
                            <div className="seperator"></div>


                            {/* Ärztlicher Leider */}
                            <div>
                                <h3>Ärztlicher Leiter</h3>

                                {thisCourse.AerztlicherLeiter.map((elem, index) => (
                                    <span key={"aerzt-leiter-key-" + index}>

                                        <AddRemoveButton
                                            removeFunction={() => OnChange.removeEntry('AerztlicherLeiter', index, thisCourse, setThisCourse)}
                                            addFunction={() => OnChange.addEntry('AerztlicherLeiter', { ...templateStandard.AerztlicherLeiter[0] }, thisCourse, setThisCourse, index)}
                                            title={<h5>{`Ärztlicher Leiter ${index + 1}`}</h5>}
                                            small={true}
                                        />

                                        {/* titel */}
                                        {printInput(
                                            () => { return false }, `aerztliche-leiter-titel-input-${index}`, `Ärztlicher Leiter Titel`, thisCourse.AerztlicherLeiter[index].Titel,
                                            true,
                                            undefined, "text", "", true
                                        )}

                                        {/* name */}
                                        {printSelect(
                                            (e) => OnChange.referentenChange(e.currentTarget.value, index, thisCourse.AerztlicherLeiter, allReferenten, setThisCourse, "AerztlicherLeiter"),
                                            `aerztliche-leiter-name-input-${index}`,
                                            'Ärztlicher Leiter Name',
                                            thisCourse.AerztlicherLeiter[index].REFERENT_ID,
                                            allReferenten.map(referent => ({ value: referent.REFERENT_ID, name: `${referent.Nachname}, ${referent.Vorname}` })),
                                            true,
                                            "Details zu Referenten werden unter dem Reiter <i style='color:gray'>Referenten</i> editiert.<br><span style='color: red'>Außer Interessenskonflikte!</span>",
                                        )}

                                        {/* foto */}
                                        {printInput(
                                            () => { return false }, `aerztliche-leiter-foto-input-${index}`, `Ärztlicher Leiter Foto`, thisCourse.AerztlicherLeiter[index].Foto,
                                            true,
                                            undefined, "text", "", true
                                        )}

                                        {/* Additional information */}
                                        <div>
                                            <div className='basic-btn toggle-btn' onClick={toggleNextSibling} >Zeige mehr Informationen</div>
                                            <div className='toggable-section' hidden>
                                                {/* Allgemein */}
                                                {printTextArea(
                                                    () => { return false }, `aerztliche-leiter-allgemein-input-${index}`, `Ärztlicher Leiter Allgemein`, thisCourse.AerztlicherLeiter[index].Allgemein,
                                                    true,
                                                    undefined, '', null, null, true
                                                )}

                                                {/* Lebenslauf */}
                                                {printTextArea(
                                                    () => { return false }, `aerztliche-leiter-lebenslauf-input-${index}`, `Ärztlicher Leiter Lebenslauf`, thisCourse.AerztlicherLeiter[index].Lebenslauf,
                                                    true,
                                                    undefined, '', null, null, true
                                                )}

                                                {/* Sonstiges */}
                                                {printTextArea(
                                                    () => { return false }, `aerztliche-leiter-sontiges-input-${index}`, `Ärztlicher Leiter Sonstiges`, thisCourse.AerztlicherLeiter[index].Sonstiges,
                                                    true,
                                                    undefined, '', null, null, true
                                                )}
                                            </div>
                                        </div>

                                        {/* Interessenskonflikte */}
                                        {printTextEditor(
                                            (e) => OnChange.interessenskonflikteChange(e, index, thisCourse, setThisCourse, "AerztlicherLeiter"),
                                            `aerztliche-leiter-interessenskonflikte-input-${index}`, "Ärztlicher Leiter Interessenskonflikte", thisCourse.AerztlicherLeiter[index].Interessenskonflikte,
                                            true,
                                            "Muss hier editiert werden"
                                        )}

                                        <div className='seperator'></div>
                                    </span>
                                ))}

                                {/* how many aerzt-leiter */}
                                <AddRemoveButton
                                    removeText='Wissenschaftliche Leitung entfernen'
                                    removeFunction={() => OnChange.removeEntry('AerztlicherLeiter', thisCourse.AerztlicherLeiter.length - 1, thisCourse, setThisCourse)}
                                    addText='Wissenschaftliche Leitung hinzufügen'
                                    addFunction={() => OnChange.addEntry('AerztlicherLeiter', { ...templateStandard.AerztlicherLeiter[0] }, thisCourse, setThisCourse)}
                                />

                            </div>
                            <div className="seperator"></div>



                            {/* Referenten */}
                            <div>
                                <h3>Referenten</h3>

                                {thisCourse.Referenten.map((elem, index) => (
                                    <span key={"referent-key-" + index}>

                                        <AddRemoveButton
                                            removeFunction={() => OnChange.removeEntry('Referenten', index, thisCourse, setThisCourse)}
                                            addFunction={() => OnChange.addEntry('Referenten', { ...templateStandard.Referenten[0] }, thisCourse, setThisCourse, index)}
                                            title={<h5>{`Referent ${index + 1}`}</h5>}
                                            small={true}
                                        />


                                        {/* titel */}
                                        {printInput(
                                            () => { return false }, `referent-titel-input-${index}`, `Referent ${index + 1} Titel`, thisCourse.Referenten[index].Titel,
                                            true,
                                            undefined, "text", "", true
                                        )}

                                        {/* Name */}
                                        <div className='input-container'>
                                            <InfoPopup text={"Details zu Referenten werden unter dem Reiter <i style='color:gray'>Referenten</i> editiert.<br><span style='color: red'>Außer Interessenskonflikte!</span>"} />

                                            <label htmlFor="referent-name-input">{`Referent ${index + 1} Name`}</label>
                                            <select
                                                id="referent-name-input"
                                                name="referent-name-input"
                                                className="form-dropdown-input"
                                                value={thisCourse.Referenten[index].REFERENT_ID}
                                                onChange={(e) => OnChange.referentenChange(e.currentTarget.value, index, thisCourse.Referenten, allReferenten, setThisCourse)}
                                            >
                                                <option value="" disabled hidden>Bitte wählen</option>
                                                {allReferenten.map((referent, i) => {
                                                    return (
                                                        <option key={`referent-name-input-key-${i}`} value={referent.REFERENT_ID}>{referent.Nachname}, {referent.Vorname}</option>
                                                    )
                                                })}
                                            </select>
                                            <br />
                                        </div>

                                        {/* foto */}
                                        {printInput(
                                            () => { return false }, `referent-foto-input-${index}`, `Referent ${index + 1} Foto`, thisCourse.Referenten[index].Foto,
                                            true,
                                            undefined, "text", "", true
                                        )}

                                        {/* Additional information */}
                                        <div>
                                            <div className='basic-btn toggle-btn' onClick={toggleNextSibling} >Zeige mehr Informationen</div>
                                            <div className='toggable-section' hidden>

                                                {/* Allgemein */}
                                                {printTextArea(
                                                    () => { return false }, `referent-allgemein-input-${index}`, <>Referent {index + 1} Allgemein</>, thisCourse.Referenten[index].Allgemein,
                                                    true,
                                                    undefined, 'large-text', null, null, true
                                                )}

                                                {/* Lebenslauf */}
                                                {printTextArea(
                                                    () => { return false }, `referent-lebenslauf-input-${index}`, <>Referent {index + 1} Lebenslauf</>, thisCourse.Referenten[index].Lebenslauf,
                                                    true,
                                                    undefined, 'large-text', null, null, true
                                                )}

                                                {/* Sonstiges */}
                                                {printTextArea(
                                                    () => { return false }, `referent-sonstiges-input-${index}`, <>Referent {index + 1} Sonstiges</>, thisCourse.Referenten[index].Sonstiges,
                                                    true,
                                                    undefined, 'large-text', null, null, true
                                                )}
                                            </div>
                                        </div>

                                        {/* Interessenskonflikte */}
                                        {printTextEditor(
                                            (e) => OnChange.interessenskonflikteChange(e, index, thisCourse, setThisCourse),
                                            `referent-interessenskonflikte-input-${index}`, <>Referent {index + 1} Interessenskonflikte</>, thisCourse.Referenten[index].Interessenskonflikte,
                                            true,
                                            "Muss hier editiert werden!", "referent-interessenskonflikte-input large-text"
                                        )}

                                        <div className='seperator'></div>
                                    </span>
                                ))}

                                {/* how many referenten */}
                                <AddRemoveButton
                                    removeText='Referent entfernen'
                                    removeFunction={() => OnChange.removeEntry('Referenten', thisCourse.Referenten.length - 1, thisCourse, setThisCourse)}
                                    addText='Referent hinzufügen'
                                    addFunction={() => OnChange.addEntry('Referenten', { ...templateStandard.Referenten[0] }, thisCourse, setThisCourse)}
                                />

                            </div>
                            <div className="seperator"></div>


                            {/* Weitere Infos */}
                            {(thisCourse.Art !== "Podcast" && thisCourse.Zertifiziert && thisCourse.Zertifiziert) ?
                                <>
                                    <h3>Weitere Infos</h3>

                                    {/* Fortbildungsart */}
                                    {printSelect(
                                        (e) => { OnChange.onChange("Fortbildungsart", e.currentTarget.value, setThisCourse) }, 'fortbildungsart-input', 'Fortbildungsart', thisCourse.Fortbildungsart, ["Videokurs", "Kurs als PDF", "Live-Seminar"]
                                    )}

                                    {/* Fortbildungskategorie */}
                                    {printInput(
                                        (e) => { OnChange.onChange("Fortbildungskategorie", e.currentTarget.value, setThisCourse) }, "fortbildungskategorie-input", "Fortbildungskategorie", thisCourse.Fortbildungskategorie,
                                        true,
                                        "Die Fortbildungskategorie kann mittels des Kürzels {FORTBILDUNGSKATEGORIE} in den restlichen Feldern verwendet werden und wird dann automatisch eingesetzt."
                                    )}

                                    {/* CME_Punkte */}
                                    {printInput(
                                        (e) => { OnChange.onChange("CME_Punkte", e.currentTarget.value, setThisCourse) }, "cmepunkte-input", "CME Punkte", thisCourse.CME_Punkte,
                                        true,
                                        "Die CME Punkte können mittels des Kürzels {CME_PUNKTE} in den restlichen Feldern verwendet werden und wird dann automatisch eingesetzt.", "number"
                                    )}

                                    {/* Kosten */}
                                    {printInput(
                                        (e) => { OnChange.onChange("Kosten", e.currentTarget.value, setThisCourse) }, "kosten-input", "Kosten", thisCourse.Kosten,
                                        true
                                    )}

                                    <div className="seperator"></div>
                                </>
                                : <></>
                            }

                            {/* Sponsor */}
                            <div>
                                <h3>Sponsor</h3>
                                {/* sponsor-dropdown */}
                                {printSelect(
                                    (e) => OnChange.onChange("SPONSOR_ID", e.currentTarget.value, setThisCourse), 'sponsor-id-input', 'Sponsor', thisCourse.SPONSOR_ID,
                                    sponsors.map(elem => ({ name: elem.Name, value: elem.SPONSOR_ID })),
                                    true, "Der Sponsoren-Name kann mittels des Kürzels {SPONSOR} in den restlichen Feldern verwendet werden und wird dann automatisch eingesetzt.", '', true
                                )}

                                {/* sponsor-info */}
                                {printTextEditor(
                                    (e) => OnChange.onChange("Sponsor_Info", e.currentTarget.value, setThisCourse), `sponsor-info-input-new`, 'Info', thisCourse.Sponsor_Info,
                                    thisCourse.Art === "Kurs"
                                )}
                            </div>
                            <div className="seperator"></div>


                            {/* Indikationen */}
                            {!thisCourse.GCP && thisCourse.Art !== "Live" ?
                                <>
                                    <div>
                                        <h3>Indikationen</h3>
                                        <div>
                                            {thisCourse.Indikationen.map((customer, i) => {
                                                return (
                                                    <span key={"indikationen-key-" + i}>
                                                        {printSelect(
                                                            (e) => { OnChange.onChange("Indikationen", e.currentTarget.value, setThisCourse, true, thisCourse.Indikationen, i) }, `indikationen-${i}-input`,
                                                            <AddRemoveButton
                                                                title={<>Indikation {i + 1}</>}
                                                                small={true}
                                                                addFunction={() => OnChange.addEntry('Indikationen', "", thisCourse, setThisCourse, i)}
                                                                removeFunction={() => OnChange.removeEntry('Indikationen', i, thisCourse, setThisCourse)}
                                                            />,
                                                            thisCourse.Indikationen[i],
                                                            [
                                                                "1 Punkt",
                                                                "2 Punkte",
                                                                "4 Punkte",
                                                                "6 Punkte",
                                                                "8 Punkte",
                                                                "Allgemeinmedizin",
                                                                "Anästhesiologie",
                                                                "Angiologie",
                                                                "Asthma",
                                                                "Autoimmunerkrankungen",
                                                                "Chirurgie",
                                                                "Demenz",
                                                                "Dermatologie",
                                                                "Diabetologie",
                                                                "Gastroenterologie",
                                                                "Gynäkologie",
                                                                "Hämatologie",
                                                                "Hepatologie",
                                                                "Immunologie",
                                                                "Impfmanagement",
                                                                "Innere Medizin",
                                                                "Interaktionen",
                                                                "Kardiologie",
                                                                "Lipidologie",
                                                                "Multiple Sklerose",
                                                                "Nephrologie",
                                                                "Neurologie",
                                                                "nicht zertifiziert",
                                                                "Onkologie",
                                                                "Opioidabhängigkeit",
                                                                "Orthopädie",
                                                                "Pädiatrie",
                                                                "Pneumologie",
                                                                "Psychiatrie/Psychotherapie",
                                                                "Radiologie",
                                                                "Rechtsmedizin",
                                                                "Rheumatologie",
                                                                "Schmerztherapie",
                                                                "Statistik",
                                                                "Suchtmedizin",
                                                                "Urologie",
                                                                "Infektiologie"
                                                            ],
                                                            true,
                                                            undefined, "indication-input"
                                                        )}
                                                    </span>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div className="seperator"></div>
                                </>
                                : <></>
                            }

                        </div>
                        : <></>
                    }


                    {/* Info */}
                    {activeStep === 2 ?
                        <div>

                            <h3>Allgemeine Informationen</h3>
                            {thisCourse.Art === "Kurs" ?
                                <>
                                    {thisCourse.Zertifiziert ?
                                        <span>
                                            {/* ALLGEMEIN */}

                                            {/* titel */}
                                            {printInput(
                                                (e) => { OnChange.onChange("Info", e.currentTarget.value, setThisCourse, true, thisCourse.Info, 'Allgemein', 'Titel') }, "allgemein-titel-input", <>Allgemein Titel</>, thisCourse.Info.Allgemein.Titel
                                            )}

                                            {/* Zielgruppe */}
                                            {printTextEditor(
                                                (e) => { OnChange.onChange("Info", e.currentTarget.value, setThisCourse, true, thisCourse.Info, 'Allgemein', 'Zielgruppe') }, `allgemein-zielgruppe-input`, 'Zielgruppe', thisCourse.Info.Allgemein.Zielgruppe
                                            )}

                                            {/* Lernziele */}
                                            {printTextEditor(
                                                (e) => { OnChange.onChange("Info", e.currentTarget.value, setThisCourse, true, thisCourse.Info, 'Allgemein', 'Lernziele') }, `allgemein-lernziele-input`, 'Lernziele', thisCourse.Info.Allgemein.Lernziele,
                                                true,
                                                undefined, 'large-text'
                                            )}

                                            {/* Zertifizierung */}
                                            {printTextEditor(
                                                (e) => { OnChange.onChange("Info", e.currentTarget.value, setThisCourse, true, thisCourse.Info, 'Allgemein', 'Zertifizierung') }, `allgemein-zertifizierung-input`, 'Zertifizierung', thisCourse.Info.Allgemein.Zertifizierung
                                            )}
                                        </span>
                                        : (<></>)
                                    }


                                    {/* if course is not certified -> only display Hinweis */}
                                    {/* Hinweis */}
                                    {printTextEditor(
                                        (e) => { OnChange.onChange("Info", e.currentTarget.value, setThisCourse, true, thisCourse.Info, 'Allgemein', 'Hinweis') }, `allgemein-hinweis-input`, 'Hinweis', thisCourse.Info.Allgemein.Hinweis
                                    )}


                                    {thisCourse.Zertifiziert ?
                                        <span>

                                            {/* Funktionsweise */}
                                            {printTextEditor(
                                                (e) => { OnChange.onChange("Info", e.currentTarget.value, setThisCourse, true, thisCourse.Info, 'Allgemein', 'Funktionsweise') }, `allgemein-funktionsweise-input`, 'Funktionsweise', thisCourse.Info.Allgemein.Funktionsweise,
                                                true,
                                                undefined, 'large-text'
                                            )}

                                            {/* NachsterSchritt */}
                                            {printTextEditor(
                                                (e) => { OnChange.onChange("Info", e.currentTarget.value, setThisCourse, true, thisCourse.Info, 'Allgemein', 'NachsterSchritt') }, `allgemein-nachsterschritt-input`, 'Nächster Schritt', thisCourse.Info.Allgemein.NachsterSchritt,
                                                true,
                                                undefined, 'large-text'
                                            )}

                                            <div className="seperator"></div>

                                            {/* haftungsausschluss */}
                                            {printInput(
                                                (e) => { OnChange.onChange("Info", e.currentTarget.value, setThisCourse, true, thisCourse.Info, 'Haftungsausschluss', 'Titel') }, "haftungsausschluss-titel-input", <>Haftungsausschluss Titel</>, thisCourse.Info.Haftungsausschluss.Titel
                                            )}
                                            {printTextEditor(
                                                (e) => { OnChange.onChange("Info", e.currentTarget.value, setThisCourse, true, thisCourse.Info, 'Haftungsausschluss', 'Text') }, `haftungsausschluss-text-input`, <>Haftungsausschluss Text</>, thisCourse.Info.Haftungsausschluss.Text,
                                                true,
                                                undefined, 'large-text'
                                            )}

                                            <div className="seperator"></div>

                                            {/* tutorielle unterstützung */}
                                            {printInput(
                                                (e) => { OnChange.onChange("Info", e.currentTarget.value, setThisCourse, true, thisCourse.Info, 'TutorielleUnterstuetzung', 'Titel') }, "tutorielleunterst-titel-input", <>Tutorielle Unterstützung Titel</>, thisCourse.Info.TutorielleUnterstuetzung.Titel
                                            )}
                                            {printTextEditor(
                                                (e) => { OnChange.onChange("Info", e.currentTarget.value, setThisCourse, true, thisCourse.Info, 'TutorielleUnterstuetzung', 'Text') }, `tutorielleunterst-text-input`, <>Tutorielle Unterstützung Text</>, thisCourse.Info.TutorielleUnterstuetzung.Text,
                                                true,
                                                undefined, 'large-text'
                                            )}

                                        </span>
                                        : (<></>)}

                                    <div className="seperator"></div>


                                    {/* impressum */}
                                    {printInput(
                                        (e) => { OnChange.onChange("Info", e.currentTarget.value, setThisCourse, true, thisCourse.Info, 'Impressum', 'Titel') }, "impressum-titel-input", <>Impressum Titel</>, thisCourse.Info.Impressum.Titel
                                    )}
                                    {printTextEditor(
                                        (e) => { OnChange.onChange("Info", e.currentTarget.value, setThisCourse, true, thisCourse.Info, 'Impressum', 'Vertrieb') }, `impressum-vertrieb-input`, <>Impressum Vertrieb</>, thisCourse.Info.Impressum.Vertrieb,
                                        true,
                                        undefined, 'large-text'
                                    )}
                                    {printTextEditor(
                                        (e) => { OnChange.onChange("Info", e.currentTarget.value, setThisCourse, true, thisCourse.Info, 'Impressum', 'Herausgeber') }, `impressum-herausgeber-input`, <>Impressum Herausgeber</>, thisCourse.Info.Impressum.Herausgeber,
                                        true,
                                        undefined, 'large-text'
                                    )}

                                    <div className="seperator"></div>


                                    {/* datenschutz */}
                                    {printInput(
                                        (e) => { OnChange.onChange("Info", e.currentTarget.value, setThisCourse, true, thisCourse.Info, 'Datenschutz', 'Titel') }, "datenschutz-titel-input", <>Datenschutzerklärung Titel</>, thisCourse.Info.Datenschutz.Titel
                                    )}
                                    {printTextEditor(
                                        (e) => { OnChange.onChange("Info", e.currentTarget.value, setThisCourse, true, thisCourse.Info, 'Datenschutz', 'Text') }, `datenschutz-text-input`, <>Datenschutzerklärung Text</>, thisCourse.Info.Datenschutz.Text,
                                        true,
                                        undefined, 'large-text'
                                    )}

                                    <div className='seperator'></div>
                                </>
                                :
                                <>
                                    <p>Für diesen Kurstyp oder diese Kursart werden keine weiteren Informationen benötigt.</p>
                                    <div className='seperator'></div>
                                </>
                            }

                        </div>
                        : <></>
                    }


                    {/* Charts */}
                    {activeStep === 3 ?
                        <div>
                            <h3>Charts</h3>

                            {/* charts only neccesarry for Kurs -> Standard & Powerpoint */}
                            {(thisCourse.Art === "Kurs") && (thisCourse.KursTyp === "Standard" || thisCourse.KursTyp === "PowerPoint") ?
                                <>
                                    {
                                        thisCourse.slides.map((currentPart, partIndex) => (

                                            // -- single part --
                                            <div key={"single-part-container-" + partIndex} className="single-part-container">
                                                <h4>Teil {partIndex + 1}</h4>
                                                {currentPart.map((currentChart, chartIndex) => {

                                                    const ChartHeader = () => (
                                                        <AddRemoveButton
                                                            title={
                                                                <div>
                                                                    {thisCourse.slides.length > 1 ? <>Teil {partIndex + 1} -&nbsp;</> : ''}
                                                                    <strong>Chart {currentChart.chartNr}</strong>
                                                                </div>
                                                            }
                                                            small={true}
                                                            removeFunction={() => OnChange.removeSpecificChart(chartIndex, partIndex, setThisCourse, thisCourse)}
                                                            addFunction={() => OnChange.addSpecificChart(chartIndex, partIndex, setThisCourse, thisCourse)}
                                                        />
                                                    )

                                                    return (
                                                        // -- single chart --
                                                        <div key={`chart-input-container-${partIndex + 1}-${chartIndex + 1}`} className="chart-input-container">

                                                            {/* chart-title and chart-time */}
                                                            {printInput(
                                                                () => OnChange.slidesChange(chartIndex, partIndex, thisCourse, setThisCourse), `chart-title-input-${partIndex + 1}-${(chartIndex + 1)}`,
                                                                <ChartHeader />,
                                                                currentChart.title,
                                                                true,
                                                                undefined, 'text', 'chart-title-input'
                                                            )}
                                                            {printInput(
                                                                () => OnChange.slidesChange(chartIndex, partIndex, thisCourse, setThisCourse), `chart-time-input-${partIndex + 1}-${(chartIndex + 1)}`, 'Zeit', currentChart.time,
                                                                true,
                                                                undefined, 'text', 'chart-time-input'
                                                            )}

                                                            {/* checkbox if chart is a video-chart */}
                                                            <div className='checkbox-input-container'>
                                                                <label htmlFor={`videochart-checkbox-${partIndex + 1}-${chartIndex + 1}`}><AiOutlineVideoCamera /></label>
                                                                <input
                                                                    id={`videochart-checkbox-${partIndex + 1}-${chartIndex + 1}`}
                                                                    type="checkbox"
                                                                    onChange={() => OnChange.slidesChange(chartIndex, partIndex, thisCourse, setThisCourse)}
                                                                    defaultChecked={thisCourse.slides[partIndex][chartIndex].videoPoster || thisCourse.slides[partIndex][chartIndex].videoPoster === ""}
                                                                    value={currentChart.videoPoster !== undefined ? 1 : 0}
                                                                    className='chart-is-video-input'
                                                                />
                                                            </div>

                                                            {/* videoposter-input if videochart */}
                                                            {printInput(
                                                                () => OnChange.slidesChange(chartIndex, partIndex, thisCourse, setThisCourse), `chart-videoposter-input-${partIndex + 1}-${(chartIndex + 1)}`, 'Videoposter', (currentChart.videoPoster !== undefined) ? currentChart.videoPoster : "",
                                                                currentChart.videoPoster !== undefined,
                                                                "Falls es sich um eine mp4-Datei anstelle eines Bildes handelt muss ein Thumbnail-Bild zusätzlich in den Ordner <i style='color:gray'>assets/images/</i> und der Datei-Name hier eingetragen werden.", 'text', 'chart-videoposter-input',
                                                                false, 'datei-name.jpg'
                                                            )}
                                                        </div>
                                                    )
                                                })}

                                                <AddRemoveButton
                                                    removeText='Letzten Chart entfernen'
                                                    removeFunction={(e) => OnChange.removeChart(partIndex, setThisCourse, thisCourse)}
                                                    addText='Chart hinzufügen'
                                                    addFunction={(e) => OnChange.addChart(partIndex, setThisCourse, thisCourse)}
                                                />
                                            </div>
                                        ))
                                    }

                                    <AddRemoveButton
                                        removeText='Letzten Kursteil entfernen'
                                        removeFunction={() => OnChange.removeEntry('slides', thisCourse.slides.length - 1, thisCourse, setThisCourse)}
                                        addText='Neuen Kursteil hinzufügen'
                                        addFunction={() => OnChange.addEntry('slides', [], thisCourse, setThisCourse)}
                                        textColor='red'
                                    />
                                </>
                                :
                                <>
                                    <p>Für diesen Kurstyp oder diese Kursart werden keine Chartinformationen benötigt.</p>
                                    <div className='seperator'></div>

                                </>
                            }
                        </div>
                        : <></>
                    }


                    {/* Wissensfragen */}
                    {activeStep === 4 ?
                        <div>
                            {/* WissensfragenSichtbar */}
                            <h3>Wissensfragen</h3>

                            {thisCourse.Art === "Kurs" ?
                                <>
                                    {/* Wissensfragen sichtbar? */}
                                    {printCheckbox(() => OnChange.onChange("WissensfragenSichtbar", thisCourse.WissensfragenSichtbar ? 0 : 1, setThisCourse), "wissensfragensichtbar-input", 'Wissensfragen sichtbar', thisCourse.WissensfragenSichtbar)}

                                    {thisCourse.WissensfragenSichtbar ?
                                        <>
                                            {/* Fragen Vorgehen Überschrift */}
                                            {printInput(
                                                (e) => { OnChange.onChange("FragenTitel", e.currentTarget.value, setThisCourse) }, 'wissensfragen-vorgehen-titel-input', <>Vorgehen Titel</>, thisCourse.FragenTitel,
                                                true,
                                                "Unterhalb des Reiters <i style='color:gray'>Wissensfragen</i> wird dieser Titel angezeigt.", 'text', '', false, undefined, true
                                            )}

                                            {/* Fragen Vorgehen Beschreibung */}
                                            {printTextEditor(
                                                (e) => { OnChange.onChange("FragenVorgehen", e.currentTarget.value, setThisCourse) }, `wissensfragen-vorgehen-beschreibung-input`, <>Vorgehen Beschreibung</>, thisCourse.FragenVorgehen
                                            )}

                                            {/* Rotes Banner EFN-Hinweis */}
                                            {printTextArea(
                                                (e) => { OnChange.onChange("EFNHinweis", e.currentTarget.value, setThisCourse) }, 'efn-hinweis-input', <>EFN-Hinweis Banner</>, thisCourse.EFNHinweis
                                            )}

                                            {/* Rotes Banner EFN-Hinweis  Legende*/}
                                            {printInput(
                                                (e) => { OnChange.onChange("EFNHinweisLegende", e.currentTarget.value, setThisCourse) }, 'efn-hinweis-input', <>EFN-Hinweis Legende</>, thisCourse.EFNHinweisLegende,
                                                true,
                                                undefined, 'text', '', false, undefined, true
                                            )}

                                            {/* App ist offline Hinweis*/}
                                            {printTextArea(
                                                (e) => { OnChange.onChange("AppOfflineHinweis", e.currentTarget.value, setThisCourse) }, 'offline-hinweis-input', <>Hinweis App ist offline</>, thisCourse.AppOfflineHinweis,
                                                true,
                                                "Wenn App offline läuft erscheint dieser Hinweis anstelle des EFN-Hinweises."
                                            )}
                                            <div className="seperator"></div>


                                            {/* Antwortschlüssel */}
                                            <h4>Antwortschlüssel</h4>
                                            {printInput(undefined, "correct-answer-key-input", "Antwortschlüssel", thisCourse.CorrectAnswerKey, true, undefined, 'text', '', true, undefined, true)}

                                            {
                                                [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((key, index) =>
                                                    <span key={`singel-answer-key-${index}`}>
                                                        {printSelect(
                                                            (e) => { OnChange.correctAnswerKeyChange(e, setThisCourse, thisCourse.CorrectAnswerKey, index) },
                                                            `answerkey-input-${index}`, `Antwort Frage ${index + 1}`, thisCourse.CorrectAnswerKey ? thisCourse.CorrectAnswerKey[index] : null, ["A", "B", "C", "D", "E"]
                                                        )}
                                                    </span>
                                                )
                                            }

                                            <div className="seperator"></div>


                                            {/* Wissensfragen */}
                                            {thisCourse.Wissensfragen.map((currentQuestion, questionIndex) => (

                                                // -- single question
                                                <div key={"wissensfragen-input-container-" + questionIndex} className="wissensfragen-input-container">
                                                    <h4>Frage {questionIndex + 1} </h4>


                                                    {/* headline */}
                                                    {printInput(
                                                        () => { OnChange.wissensfragenChange(questionIndex, thisCourse, setThisCourse) }, `headline-input-${questionIndex}`, 'Überschrift', thisCourse.Wissensfragen[questionIndex].headline,
                                                        true,
                                                        undefined, "text", "headline-input"
                                                    )}


                                                    {/* subheadline */}
                                                    {printInput(
                                                        () => { OnChange.wissensfragenChange(questionIndex, thisCourse, setThisCourse) }, `subheadline-input-${questionIndex}`, 'Unterüberschrift', thisCourse.Wissensfragen[questionIndex].subheadline,
                                                        true,
                                                        "Muss nicht ausgefüllt werden.", "text", "subheadline-input"
                                                    )}


                                                    {/* question */}
                                                    {printTextEditor(
                                                        () => { OnChange.wissensfragenChange(questionIndex, thisCourse, setThisCourse) }, `question-input-${questionIndex}`, 'Frage', thisCourse.Wissensfragen[questionIndex].question,
                                                        true,
                                                        undefined, 'question-input small-text'
                                                    )}


                                                    {/* legende */}
                                                    {printInput(
                                                        () => { OnChange.wissensfragenChange(questionIndex, thisCourse, setThisCourse) }, `legende-input-${questionIndex}`, 'Legende', thisCourse.Wissensfragen[questionIndex].legende,
                                                        true,
                                                        undefined, "text", "legende-input", false, undefined, true
                                                    )}


                                                    {/* answers */}
                                                    <div className="wissensfragen-answer-container">
                                                        <h5>Antworten Frage {questionIndex + 1} – {parse(thisCourse.Wissensfragen[questionIndex].question)}</h5>
                                                        {currentQuestion.answers.map((currentAnswer, answerIndex) => {
                                                            // current answer-letter
                                                            const thisAnswer = answerIndex === 0 ? 'A' : answerIndex === 1 ? 'B' : answerIndex === 2 ? 'C' : answerIndex === 3 ? 'D' : answerIndex === 4 ? 'E' : false;
                                                            // correct answer-letter of this question
                                                            const answerCorrect = thisCourse.CorrectAnswerKey ? (thisCourse.CorrectAnswerKey[questionIndex] === thisAnswer ? true : false) : false;
                                                            return (
                                                                // -- single answer
                                                                <div key={`wissensfragen-answers-key-${answerIndex}`} className="wissensfragen-answers-input-container">
                                                                    {printTextEditor(
                                                                        () => { OnChange.wissensfragenChange(questionIndex, thisCourse, setThisCourse) }, `answer-answertext-input-${answerIndex}`, `Antwort ${currentAnswer.name}`, currentAnswer.answertext,
                                                                        true, undefined, `answer-answertext-input small-text ${answerCorrect ? 'correct-answer' : ''}`
                                                                    )}
                                                                </div>
                                                            )
                                                        })}
                                                    </div>

                                                </div>
                                            ))}
                                        </>
                                        : <></>
                                    }
                                </>
                                :
                                <>
                                    <p>Für diesen Kurstyp oder diese Kursart werden keine Wissensfragen benötigt.</p>
                                    <div className='seperator'></div>
                                </>
                            }

                        </div>
                        : <></>
                    }


                    {/* cmeducation.de */}
                    {activeStep === 5 ?
                        <div>

                            {/* Website Beschreibung */}
                            <h3>Website Informationen</h3>

                            {thisCourse.Art !== "Live" ?
                                <>
                                    {/* Sonstige Notizen */}
                                    <h4>Instagram-Beschreibung</h4>
                                    {printTextArea(
                                        (e) => { OnChange.onChange("Notizen", e.currentTarget.value, setThisCourse) }, 'notizen-input', "gekürzte Kursbeschreibung", thisCourse.Notizen,
                                        true,
                                        "Maximal 500 Zeichen.", "large-text", 500
                                    )}
                                    {/*  <h4>Notizen</h4>
                                    {printTextArea(
                                        (e) => { OnChange.onChange("Notizen", e.currentTarget.value, setThisCourse) }, 'notizen-input', "Weitere Notizen", thisCourse.Notizen,
                                        true,
                                        "Für interne Dokumentation.", "large-text"
                                    )} */}

                                    <div className='seperator'></div>


                                    {/* Kursbeschreibung Website */}
                                    <h4>Erweiterte Kursbeschreibung</h4>
                                    {printTextEditor(
                                        (e) => { OnChange.onChange("KursbeschreibungWebsite", e.currentTarget.value, setThisCourse) }, 'beschreibung-website-input', "Kursbeschreibung Website", thisCourse.KursbeschreibungWebsite,
                                        true,
                                        "Erweiterte Kursbeschreibung für CMEducation inkl. wichtigster SEO-Begriffe.<br>Nach Möglichkeit ca. 300 Wörter.", "huge-text", null, 300
                                    )}

                                    <div className='seperator'></div>


                                    {/* Metabeschreibung */}
                                    <h4>Suchmaschinen-Optimierung</h4>
                                    {printTextArea(
                                        (e) => { OnChange.onChange("MetaBeschreibung", e.currentTarget.value, setThisCourse) }, 'meta-beschreibung-website-input', "Metabeschreibung", thisCourse.MetaBeschreibung,
                                        true,
                                        "Gekürzte Kursbeschreibung max. 156 Zeichen inkl. Leerzeichen für die Vorschau auf mobilen Endgeräten und Desktop.", "large-text", 156
                                    )}


                                    {/* Keyphrase */}
                                    {printInput(
                                        (e) => { OnChange.onChange("Keyphrase", e.currentTarget.value, setThisCourse) }, 'keyphrase-input', "Keyphrase", thisCourse.Keyphrase,
                                        true,
                                        "KeyPhrase ist der Suchbegriff, für den eine Seite oder ein Beitrag am meisten ranken soll, wenn man danach sucht. Kann eine Wortkombination sein z.B.:<br><i style='color: gray'>CME Kurs chronische Rückenschmerzen</i>"
                                    )}


                                    {/* Keywords */}
                                    {printTextArea(
                                        (e) => { OnChange.onChange("Keywords", e.currentTarget.value, setThisCourse) }, 'keywords-input', "Keywords", thisCourse.Keywords,
                                        true,
                                        undefined, "large-text"
                                    )}
                                </>
                                :
                                <>
                                    <p>Für diesen Kurstyp oder diese Kursart werden keine Informationen für CMEducation benötigt.</p>
                                    <div className='seperator'></div>
                                </>
                            }


                        </div>
                        : <></>
                    }


                    {/* Shuttle-Infos */}
                    {activeStep === 6 ?
                        <>
                            <h3>CME Shuttle Informationen</h3>

                            {!thisCourse.GCP && thisCourse.Art !== "Live" ?
                                <div>


                                    {/* Veröffentlich button */}
                                    <div id='course-public-container' active={thisCourse.Public} onClick={(e) => setThisCourse(prev => ({ ...prev, Public: thisCourse.Public === 1 ? 0 : 1 }))}>
                                        <p>{thisCourse.Public ? "Kurs in CME Shuttle verfügbar" : "Kurs in CME Shuttle veröffentlichen"}</p>
                                    </div>


                                    {/* Podcast-Serie */}{
                                        thisCourse.Art === "Podcast" ?
                                            <div>
                                                <h4>Podcast Serie</h4>
                                                {printSelect(
                                                    (e) => OnChange.onChange("Podcast_Serie", e.currentTarget.value, setThisCourse),
                                                    'podcast-serie-input', 'Podcast Serie', thisCourse.Podcast_Serie, allPodcastSerien.map(elem => { return { value: elem.PODCAST_SERIE_ID, name: elem.Name } })
                                                )}

                                                {/* preview for serie-banner */}
                                                {printInputWithPreview(
                                                    true,
                                                    undefined, 'podcast-serie-banner-input', 'Podcast Serie Banner',
                                                    thisCourse.Podcast_Serie ? allPodcastSerien.filter(elem => parseInt(elem.PODCAST_SERIE_ID) === parseInt(thisCourse.Podcast_Serie))[0].Banner_url : "",
                                                    true, undefined, 'text', '', true
                                                )}

                                                <div className='seperator'></div>
                                            </div>
                                            : <></>
                                    }


                                    {/* URLs */}
                                    <h4>URL-Pfade</h4>
                                    <div>
                                        {/* url kurs */}
                                        {printInputWithPreview(
                                            false, (e) => { OnChange.onChange("urlKurs", e.currentTarget.value, setThisCourse) }, "url-kurs-input", thisCourse.Art === "Podcast" ? "URL Audio-Datei" : (thisCourse.Art === "Webinar") ? "Anmelde-URL" : "URL Kurs", thisCourse.urlKurs
                                        )}

                                        {/* url zip */}
                                        {printInput(
                                            (e) => { OnChange.onChange("urlZip", e.currentTarget.value, setThisCourse) }, "url-zip-input", "URL Zip-Datei", thisCourse.urlZip,
                                            thisCourse.Art === "Kurs"
                                        )}

                                        {/* kurs-banner-image */}
                                        {printInputWithPreview(
                                            true, (e) => { OnChange.onChange("KursBannerImage", e.currentTarget.value, setThisCourse) }, "kursbanner-input", "URL Banner", thisCourse.KursBannerImage,
                                            thisCourse.Art !== "Podcast"
                                        )}

                                        {/* Kurs-Kachel-URL */}
                                        {printInputWithPreview(
                                            true, (e) => { OnChange.onChange("KursTileImage", e.currentTarget.value, setThisCourse) }, "kurskachel-input", "URL Kachel", thisCourse.KursTileImage
                                        )}

                                        {/* Podcast-Cover-URL */}
                                        {printInputWithPreview(
                                            true, (e) => { OnChange.onChange("PodcastCover", e.currentTarget.value, setThisCourse) }, "podcast-cover-input", "Podcast-Cover", thisCourse.PodcastCover,
                                            thisCourse.Art === "Podcast"
                                        )}

                                    </div>
                                    <div className='seperator'></div>


                                    {/* Ärztliche Leiter Thumbnail-Url */}
                                    <h4>Ärztliche Leiter Thumbnail</h4>
                                    {thisCourse.AerztlicherLeiter.map((elem, index) => (
                                        <span key={"aerzt-leiter-key-" + index}>
                                            {printInputWithPreview(
                                                true, undefined, `aerztliche-leiter-small-foto-input-${index}`, `Ärztlicher Leiter Thumbnail-URL`, thisCourse.AerztlicherLeiter[index].Foto_small,
                                                true,
                                                "Wird unter dem Reiter <i style='color:gray'>Referenten</i> editiert", "text", "", true
                                            )}
                                        </span>
                                    ))}
                                    <div className='seperator'></div>


                                    {/* Referenten Thumbnail-Url */}
                                    <div>
                                        <h4>Referenten Thumbnail</h4>
                                        {thisCourse.Referenten.map((elem, index) => (
                                            <span key={"referent-key-" + index}>
                                                {/* foto-small */}
                                                {printInputWithPreview(
                                                    true, undefined, `referent-small-foto-input-${index}`, `"${thisCourse.Referenten[index].Nachname}" Thumbnail-URL`, thisCourse.Referenten[index].Foto_small,
                                                    true,
                                                    "Wird unter dem Reiter <i style='color:gray'>Referenten</i> editiert", "text", "", true
                                                )}
                                            </span>
                                        ))}
                                    </div>
                                    <div className='seperator'></div>


                                    {/* Webinar-Info */}
                                    {thisCourse.Art === "Webinar" ?
                                        <>
                                            <h3>Webinar Informationen</h3>
                                            <div>
                                                {thisCourse.WebinarInfo.map((thisWebinar, i) => {
                                                    return (
                                                        <span key={"webinar-key-" + i}>

                                                            {/* how many dates */}
                                                            <AddRemoveButton
                                                                small={true}
                                                                title={<h5>Datum {i + 1}</h5>}
                                                                removeFunction={() => OnChange.removeEntry('WebinarInfo', i, thisCourse, setThisCourse)}
                                                                addFunction={() => OnChange.addEntry('WebinarInfo', {}, thisCourse, setThisCourse, i)}
                                                            />

                                                            {printInput(
                                                                (e) => { OnChange.onChange("WebinarInfo", e.currentTarget.value, setThisCourse, true, thisCourse.WebinarInfo, i, 'Datum') }, `webinar-${i}-datum-input`, "Webinar-Datum", thisCourse.WebinarInfo[i].Datum,
                                                                true,
                                                                undefined, "text", "webinar-date-input"
                                                            )}

                                                            {printInput(
                                                                (e) => { OnChange.onChange("WebinarInfo", e.currentTarget.value, setThisCourse, true, thisCourse.WebinarInfo, i, 'Beginn') }, `webinar-${i}-beginn-input`, "Beginn", thisCourse.WebinarInfo[i].Beginn,
                                                                true,
                                                                undefined, "text", "webinar-begin-input"
                                                            )}

                                                            {printInput(
                                                                (e) => { OnChange.onChange("WebinarInfo", e.currentTarget.value, setThisCourse, true, thisCourse.WebinarInfo, i, 'Ende') }, `webinar-${i}-ende-input`, "Ende", thisCourse.WebinarInfo[i].Ende,
                                                                true,
                                                                undefined, "text", "webinar-end-input"
                                                            )}

                                                        </span>
                                                    )
                                                })}
                                                {/* how many dates */}
                                                <AddRemoveButton
                                                    removeText='Datum entfernen'
                                                    removeFunction={() => OnChange.removeEntry('WebinarInfo', thisCourse.WebinarInfo.length - 1, thisCourse, setThisCourse)}
                                                    addText='Datum hinzufügen'
                                                    addFunction={() => OnChange.addEntry('WebinarInfo', {}, thisCourse, setThisCourse)}
                                                />
                                            </div>
                                            <div className='seperator'></div>
                                        </>
                                        : <></>
                                    }


                                    {/* login */}
                                    <div>
                                        <h4>Logins</h4>
                                        {thisCourse.login.map((customer, i) => {
                                            return (
                                                <span key={"login-key-" + i}>
                                                    {printSelect(
                                                        (e) => { OnChange.onChange("login", e.currentTarget.value, setThisCourse, true, thisCourse.login, i) },
                                                        `login-${i}-input`,
                                                        <AddRemoveButton
                                                            title={<>Kundenlogin {i + 1}</>}
                                                            small={true}
                                                            removeFunction={() => OnChange.removeEntry('login', i, thisCourse, setThisCourse)}
                                                            addFunction={() => OnChange.addEntry('login', "", thisCourse, setThisCourse, i)}
                                                        />,
                                                        thisCourse.login[i],
                                                        Object.keys(loginData).sort(),
                                                        true, undefined, "login-input"
                                                    )}
                                                </span>
                                            )
                                        })}
                                        <AddRemoveButton
                                            removeText='Login löschen'
                                            addText='Login hinzufügen'
                                            removeFunction={() => OnChange.removeEntry('login', thisCourse.login.length - 1, thisCourse, setThisCourse)}
                                            addFunction={() => OnChange.addEntry('login', "", thisCourse, setThisCourse)}
                                        />,
                                    </div>
                                </div>
                                :
                                <>
                                    <p>Für GCP-Module oder Live-Veranstaltungen werden keine Shuttle-Informationen benötigt.</p>
                                    <div className='seperator'></div>
                                </>
                            }
                        </>
                        : <></>
                    }


                    {/* file manager */}
                    {activeStep === 7 ?
                        <div >

                            {/* json-file handling */}
                            <div className="file-manager-btn-container">
                                <h2>Dateiverwaltung</h2>

                                {/* download */}
                                <br /><h5><label>kurs_link.json</label></h5>
                                <div className="basic-btn left" onClick={() => downloadKursLinkJson(thisCourse)}>
                                    <button type="button">Download kurs_link.json</button>
                                </div>

                                {/* download */}
                                <br /><h5><label>offline_info.json</label></h5>
                                <div className="basic-btn left" onClick={() => downloadJson(thisCourse, tokenObject, setTokenObject)}>
                                    <button type="button">Download offline_info.json</button>
                                    <div id="error-message"></div>
                                </div>

                                <div className='seperator'></div>

                                {/* select one */}
                                {printFileInput(
                                    (e) => overwriteFile(e, thisCourse, setThisCourse), 'select-json-file', 'Aktuelle Daten überschreiben', true, undefined, 'left'
                                )}

                                {/* success/errormessage */}
                                <div id="select-json-file-message"></div>

                            </div>

                            <div className='seperator'></div>

                            <div className='file-manager-btn-container'>
                                <h2>PDF generieren</h2>
                                <PdfCreator thisCourse={thisCourse} />
                            </div>
                        </div>
                        : <></>
                    }

                </form>

                {/* navigation-buttons */}
                <div id="prev-next-btn-container">
                    <button style={activeStep === 0 ? { display: "none" } : {}} className="prev-btn" onClick={() => setActiveStep(activeStep - 1)}><AiOutlineLeft /></button>
                    <button style={activeStep === 7 ? { display: "none" } : {}} className="next-btn" onClick={() => setActiveStep(activeStep + 1)}><AiOutlineRight /></button>
                </div>

            </div>
        )
    }
    else {
        return <Loader />
    }
}

export default Form
import { useState, useEffect, useContext } from "react";
import { TokenContext } from "./Main";
import { AiOutlineDown } from "react-icons/ai";
import { FiTrash2 } from 'react-icons/fi'

import { sendPatchRequest, sendRequest } from "../rest/requests";
import { toggleTable } from "../functions/helper";
import { printInput } from "./InputComponents";

import Loader from "./Loader";
import Interessensgebiete from "./Interessensgebiete";

const Fachgebiete = ({ }) => {

    // access-token
    const [tokenObject, setTokenObject] = useContext(TokenContext);

    // init
    const [initialized, setInitialized] = useState(false);

    // fachgebiete
    const [fachgebiete, setFachgebiete] = useState();


    // initialize component
    useEffect(() => { init() }, []);
    const init = async () => {
        // GET FACHGEBIETE FROM DATABASE
        const response = await sendRequest(tokenObject, setTokenObject, 'GET', '/fachgebiete', 'Lade Fachgebiete', 'Fachgebiete geladen');
        if (response.success) {
            setFachgebiete(response.data);
            setInitialized(true);
        }
    }


    // update 
    const update = async (newName, index) => {
        // set updated array
        const newFachgebiete = [...fachgebiete];
        newFachgebiete[index].Name = newName;

        // update state
        setFachgebiete(newFachgebiete);

        // UPDATE FACHGEBIET IN DATABASE
        const response = await sendPatchRequest(tokenObject, setTokenObject, '/fachgebiete', newFachgebiete[index], `Speichere Fachgebiet`, `Fachgebiet gespeichert`);
        if (!response.success) setInitialized(false);
    }

    // add
    const add = async () => {
        const newValue = window.prompt("Bitte trage den Names des Fachgebietes ein");

        if (newValue) {
            setInitialized(false);

            // ADD FACHGEBIET TO DATABASE
            const response = await sendRequest(tokenObject, setTokenObject, 'POST', '/fachgebiete', 'Erstelle Fachgebiet', 'Fachgebiet erstellt', { "Name": newValue });
            if (response.success) init();
        }
    }

    // delete
    const deleteFachgebiet = async (thisFachgebiet) => {
        const confirm = window.confirm(`Fachgebiet "${thisFachgebiet.Name}" wirklich löschen?`);

        if (confirm) {
            setInitialized(false);

            // DELETE FACHGEBIET FROM DATABASE
            const response = await sendRequest(tokenObject, setTokenObject, 'DELETE', '/fachgebiete', `Lösche ${thisFachgebiet.Name}`, `${thisFachgebiet.Name} gelöscht`, thisFachgebiet);
            if (response.success) init();
        }
    }


    if (initialized) {
        return (
            <div className="basic-container">
                <div>
                    <h3>Fachgebiete</h3>

                    <div className="table-container form-container" active="false">
                        <div className="table-header" onClick={toggleTable}>
                            <AiOutlineDown />
                            {fachgebiete.length} Fachgebiete
                        </div>
                        <table>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* map fachgebiete */}
                                {fachgebiete.map((thisFachgebiet, index) => {

                                    {/* name */ }
                                    return (
                                        <tr key={`fachgebiete-list-key-${index}`}>
                                            <td className="column-icon">{thisFachgebiet.FACHGEBIET_ID}</td>
                                            <td className="column-input">{printInput((e) => update(e.currentTarget.value, index), `single-fachgebiet-name-${index}`, '', thisFachgebiet.Name)}</td>
                                            <td className="column-icon delete pointer" onClick={() => deleteFachgebiet(thisFachgebiet)}><FiTrash2 /></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>

                    {/* add */}
                    <div className="basic-btn" onClick={add}>Fachgebiet hinzufügen</div>
                </div>
                <div className="seperator"></div>

                <div>
                    <Interessensgebiete />
                </div>
            </div >
        )
    }
    else {
        return <Loader />
    }
}

export default Fachgebiete
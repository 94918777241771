// standard templates
import templateStandard from './../assets/course-templates/template-standard.json'

// templates german
import templatePdf from './../assets/course-templates/template-pdf.json'
import templateLive from './../assets/course-templates/template-live.json'
// templates english
import templateStandard_en from '../assets/course-templates/template-standard-english.json'
import templateLive_en from './../assets/course-templates/template-live-english.json'
// template aerztekammer
import templateBerlin from './../assets/course-templates/template-berlin.json'


const overwriteCourseWithTemplates = (course, template, courseIsEnglish = false) => {

    // overwrite standard-english
    if (courseIsEnglish) course = { ...course, ...templateStandard_en };

    // overwrite specific values depending on "Kurs.Art" and "Kurs.KursTyp"
    switch (template) {

        case "PDF":
            course = { ...course, ...templatePdf };
            break;

        case "Live":
            course = { ...course, ...templateLive };
            if (courseIsEnglish) course = { ...course, ...templateLive_en };
            break;

        case "Video":
            // default
            break;

        case "GCP-Modul":
            course.GCP = 1;
            break;

        case false:
            throw "Fehler bei switch-func overwriteCourseWithTemplates"
    }

    // which template for ärztekammer
    let aekTemplate = course.Aerztekammer === "Bayern" ? templateStandard : (course.Aerztekammer === "Berlin" ? templateBerlin : undefined);

    // overwrite specific values depending on which Ärztekammer
    if (aekTemplate) {
        // allgemein
        course.Info.Allgemein.Zertifizierung = aekTemplate.Info.Allgemein.Zertifizierung;
        // impressum
        course.Info.Impressum.Vertrieb = aekTemplate.Info.Impressum.Vertrieb;
        course.Info.Impressum.Herausgeber = aekTemplate.Info.Impressum.Herausgeber;
        // datenschutz
        course.Info.Datenschutz.Text = aekTemplate.Info.Datenschutz.Text;
        // mail
        course.Mail_Erfolg = aekTemplate.Mail_Erfolg;
        course.Mail_Fail = aekTemplate.Mail_Fail;
        course.Mail_AD_Erfolg = aekTemplate.Mail_AD_Erfolg;
        course.Mail_AD_Fail = aekTemplate.Mail_AD_Fail;
    }

    return course;
}



// in case aerztekammer changes, select a different json-template
const aerztekammerChange = (e, thisCourse, setThisCourse) => {

    // which aek
    const aerztekammer = e.currentTarget ? e.currentTarget.value : undefined;

    // overwrite from template
    const overwriteFromTemplate = window.confirm(`Sollen Kursinfos gemäß der Vorlage für die Ärztekammer ${aerztekammer} überschrieben werden?`);

    // use templates to overwrite this Course
    if (overwriteFromTemplate) {

        // set which template
        let template;
        if (thisCourse.Art === 'Kurs') {
            if (thisCourse.KursTyp === 'Standard' || thisCourse.KursTyp === 'BigVideo') template = 'Video';
            else if (thisCourse.KursTyp === 'PDF') template = 'PDF';
            else if (thisCourse.KursTyp === 'PowerPoint') template = 'PowerPoint';
        }
        else if (thisCourse.Art === 'Webinar' || thisCourse.Art === 'Podcast' || thisCourse.Art === 'Live') {
            template = thisCourse.Art;
        }

        // overwrite
        let newCourse = { ...thisCourse, Aerztekammer: aerztekammer };
        newCourse = overwriteCourseWithTemplates(newCourse, template);

        // set state
        setThisCourse(newCourse);
    }
    // dont use template to overwrite, just change Aerztekammer
    else {
        setThisCourse(prev => ({
            ...prev,
            Aerztekammer: aerztekammer
        }))
    }
}

export { overwriteCourseWithTemplates, aerztekammerChange }
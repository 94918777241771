import { sendRequest } from "./requests";


// GET ALL REFERENTEN
const getReferentenFromDatabase = async (tokenObject, setTokenObject) => {
    const response = await sendRequest(tokenObject, setTokenObject, 'GET', '/referenten', 'Lade Referenten aus Datenbank', 'Referenten geladen');
    return response.data;
}

// GET ALL COURSES
const getCoursesFromDatabase = async (tokenObject, setTokenObject) => {
    const receivedCoursesResponse = await sendRequest(tokenObject, setTokenObject, 'GET', '/kurse', 'Lade Kurse aus Datenbank', 'Kurse geladen');
    const receivedCourses = receivedCoursesResponse.data;
    const courseTypesArray = [
        /* 
        { id: "id used by code", data: [array of courses,etc], name: "display name", types: [array of possible types - used for creating a template] } 
        */
        { id: "CME_Kurse", data: receivedCourses.CME_Kurse, name: "CME-Kurse", types: ["Video", "PDF"] },
        { id: "Live", data: receivedCourses.Live, name: "Live-Veranstaltungen", types: ["Live"] },
        { id: "Webinare", data: receivedCourses.Webinare, name: "Webinar-Anmeldungen", types: ["Webinar"] },
        { id: "Podcasts", data: receivedCourses.Podcasts, name: "Podcasts", types: ["Podcast"] },
        { id: "GCP", data: receivedCourses.GCP, name: "GCP-Module", types: ["GCP-Modul"] }
    ];

    return courseTypesArray;
}

// GET ALL COURSES IN ONE ARRAY
const getAllCoursesInOneArray = async (tokenObject, setTokenObject) => {

    // GET COURSE-OBJECT FROM DB
    const receivedCoursesResponse = await sendRequest(tokenObject, setTokenObject, 'GET', '/kurse', 'Lade Kurse aus Datenbank', 'Kurse geladen');
    const receivedCourses = receivedCoursesResponse.data;

    // -- create one array for all course-types
    const coursesArray = [];
    const courseTypesKeys = Object.keys(receivedCourses);

    // run through keys (e.g. CME_Kurse)
    for (let i = 0; i < courseTypesKeys.length; i++) {
        const key = courseTypesKeys[i];
        const courses = receivedCourses[key]

        // run through courses with this key
        for (let j = 0; j < courses.length; j++) {
            const course = courses[j];
            coursesArray.push(course);
        }
    }

    return coursesArray;
}

// GET ALL GCP
const getGcpFromDatabase = async (tokenObject, setTokenObject) => {
    const response = await sendRequest(tokenObject, setTokenObject, 'GET', '/gcp', 'Lade GCP-Kurse aus Datenbank', 'GCP-Kurse geladen');
    return response.data;
}

// GET ALL TEILNEHMER
const getTeilnehmerFromDatabase = async (tokenObject, setTokenObject) => {
    const response = await sendRequest(tokenObject, setTokenObject, 'GET', '/teilnehmer', 'Lade Teilnehmer aus Datenbank', 'Teilnehmer geladen');
    return response.data;
}

// GET ALL RELATIONS
const getRelationsFromDatabase = async (tokenObject, setTokenObject) => {
    const response = await sendRequest(tokenObject, setTokenObject, 'GET', '/kursteilnahme/allKursTeilnehmerRelations', 'Lade Kurs-Teilnehmer-Relations', 'Kurs-Teilnehmer-Relations geladen');
    return response.data;
}

// GET ALL VORLAGEN
const getVorlagenFromDatabase = async (tokenObject, setTokenObject) => {
    const response = await sendRequest(tokenObject, setTokenObject, 'GET', '/zertifikate/vorlagen', 'Lade Zertifikat-Vorlagen', 'Zertifikat-Vorlagen geladen');
    return response.data;
}

// GET ALL PODCAST-SERIEN
const getAllPodcastSerien = async (tokenObject, setTokenObject) => {
    const response = await sendRequest(tokenObject, setTokenObject, 'GET', `/podcast-serien`, 'Lade Podcast-Serien', 'Podcast-Serien geladen');
    return response.data;
}



export { getReferentenFromDatabase, getCoursesFromDatabase, getAllCoursesInOneArray, getGcpFromDatabase, getTeilnehmerFromDatabase, getRelationsFromDatabase, getVorlagenFromDatabase, getAllPodcastSerien }
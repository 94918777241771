import { useEffect, useState, useContext } from "react"
import { TokenContext } from "./Main";
import { FiTrash2 } from 'react-icons/fi'
import { AiOutlineArrowDown } from 'react-icons/ai'
import { IoReload } from 'react-icons/io5'

import { sendPatchRequest, sendRequest } from "../rest/requests";
import { printInput, printSelect } from "./InputComponents";
import { getAllCoursesInOneArray } from "../rest/requests_get_all";
import { filterArray, sortBy, sortByDate } from "../functions/helper";

import Loader from "./Loader";



export default function Links({ }) {

    // access-token
    const [tokenObject, setTokenObject] = useContext(TokenContext);

    // init
    const [initialized, setInitialized] = useState(false);

    // links
    const [links, setLinks] = useState();

    // filtered and sorted links
    const [filteredLinks, setFilteredLinks] = useState();
    const [focusedLink, setFocusedLink] = useState();
    const [sortingReversed, setSortingReversed] = useState(false)

    // filter
    const [filterSponsor, setFilterSponsor] = useState("Alle");
    const [filterCourse, setFilterCourse] = useState("Alle");

    // courses
    const [coursesArray, setCoursesArray] = useState();

    // sponsors
    const [sponsoren, setSponsoren] = useState();

    // when updating - set to index which is currently updating
    const [updatingInProgress, setUpdatingInProgress] = useState();


    // -- init
    useEffect(() => {
        init();
    }, [])
    const init = async () => {
        // GET LINKS FROM DATABASE
        const links_response = await sendRequest(tokenObject, setTokenObject, 'GET', '/links', 'Lade Links', 'Links geladen');
        // GET SPONSORS FROM DATABASE
        const sponsor_response = await sendRequest(tokenObject, setTokenObject, 'GET', '/sponsoren', 'Lade Sponsoren', 'Sponsoren geladen');
        // GET COURSES FROM DATABASE
        const all_courses = await getAllCoursesInOneArray(tokenObject, setTokenObject);
        if (links_response.success && sponsor_response.success && all_courses) {
            setLinks(links_response.data);
            setFilteredLinks(links_response.data);
            setSponsoren(sponsor_response.data);
            setCoursesArray(all_courses.filter(elem => elem.Art !== 'Podcast' && elem.KursKey));
            setInitialized(true);
        }
    }

    // -- filter
    useEffect(() => {
        if (filterSponsor && filteredLinks) {
            const newArr = filterArray(links, [{ key: 'Sponsor', value: filterSponsor }, { key: 'KursKey', value: filterCourse }]);
            setFocusedLink();
            setFilteredLinks(newArr);
        }
    }, [filterSponsor, filterCourse])


    // -- delete link
    const deleteLink = async (link) => {
        const confirmation = window.confirm(`Sicher, dass du den Link "${link.Kampagne}" löschen möchtest`);
        if (confirmation) {
            setInitialized(false);
            // REMOVE LINK FROM DATABASE
            const response = await sendRequest(tokenObject, setTokenObject, 'DELETE', '/links', 'Lösche Link', 'Link gelöscht', { LINK_ID: link.LINK_ID });
            // on error
            if (response.success) init();
            else alert("Fehler beim Löschen des Links");
        }
    }
    // -- add link
    const addLink = async () => {
        setInitialized(false);
        // ADD LINK TO DATABASE
        const response = await sendRequest(tokenObject, setTokenObject, 'POST', '/links', 'Erstelle Link', 'Link erstellt', {});
        // init component again
        if (response.success) init();
        else alert("Fehler beim Hinzufügen eines Links");
    }
    // -- update link
    const updateLink = async (key, value, index) => {
        if (updatingInProgress === undefined || updatingInProgress === index) {
            // set updating
            setUpdatingInProgress(index);

            // new object
            const newLinks = [...filteredLinks];
            newLinks[index][key] = value;

            // automaticly update Laufzeit_Ende and Sponsor if KursKey got changed
            if (key === 'KursKey') {
                const thisCourse = coursesArray.filter(course => course.KursKey === value)[0];
                newLinks[index].Sponsor = thisCourse.Sponsor_Name;
                newLinks[index].Laufzeit_Ende = thisCourse.Laufzeit_Ende;
            }

            // update this link locally in state
            setFilteredLinks(newLinks);
            // UPDATE THIS LINK IN DATABASE
            const response = await sendPatchRequest(tokenObject, setTokenObject, `/links`, newLinks[index], `Speichere Link`, `Link gespeichert`,)
            // on error
            if (!response.success) { alert("Fehler beim Updaten der Links"); setInitialized(false); }

            // reset updating
            setUpdatingInProgress();
        }
    }


    // -- sort links
    const sortLinks = (e, key_one, byDate = false, key_two = undefined) => {
        // reset focused
        setFocusedLink();
        // set elem = active
        const headerElem = e.currentTarget;
        // header
        const tableHeader = headerElem.parentNode;
        const headerCells = tableHeader.querySelectorAll('th');
        // remove all active
        for (let i = 0; i < headerCells.length; i++) {
            const cell = headerCells[i];
            cell.removeAttribute('active');
        }
        // add active to clicked cell
        headerElem.setAttribute('active', '');
        headerElem.setAttribute('reverse', sortingReversed);

        // sort current filteredLinks by date or key
        const sorted = byDate ? sortByDate(filteredLinks, key_one, sortingReversed) : sortBy(filteredLinks, key_one, key_two, sortingReversed);
        // set new filteredLinks-state
        setFilteredLinks(sorted);
        // set reverse-state if table is reversed
        setSortingReversed(!sortingReversed);
    }


    if (initialized && filteredLinks && sponsoren && coursesArray && filterSponsor) {
        return (
            <div className="basic-container">

                <h3>Übersicht der vergebenen Links</h3>

                <div className="form-container">


                    {/* table */}
                    <div>
                        {/* select which customer */}
                        <div>
                            {/* reset-button */}
                            <div className='filter-header-container' onClick={() => { setFilterSponsor("Alle"); setFilterCourse('Alle') }}>
                                <h5>Links</h5>
                                <IoReload />
                            </div>
                            {/* dropdown for sponsors */}
                            {printSelect
                                ((e) => setFilterSponsor(e.currentTarget.value), "customer-filter-list", 'Kunde:', filterSponsor,
                                    ["Alle"].concat(sponsoren.map((sponsor, i) => sponsor.Name))
                                )}
                            {/* dropdown for course */}
                            {printSelect
                                ((e) => setFilterCourse(e.currentTarget.value), "course-filter-list", 'Kurs:', filterCourse,
                                    ["Alle"].concat(coursesArray.map((course, i) => course.KursKey).sort())
                                )}
                        </div>

                        {/* table with links */}
                        <div className="table-container compact">

                            <div className="table-legend">
                                <span className="link-focused">Ausgewählt</span>
                                <span className="active">Aktiv</span>
                                <span className="expired">Ausgelaufen</span>
                            </div>

                            {/* long row for link */}
                            {focusedLink !== undefined ?
                                <div className="link-focused">
                                    {printInput((e) => updateLink('Link', e.currentTarget.value, focusedLink), `link-focused-link-${focusedLink}`, '', filteredLinks[focusedLink].Link, true, undefined, 'text', '', false, 'Link', true)}
                                </div>
                                :
                                <div>Keine Zeile ausgewählt</div>
                            }

                            {/* table */}
                            <table>
                                <thead>
                                    <tr className="sorted-table-header">
                                        <th className="pointer" onClick={(e) => sortLinks(e, 'KursKey')}>Kurs<AiOutlineArrowDown /></th>
                                        <th className="pointer" onClick={(e) => sortLinks(e, 'Laufzeit_Ende', true)}>Gültig bis<AiOutlineArrowDown /></th>
                                        <th className="pointer" onClick={(e) => sortLinks(e, 'Sponsor')}>Sponsor<AiOutlineArrowDown /></th>
                                        <th className="pointer" onClick={(e) => sortLinks(e, 'Kampagne')}>Kampagne<AiOutlineArrowDown /></th>
                                        <th className="pointer" onClick={(e) => sortLinks(e, 'Link')}>Link<AiOutlineArrowDown /></th>
                                        <th className="pointer" onClick={(e) => sortLinks(e, 'Laufzeit_Start', true)}>Ausgegeben<AiOutlineArrowDown /></th>
                                        <th className="pointer" onClick={(e) => sortLinks(e, 'Empfaenger')}>An<AiOutlineArrowDown /></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredLinks.map((thisLink, index) => {
                                        const today = (new Date()).setHours(0, 0, 0, 0);
                                        const end = (new Date(thisLink.Laufzeit_Ende)).setHours(0, 0, 0, 0);

                                        const state = today > end ? "expired" : "active";
                                        return (
                                            <tr key={`single-link-key-${index}`} onFocus={() => setFocusedLink(index)} className={focusedLink === index ? 'link-focused' : '' + ` ${state}`}>

                                                {/* kurskey */}
                                                <td className="column-input column-width-10">{printSelect((e) => updateLink('KursKey', e.currentTarget.value, index), `link-kurs-${index}`, 'Kurs', thisLink.KursKey, coursesArray.map(elem => elem.KursKey))}</td>

                                                {/* Laufzeit_Ende */}
                                                <td className="column-input column-width-10">{printInput((e) => updateLink('Laufzeit_Ende', e.currentTarget.value, index), `link-Laufzeit_Ende-${index}`, 'Laufzeit_Ende', thisLink.Laufzeit_Ende, true, undefined, 'date')}</td>

                                                {/* sponsor-id */}
                                                <td className="column-input column-width-10">{printSelect((e) => updateLink('Sponsor', e.currentTarget.value, index), `link-sponsor-${index}`, 'Sponsor', thisLink.Sponsor, sponsoren.map(elem => ({ value: elem.Name, name: elem.Kuerzel ? elem.Kuerzel : 'Kein Sponsor' })))}</td>

                                                {/* kampagne */}
                                                <td className="column-input">{printInput((e) => updateLink('Kampagne', e.currentTarget.value, index), `link-kampagne-${index}`, 'Kampagne', thisLink.Kampagne)}</td>

                                                {/* link */}
                                                <td className="column-input">{printInput((e) => updateLink('Link', e.currentTarget.value, index), `link-link-${index}`, 'Link', thisLink.Link)}</td>

                                                {/* Laufzeit_Start */}
                                                <td className="column-input column-width-10">{printInput((e) => updateLink('Laufzeit_Start', e.currentTarget.value, index), `link-Laufzeit_Start-${index}`, 'Laufzeit_Start', thisLink.Laufzeit_Start, true, undefined, 'date')}</td>

                                                {/* empfänger */}
                                                <td className="column-input">{printInput((e) => updateLink('Empfaenger', e.currentTarget.value, index), `link-empfaenger-${index}`, 'Empfaenger', thisLink.Empfaenger)}</td>

                                                <td className="column-icon delete pointer" onClick={() => deleteLink(thisLink)}><FiTrash2 /></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>


                    {/* new logins */}
                    <div className="basic-btn" onClick={addLink}>Link hinzufügen</div>
                </div>
            </div >
        )
    }
    else {
        return (
            <Loader />
        )
    }
}
import { useEffect, useState } from 'react';

import { Document, Page, Text, Font, View, StyleSheet, PDFViewer, } from "@react-pdf/renderer";

import { printCheckbox } from '../InputComponents';

import './../../assets/css/pdfcreator.css'
import FiraSans from './../../assets/fonts/FiraSans-Book.otf'
import FiraSansBold from './../../assets/fonts/FiraSans-Bold.otf'
import FiraSansBookItalic from './../../assets/fonts/FiraSans-BookItalic.otf'



const PdfCreator = ({ thisCourse }) => {

    // -- states
    const [pdfInitialized, setPdfInitialized] = useState(false);
    const [courseInfos, setCourseInfos] = useState(null);


    // -- selection of infos contained in pdf
    const [selectedInfos, setSelectedInfos] = useState({
        "Format_Hinweis": false,
        "Beschreibung": true,
        "Referenten": true,
        "Zielgruppe": true,
        "Lernziele": true,
        "Zertifizierung": true,
        "Antwortschluessel": true,
        "Wissensfragen": true,
        "Charts": true,
        "CMEducationUrl": true,
        "Sponsor": true
    });

    const changeSelection = (key) => {
        var newSelection = { ...selectedInfos };
        newSelection[key] = !newSelection[key];

        setSelectedInfos(newSelection);
    }

    // -- on course-info change -> hide pdf
    useEffect(() => {
        if (pdfInitialized) setPdfInitialized(false);
    }, [thisCourse]);


    // -- show pdf-function
    const showPdf = () => {
        var thisCourseCopy = JSON.stringify({ ...thisCourse });

        // remove html-elements
        thisCourseCopy = thisCourseCopy.replaceAll('<li>', '- ');
        thisCourseCopy = thisCourseCopy.replaceAll('</li>', '');
        thisCourseCopy = thisCourseCopy.replaceAll('<ul>', '');
        thisCourseCopy = thisCourseCopy.replaceAll('</ul>', '');
        thisCourseCopy = thisCourseCopy.replaceAll('<u>', '');
        thisCourseCopy = thisCourseCopy.replaceAll('</u>', '');
        thisCourseCopy = thisCourseCopy.replaceAll('<i>', '');
        thisCourseCopy = thisCourseCopy.replaceAll('</i>', '');
        thisCourseCopy = thisCourseCopy.replaceAll('<br><br>', '\\n');
        thisCourseCopy = thisCourseCopy.replaceAll('<br>', '\\n');
        thisCourseCopy = thisCourseCopy.replaceAll('<strong>', '');
        thisCourseCopy = thisCourseCopy.replaceAll('</strong>', '');
        thisCourseCopy = thisCourseCopy.replaceAll('<sub>', '');
        thisCourseCopy = thisCourseCopy.replaceAll('</sub>', '');
        thisCourseCopy = thisCourseCopy.replaceAll('<sup>', '');
        thisCourseCopy = thisCourseCopy.replaceAll('</sup>', '');
        // replace shortcodes
        thisCourseCopy = thisCourseCopy.replaceAll("{SPONSOR}", thisCourse.Sponsor_Name);
        thisCourseCopy = thisCourseCopy.replaceAll("{VNR}", thisCourse.VNR);
        thisCourseCopy = thisCourseCopy.replaceAll("{TITEL}", thisCourse.KursTitel);
        thisCourseCopy = thisCourseCopy.replaceAll("{CME_PUNKTE}", thisCourse.CME_Punkte);
        thisCourseCopy = thisCourseCopy.replaceAll("{FORTBILDUNGSKATEGORIE}", thisCourse.Fortbildungskategorie);

        thisCourseCopy = JSON.parse(thisCourseCopy);
        setCourseInfos(thisCourseCopy);
        setPdfInitialized(true);
    }

    return (
        <div className="pdf-creator-container">


            <div id="pdf-info-selection">

                {Object.keys(selectedInfos).map((key, index) => (
                    <span key={`pdfcreator-key-${index}`}>
                        {printCheckbox(() => changeSelection(key), `pdf-${key}-input`, key, selectedInfos[key])}
                    </span>
                ))}


            </div>

            <button className='basic-btn' onClick={showPdf} >Erstelle PDF</button>


            {/* DOCUMENT */}
            {pdfInitialized ?
                <PDFViewer style={styles.viewer}>
                    {/* Start of the document*/}
                    <Document>
                        {/*render a single page*/}
                        <Page size="A4" style={styles.page}>

                            {/* text format-info */}
                            {selectedInfos.Format_Hinweis ?
                                <View style={[styles.section, { color: 'green' }]}>
                                    <Text>HINWEIS: Die Inhalte dieses PDFs werden aus der Kursseite generiert und entsprechen in der Darstellung der Silbentrennung, Formatierung und Zeilenumbrüche nicht der tatsächliche Darstellung auf der Website. Die korrekte Darstellung finden Sie in dem Link zum Kurs.</Text>
                                </View>
                                :
                                <></>
                            }

                            {/* TITLE */}
                            <Text style={[styles.section, styles.header, styles.courseTitel]}>{courseInfos.KursTitel}</Text>

                            {/* CMEducation URL */}
                            {selectedInfos.CMEducationUrl === true ?
                                <View style={styles.section}>
                                    <Text style={styles.header}>Link zu CMEducation</Text>
                                    <Text>{courseInfos.cmeducationURL}</Text>
                                </View>
                                : <></>
                            }

                            {/* BESCHREIBUNG */}
                            {
                                selectedInfos.Beschreibung === true ?
                                    <View style={styles.section}>
                                        <Text style={styles.header}>Beschreibung</Text>
                                        <Text>{courseInfos.KursBeschreibung}</Text>
                                    </View>
                                    : <></>
                            }

                            {/* ÄRZTLEITER & REFERENTEN */}
                            {
                                selectedInfos.Referenten === true ?
                                    <View style={styles.section}>
                                        <Text style={styles.header}>Ärztlicher Leiter und Referenten</Text>
                                        {[...courseInfos.AerztlicherLeiter, ...courseInfos.Referenten].map((thisReferent, index) => {
                                            if (thisReferent.Vorname && thisReferent.Nachname)
                                                return (
                                                    <View style={styles.innersection} key={`referenten-container-${index}`}>

                                                        <Text style={styles.subheader}>{thisReferent.Titel} {thisReferent.Vorname} {thisReferent.Nachname}</Text>

                                                        <Text style={styles.subsubheader}>Allgemein</Text>
                                                        <Text>{thisReferent.Allgemein}</Text>

                                                        {thisReferent.Interessenskonflikte ?
                                                            <>
                                                                <Text style={styles.subsubheader}>Interessenskonflikte</Text>
                                                                <Text>{thisReferent.Interessenskonflikte}</Text></>
                                                            : <></>
                                                        }

                                                        {thisReferent.Lebenslauf ?
                                                            <>
                                                                <Text style={styles.subsubheader}>Lebenslauf</Text>
                                                                <Text>{thisReferent.Lebenslauf}</Text></>
                                                            : <></>
                                                        }
                                                    </View>
                                                )
                                        }
                                        )}
                                    </View>
                                    : <></>
                            }

                            {/* ZIELGRUPPE */}
                            {
                                selectedInfos.Zielgruppe === true ?
                                    <View style={styles.section}>
                                        <Text style={styles.header}>Zielgruppe</Text>
                                        <Text>{courseInfos.Info.Allgemein.Zielgruppe}</Text>
                                    </View>
                                    : <></>
                            }

                            {/* LERNZIELE */}
                            {
                                selectedInfos.Lernziele === true ?
                                    <View style={styles.section}>
                                        <Text style={styles.header}>Lernziele</Text>
                                        <Text>{courseInfos.Info.Allgemein.Lernziele}</Text>
                                    </View>
                                    : <></>
                            }

                            {/* ZERTIFIZIERUNG */}
                            {
                                selectedInfos.Zertifizierung === true ?
                                    <View style={styles.section}>
                                        <Text style={styles.header}>Zertifizierung</Text>
                                        <Text>{courseInfos.Info.Allgemein.Zertifizierung}</Text>
                                    </View>
                                    : <></>
                            }

                            {/* SPONSOR */}
                            {
                                selectedInfos.Sponsor === true ?
                                    <View style={styles.section}>
                                        <Text style={styles.header}>Sponsor</Text>
                                        <Text style={styles.bold}>{courseInfos.Sponsor_Name}</Text>
                                        <Text>{courseInfos.Sponsor_Info}</Text>
                                    </View>
                                    : <></>
                            }

                            {/* ANTWORTSCHLÜSSEL */}
                            {
                                selectedInfos.Antwortschluessel === true ?
                                    <View style={styles.section}>
                                        <Text style={styles.header}>Antwortschlüssel</Text>
                                        <View style={styles.innersection}>
                                            <Text>{courseInfos.CorrectAnswerKey}</Text>
                                        </View>

                                        <View>
                                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((question, index) =>
                                                <Text key={`answerkey-text-key-${index}`} style={[styles.innersection]}><Text style={styles.bold}>Frage {index + 1}: </Text> {courseInfos.CorrectAnswerKey ? courseInfos.CorrectAnswerKey[index] : "Keine Angabe"}</Text>
                                            )}
                                        </View>
                                    </View>
                                    : <></>
                            }

                            {/* WISSENSFRAGEN */}
                            {
                                selectedInfos.Wissensfragen === true ?
                                    <View style={styles.section}>
                                        <Text style={styles.header}>Wissensfragen</Text>
                                        {courseInfos.Wissensfragen.map((thisWissensfrage, questionIndex) =>

                                            // single question
                                            <View style={[styles.innersection, styles.questionContainer]} key={`wissensfrage-container-${questionIndex}`}>
                                                <Text style={styles.bold}>{`FRAGE: ${questionIndex + 1}: ${thisWissensfrage.question}`}</Text>

                                                {thisWissensfrage.answers.map((thisAnswer, answerIndex) => {
                                                    var correctanswerIndex;

                                                    var correctAnswer = courseInfos.CorrectAnswerKey ? courseInfos.CorrectAnswerKey.charAt(questionIndex) : false;

                                                    switch (correctAnswer) {
                                                        case "A":
                                                            correctanswerIndex = 0;
                                                            break;
                                                        case "B":
                                                            correctanswerIndex = 1;
                                                            break;
                                                        case "C":
                                                            correctanswerIndex = 2;
                                                            break;
                                                        case "D":
                                                            correctanswerIndex = 3;
                                                            break;
                                                        case "E":
                                                            correctanswerIndex = 4;
                                                            break;

                                                        default:
                                                            break;
                                                    }
                                                    return (
                                                        // single answer
                                                        <View key={`single-answer-container-${answerIndex}`}>
                                                            <Text style={[{ marginVertical: 5 }, (correctanswerIndex === answerIndex) ? { color: "green" } : {}]}>
                                                                <Text style={styles.bold}>{`${thisAnswer.name}: `}</Text>
                                                                {thisAnswer.answertext}
                                                            </Text>
                                                        </View>
                                                    )
                                                })}

                                                {thisWissensfrage.legende ? <Text style={[styles.italic, styles.legende]}>Legende: {thisWissensfrage.legende}</Text> : <Text></Text>}
                                            </View>
                                        )}
                                    </View>
                                    : <></>
                            }

                            {/* CHARTS */}
                            {
                                selectedInfos.Charts === true ?
                                    <View style={styles.section}>
                                        <Text style={styles.header}>Charts</Text>
                                        {courseInfos.slides[0].map((thisSlide, slideIndex) =>
                                            <View style={styles.innersection} key={`single-chart-container-${slideIndex}`}>
                                                <Text><Text style={styles.italic}>{thisSlide.time} </Text>– {thisSlide.title}</Text>
                                            </View>
                                        )}
                                    </View>
                                    : <></>
                            }
                        </Page>
                    </Document>
                </PDFViewer>
                :
                <></>
            }
        </div>
    )
}

// Register font
Font.register({ family: 'FiraSans', src: FiraSans });
Font.register({ family: 'FiraSansBold', src: FiraSansBold });
Font.register({ family: 'FiraSansBookItalic', src: FiraSansBookItalic });

// Create styles
const styles = StyleSheet.create({
    page: {
        backgroundColor: "white",

        fontFamily: "FiraSans",
        color: "#3b3b3b",
        fontSize: 12,
        paddingVertical: 50,
    },
    courseTitel: {
        fontSize: 25,
        fontFamily: "FiraSans",
        color: "#eb4260"
    },
    header: {
        marginTop: 8,
        fontSize: 18,
        fontFamily: "FiraSansBold",
    },
    subheader: {
        fontSize: 14,
        fontFamily: "FiraSansBold",
    },
    subsubheader: {
        fontSize: 14,
        color: "#adadad",
        marginTop: 5
    },
    bold: {
        fontFamily: "FiraSansBold",
    },
    italic: {
        fontFamily: "FiraSansBookItalic",
    },
    section: {
        marginHorizontal: 50,
        marginBottom: 15,
        padding: 2,
    },
    innersection: {
        marginVertical: 10,
    },
    questionContainer: {

        borderBottomColor: "#adadad",
        paddingBottom: 20,
        borderBottomWidth: 1
    },
    viewer: {
        flex: 1,
        marginTop: 50,
        width: "100%",
        height: window.innerHeight,
    },
    legende: {
        fontSize: 10,
        color: "#adadad"
    }
});


export default PdfCreator
import { useState, useEffect } from "react";
import { ImCancelCircle } from 'react-icons/im'
import { IoIosArrowDropdown } from 'react-icons/io'

import Loader from "./Loader";
import SearchConditionHandler from "./SearchConditionHandler";

import { lowerCaseAndReplaceSpaces } from "../functions/helper.js";


// maximun entrys
const max = 10000;
// search-delay in ms
const searchDelay = 500;

const Search = ({ input, callback, searchKeys, showAll = true, id = 0, focus = true, conditionKeys = null }) => {

    // -- states
    const [andConditions, setAndConditions] = useState([]);
    const [orConditions, setOrConditions] = useState([]);
    const [conditionHandlerActive, setConditionHandlerActive] = useState(false);

    // -- mount
    useEffect(() => {
        // on mount -> focus search-input
        if (focus) getElements().searchInput.focus();
    }, [])


    // -- on conditions-change
    useEffect(() => {
        if (andConditions.length || orConditions.length) searchThis();
    }, [andConditions, orConditions])


    // -- get html-elemnts in dom
    const getElements = () => {
        // whole component
        const thisComponent = document.querySelector(`#search-component-${id}`);
        // input
        const searchInput = thisComponent.querySelector('.search-input')
        // loader
        const loader = thisComponent.querySelector('.search-loader');
        // status-message
        const status = thisComponent.querySelector('.search-status');

        return {
            component: thisComponent,
            loader: loader,
            status: status,
            searchInput: searchInput
        }
    }


    // -- start search
    var searchTimeout;
    const searchThis = (e) => {
        try {
            if (e) e.preventDefault();

            // get html-elements
            const elements = getElements();

            const loader = elements.loader;
            const status = elements.status;
            const searchInput = elements.searchInput;

            loader.style.display = "flex";
            status.innerHTML = "Suche ...";


            // search-field input
            const searchValue = lowerCaseAndReplaceSpaces(searchInput.value);

            // input copy
            var searchArray = [...input];

            // clear timeout
            if (searchTimeout) clearTimeout(searchTimeout);

            // always allow searching if conditions are set
            const conditionsSet = orConditions.length || andConditions.length;

            // dont search if input less than two letters/digits
            if ((!searchValue || searchValue === "") && !conditionsSet) {
                // field empty -> show all or none
                resetInput();

                loader.style.display = "none";
                status.innerHTML = "";
            }
            else if ((searchValue.length <= 2) && !conditionsSet) {
                // do nothing
                loader.style.display = "none";
                status.innerHTML = "Zu wenige Zeichen";
            }
            else {
                searchTimeout = setTimeout(() => {

                    const result = [];

                    // -- run through input
                    inputLoop:
                    for (let i = 0; i < searchArray.length; i++) {
                        // this entry in search-Array
                        const inputElement = searchArray[i];


                        // -- AND-OR-conditions if conditionKeys got set
                        if (conditionKeys) {

                            // AND-conditions
                            for (let j = 0; j < andConditions.length; j++) {

                                // value from search-input
                                const inputValue = inputElement[andConditions[j].key];
                                // value of this condition
                                const conditionValue = andConditions[j].value;

                                // inputValue is null
                                if (!inputValue) continue inputLoop // FAILED TO FULLFILL - break loop
                                // inputValue is an array
                                else if (typeof inputValue === 'object') {
                                    if (!inputValue.includes(conditionValue)) continue inputLoop; // FAILED TO FULLFILL - break loop
                                }
                                // inputValue is a string
                                else {
                                    if (inputValue !== conditionValue) continue inputLoop; // FAILED TO FULLFILL - break loop
                                }
                            }
                            // FULLFILLED


                            // OR-condition
                            if (orConditions.length) {
                                let orConditionFullfilled = false;
                                for (let j = 0; j < orConditions.length; j++) {

                                    // value from search-input
                                    const inputValue = inputElement[orConditions[j].key];
                                    // value of this condition
                                    const conditionValue = orConditions[j].value;

                                    // inputValue is null
                                    if (!inputValue) {
                                        /* do nothing */
                                    }
                                    // inputValue is an array
                                    else if (typeof inputValue === 'object') {
                                        if (inputValue.includes(conditionValue)) orConditionFullfilled = true;
                                    }
                                    // inputValue is a string
                                    else {
                                        if (inputValue === conditionValue) orConditionFullfilled = true;
                                    }
                                }
                                if (!orConditionFullfilled) continue inputLoop; // FAILED TO FULLFILL - break loop
                            }
                            // FULLFILLED
                        }


                        // -- run through searchkeys
                        for (let j = 0; j < searchKeys.length; j++) {
                            // use previous and next key for comparison too -> e.g. Vorname Nachname as input
                            const prevKey = searchKeys[j - 1];
                            const key = searchKeys[j];
                            const nextKey = searchKeys[j + 1];

                            // stringify input-object to one string
                            var prevValue = prevKey ? lowerCaseAndReplaceSpaces(JSON.stringify(inputElement[prevKey])) : "";
                            var value = lowerCaseAndReplaceSpaces(JSON.stringify(inputElement[key]))
                            var nextValue = nextKey ? lowerCaseAndReplaceSpaces(JSON.stringify(inputElement[nextKey])) : "";

                            var compareThisValue = (prevValue + value + nextValue).replaceAll('"', '');
                            var compareThisToo = (nextValue + value + prevValue).replaceAll('"', '');

                            // check if this object-key value contains input-string
                            if (compareThisValue.includes(searchValue) || compareThisToo.includes(searchValue)) {
                                // FOUND
                                result.push(inputElement);
                                break;
                            }
                        }
                    }

                    // -- set result
                    callback(result.slice(0, max));

                    // -- finished searching
                    loader.style.display = "none";
                    status.innerHTML = result.length > max ? `<span style='color: red'>Maximum ${max} Ergebnisse werden angezeigt</span>` : `${result.length} ${result.length === 1 ? 'Eintrag' : 'Einträge'} gefunden`
                }, searchDelay);
            }
        }
        catch (e) {
            console.log("FEHLER: ", e);
        }
    }


    // -- reset input
    const resetInput = () => {
        // set callback back to input
        if (showAll) callback([...input].slice(0, max)); // all input-entrys
        else callback([]); // show nothing

        // empty search-field
        const elements = getElements();
        elements.searchInput.value = "";
        elements.loader.display = "none";
        elements.status.innerHTML = "";

        // clear search timeout
        clearTimeout(searchTimeout);

        // clear conditions
        setAndConditions([]);
        setOrConditions([]);
    }


    return (
        <div id={`search-component-${id}`} className='form-container'>

            {/* input-form */}
            <form className='search-form' onSubmit={searchThis}>

                <div className='search-input-container input-container'>
                    {/* search input */}
                    <input className="search-input" name="search-input" placeholder='Suche ...' type="text" onChange={searchThis} />


                    {/* loader while searching */}
                    <div className='search-loader-status-container'>
                        <div className='search-loader'><Loader /></div>
                        <div className='search-status gray-info'></div>
                        {conditionKeys ? <div className={`search-icon-btn collapse-arrow ${conditionHandlerActive ? 'point-up' : ''}`} onClick={() => setConditionHandlerActive(!conditionHandlerActive)}><IoIosArrowDropdown /></div> : <></>}
                        <div className='search-icon-btn' onClick={resetInput}><ImCancelCircle /></div>
                    </div>
                </div>

            </form>

            {/* and-or-conditions if fach- and interessensgebiete are set */}
            {conditionKeys ?
                <SearchConditionHandler
                    andConditions={andConditions} setAndConditions={setAndConditions}
                    orConditions={orConditions} setOrConditions={setOrConditions}
                    conditionHandlerActive={conditionHandlerActive}
                    conditionKeys={conditionKeys}

                    searchValue={getElements().searchInput.value}
                />
                :
                <></>
            }
        </div>
    )
}

export default Search
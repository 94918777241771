import { useState, useEffect, useContext } from 'react'
import { TokenContext } from '../Main.js'

import { IoMdExit, IoMdEye } from 'react-icons/io'

import { backendUrl } from '../../rest/config.js'
import { printCheckbox, printInput, printSelect } from '../InputComponents.js';
import { addSpecificElement, removeSpecificElement } from './../../functions/helper.js'
import { sendPatchRequest, sendRequest } from '../../rest/requests.js';
import { defaultHeaderTitle } from '../../functions/global_variables.js'

import Loader from '../Loader';
import Popup from '../Popup.js'
import AddRemoveButton from '../Kurse/AddRemoveButton.js'


const ZertifikatForm = ({ setHeaderTitle, selectedZertifikatVorlage, zertifikatVorlagen }) => {

    // access-token
    const [tokenObject, setTokenObject] = useContext(TokenContext);

    const [initialized, setInitialized] = useState(false);

    // -- this vorlage
    const [thisVorlage, setThisVorlage] = useState();

    // preview-url
    const [previewUrl, setPreviewUrl] = useState();


    // -- init
    useEffect(() => {
        setHeaderTitle(selectedZertifikatVorlage.Name);

        // scroll to top when starting this form
        document.querySelector('#sections-container').scrollTop = 0;
        initZertifikatForm();

        return () => setHeaderTitle(defaultHeaderTitle)
    }, []);
    const initZertifikatForm = async () => {

        // GET THIS VORLAGE FROM DATABASE
        const response = await sendRequest(tokenObject, setTokenObject, 'POST', '/zertifikate/vorlagen/get', `Lade "${selectedZertifikatVorlage.Name}"`, `"${selectedZertifikatVorlage.Name}" geladen`, { Name: selectedZertifikatVorlage.Name });

        if (response.success) {
            // set states
            setThisVorlage(response.data[0]);
            setInitialized(true);
        }
    }



    // UPDATE THIS VORLAGE IN DATABASE
    const paragraphOnChange = async (e, index, key) => {

        const value = e.currentTarget.value;

        // get changed paragraph-infos
        const thisParagraph = thisVorlage.Positionen[index];
        thisParagraph[key] = value;

        // replace the specific paragraph in thisVorlage
        var thisVorlageCopy = { ...thisVorlage };
        thisVorlageCopy.Positionen[index] = thisParagraph;

        setThisVorlage(thisVorlageCopy);
    }

    useEffect(() => {
        if (initialized) {
            // UPDATE VORLAGE IN DATABASE
            sendPatchRequest(tokenObject, setTokenObject, '/zertifikate/vorlagen/update', thisVorlage, `Speichere Vorlage "${thisVorlage.Name}"`, `Vorlage "${thisVorlage.Name}" gespeichert`);
        }
    }, [thisVorlage])


    // -- add remove functions
    const addParagraph = (index) => {
        var newVorlage = { ...thisVorlage };
        newVorlage.Positionen = addSpecificElement(newVorlage.Positionen, index, { text: "" });
        setThisVorlage(newVorlage);
    }
    const removeParagraph = (index) => {
        if (window.confirm(`Sicher, dass du Paragraf ${index + 1} löschen möchtest?`)) {
            var newVorlage = { ...thisVorlage };
            newVorlage.Positionen = removeSpecificElement(newVorlage.Positionen, index);
            setThisVorlage(newVorlage);
        }
    }


    // -- texteditor
    function modifyTextarea(e, modifyType) {
        // SHORTCODES FÜR TEXTAREA WERDEN IM BACKEND "pdf.js" DEFINIERT

        let thisTextarea = e.currentTarget.parentNode.parentNode.querySelector('textarea');

        // get selected text
        var start = thisTextarea.selectionStart;

        // define new textarea-input
        var modifiedTextarea = thisTextarea.value;
        modifiedTextarea = modifiedTextarea.slice(0, start) + modifyType + modifiedTextarea.slice(start)

        // -- fire onchange event to change state
        const lastValue = thisTextarea.value;
        thisTextarea.value = modifiedTextarea;
        const event = new Event("change", { bubbles: true });
        const tracker = thisTextarea._valueTracker;
        if (tracker) {
            tracker.setValue(lastValue);
        }
        thisTextarea.dispatchEvent(event);

        // focus selected
        thisTextarea.select();
        thisTextarea.selectionStart = start + (modifyType.length);
    }


    // -- preview vorlage
    const updatePreview = async () => {
        const id = document.querySelector('#preview-input-id').value;
        const isGcp = document.querySelector('#preview-is-gcp-input').checked;

        if (id) {
            const body = { VNR: !isGcp ? id : '', GCP_ID: isGcp ? id : '', Positionen: thisVorlage.Positionen };

            // get pdf preview
            let url = `${backendUrl}/zertifikate/vorlagen/preview`;
            let options = {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Authorization': `Bearer ${tokenObject.accessToken}`,
                    'Content-Type': 'application/json; charset=utf-8'
                },
                'body': JSON.stringify(body)
            }
            const response = await fetch(url, options)
                .then(response => {
                    if (response.ok) return response.blob();
                    else return false;
                })
                .then(data => {
                    if (data) {
                        // open received pdf-stream in new window
                        const url = URL.createObjectURL(data);
                        setPreviewUrl(url)
                        return url;
                    }
                    else return false
                })

            if (!response) alert('Fehler bei Zertifikat-Vorschau');
            else return response;
        }
    }

    if (initialized && thisVorlage) {
        return (

            <div className='basic-container'>
                <div className='form-container'>
                    <h3>{thisVorlage.Name}</h3>

                    {/* Vorschau section */}
                    <div className='form-container'>
                        <h5>Vorschau</h5>

                        {/* vnr oder gcp-id input */}
                        <div className='input-container'>
                            <label htmlFor='preview-input-id'>VNR oder GCP_ID: </label>
                            <input id='preview-input-id' defaultValue='1111111111111111111' />
                        </div>
                        {printCheckbox(undefined, 'preview-is-gcp-input', 'Vorlage ist für GCP-Kurs', 0, true)}

                        {/* preview button */}
                        <div className='vorlagen-preview-btn lower basic-small-btn' onClick={async () => { window.open(await updatePreview()) }}><IoMdExit /></div>
                        <Popup content={<iframe style={{ width: '100%', height: '100%' }} src={previewUrl} />} button={<div className='vorlagen-preview-btn basic-small-btn' onClick={updatePreview}><IoMdEye /></div>} large={true} />
                    </div>
                    <div className='seperator'></div>


                    {/* main form */}
                    <form>

                        {thisVorlage.Positionen.map((paragraph, index) => {
                            return (
                                <div key={`vorlage-pos-paragraph-key-${index}`} id={`paragraph-${index}`} className='paragraph-container'>


                                    {/* add and remove buttons */}
                                    <AddRemoveButton
                                        title={<h5>Paragraf {index + 1}</h5>}
                                        small={true}
                                        removeFunction={() => removeParagraph(index)}
                                        addFunction={() => addParagraph(index)}
                                    />

                                    {/* paragraph inputs */}
                                    <div className='paragraph'>
                                        <div>
                                            {/* x- and y-coordinates */}
                                            {printInput((e) => { paragraphOnChange(e, index, "x") }, `paragraph-${index}-input-x`, 'X: ', paragraph.x, true, "Wenn nichts definiert:<br>Text in Seitenmitte", "number", '', false, '50')}
                                            {printInput((e) => { paragraphOnChange(e, index, "y") }, `paragraph-${index}-input-y`, 'Y: ', paragraph.y, true, "Wenn nichts definiert:<br>Automatischer Zeilenumbruch zum letzten Absatz", "number", '', false, 'Aktuelle Position')}

                                            {/* font and font-size */}
                                            {printSelect((e) => { paragraphOnChange(e, index, "font") }, `paragraph-${index}-input-font`, 'Font: ', paragraph.font, ['fira-sans', 'fira-sans-bold', 'fira-sans-book-italic'], true, "Wenn nichts definiert:<br>Fira Sans", '', false, 'Fira Sans')}
                                            {printInput((e) => { paragraphOnChange(e, index, "fontSize") }, `paragraph-${index}-input-fontSize`, 'Font-Size: ', paragraph.fontSize, true, "Wenn nichts definiert: 12", "number", '', false, '12')}

                                            {/* which page */}
                                            {printInput((e) => { paragraphOnChange(e, index, "page") }, `paragraph-${index}-input-page`, 'Seite: ', paragraph.page, true, "Wenn nichts definiert:<br>Seite 1", "number", '', false, '1')}


                                            {/* width, height, align */}
                                            {printInput((e) => { paragraphOnChange(e, index, "width") }, `paragraph-${index}-input-width`, 'Breite: ', paragraph.width, true, "Muss nicht definiert werden", "number", '', false, '100%')}
                                            {printInput((e) => { paragraphOnChange(e, index, "height") }, `paragraph-${index}-input-height`, 'Höhe: ', paragraph.height, true, "Muss nicht definiert werden", "number", '', false, 'Content')}
                                            {printSelect((e) => { paragraphOnChange(e, index, "align") }, `paragraph-${index}-input-align`, 'Align innerhalb Box: ', paragraph.align, ['left', 'center', 'right', ''], true, undefined, '', false, "optional")}

                                            {/* color */}
                                            {printInput((e) => { paragraphOnChange(e, index, "color") }, `paragraph-${index}-input-color`, 'Farbe: ', paragraph.color, true, "Wenn nichts definiert: black\nAuch hex möglich.", "text", '', false, 'black')}

                                            {/* main-paragraph image */}
                                            {paragraph.text ?
                                                printInput(() => { }, 'no-value', 'Bild: ', 'Bild kann nicht definiert werden wenn "Text" ausgefüllt ist', true, "Um ein Bild auszuwählen, muss das Textfeld leer sein.", "text", '', true)
                                                :
                                                printSelect(
                                                    (e) => { paragraphOnChange(e, index, "image") }, `paragraph-${index}-input-image`, 'Bild: ', paragraph.image,
                                                    [
                                                        '{efn_barcode}',
                                                        '{vnr_barcode}',
                                                        '{efn_qrcode}',
                                                        '{vnr_qrcode}',
                                                        '{academy_logo}',
                                                        '{academy_stamp}',
                                                        '{cmeducation_logo}',
                                                        '{stamp_container}',
                                                        '{signature_marcus}',
                                                        '{signature_markus}',
                                                        ''
                                                    ]
                                                )
                                            }

                                            <div className="input-container large">
                                                {!paragraph.image ?
                                                    //  texteditor with shortcode-buttons
                                                    <>
                                                        <label htmlFor={`paragraph-${index}-input-text`}>Text: </label>
                                                        <div className="textarea-editor">

                                                            <textarea
                                                                id={`paragraph-${index}-input-text`}
                                                                name={`paragraph-${index}-input-text`}
                                                                value={paragraph.text}
                                                                className='large-text'

                                                                onChange={(e) => { paragraphOnChange(e, index, "text") }}

                                                                placeholder='Neuer Absatz'
                                                            />

                                                            <div className="editor-btns">
                                                                <div onClick={(e) => modifyTextarea(e, "{title}")}>Kurstitel</div>
                                                                <div onClick={(e) => modifyTextarea(e, "{subtitle}")}>Kurs-Untertitel</div>
                                                                <div onClick={(e) => modifyTextarea(e, "{workplace}")}>Arbeitsplatz</div>
                                                                <div onClick={(e) => modifyTextarea(e, "{vnr}")}>VNR</div>
                                                                <div onClick={(e) => modifyTextarea(e, "{category}")}>Fortbildungskategorie</div>
                                                                <div onClick={(e) => modifyTextarea(e, "{cme_points}")}>CME Punkte</div>
                                                                <div onClick={(e) => modifyTextarea(e, "{teilnehmer_title}")}>Teilnehmer Titel</div>
                                                                <div onClick={(e) => modifyTextarea(e, "{name}")}>Teilnehmer Name</div>
                                                                <div onClick={(e) => modifyTextarea(e, "{address}")}>Teilnehmer Adresse</div>
                                                                <div onClick={(e) => modifyTextarea(e, "{efn}")}>EFN</div>
                                                                <div onClick={(e) => modifyTextarea(e, "{date}")}>Teilnahmedatum</div>
                                                                <div onClick={(e) => modifyTextarea(e, "{today}")}>Heute</div>
                                                                <div onClick={(e) => modifyTextarea(e, "{wissenschaftliche_leitung}")}>Wissenschaftliche Leitung</div>
                                                                <div onClick={(e) => modifyTextarea(e, "{referenten}")}>Referenten</div>

                                                            </div>

                                                            <div className='editor-btns'>
                                                                <div onClick={(e) => modifyTextarea(e, "{event_title}")}>Veranstaltungstitel</div>
                                                                <div onClick={(e) => modifyTextarea(e, "{event_date}")}>Veranstaltungsdatum</div>
                                                                <div onClick={(e) => modifyTextarea(e, "{event_location}")}>Veranstaltungsort</div>
                                                                <div onClick={(e) => modifyTextarea(e, "{event_type}")}>Veranstaltungsart</div>
                                                                <div onClick={(e) => modifyTextarea(e, "{event_nights}")}>Anzahl Übernachtungen</div>
                                                                <div onClick={(e) => modifyTextarea(e, "{event_travelcosts}")}>Reisekosten</div>
                                                                <div onClick={(e) => modifyTextarea(e, "{event_accommodation_costs}")}>Übernachtungskosten</div>
                                                                <div onClick={(e) => modifyTextarea(e, "{event_cateringcosts}")}>Verpflegungskosten</div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <p>Ausgewähltes Bild: {
                                                            paragraph.image === '{efn_barcode}' ? "EFN Strichcode"
                                                                : paragraph.image === '{vnr_barcode}' ? "VNR Strichcode"
                                                                    : paragraph.image === '{efn_qrcode}' ? "EFN QR-Code"
                                                                        : paragraph.image === '{vnr_qrcode}' ? "VNR QR-Code"
                                                                            : paragraph.image === '{academy_logo}' ? "Academy Verlag Logo"
                                                                                : paragraph.image === '{academy_stamp}' ? "Academy Stempel"
                                                                                    : paragraph.image === '{cmeducation_logo}' ? "CMEducation Logo"
                                                                                        : paragraph.image === '{stamp_container}' ? "Stempel-Kasten"
                                                                                            : paragraph.image === '{signature_marcus}' ? "Unterschrift Marcus Schöb"
                                                                                                : paragraph.image === '{signature_markus}' ? "Unterschrift Markus Götz"
                                                                                                    : 'Kein Bildname definiert'
                                                        }</p>
                                                        <p style={{ color: "gray" }}>Info: Es kann kein Text definiert werden wenn ein Bild ausgewählt ist</p>
                                                    </>

                                                }
                                            </div>


                                        </div>
                                    </div>

                                </div>
                            )
                        })}

                    </form>


                </div >
            </div >
        )
    }
    else {
        return (
            <Loader />
        )
    }
}

export default ZertifikatForm
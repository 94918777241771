import { backendUrl, setStatus } from "./config.js";


// -- download certificate
const downloadCertificate = async (tokenObject, setTokenObject, relationArray) => {
    setStatus(`Lade Zertifikate herunter`, true);

    // -- request-information
    let url = `${backendUrl}/zertifikate/downloadCertificate`;
    let options = {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Authorization': `Bearer ${tokenObject.accessToken}`,
            'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(relationArray)
    }

    // -- fetch it
    return await fetch(url, options)
        .then(res => {
            if (res.ok) return res.blob();
            else throw `${res.status} - ${res.statusText}`;
        })
        .then(blob => {
            // success
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = relationArray[0].GCP_ID ? `zertifikate_gcp_${relationArray[0].GCP_ID}.pdf` : `zertifikate_${relationArray[0].VNR}.pdf`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove();  //afterwards we remove the element again      

            setStatus(`Zertifikate heruntergeladen`, false);
            return true;
        })
        .catch(err => {
            setStatus(err, true, true);
            console.log("Fehler:", err)
            return false;
        });
}


export { downloadCertificate }
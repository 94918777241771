import { useContext, useState } from "react";
import { TokenContext } from "../Main.js";

import { exportJson, exportXlsx } from "../../functions/fileHandler.js";
import { sendRequest } from "../../rest/requests.js";
import { formatDateToString } from "../../functions/helper.js";
import { getAllCoursesInOneArray } from "../../rest/requests_get_all.js";


export const Export = ({ }) => {

    // access-token
    const [tokenObject, setTokenObject] = useContext(TokenContext);

    // error message
    const [errorMessage, setErrorMessage] = useState("");


    // -- export a json file type "Kurse", "Teilnehmer" or "Kursteilnahmen"
    const exportJsonFile = async (type = "Kurse") => {
        // clear error-message
        setErrorMessage("");

        let response;
        let data;

        switch (type) {
            case "Kurse":
                // GET ALL KURSE FROM DATABASE
                response = await getAllCoursesInOneArray(tokenObject, setTokenObject);
                if (response) { data = response; response = { success: true } }
                break;
            case "Teilnehmer":
                // GET ALL TEILNEHMER FROM DATABASE
                response = await sendRequest(tokenObject, setTokenObject, 'GET', '/teilnehmer', 'Lade alle Teilnehmer', 'Teilnehmer geladen');
                data = response.data;
                break;
            case "Kursteilnahmen":
                // GET ALL KURS-TEILNEHMER-RELATIONS FROM DATABASE
                response = await sendRequest(tokenObject, setTokenObject, 'GET', '/kursteilnahme/allKursTeilnehmerRelations', 'Lade alle Kurs-Teilnehmer-Relations', 'Alle Kurs-Teilnehmer-Relations geladen');
                data = response.data;
                break;

            default:
                response.success = false;
                break;
        }

        // log
        console.log(`${data.length} ${type} exportiert`);

        // success
        if (response.success) exportJson(data, `alle_${type}`);
        // error
        else setErrorMessage('Fehler bei Datenbankzugriff');
    }



    // -- download xlsx teilnehmer with kursteilnahmen
    const exportXlsxTeilnKursRelation = async () => {
        // result
        const result = [];

        // filename
        const filename = 'teilnehmer-teilnahmen';

        // teilnehmer
        const teilnehmerResponse = await sendRequest(tokenObject, setTokenObject, 'GET', '/teilnehmer', 'Lade alle Teilnehmer', 'Teilnehmer geladen');
        const teilnehmer = teilnehmerResponse.data;

        // kursteilnahmen
        const kursteilnahmenResponse = await sendRequest(tokenObject, setTokenObject, 'GET', '/kursteilnahme/allKursTeilnehmerRelations', 'Lade alle Kurs-Teilnehmer-Relations', 'Alle Kurs-Teilnehmer-Relations geladen');
        const kursteilnahmen = kursteilnahmenResponse.data;


        for (let i = 0; i < teilnehmer.length; i++) {
            const singleTeilnehmer = teilnehmer[i];

            // get courses of teilnehmer
            const coursesOfTeilnehmer = kursteilnahmen.filter(elem => elem.EFN === singleTeilnehmer.EFN);

            for (let j = 1; j <= coursesOfTeilnehmer.length; j++) {
                const singleCourse = coursesOfTeilnehmer[j - 1];
                // add vnr and teilnahmedatum
                singleTeilnehmer[`Kurs ${j} KursKey`] = singleCourse.KursKey;
                singleTeilnehmer[`Kurs ${j} VNR`] = singleCourse.VNR;
                singleTeilnehmer[`Kurs ${j} Teilnahmedatum`] = formatDateToString(singleCourse.Teilnahmedatum);
                singleTeilnehmer[`Kurs ${j} AnswerKey`] = singleCourse.AnswerKey;
                singleTeilnehmer[`Kurs ${j} EIV_Datum`] = singleCourse.EIV_Datum;
                singleTeilnehmer[`Kurs ${j} Antwortbogen`] = singleCourse.Antwortbogen;
                singleTeilnehmer[`Kurs ${j} AD_Vorname`] = singleCourse.AD_Vorname;
                singleTeilnehmer[`Kurs ${j} AD_Nachname`] = singleCourse.AD_Nachname;
                singleTeilnehmer[`Kurs ${j} AD_Email`] = singleCourse.AD_Email;
                console.log('singleCourse', singleCourse);
            }

            // delete useless info
            delete singleTeilnehmer.Datenspeicherung;
            delete singleTeilnehmer.Zustimmung;

            // push to result
            result.push(singleTeilnehmer);
        }

        // download xlsx-file
        exportXlsx(result, filename);
    }


    // -- download xlsx-template
    const exportXlsxTeilnKursRelTemplate = async () => {

        // filename
        const filename = 'VORLAGE_teiln-kurs_teiln_relations';

        var templateRow = {
            "EFN": "",
            "Land": "",
            "Titel": "",
            "Vorname": "",
            "Nachname": "",
            "Strasse": "",
            "Postleitzahl": "",
            "Stadt": "",
            "Mail": "",
            "Fachgebiet": "",
            "Zustimmung": 0,
            "Datenspeicherung": 0,
            "Veranstaltung": ""
        }

        for (let i = 1; i <= 200; i++) {
            // fill courses
            const currentCourse = {
                [`Kurs ${i} VNR`]: "",
                [`Kurs ${i} Teilnahmedatum`]: "",
                [`Kurs ${i} AnswerKey`]: "",
                [`Kurs ${i} KursKey`]: "",
                [`Kurs ${i} EIV_Datum`]: "",
                [`Kurs ${i} Antwortbogen`]: (i === 1) ? 0 : "",
                [`Kurs ${i} AD_Vorname`]: "",
                [`Kurs ${i} AD_Nachname`]: "",
                [`Kurs ${i} AD_Email`]: "",
            }

            templateRow = { ...templateRow, ...currentCourse }
        }

        // build and download xlsx
        exportXlsx([templateRow], filename);
    }

    return (
        <div className='basic-container'>
            <h3>Export</h3>


            {/* JSON */}
            <div>
                {/* Kurse */}
                <div>
                    <h5>Alle Kurse - JSON</h5>
                    <form className="form-container">
                        <div className="basic-btn left" onClick={() => exportJsonFile("Kurse")}>Download&nbsp;<strong>Kurse</strong></div>

                        <div style={{ color: "red" }}>{errorMessage}</div>

                    </form>
                </div>

                {/* Teilnehmer */}
                <div>
                    <h5>Alle Teilnehmer - JSON</h5>
                    <form className="form-container">
                        <div className="basic-btn left" onClick={() => exportJsonFile("Teilnehmer")}>Download&nbsp;<strong>Teilnehmer</strong></div>
                        <div style={{ color: "red" }}>{errorMessage}</div>
                    </form>
                </div>

                {/* Teilnehmer */}
                <div>
                    <h5>Alle Kursteilnahmen - JSON</h5>
                    <form className="form-container">
                        <div className="basic-btn left" onClick={() => exportJsonFile("Kursteilnahmen")}>Download&nbsp;<strong>Kursteilnahmen</strong></div>
                        <div style={{ color: "red" }}>{errorMessage}</div>
                    </form>
                </div>
            </div>
            <div className="seperator"></div>


            {/* XLSX */}
            <div>
                {/* Kurse & Kursteilnahme */}
                <div>
                    <h5>Alle Teilnehmer und Kursteilnahmen - XLSX</h5>
                    <form className="form-container">
                        <div className="basic-btn left" onClick={exportXlsxTeilnKursRelation}>Download&nbsp;<strong>Teilnehmer und Kursteilnahmen</strong></div>

                        <div style={{ color: "red" }}>{errorMessage}</div>

                    </form>
                </div>
                <div className="seperator"></div>


                {/* Vorlage für xlsx-Datei */}
                <div>
                    <h5>Vorlage für Teilnehmer und Kursteilnahme - XLSX</h5>
                    <form className="form-container">
                        <div className="basic-btn left" onClick={exportXlsxTeilnKursRelTemplate}>Download&nbsp;<strong>xlsx-Vorlage</strong></div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Export
import { useContext } from 'react';
import { TokenContext } from '../Main';
import { AiOutlineCopy } from 'react-icons/ai'
import { sendRequest } from '../../rest/requests';

const TeilnehmerCopyButton = ({ teilnehmer, callback }) => {

    // access-token
    const [tokenObject, setTokenObject] = useContext(TokenContext);

    const copyTeilnehmer = async (e) => {
        e.stopPropagation();

        // set new teilnehmer
        const newTeilnehmer = { ...teilnehmer };
        // enter new efn
        const newEfn = window.prompt("Bitte trage eine neue EFN ein");

        if (newEfn) {
            // set new efn
            newTeilnehmer.EFN = newEfn;

            // ADD COPIED TEILNEHMER TO DATABASE
            const response = await sendRequest(tokenObject, setTokenObject, 'POST', '/teilnehmer/erstellen', `Erstelle Teilnehmer mit EFN ${newTeilnehmer.EFN}`, `Teilnehmer mit EFN ${newTeilnehmer.EFN} erstellt`, newTeilnehmer)

            if (response.success && callback) callback(newTeilnehmer);
        }
    }


    return (

        <td className='column-icon add underline' onClick={copyTeilnehmer}>
            <AiOutlineCopy />
        </td>
    )
}

export default TeilnehmerCopyButton
import { downloadCertificate } from "../rest/requests_download.js";
import { sendRequest } from "../rest/requests";


// -- global month array
const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];


// -- BASIC FUNCTIONS
//#region 
// -- format date to dd.mm.yyyy
const formatDateToString = (date) => {
    // if date is 0000-00-00
    if (!date || isNaN(Date.parse(date))) return "xx.xx.xxxx";
    else {
        var MyDate = new Date(date);
        return ('0' + MyDate.getDate()).slice(-2) + '.'
            + ('0' + (MyDate.getMonth() + 1)).slice(-2) + '.'
            + MyDate.getFullYear();
    }
}
// -- format date-string dd.mm.yyyy to date-format
const formateStringToDate = (dateString) => {
    var date_regex = /^(0[1-9]|1\d|2\d|3[01])\.(0[1-9]|1[0-2])\.(19|20)\d{2}$/;
    if (date_regex.test(dateString)) {
        // format correct
        const newDateString = `${dateString.slice(6, 10)}-${dateString.slice(3, 5)}-${dateString.slice(0, 2)}`
        return new Date(newDateString);
    }
    else {
        // incorrect -> fallback
        return new Date("5000-01-01");
    }
}

// -- toggle next sibling element
const toggleNextSibling = (e) => {
    const elem = e.currentTarget;
    const sibling = elem.nextSibling;
    sibling.toggleAttribute('hidden');

    if (sibling.hasAttribute("hidden")) elem.innerHTML = "Zeige mehr Informationen";
    else elem.innerHTML = "Zeige weniger Informationen";
}

// -- toggle a table
const toggleTable = (e) => {
    const tableContainer = e.currentTarget.parentNode;

    var isActive = tableContainer.getAttribute('active');
    if (isActive === "true") tableContainer.setAttribute('active', 'false');
    else tableContainer.setAttribute('active', 'true');
}

// -- scroll to id
const scrollToId = (id) => {
    const element = document.getElementById(id);
    if (element) {
        element.scrollIntoView({ block: 'center' });
    }
}

// -- function to lower case and replace spaces
const lowerCaseAndReplaceSpaces = (input) => {
    if (input) return input.toLowerCase().replaceAll(' ', '');
    else return "";
}

// -- basic array-functions
function addSpecificElement(array, elemIndex, obj) {

    const index = elemIndex;
    if (index > -1) {
        array.splice(index + 1, 0, obj);
    }

    return array;
}
function removeSpecificElement(array, elemIndex) {

    const index = elemIndex;
    if (index > -1) {
        array.splice(index, 1);
    }

    return array;
}


// -- filter array
/**
 *  Takes an Array and compares object-entries with filterConditions
 * 
 * @param {Array} array Array of Objects
 * @param {Array} filterConditions Array with Syntax [{key: 'key-1', value: 'value-1'}, {key: 'key-2', value: 'value-2'}, etc]
 * @returns new array with filtered entries
 */
const filterArray = (array, filterConditions) => {

    // result array
    var filteredArray = [];

    // run through input array
    for (let i = 0; i < array.length; i++) {

        // one element in input-array
        const entry = array[i];

        // run through filter-array and decide if this entry is to be used
        let useEntry = true;
        for (let j = 0; j < filterConditions.length; j++) {
            try {
                const keyValue = filterConditions[j];
                const entryValue = entry[keyValue.key];
                const entryType = typeof entryValue;

                // use all
                if (keyValue.value === "Alle") continue;
                // dont use undefined values
                else if (!entryValue && entryValue !== "") useEntry = false;
                // entry is string
                else if (entryType === 'string' && entryValue !== keyValue.value) useEntry = false;
                // entry is object
                else if (!entryValue.includes(keyValue.value)) useEntry = false;

                // dont use this entry and break loop
                if (!useEntry) break;
            }
            catch (e) { console.log('Fehler in filterArray: ', e); }
        }

        // entry will be used
        if (useEntry) filteredArray.push(entry);
    }

    return filteredArray;
}

// -- sort
// sort by key
const sortBy = (array, sortKey, secondSortKey = undefined, reverse = false) => {
    return [...array].sort((a, b) => {
        const firstElem = secondSortKey !== undefined ? a[sortKey][secondSortKey] : a[sortKey];
        const secondElem = secondSortKey !== undefined ? b[sortKey][secondSortKey] : b[sortKey];

        // push undefined entries in front
        if (!firstElem) return -1;
        else if (!secondElem) return 1;
        // compare defined entries
        else return firstElem.localeCompare(secondElem) * (reverse ? -1 : 1);
    });
}
// sort by start date
const sortByDate = (array, key = 'Laufzeit_Start', reverse = false) => {
    return [...array].sort((a, b) => {
        const firstElem = a[key];
        const secondElem = b[key];

        // push undefined entries in front
        if (!firstElem) return -1;
        else if (!secondElem) return 1;

        const firstDate = new Date(firstElem);
        const secondDate = new Date(secondElem);

        // push wrong dates in front
        if (isNaN(firstDate)) return -1;
        else if (isNaN(secondDate)) return 1;

        // sort normal dates
        else if (firstDate < secondDate) return reverse ? -1 : 1;
        else return reverse ? 1 : -1
    });
}


//#endregion



// -- TEILNEHMER-HELP-FUNCTIONS FOR GCP AND STANDARD-COURSE
//#region 
// -- manage teilnehmer-selection
const checkedRelationsChange = async (e, relation, checkedTeilnehmer, setCheckedTeilnehmer, key) => {
    e.stopPropagation();
    var newCheckedTeilnehmer = [...checkedTeilnehmer];

    const checked = e.currentTarget.checked;

    if (checked) {
        // add
        newCheckedTeilnehmer.push(relation);
    }
    else {
        // remove
        newCheckedTeilnehmer.forEach((thisRelation, index) => {
            if (thisRelation[key] === relation[key]) newCheckedTeilnehmer.splice(index, 1)
        })
    }
    // add this relation
    setCheckedTeilnehmer(newCheckedTeilnehmer);
}

// -- uncheck all selected teilnehmer
const uncheckAll = (setCheckedTeilnehmer) => {
    setCheckedTeilnehmer([]);
    document.querySelectorAll('.relation-checkbox').forEach(elem => elem.checked = false)
}
// -- check all teilnehmer
const checkAll = (allRelations, setCheckedTeilnehmer) => {
    setCheckedTeilnehmer(allRelations);
    document.querySelectorAll('.relation-checkbox').forEach(elem => elem.checked = true)
}

// -- send certificate to checkedTeilnehmer
const sendCertificateToCheckedRelations = async (tokenObject, setTokenObject, checkedTeilnehmer) => {
    for (let i = 0; i < checkedTeilnehmer.length; i++) {
        const relation = checkedTeilnehmer[i];
        await sendRequest(tokenObject, setTokenObject, 'POST', '/zertifikate/sendCertificate', `Sende Zertifikat an "${relation.Vorname} ${relation.Nachname}"`, `Zertifikat an "${relation.Vorname} ${relation.Nachname}" verschickt`, relation);
    }
}

// -- download certificate from checkedTeilnehmer
const downloadCertificateFromCheckedRelations = async (tokenObject, setTokenObject, checkedTeilnehmer) => {
    await downloadCertificate(tokenObject, setTokenObject, checkedTeilnehmer);
}

// -- teilnehmer deletion conform
const confirmTeilnehmerDeletion = (teilnehmer) => {
    return window.confirm(
        `Sicher, dass du folgenden Teilnehmer löschen möchtest?\n\n${teilnehmer.Vorname} ${teilnehmer.Nachname}\n${teilnehmer.Strasse}\n${teilnehmer.Postleitzahl} ${teilnehmer.Stadt}\n\nEFN: ${teilnehmer.EFN}
        `
    );
}
//#endregion



// -- LOCALSTORAGE HANDLER, USED FOR LOCAL BACKUPS
//#region 
const addCourseToLocalstorage = (course) => {
    var backup = getLocalStorage();

    if (backup !== null) {
        var courseExists = false;
        backup.forEach((thiscourse, index) => {
            if (thiscourse.KursKey === course.KursKey) {
                // update course
                backup[index] = course;
                courseExists = true;
            }
        })

        if (!courseExists) {
            // add new course
            backup.push(course);
        }

    }
    else {
        // nothing set yet
        backup = [course];
    }

    window.localStorage.setItem('course_creator_infos', JSON.stringify(backup));
}

const getLocalStorage = () => {
    var backup = JSON.parse(window.localStorage.getItem('course_creator_infos'));
    return backup;
}
//#endregion



export { months, formatDateToString, formateStringToDate, toggleNextSibling, addSpecificElement, removeSpecificElement, lowerCaseAndReplaceSpaces, scrollToId, filterArray, sortBy, sortByDate, addCourseToLocalstorage, getLocalStorage, checkedRelationsChange, sendCertificateToCheckedRelations, downloadCertificateFromCheckedRelations, confirmTeilnehmerDeletion, checkAll, uncheckAll, toggleTable }
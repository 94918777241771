import { backendUrl, setStatus } from "./config.js";


/**
 *  Function for sending requests to backend
 * 
 * @param {Object} tokenObject Access-Token genereted when logged in
 * @param {Function} setTokenObject setToken-Function to reset it if session expired
 * @param {String} method Request-Method (GET, POST, PATCH, ...)
 * @param {String} route Rest-Api-Route
 * @param {String} loadingMessage Information displayed while loading
 * @param {String} finishedLoadingMessage Information displayed when finished
 * @param {Object} body Request-Body sent to the backend
 * @param {Boolean} returnRes Return blank response
 * @param {Function} updateState updates local state object, so no refresh is neccesarry
 * @returns on success -> database response / on failure -> false
 */
const sendRequest = async (tokenObject, setTokenObject, method, route, loadingMessage = undefined, finishedLoadingMessage = undefined, body = undefined) => {

    // loading information displayed in StatusBar
    if (loadingMessage && finishedLoadingMessage) setStatus(loadingMessage, true);

    // -- request-information
    let url = `${backendUrl}${route}`;
    let options = {
        method: method,
        mode: 'cors',
        headers: {
            'Authorization': `Bearer ${tokenObject ? tokenObject.accessToken : ''}`,
            'Content-Type': 'application/json; charset=utf-8'
        }
    }
    // add body to request if neccesarry
    if (body) {
        options.body = JSON.stringify(body);
    }

    // -- fetch it
    return await fetch(url, options)
        .then(async (res) => {

            // status 403 means session has expired
            if (res.status === 403) {
                console.log('Session abgelaufen - Lade neu');

                // show expired-info overlay
                var overlay = document.querySelector('#session-expired-overlay');
                overlay.style.display = 'block';

                // get and set new access-token
                const response = await sendRequest(undefined, undefined, 'POST', `/nutzer/token/${tokenObject.userMail}`, undefined, undefined, { "token": tokenObject.refreshToken })

                if (response.success) {
                    const newAccessToken = response.data;

                    // set new access-token
                    setTokenObject((prevState) => ({
                        ...prevState,
                        'accessToken': newAccessToken.accessToken,
                        'refreshToken': tokenObject.refreshToken
                    }));

                    // fetch again
                    options.headers.Authorization = `Bearer ${newAccessToken.accessToken}`; // set new auth-token
                    const responseNewToken = await fetch(url, options).then(res => res);

                    // set response again
                    res = responseNewToken;
                }
                else { throw "Neuer Access-Token konnte nicht abgerufen werden" }

                // hide expired-info overlay after a few seconds
                setTimeout(() => overlay.style.display = 'none', 2000);
            }

            // convert to json-object
            return res.json();
        })
        .then(response => {
            if (response.success) {
                // SUCCESS
                if (loadingMessage && finishedLoadingMessage) setStatus(finishedLoadingMessage, false);
            }
            else {
                // FAILURE - BACKEND RESPONSE WITH { success: false }
                console.log("Fehler bei Backend-Zugriff", response);
                if (loadingMessage && finishedLoadingMessage) setStatus(response.message ? response.message : "Undefinierter Fehler", false, true);
            }

            // return response-object
            return response;
        })
        .catch(err => {
            // FAILURE
            if (loadingMessage && finishedLoadingMessage) setStatus(err, true, true);
            console.log("Fehler in sendRequest", err)
            return false;
        });
}


// UPDATE EVERY {updateIntervall} SECONDS -> TO PREVENT TOO MANY DATABASE-REQUESTS
const updateIntervall = 1;
let timeout = null;

/**
 *  Function that sends a patch request to backend after {updateIntervall} seconds
 *  Prevents to many database-accesses
 * 
 * @param {Object} tokenObject Access-Token genereted when logged in
 * @param {Function} setTokenObject setToken-Function to reset it if session expired
 * @param {String} route Rest-Api-Route
 * @param {Object} body Request-Body sent to the backend
 * @param {String} loadingMessage Information displayed while loading
 * @param {String} finishedLoadingMessage Information displayed when finished
 * @param {Function} callback callback when request is done
 * @returns on success -> database response / on failure -> false
 */
const sendPatchRequest = (tokenObject, setTokenObject, route, body, loadingMessage = "Speichern", loadingFinished = "Speichern abgeschlossen", callback = undefined) => {
    // -- update-function
    const update = async (request, callback = undefined) => {
        setStatus('Lädt', true);
        // start a promise
        const promise = new Promise((resolve) => {
            // clear timeout
            if (timeout) clearTimeout(timeout);
            // set timeout
            timeout = setTimeout(async () => {
                // await request
                const response = await request();
                // resolve
                resolve(response);
                // execute callback
                if (callback) callback();
            }, updateIntervall * 1000);
        })

        // await promise and return result
        return await promise;
    }

    // -- update
    return update(
        () => sendRequest(tokenObject, setTokenObject, 'PATCH', route, loadingMessage, loadingFinished, body),
        callback ? callback : undefined
    );
}



export { sendRequest, sendPatchRequest }
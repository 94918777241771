import { useEffect, useState, useContext } from 'react';
import { TokenContext } from '../Main.js';

import { FiTrash2, FiGitMerge } from 'react-icons/fi'
import { AiOutlineDown } from 'react-icons/ai'
import { BiCheckboxMinus, BiCheckbox } from "react-icons/bi"

import Loader from '../Loader.js';
import Popup from '../Popup.js';

import { sendRequest } from '../../rest/requests.js';
import { lowerCaseAndReplaceSpaces, uncheckAll, checkAll, checkedRelationsChange, toggleTable, confirmTeilnehmerDeletion } from './../../functions/helper.js'
import { getTeilnehmerFromDatabase } from '../../rest/requests_get_all.js';
import { deleteFromState } from '../../functions/localStateUpdates.js';

import Search from './../Search.js'

const TeilnehmerMerge = ({ allTeilnehmer, setAllTeilnehmer, allRelations, setSelectedTeilnehmer, searchResults, restartApp, setSearchResults }) => {

    // access-token
    const [tokenObject, setTokenObject] = useContext(TokenContext);

    // checked teilnehmer
    const [checkedTeilnehmer, setCheckedTeilnehmer] = useState([]);

    // proccess of merging
    const [inProgress, setInProgress] = useState(false);


    // -- uncheck all when search-results changed
    useEffect(() => {
        uncheckAll(setCheckedTeilnehmer);
    }, [searchResults])


    // -- merge checked teilnehmer
    const mergeTeilnehmer = async (mainTeilnehmer) => {
        setInProgress(true);
        const teilnehmerToBeDeleted = checkedTeilnehmer.filter(thisTeilnehmer => thisTeilnehmer.EFN !== mainTeilnehmer.EFN);

        if (window.confirm(`${mainTeilnehmer.Vorname} ${mainTeilnehmer.Nachname}\n${mainTeilnehmer.Strasse}\n${mainTeilnehmer.Postleitzahl} ${mainTeilnehmer.Stadt}\n\nEFN: ${mainTeilnehmer.EFN}\n\nDiesen Teilnehmer beibehalten?\n\nDie restlichen Einträge werden gelöscht.`)) {

            // GET KURS-TEILNEHMER-RELATIONS FROM DATABASE
            var kursteilnahmeRelations = [];
            for (let i = 0; i < teilnehmerToBeDeleted.length; i++) {
                const thisTeilnehmer = teilnehmerToBeDeleted[i];

                const response = await sendRequest(tokenObject, setTokenObject, 'POST', '/kursteilnahme/absolvierteKurse', undefined, undefined, { "EFN": thisTeilnehmer.EFN });
                const kursTeilnehmerRelations = response.data;

                kursteilnahmeRelations = kursteilnahmeRelations.concat(kursTeilnehmerRelations);
            }

            // ADD KURS-TEILNEHMER-RELATIONS TO mainTeilnehmer 
            for (let i = 0; i < kursteilnahmeRelations.length; i++) {
                const thisRelation = kursteilnahmeRelations[i];
                // replace EFN
                thisRelation.EFN = mainTeilnehmer.EFN;

                await sendRequest(tokenObject, setTokenObject, 'POST', '/kursteilnahme/createRelation', undefined, undefined, thisRelation);
            }

            // DELETE OLD TEILNEHMER
            for (let i = 0; i < teilnehmerToBeDeleted.length; i++) {
                const thisTeilnehmer = teilnehmerToBeDeleted[i];

                if (confirmTeilnehmerDeletion(thisTeilnehmer)) {
                    await sendRequest(tokenObject, setTokenObject, 'DELETE', `/teilnehmer/delete/${thisTeilnehmer.EFN}`, `Lösche Teilnehmer "${thisTeilnehmer.EFN}"`, `Teilnehmer mit EFN: "${thisTeilnehmer.EFN}" gelöscht`)
                }
            }


            // restart app
            restartApp();

        }
    }


    // -- delete these teilnehmer
    const deleteTeilnehmer = async () => {

        var newSearchResults = [...searchResults];

        // run through checked teilnehmer
        for (let i = 0; i < checkedTeilnehmer.length; i++) {
            const thisTeilnehmer = checkedTeilnehmer[i];

            if (confirmTeilnehmerDeletion(thisTeilnehmer)) {
                // DELETE THIS TEILNEHMER
                const response = await sendRequest(tokenObject, setTokenObject, 'DELETE', `/teilnehmer/delete/${thisTeilnehmer.EFN}`, `Lösche Teilnehmer "${thisTeilnehmer.EFN}"`, `Teilnehmer mit EFN: "${thisTeilnehmer.EFN}" gelöscht`)
                // delete locally
                if (response) newSearchResults = deleteFromState(newSearchResults, undefined, "EFN", thisTeilnehmer.EFN);
            }
        }

        // GET ALL TEILNEHMER FROM DB - update allTeilnehmer-state
        setAllTeilnehmer(await getTeilnehmerFromDatabase(tokenObject, setTokenObject));

        // refresh
        setSearchResults(newSearchResults);
    }


    // -- show double entries in database
    const showDoubleEntries = () => {
        // copy all Teilnehmer
        var teilnehmerCopy = allTeilnehmer.map(object => ({ ...object }))

        // sort by surname
        teilnehmerCopy = teilnehmerCopy.sort((a, b) => {
            const firstElem = lowerCaseAndReplaceSpaces(a.Nachname + a.Vorname);
            const secondElem = lowerCaseAndReplaceSpaces(b.Nachname + b.Vorname);
            return firstElem.localeCompare(secondElem);
        });


        var doubleEntries = [];

        // run through copy of all teilnehmer
        for (let i = 0; i < teilnehmerCopy.length; i++) {
            const prevTeilnehmer = teilnehmerCopy[i - 1]
            const thisTeilnehmer = teilnehmerCopy[i];
            const nextTeilnehmer = teilnehmerCopy[i + 1];

            // -- check if name is equal to previous or next teilnehmer
            if (
                (
                    prevTeilnehmer
                    &&
                    (
                        lowerCaseAndReplaceSpaces(prevTeilnehmer.Vorname).includes(lowerCaseAndReplaceSpaces(thisTeilnehmer.Vorname))
                        ||
                        lowerCaseAndReplaceSpaces(thisTeilnehmer.Vorname).includes(lowerCaseAndReplaceSpaces(prevTeilnehmer.Vorname))
                    )
                    &&
                    (
                        lowerCaseAndReplaceSpaces(prevTeilnehmer.Nachname).includes(lowerCaseAndReplaceSpaces(thisTeilnehmer.Nachname))
                        ||
                        lowerCaseAndReplaceSpaces(thisTeilnehmer.Nachname).includes(lowerCaseAndReplaceSpaces(prevTeilnehmer.Nachname))
                    )
                )
                ||
                (
                    nextTeilnehmer
                    &&
                    (
                        lowerCaseAndReplaceSpaces(nextTeilnehmer.Vorname).includes(lowerCaseAndReplaceSpaces(thisTeilnehmer.Vorname))
                        ||
                        lowerCaseAndReplaceSpaces(thisTeilnehmer.Vorname).includes(lowerCaseAndReplaceSpaces(nextTeilnehmer.Vorname))
                    )
                    &&
                    (
                        lowerCaseAndReplaceSpaces(nextTeilnehmer.Nachname).includes(lowerCaseAndReplaceSpaces(thisTeilnehmer.Nachname))
                        ||
                        lowerCaseAndReplaceSpaces(thisTeilnehmer.Nachname).includes(lowerCaseAndReplaceSpaces(nextTeilnehmer.Nachname))
                    )
                )
            ) {
                // double entry -> push to array
                doubleEntries.push(thisTeilnehmer);
            }
        }

        setSearchResults(doubleEntries);
    }


    // -- merge-popup
    const MergePopup = ({ button }) => <Popup
        id={2}
        content={
            !inProgress ?
                <div>
                    <h2>Diese Teilnehmer zusammenführen</h2>
                    <h5>Wähle welche Informationen beibehalten werden sollen</h5>
                    <div className='table-container'>
                        <table>
                            <thead>
                                <tr>
                                    <th>EFN</th>
                                    <th>Name</th>
                                    <th>Straße</th>
                                    <th>Wohnort</th>
                                    <th>Kurse</th>
                                </tr>
                            </thead>
                            <tbody>
                                {checkedTeilnehmer.map((teilnehmer, index) => {

                                    // how many courses
                                    var courseCount = 0;
                                    allRelations.map(elem => { if (elem.EFN === teilnehmer.EFN) courseCount++ })

                                    return (
                                        <tr key={`merge-teilnehmer-${index}`} className='pointer' onClick={() => mergeTeilnehmer(teilnehmer)}>
                                            <td>{teilnehmer.EFN}</td>
                                            <td>{teilnehmer.Titel} {teilnehmer.Vorname} {teilnehmer.Nachname}</td>
                                            <td>{teilnehmer.Strasse}</td>
                                            <td>{teilnehmer.Postleitzahl} {teilnehmer.Stadt}</td>
                                            <td>{courseCount}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                :
                <Loader />
        }
        button={button}
    />


    return (
        <div>
            <h3>Teilnehmer zusammenführen oder löschen</h3>

            {/* search input */}
            <Search
                id={2}
                focus={false}
                showAll={false}

                input={allTeilnehmer}
                searchKeys={["EFN", "Vorname", "Nachname", "Mail"]}

                callback={(result) => setSearchResults(result)}
            />


            {/* BUTTONS */}
            <div className='form-container search-create-teilnehmer-container search-status-btn-container'>

                {/* show double entries */}
                <div className='basic-small-btn' onClick={showDoubleEntries}>Zeige doppelte Einträge</div>

                {/* merge and delete button */}
                {checkedTeilnehmer.length > 0 ?
                    <>
                        {/* popup teilnehmer-merge-list */}
                        <MergePopup button={<div className='basic-small-btn'><FiGitMerge />Teilnehmer zusammenführen</div>} />

                        {/* delete entries */}
                        <div className='basic-small-btn' style={{ color: 'red' }} onClick={deleteTeilnehmer}><FiTrash2 />Ausgewählte Teilnehmer löschen</div>
                    </>
                    : <></>
                }
            </div>


            {/* table for merging/deleting teilnehmer */}
            <div className='table-container' active='true'>

                {/* header */}
                <div className='table-header' onClick={toggleTable}>
                    <AiOutlineDown />{searchResults.length} Teilnehmer
                </div>

                <table>
                    {/* headline */}
                    <thead>
                        <tr>
                            <th className='column-icon underline'
                                onClick={checkedTeilnehmer.length > 0 ? () => uncheckAll(setCheckedTeilnehmer) : () => checkAll(searchResults, setCheckedTeilnehmer)}>
                                {checkedTeilnehmer.length > 0 ? <BiCheckboxMinus /> : <BiCheckbox />}
                            </th>
                            <th>Nachname</th>
                            <th>Vorname</th>
                            <th>EFN</th>
                            <th>Straße</th>
                            <th>Ort</th>
                            <th>Kurse</th>
                            <th></th>
                        </tr>
                    </thead>

                    {/* rows */}
                    <tbody>
                        {searchResults.map((teilnehmer, index) => {

                            // amount of courses
                            var courseCount = 0;
                            allRelations.map(elem => { if (elem.EFN === teilnehmer.EFN) courseCount++ })

                            // check if thisteilnehmer is checked
                            var isChecked = false;
                            for (let i = 0; i < checkedTeilnehmer.length; i++) {
                                const elem = checkedTeilnehmer[i];
                                if (elem.EFN === teilnehmer.EFN) isChecked = true;
                            }

                            return (
                                <tr key={`teilnehmer - row - ${index}`} className='pointer' onClick={() => setSelectedTeilnehmer(teilnehmer)}>
                                    <td className='column-icon' onClick={(e) => e.stopPropagation()}>
                                        <input type="checkbox" className="relation-checkbox" onClick={(e) => checkedRelationsChange(e, teilnehmer, checkedTeilnehmer, setCheckedTeilnehmer, 'EFN')} />
                                    </td>
                                    <td>{teilnehmer.Nachname}</td>
                                    <td>{teilnehmer.Vorname}</td>
                                    <td>{teilnehmer.EFN}</td>
                                    <td>{teilnehmer.Strasse}</td>
                                    <td>{teilnehmer.Stadt}</td>
                                    <td>{courseCount}</td>
                                    {/* small merge-button */}
                                    {/* display popup if teilnehmer are checked && not more than 20 are checked (more than 20 -> app crashes) */}
                                    {(checkedTeilnehmer.length > 0 && checkedTeilnehmer.length < 20 && isChecked) ?
                                        <td className='column-icon add' onClick={(e) => e.stopPropagation()}>
                                            <MergePopup button={<FiGitMerge />} />
                                        </td>
                                        : <></>
                                    }
                                </tr>
                            )
                        }
                        )}
                    </tbody>
                </table>

            </div>

        </div>
    )
}

export default TeilnehmerMerge
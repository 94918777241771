import { useState, useContext } from "react"
import { TokenContext } from "../Main"
import { AiOutlinePlusCircle } from "react-icons/ai"
import { FiTrash2 } from "react-icons/fi"

import Popup from "../Popup"
import AddNewForm from "../AddNewForm"
import PodcastSerieForm from "./PodcastSerieForm"

import { sendRequest } from "../../rest/requests"
import { getAllPodcastSerien } from "../../rest/requests_get_all"
import Loader from "../Loader"

const PodcastSerienManager = ({ allPodcastSerien, setAllPodcastSerien }) => {

    // access-token
    const [tokenObject, setTokenObject] = useContext(TokenContext);

    const [initialized, setInitialized] = useState(true);
    const [selectedSerienId, setSelectedSerienId] = useState();

    // -- add new podcast serie
    const addNewSerie = async (name) => {
        setInitialized(false);

        // ADD NEW SERIE TO DATABASE
        const addResponse = await sendRequest(tokenObject, setTokenObject, 'POST', '/podcast-serien/erstellen', `Erstelle neue Serie "${name}"`, `Serie "${name}" erstellt`, { Name: name });

        if (addResponse.success) {
            // GET ALL SERIEN FROM DB AND UPDATE LOCAL-STATE
            const getResponse = await getAllPodcastSerien(tokenObject, setTokenObject);

            if (getResponse) {
                setAllPodcastSerien(getResponse);
                setInitialized(true);
            }
        }
    }

    // -- delete a podcast serie
    const deleteSerie = async (e, thisSerie) => {
        e.stopPropagation();

        if (window.confirm(`Sicher, dass du die Podcast-Serie "${thisSerie.Name}" löschen möchtest?`)) {
            setInitialized(false);

            // DELETE SERIE FROM DATABASE
            const deleteResponse = await sendRequest(tokenObject, setTokenObject, 'DELETE', `/podcast-serien/delete/${thisSerie.PODCAST_SERIE_ID}`, `Lösche Serie "${thisSerie.Name}"`, `Serie "${thisSerie.Name}" gelöscht`);

            if (deleteResponse.success) {
                // GET ALL SERIEN AND UPDATE LOCAL-STATE
                const getResponse = await getAllPodcastSerien(tokenObject, setTokenObject)

                if (getResponse) {
                    setAllPodcastSerien(getResponse);
                    setInitialized(true);
                }
            }
        }
    }


    if (initialized && !selectedSerienId)
        return (
            <div className="basic-container">

                {/* header */}
                <span className="headline-add-btn">
                    <h1>Podcast Serien</h1>

                    {/* add-new popup */}
                    <Popup
                        content={
                            <div className='form-container'>
                                {/* use template */}
                                <h5>Neue Podcast-Serie erstellen</h5>

                                {/* Create Vorlage */}
                                <AddNewForm onSubmit={addNewSerie} inputName={"Name: "} title={""} />
                            </div>
                        }
                        button={<AiOutlinePlusCircle />}
                    />
                </span>


                {/* serien-table */}
                <div className='table-container'>
                    {/* table */}
                    <table>
                        {/* headline */}
                        <thead>
                            <tr>
                                <th>id</th>
                                <th>Name</th>
                                <th>Untertitel</th>
                                <th className='column-icon'></th>
                            </tr>
                        </thead>

                        <tbody>
                            {/* teilnehmer-list */}
                            {allPodcastSerien.map((serie, index) =>
                                <tr key={`podcast-serie-row-${index}`} className='pointer' onClick={() => setSelectedSerienId(serie.PODCAST_SERIE_ID)}>
                                    <td>{serie.PODCAST_SERIE_ID}</td>
                                    <td>{serie.Name}</td>
                                    <td>{serie.Untertitel}</td>
                                    <td className='column-icon delete' onClick={(e) => deleteSerie(e, serie)}><FiTrash2 /></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

            </div>
        )
    else if (selectedSerienId) {
        return (
            <PodcastSerieForm
                serienId={selectedSerienId}
                setAllPodcastSerien={setAllPodcastSerien}
            />
        )
    }
    else {
        return <Loader />
    }
}

export default PodcastSerienManager
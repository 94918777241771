import { useState, useEffect, useContext } from 'react'
import { TokenContext } from '../Main';

import { AiOutlineFilePdf, AiOutlineDownload } from 'react-icons/ai'
import { BiMailSend } from "react-icons/bi"

import { backendUrl } from '../../rest/config';
import { printCheckbox, printInput } from '../InputComponents';
import { downloadCertificate } from '../../rest/requests_download.js';
import { sendPatchRequest, sendRequest } from '../../rest/requests';
import { formatDateToString } from '../../functions/helper';

import TeilnehmerForm from './../Teilnehmer/TeilnehmerForm'
import Loader from '../Loader';
import BackButton from '../BackButton';


const RelationForm = ({ relationId, setRelationId, allTeilnehmer, setAllTeilnehmer, getAndSetRelations = undefined }) => {

    // access-token
    const [tokenObject, setTokenObject] = useContext(TokenContext);

    const [initialized, setInitialized] = useState(false);

    const [thisRelation, setThisRelation] = useState();


    // -- init
    useEffect(() => {
        initRelationForm();
    }, []);
    const initRelationForm = async () => {
        // get relation
        const response = await sendRequest(tokenObject, setTokenObject, 'POST', '/kursteilnahme/kursTeilnehmerRelation', `Lade Relation mit id: "${relationId}"`, `Relation mit id: "${relationId}" geladen`, { "KURS_TEILNEHMER_ID": relationId });

        if (response.success) {
            setThisRelation(response.data[0]);
            setInitialized(true);
        }
    }



    // UPDATE RELATION ON UPDATE IN DATABASE
    useEffect(() => {
        // update if initialized
        if (initialized) {
            // update in database
            sendPatchRequest(tokenObject, setTokenObject, '/kursteilnahme/updateRelation', thisRelation, `Speichere Kurs-Teilnehmer Infos`, `Infos gespeichert`, getAndSetRelations);
        }
    }, [thisRelation])


    // -- update thisRelation-state
    const updateThisRelation = async (e, key) => {

        let value;

        const type = e.currentTarget.type;
        switch (type) {
            case "checkbox":
                // set value to 1 or 0
                value = e.currentTarget.checked ? 1 : 0;
                break;
            case "date":
                // validate date
                value = e.currentTarget.value;
                const regex = /^\d{4}-\d{2}-\d{2}$/;
                if (value.match(regex) === null) return false;
                break;
            case "email":
                value = e.currentTarget.value ? e.currentTarget.value.replaceAll(' ', '') : '';
                break;

            default:
                // default setting value
                value = e.currentTarget.value;
                break;
        }

        setThisRelation((prevState) => ({
            ...prevState,
            [key]: value,
        }))
    }

    // -- update answerkey
    const answerKeyChange = (e, index) => {
        const selectedAnswer = e.currentTarget.value;

        // copy answerkey
        var newAnswerKey = "";
        // make sure answerkey is 10 letters long
        for (let i = 0; i < 10; i++) {
            if (!thisRelation.AnswerKey || !thisRelation.AnswerKey[i]) newAnswerKey += "x";
            else newAnswerKey += thisRelation.AnswerKey[i];
        }

        // create new answer-key
        newAnswerKey = newAnswerKey.split('');
        newAnswerKey[index] = selectedAnswer;
        newAnswerKey = newAnswerKey.join('');

        setThisRelation((prevState) => ({
            ...prevState,
            AnswerKey: newAnswerKey
        }))
    }




    // -- send certificate to teilnehmer
    const sendCertificateToThisTeilnehmer = async () => {
        // update relation before sending/downloading
        const response = await sendRequest(tokenObject, setTokenObject, 'POST', '/kursteilnahme/kursTeilnehmerRelation', `Lade Relation mit id: "${relationId}"`, `Relation mit id: "${relationId}" geladen`, { "KURS_TEILNEHMER_ID": relationId });
        if (response.success) {
            const relations = response.data;
            // update relation
            setThisRelation(relations[0]);
            sendRequest(tokenObject, setTokenObject, 'POST', '/zertifikate/sendCertificate', `Sende Zertifikat an "${relations[0].Vorname} ${relations[0].Nachname}"`, `Zertifikat an "${relations[0].Vorname} ${relations[0].Nachname}" verschickt`, relations[0]);
        }
    }

    // -- download certificate from teilnehmer
    const downloadCertificateFromThisTeilnehmer = async () => {
        // update relation before sending/downloading
        const response = await sendRequest(tokenObject, setTokenObject, 'POST', '/kursteilnahme/kursTeilnehmerRelation', `Lade Relation mit id: "${relationId}"`, `Relation mit id: "${relationId}" geladen`, { "KURS_TEILNEHMER_ID": relationId });
        if (response.success) {
            const relations = response.data;
            // update relation
            setThisRelation(relations[0]);
            downloadCertificate(tokenObject, setTokenObject, relations);
        }
    }



    if (initialized) {
        return (
            <div className='basic-container'>

                {/* go back */}
                <BackButton onClick={() => setRelationId(undefined)} />

                <h3>Info</h3>
                <div className='info-container'>
                    <div className='info-container-left'>
                        <p><span className='gray-info'>Relation-ID: </span>{thisRelation.KURS_TEILNEHMER_ID}</p>
                        <p><span className='gray-info'>Kurs: </span>{thisRelation.KursTitel}</p>
                        <p><span className='gray-info'>VNR: </span>{thisRelation.VNR}</p>
                        <span className='gray-info'>{printInput((e) => updateThisRelation(e, "Teilnahmedatum"), 'relation-form-datum-input', "Teilnahmedatum: ", thisRelation.Teilnahmedatum.split('T')[0], true, undefined, "date")}</span>
                        <p><span className='gray-info'>EIV-Meldedatum: </span>{thisRelation.EIV_Datum ? formatDateToString(thisRelation.EIV_Datum) : "-"}</p>
                    </div>
                    <div className='info-container-right'>
                        <div className='basic-small-btn right width-200' onClick={() => window.open(`${backendUrl}/zertifikate/kurs/${thisRelation.VNR}/${thisRelation.EFN}`)}><AiOutlineFilePdf />Öffne Zertifikat</div>
                        <div className='basic-small-btn right width-200' onClick={downloadCertificateFromThisTeilnehmer}><AiOutlineDownload />Download Zertifikat</div>
                        <div className='basic-small-btn right width-200' onClick={sendCertificateToThisTeilnehmer}><BiMailSend />Sende Zertifikat</div>
                    </div>
                </div>

                <div className='seperator'></div>

                <TeilnehmerForm
                    selectedTeilnehmer={thisRelation}
                    insideRelationComponent={true}
                    allTeilnehmer={allTeilnehmer} setAllTeilnehmer={setAllTeilnehmer}

                    updateRelation={getAndSetRelations}
                />

                <h3>Außendienst</h3>
                <div className='form-container'>
                    <form>
                        {printInput((e) => updateThisRelation(e, "AD_Vorname"), 'relation-form-ad-vorname-input', "AD Vorname: ", thisRelation.AD_Vorname)}
                        {printInput((e) => updateThisRelation(e, "AD_Nachname"), 'relation-form-ad-nachname-input', "AD Nachname: ", thisRelation.AD_Nachname)}
                        {printInput((e) => updateThisRelation(e, "AD_Email"), 'relation-form-ad-nachname-input', "AD Mail: ", thisRelation.AD_Email, true, undefined, 'email')}
                    </form>
                </div>

                <div className='seperator'></div>

                <h3>Sonstiges</h3>
                {printCheckbox((e) => updateThisRelation(e, "Antwortbogen"), 'Antwortbogen', 'Antwortbogen: ', thisRelation.Antwortbogen)}

                <div className='seperator'></div>


                <h3>Kursantworten</h3>
                <div className='form-container'>
                    <form>
                        {printInput(() => { return false }, 'relation-form-answer-key-input', "Abgegebene Antworten: ", thisRelation.AnswerKey, true, undefined, "text", "", true)}
                        {printInput(() => { return false }, 'relation-form-ad-vorname-input', "Korrekte Antworten: ", thisRelation.CorrectAnswerKey, true, undefined, "text", "", true)}

                        {thisRelation.CorrectAnswerKey ?
                            thisRelation.CorrectAnswerKey.split('').map((correctAnswer, index) => {

                                const thisAnswer = thisRelation.AnswerKey ? thisRelation.AnswerKey[index] : null;

                                return (
                                    <div className='radio-button-row' key={`radio-button-row-key-${index}`}>
                                        <div className='question'>Frage {index + 1}: </div>

                                        <label className="radio-button-row-element" iscorrect={correctAnswer === "A" ? "true" : (thisAnswer === "A" ? "false" : "")} htmlFor={`answer-key-a-${index}`}>
                                            A
                                            <input type="radio" id={`answer-key-a-${index}`} name={`answer-key-radio-btn-${index}`} value="A" checked={thisAnswer === "A"} onChange={(e) => { answerKeyChange(e, index) }} />
                                        </label>

                                        <label className="radio-button-row-element" iscorrect={correctAnswer === "B" ? "true" : (thisAnswer === "B" ? "false" : "")} htmlFor={`answer-key-b-${index}`}>
                                            B
                                            <input type="radio" id={`answer-key-b-${index}`} name={`answer-key-radio-btn-${index}`} value="B" checked={thisAnswer === "B"} onChange={(e) => { answerKeyChange(e, index) }} />
                                        </label>

                                        <label className="radio-button-row-element" iscorrect={correctAnswer === "C" ? "true" : (thisAnswer === "C" ? "false" : "")} htmlFor={`answer-key-c-${index}`}>
                                            C
                                            <input type="radio" id={`answer-key-c-${index}`} name={`answer-key-radio-btn-${index}`} value="C" checked={thisAnswer === "C"} onChange={(e) => { answerKeyChange(e, index) }} />
                                        </label>


                                        <label className="radio-button-row-element" iscorrect={correctAnswer === "D" ? "true" : (thisAnswer === "D" ? "false" : "")} htmlFor={`answer-key-d-${index}`}>
                                            D
                                            <input type="radio" id={`answer-key-d-${index}`} name={`answer-key-radio-btn-${index}`} value="D" checked={thisAnswer === "D"} onChange={(e) => { answerKeyChange(e, index) }} />
                                        </label>


                                        <label className="radio-button-row-element" iscorrect={correctAnswer === "E" ? "true" : (thisAnswer === "E" ? "false" : "")} htmlFor={`answer-key-e-${index}`}>
                                            E
                                            <input type="radio" id={`answer-key-e-${index}`} name={`answer-key-radio-btn-${index}`} value="E" checked={thisAnswer === "E"} onChange={(e) => { answerKeyChange(e, index) }} />
                                        </label>

                                    </div>
                                )
                            })
                            :
                            <></>
                        }

                        <div className='seperator'></div>
                    </form>
                </div>
            </div>
        )
    }
    else {
        return <Loader />
    }
}

export default RelationForm
import * as xlsx from "xlsx";

import { validateKursInfo } from './validation.js';
import { getLocalStorage } from './helper.js';
import { sendRequest } from "../rest/requests.js";

//#region 
// FILEHANDLER COURSE

// -- new json file for a new course
function uploadNewCourseFile(e, setNewFile) {
    importJson(e, (json) => {
        try {
            // message
            var elem = document.querySelector("#select-new-json-file-message");
            elem.innerHTML = "";

            if (json) {
                // message
                // successfully loaded file

                // make sure public is by default = 0
                if (json.Public) json.Public = 0;

                setNewFile(json);
                elem.style.color = "green";
                elem.innerHTML = "JSON-Datei erfolgreich geladen."
            }
            else {
                // failed loading file
                setNewFile();
                elem.style.color = "red";
                elem.innerHTML = "Fehlerhafte Datei";
            }
        }
        catch (e) {
            console.log(e);
        }
    })
}

// -- overwrite json file within KursForm
function overwriteFile(event, thisCourse, setThisCourse) {
    var confirmed = window.confirm("Sicher dass du die aktuellen Informationen überschreiben möchtest?");

    if (confirmed) {
        importJson(event, (json) => {
            try {
                // message
                var elem = document.querySelector("#select-json-file-message");
                elem.innerHTML = "";

                if (json) {

                    // successfully loaded file
                    elem.style.color = "green";
                    elem.innerHTML = "JSON-Datei erfolgreich geladen."

                    // overwrite thisCourse-State
                    // copy this course
                    let newCourse = { ...thisCourse };

                    Object.keys(thisCourse).forEach(key => {
                        // never overwrite KursKey
                        if (key === "KursKey") return;
                        // never set public = 1
                        else if (key === "Public") newCourse.Public = 0;
                        else {
                            // only overwrite thisCourse with new file-input
                            if (json[key]) newCourse[key] = json[key];
                        }
                    });

                    // set state
                    setThisCourse(newCourse);
                }
                else {
                    // error loading file
                    elem.style.color = "red";
                    elem.innerHTML = "Fehlerhafte Datei"
                }
            }
            catch (e) {
                console.log(e);
            }
        })
    }
    else {
        event.target.value = "";
    }
}

// -- download new offline_info.json file
async function downloadJson(kursInfo, tokenObject, setTokenObject) {
    var confirmation = true;

    if (!validateKursInfo(kursInfo, document.querySelector('#error-message'))) {
        confirmation = window.confirm("Es sind Warnungen vorhanden. Trotzdem herunterladen?");
    }

    if (confirmation) {
        var kursInfoCopy = { ...kursInfo };
        const kursKey = kursInfoCopy.KursKey;

        // GET PUBLIC COURSE FROM DATABASE
        const response = await sendRequest(tokenObject, setTokenObject, 'GET', `/kurse/${kursKey}`, `Lade Kurs "${kursKey}"`, `Kurs "${kursKey}" geladen`);

        if (response.success) {
            const thisCourse = response.data;

            const fileData = JSON.stringify(thisCourse);

            // --save file
            const blob = new Blob([fileData], { type: "application/json" });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.download = 'offline_info.json';
            link.href = url;
            link.click();
        }
    }
}

// -- download new kurs_link.json file
function downloadKursLinkJson(thisCourse) {

    const content = {
        "KursKey": thisCourse.KursKey
    }

    // -- stringify kursInfos
    var contentString = JSON.stringify(content);
    const fileData = contentString;

    // --save file
    const blob = new Blob([fileData], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = 'kurs_link.json';
    link.href = url;
    link.click();
}
//#endregion


//#region 
// EXPORT FILES

// -- export json-file for all courses from localstorage
const downloadBackup = async () => {
    var allSavedInfos = getLocalStorage();

    if (allSavedInfos !== null) {
        // -- replace all \n with <br>
        const fileData = JSON.stringify(allSavedInfos);

        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const year = today.getFullYear();

        const filename = `kurse_backup_${day}_${month}_${year}.json`;

        // -- save file
        const blob = new Blob([fileData], { type: "application/json" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.download = filename;
        link.href = url;
        link.click();
    }
    else {
        alert("Kein Backup vorhanden");
    }
}

// -- export xlsx file
const exportXlsx = (content, name, isJson = true) => {
    try {
        // -- build xlsx file
        var worksheet;

        // build from json-file
        if (isJson) worksheet = xlsx.utils.json_to_sheet(content);
        // build from DOM-table
        else worksheet = xlsx.utils.table_to_sheet(content);

        var workbook = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(workbook, worksheet, "");

        xlsx.writeFileXLSX(workbook, `${name}.xlsx`);
    }
    catch (e) {
        console.log('Fehler in filehandler', e);
    }
}

// -- export a json-file
const exportJson = (content, name) => {
    try {
        // --save file
        const blob = new Blob([JSON.stringify(content)], { type: "application/json" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.download = `${name}.json`;
        link.href = url;
        link.click();

    }
    catch (e) {
        console.log("Fehler in exportJson", e);
    }
}
//#endregion

//#region 
// IMPORT FILES
const importXlsx = (e, callback) => {
    try {
        const files = e.currentTarget.files;

        // -- check if file exists and is xlsx-format
        if (files && files[0] && files[0].name.includes('.xlsx')) {
            // read file
            const reader = new FileReader();
            reader.onload = async (e) => {
                try {
                    const data = e.target.result;
                    const workbook = xlsx.read(data, { type: "array" });
                    const sheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[sheetName];
                    const json = xlsx.utils.sheet_to_json(worksheet, { raw: false });

                    callback(json);
                }
                catch (e) {
                    console.log(e);
                    callback(false);
                }
            };
            // read file
            reader.readAsArrayBuffer(files[0]);
        }
        else {
            console.log("Fehler bei Datei-Upload. Stelle sicher, dass es sich um eine XLSX-Datei handelt.");
            callback(false);
        }
    }
    catch (e) {
        console.log(e);
        callback(false);
    }
}
const importJson = (e, callback) => {
    try {
        const files = e.currentTarget.files;

        // -- check if file exists and is xlsx-format
        if (files && files[0] && files[0].name.includes('.json')) {
            var reader = new FileReader();
            reader.onload = (e) => {
                try {
                    // successfully loaded file
                    var json = JSON.parse(e.target.result);
                    callback(json)
                }
                catch (e) {
                    // failed loading file
                    console.log(e);
                    callback(false);
                }
            };
            reader.readAsText(e.target.files[0]);
        }
        else {
            console.log("Fehler bei Datei-Upload. Stelle sicher, dass es sich um eine JSON-Datei handelt.");
            callback(false);
        }
    }
    catch (e) {
        console.log(e);
        callback(false);
    }
}
//#endregion

export { uploadNewCourseFile, overwriteFile, downloadJson, downloadKursLinkJson, downloadBackup, exportXlsx, exportJson, importXlsx, importJson }
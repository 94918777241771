import { useState, useEffect, useContext } from 'react'
import { TokenContext } from '../Main.js';

import { FiTrash2 } from 'react-icons/fi'

import { printCheckbox, printInput, printSelect } from '../InputComponents.js';
import { formatDateToString } from './../../functions/helper.js'
import { sendPatchRequest, sendRequest } from '../../rest/requests.js';
import { getTeilnehmerFromDatabase } from '../../rest/requests_get_all.js';

import RelationForm from '../Kursteilnahme/RelationForm'
import Loader from '../Loader';
import BackButton from '../BackButton.js';
import Popup from '../Popup.js';


const TeilnehmerForm = ({ selectedTeilnehmer, insideRelationComponent = false, allTeilnehmer, setAllTeilnehmer, setSelectedTeilnehmer, updateRelation = undefined }) => {

    // access-token
    const [tokenObject, setTokenObject] = useContext(TokenContext);

    const [initialized, setInitialized] = useState(false);

    // -- this teilnehmer
    const [thisTeilnehmer, setThisTeilnehmer] = useState();
    const [teilnehmersCourses, setTeilnehmersCourses] = useState();
    const [teilnehmersGcp, setTeilnehmersGcp] = useState();

    // -- course-teilnehmer-relaton
    const [selectedRelationId, setSelectedRelationId] = useState();
    // -- all interessensgebiete
    const [interessensgebiete, setInteressensgebiete] = useState()
    // -- all fachgebiete
    const [fachgebiete, setFachgebiete] = useState();




    // -- init
    useEffect(() => {
        // scroll to top when starting this form
        document.querySelector('#sections-container').scrollTop = 0;
        initTeilnehmerForm();
    }, []);
    const initTeilnehmerForm = async () => {
        const thisEfn = selectedTeilnehmer.EFN;

        // GET THIS TEILNEHMER FROM DATABASE
        const teilnehmerResponse = await sendRequest(tokenObject, setTokenObject, 'GET', `/teilnehmer/${thisEfn}`, `Lade Teilnehmer mit EFN: "${thisEfn}"`, `Teilnehmer mit EFN: "${thisEfn}" geladen`);
        const receivedTeilnehmer = teilnehmerResponse.data;

        // GET COURSES OF TEILNEHMER
        const coursesOfTeilnehmerResponse = await sendRequest(tokenObject, setTokenObject, 'POST', '/kursteilnahme/absolvierteKurse', `Lade Kurse des Teilnehmers mit EFN: "${thisEfn}"`, `Kurse für Teilnehmer mit EFN "${thisEfn}" geladen`, { "EFN": thisEfn });
        const receivedCoursesOfTeilnehmer = !insideRelationComponent ? coursesOfTeilnehmerResponse.data : [];

        // GET GCP-COURSES OF TEILNEHMER
        const gcpOfTeilnehmerResponse = await sendRequest(tokenObject, setTokenObject, 'POST', '/gcp/relations/getAllGcpOfTeilnehmer', `Lade GCP-Kurse des Teilnehmers mit EFN "${thisEfn}"`, `GCP-Kurse für Teilnehmer mit EFN "${thisEfn}" geladen`, { "EFN": thisEfn });
        const receivedGcpOfTeilnehmer = !insideRelationComponent ? gcpOfTeilnehmerResponse.data : [];

        // GET ALL FACHGEBIETE FROM DATABASE
        const fachgebieteResponse = await sendRequest(tokenObject, setTokenObject, 'GET', '/fachgebiete', "Lade Fachgebiete", "Fachgebiete geladen");
        const receivedFachgebiete = fachgebieteResponse.data;

        // GET ALL INTERESSENSGEBIETE FROM DATABASE
        const interessensgebieteResponse = await sendRequest(tokenObject, setTokenObject, 'GET', '/interessensgebiete', "Lade Interessensgebiete", "Interessensgebiete geladen");
        const receivedInteressensgebiete = interessensgebieteResponse.data;


        if (teilnehmerResponse.success && coursesOfTeilnehmerResponse.success && gcpOfTeilnehmerResponse.success && interessensgebieteResponse.success && fachgebieteResponse.success) {
            // set states
            setThisTeilnehmer(receivedTeilnehmer[0]);
            setTeilnehmersCourses(receivedCoursesOfTeilnehmer);
            setTeilnehmersGcp(receivedGcpOfTeilnehmer);
            setInteressensgebiete(receivedInteressensgebiete);
            setFachgebiete(receivedFachgebiete);
            setInitialized(true);
        }
    }



    // -- selectedRelationId changed to undefined -> update teilnehmer
    useEffect(() => {
        if (initialized && !selectedRelationId) {
            setInitialized(false);
            initTeilnehmerForm();
        }
    }, [selectedRelationId])


    // -- update teilnehmer-state
    const updateThisTeilnehmer = async (e, key) => {
        var value;
        // set value 1 or 0 if type checkbox
        if (e.currentTarget.type === "checkbox") value = e.currentTarget.checked ? 1 : 0;
        else value = e.currentTarget.value;
        // dont allow spaces if type email
        if (e.currentTarget.type === "email" && value) value = value.replaceAll(' ', '');

        setThisTeilnehmer((prevState) => ({
            ...prevState,
            [key]: value,
        }))
    }

    // UPDATE THIS TEILNEHMER IN DATABASE
    useEffect(() => {
        if (initialized) {
            // update teilnehmer in database
            sendPatchRequest(
                tokenObject, setTokenObject,
                `/teilnehmer/${thisTeilnehmer.EFN}`, thisTeilnehmer, `Speichere Teilnehmer "${thisTeilnehmer.Nachname}"`, `Teilnehmer "${thisTeilnehmer.Nachname}" gespeichert`,
                setAllTeilnehmer ? updateGlobalTeilnehmer : undefined
            );
        }
    }, [thisTeilnehmer])


    // -- update all teilnehmer globally in cmeassist
    const updateGlobalTeilnehmer = async () => {
        setAllTeilnehmer(await getTeilnehmerFromDatabase(tokenObject, setTokenObject));
        if (updateRelation) updateRelation();
    }


    // -- delete teilnehmer from course
    const deleteFromCourse = async (e, relation) => {
        e.stopPropagation();

        const confirmation = window.confirm(`Sicher, dass du den Teilnehmer "${relation.Nachname}" von Kurs "${relation.KursTitel}" löschen möchtest`);

        if (confirmation) {
            // REMOVE A KURS-TEILNEHMER-RELATION
            const response = await sendRequest(tokenObject, setTokenObject, 'DELETE', '/kursteilnahme/deleteRelation', `Lösche Teilnehmer`, `Teilnehmer erfolgreich gelöscht`, { "KURS_TEILNEHMER_ID": relation.KURS_TEILNEHMER_ID });
            if (response.success) initTeilnehmerForm();
        }
    }

    // -- delete teilnehmer from gcp
    const deleteFromGcp = async (e, relation) => {
        e.stopPropagation();

        const confirmation = window.confirm(`Sicher, dass du den Teilnehmer "${relation.Nachname}" von GCP-Kurs "${relation.GCPKey}" löschen möchtest`);

        if (confirmation) {
            // REMOVE A GCP-TEILNEHMER-RELATION
            const response = await sendRequest(tokenObject, setTokenObject, 'DELETE', '/gcp/relations/deleteGcpTeilnRelation', `Lösche Teilnehmer`, `Teilnehmer erfolgreich gelöscht`, { "GCP_TEILNEHMER_ID": relation.GCP_TEILNEHMER_ID });
            if (response.success) initTeilnehmerForm();
        }
    }



    // -- change interessensgebiete of teilnehmer
    const changeInteressensgebiete = async (thisInteressensgebiet) => {

        // name of new interessensgebiet
        const name = thisInteressensgebiet.Name;
        // copy of current interessensgebiete
        const newArr = [...thisTeilnehmer.Interessensgebiete];


        // delete
        if (newArr.includes(name)) newArr.splice(newArr.indexOf(name), 1);
        // add
        else newArr.push(name);


        // set state
        setThisTeilnehmer(prev => ({
            ...prev,
            Interessensgebiete: newArr
        }))
    }


    // update EFN
    const updateEfn = async (e) => {
        e.preventDefault();

        const oldEfn = thisTeilnehmer.EFN;
        const newEfn = window.prompt('Neue EFN eingeben', oldEfn);

        if (newEfn && (newEfn !== oldEfn)) {

            // UPDATE THIS TEILNEHMERS EFN IN DATABASE
            const response = await sendPatchRequest(
                tokenObject, setTokenObject,
                `/teilnehmer/change-efn/${oldEfn}`, { 'EFN': newEfn }, `Aktualisiere EFN von "${thisTeilnehmer.Nachname}"`, `EFN von "${thisTeilnehmer.Nachname}" gespeichert`,
                setAllTeilnehmer ? updateGlobalTeilnehmer : undefined
            );

            // on success - update state
            if (response.success) {
                setThisTeilnehmer((prev) => ({
                    ...prev,
                    EFN: newEfn
                }))
            }
            // error
            else alert("Fehler beim Aktualisieren der EFN");
        }
    }



    if (initialized && !selectedRelationId && thisTeilnehmer) {
        return (

            <div className='basic-container'>

                {/* go back - dont display if inside Relationform (RelationForm provides BackButton) */}
                {!insideRelationComponent ? <BackButton onClick={() => setSelectedTeilnehmer()} /> : <></>}

                {/* teilnehmer form */}
                <div className='form-container'>
                    <h3>{thisTeilnehmer.Vorname} {thisTeilnehmer.Nachname}</h3>
                    <form>
                        {printInput(() => false, "EFN", <span>EFN: <button onClick={updateEfn}>EFN ändern</button></span>, thisTeilnehmer.EFN, true, undefined, "text", "", true)}
                        {printInput((e) => updateThisTeilnehmer(e, "Telefon"), "Telefon", "Telefon: ", thisTeilnehmer.Telefon)}
                        {printInput((e) => updateThisTeilnehmer(e, "Mail"), "Mail", "Mail: ", thisTeilnehmer.Mail, true, undefined, 'email')}
                        {printInput((e) => updateThisTeilnehmer(e, "Mail_Alternativ"), "Mail_Alternativ", "Alternative Mail: ", thisTeilnehmer.Mail_Alternativ, true, undefined, 'email')}

                        {/* Land */}
                        {printSelect((e) => updateThisTeilnehmer(e, "Land"), "Land", "Land: ", thisTeilnehmer.Land, [
                            "Andere",
                            "Canada",
                            "Croatia",
                            "Cyprus",
                            "Deutschland",
                            "Finland",
                            "Greece",
                            "Italy",
                            "Malta",
                            "Norway",
                            "Österreich",
                            "Portugal",
                            "Schweiz",
                            "Slovenia",
                            "Spain",
                            "United Kingdom",
                            "USA"
                        ])}

                        {printInput((e) => updateThisTeilnehmer(e, "Titel"), "Titel", "Titel: ", thisTeilnehmer.Titel)}
                        {printInput((e) => updateThisTeilnehmer(e, "Vorname"), "Vorname", "Vorname: ", thisTeilnehmer.Vorname)}
                        {printInput((e) => updateThisTeilnehmer(e, "Nachname"), "Nachname", "Nachname: ", thisTeilnehmer.Nachname)}

                        {printInput((e) => updateThisTeilnehmer(e, "Klinik"), "Klinik", "Klinik: ", thisTeilnehmer.Klinik)}
                        {printInput((e) => updateThisTeilnehmer(e, "Abteilung"), "Abteilung", "Abteilung: ", thisTeilnehmer.Abteilung)}
                        {printInput((e) => updateThisTeilnehmer(e, "Strasse"), "Strasse", "Strasse: ", thisTeilnehmer.Strasse)}
                        {printInput((e) => updateThisTeilnehmer(e, "Postleitzahl"), "Postleitzahl", "Postleitzahl: ", thisTeilnehmer.Postleitzahl)}
                        {printInput((e) => updateThisTeilnehmer(e, "Stadt"), "Stadt", "Stadt: ", thisTeilnehmer.Stadt)}
                        {printSelect((e) => updateThisTeilnehmer(e, "Fachgebiet"), "Fachgebiet", "Fachgebiet: ", thisTeilnehmer.Fachgebiet, fachgebiete.map(elem => elem.Name))}

                        {/* Interessensgebiete */}
                        <Popup
                            button={
                                <div className='input-container large teilnehmer-interessensgebiete'>
                                    <label>Interessensgebiete:</label>
                                    {thisTeilnehmer.Interessensgebiete.length ?
                                        <div className='condition-value-container'>
                                            {/* map different Interessensgebiete */}
                                            {thisTeilnehmer.Interessensgebiete.map((elem, index) => (
                                                <div key={`teilnehmer-interessensgebiet-${index}`} className="condition-single-container">
                                                    <div className='condition-single-name'>
                                                        {elem}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        :
                                        <div className='basic-btn large'>Interessensgebiete auswählen</div>
                                    }
                                </div>
                            }

                            content={(closePopup) =>
                                <div className='input-container large'>
                                    <label>Alle Interessensgebiete</label>
                                    <div className='condition-value-container'>
                                        {/* map different Interessensgebiete */}
                                        {interessensgebiete.map((elem, index) => {
                                            const isActive = thisTeilnehmer.Interessensgebiete.includes(elem.Name);
                                            return (
                                                <div key={`teilnehmer-interessensgebiet-${index}`} className="condition-single-container pointer" active={isActive ? "true" : "false"}>
                                                    <div className='condition-single-name' onClick={() => changeInteressensgebiete(elem)}>
                                                        {elem.Name}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div onClick={closePopup} className='basic-small-btn'>Zurück</div>
                                </div>
                            }

                            contentIsFunction={true}
                        />

                        {/* arbeitsstätte */}
                        <div className='input-container multiple-checkbox-container'>
                            <label>Arbeitsstätten:</label>
                            <div className='multiple-checkboxes'>
                                {printCheckbox((e) => updateThisTeilnehmer(e, "Ambulant"), 'Praxis', <span>Praxis <small>(vorher Ambulant)</small></span>, thisTeilnehmer.Ambulant)}
                                {printCheckbox((e) => updateThisTeilnehmer(e, "Stationaer"), 'Klinik', <span>Klinik <small>(vorher Stationaer)</small></span>, thisTeilnehmer.Stationaer)}
                                {printCheckbox((e) => updateThisTeilnehmer(e, "Andere"), 'Andere', 'Andere', thisTeilnehmer.Andere)}
                            </div>
                        </div>


                        <div className='input-container multiple-checkbox-container'>
                            <label>Zustimmungen:</label>
                            <div className='multiple-checkboxes large'>


                                {/* Zustimmung */}
                                {printCheckbox((e) => updateThisTeilnehmer(e, "Zustimmung"), 'Zustimmung',
                                    "Der Teilnehmer ist einverstanden, zukünftig von der Academy Verlagsgesellschaft mbH über neue CME-Maßnahmen informiert zu werden.",
                                    thisTeilnehmer.Zustimmung
                                )}

                                {/* Datenspeicherung */}
                                {printCheckbox((e) => updateThisTeilnehmer(e, "Datenspeicherung"), 'Datenspeicherung',
                                    "Der Teilnehmer hat der Verarbeitung und Speicherung seiner personenbezogenen Daten zugestimmt.",
                                    thisTeilnehmer.Datenspeicherung
                                )}
                            </div>
                        </div>
                    </form>

                    <div className='seperator'></div>

                    {/* display courses if Teilnehmerform is not part of Relationform */}
                    {!insideRelationComponent ?
                        <>
                            {/* Kurse */}
                            {teilnehmersCourses.length > 0 ?
                                <>
                                    <h3>Absoliverte Kurse</h3>
                                    <div className='table-container'>
                                        <table>

                                            <thead>
                                                <tr>
                                                    <th>Titel</th>
                                                    <th>KursKey</th>
                                                    <th>VNR</th>
                                                    <th>Teilnahmedatum</th>
                                                    <th className='column-icon'></th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {teilnehmersCourses.map((relation, index) =>
                                                    <tr key={`teilnehmerform-finished-relation-key-${index}`} className='pointer' onClick={() => { if (relation.KursKey) setSelectedRelationId(relation.KURS_TEILNEHMER_ID) }}>
                                                        <td>{relation.KursTitel}</td>
                                                        <td>{relation.KursKey}</td>
                                                        <td>{relation.VNR}</td>
                                                        <td>{formatDateToString(relation.Teilnahmedatum)}</td>
                                                        <td className='column-icon underline delete' onClick={(e) => deleteFromCourse(e, relation)}><FiTrash2 /></td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                                : <></>
                            }

                            {/* GCP-Kurse */}
                            {teilnehmersGcp.length > 0 ?
                                <>
                                    <h3>Absoliverte GCP-Kurse</h3>
                                    <div className='table-container'>
                                        <table>

                                            <thead>
                                                <tr>
                                                    <th>Titel</th>
                                                    <th>ID</th>
                                                    <th>Teilnahmedatum</th>
                                                    <th className='column-icon'></th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {teilnehmersGcp.map((relation, index) =>
                                                    <tr key={`teilnehmerform-finished-gcp-relation-key-${index}`}>
                                                        <td>{relation.GCPKey}</td>
                                                        <td>{relation.GCP_ID}</td>
                                                        <td>{formatDateToString(relation.Teilnahmedatum)}</td>
                                                        <td className='column-icon underline delete' onClick={(e) => deleteFromGcp(e, relation)}><FiTrash2 /></td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                                : <></>
                            }
                        </>
                        : <></>}
                </div>
            </div>
        )
    }
    else if (selectedRelationId) {
        return (
            <RelationForm
                relationId={selectedRelationId}
                setRelationId={setSelectedRelationId}
                allTeilnehmer={allTeilnehmer} setAllTeilnehmer={setAllTeilnehmer}
            />
        )
    }
    else {
        return (
            <Loader />
        )
    }
}

export default TeilnehmerForm
import { useState, useEffect, useContext } from 'react';
import { TokenContext } from './Main';

import Header from './Header'
import StatusBar from './StatusBar';
import Loader from './Loader';

import BurgerMenu from './BurgerMenu';
import KurseManager from './Kurse/KurseManager';
import ReferentenManager from './Referenten/ReferentenManager';
import TeilnehmerManager from './Teilnehmer/TeilnehmerManager';
import Werkzeuge from './Werkzeuge/Werkzeuge';
import KursteilnahmeManager from './Kursteilnahme/KursteilnahmeManager';
import GcpManager from './GCP/GcpManager';
import ShuttleInformation from './ShuttleInformation';
import ZertifikateManager from './Zertifikate_Vorlagen/ZertifikateManager';
import UserManager from './Werkzeuge/UserManager';
import PodcastSerienManager from './PodcastSerien/PodcastSerienManager';
import StatisticManager from './Statistics/StatisticManager'
import Fachgebiete from './Fachgebiete';
import SponsorenManager from './Sponsoren/SponsorenManager';
import Links from './Links';

import { setStatus } from '../rest/config';
import { getReferentenFromDatabase, getCoursesFromDatabase, getGcpFromDatabase, getTeilnehmerFromDatabase, getRelationsFromDatabase, getVorlagenFromDatabase, getAllPodcastSerien } from './../rest/requests_get_all'
import { defaultHeaderTitle } from '../functions/global_variables';


export const Overview = ({ userLastname, logoutUser, userRole }) => {

    // access-token
    const [tokenObject, setTokenObject] = useContext(TokenContext);

    const [headerTitle, setHeaderTitle] = useState(defaultHeaderTitle);
    const [burgerMenu, setBurgerMenu] = useState(false);
    const [activeMenu, setActiveMenu] = useState(0);
    const [initialized, setInitialized] = useState(false);

    // -- all courses
    const [allCourses, setAllCourses] = useState();
    // -- all gcp-courses
    const [allGcp, setAllGcp] = useState();
    // -- referenten
    const [allReferenten, setAllReferenten] = useState();
    // -- teilnehmer
    const [allTeilnehmer, setAllTeilnehmer] = useState();
    // -- kurs-teilnehmer-relations
    const [allRelations, setAllRelations] = useState();
    // -- zertifikat-vorlagen
    const [zertifikatVorlagen, setZertifikatVorlagen] = useState();
    // -- podcast-serien
    const [allPodcastSerien, setAllPodcastSerien] = useState();

    // -- init
    useEffect(() => { initOverview(); }, [])

    const initOverview = async () => {
        console.log('%cINIT APP', 'color: #00ff00');

        // get referenten
        setAllReferenten(await getReferentenFromDatabase(tokenObject, setTokenObject));

        // get all courses
        setAllCourses(await getCoursesFromDatabase(tokenObject, setTokenObject));

        // get all gcp
        setAllGcp(await getGcpFromDatabase(tokenObject, setTokenObject));

        // get all teilnehmer
        setAllTeilnehmer(await getTeilnehmerFromDatabase(tokenObject, setTokenObject));

        // get all relations
        setAllRelations(await getRelationsFromDatabase(tokenObject, setTokenObject));

        // get all zertifikat-vorlagen
        setZertifikatVorlagen(await getVorlagenFromDatabase(tokenObject, setTokenObject));

        // get all podcastSerien
        setAllPodcastSerien(await getAllPodcastSerien(tokenObject, setTokenObject));


        // everything loaded
        setStatus("Informationen aus Datenbank geladen", false);

        setInitialized(true);
        setBurgerMenu(true);
    }


    // -- restart whole app
    const restartApp = async () => {
        setInitialized(false);
        // init again if no step is defined
        await initOverview();
    }


    //  -- restart Overview.js
    const reloadMain = async (menuIndex) => {
        if (initialized) {
            setInitialized(false);
            // jump to menu
            setActiveMenu(menuIndex);
            setTimeout(() => setInitialized(true), 100);
        }
    }


    // -- render active menu
    const renderActiveMenu = () => {

        // which menu needs to be returned
        switch (activeMenu) {
            case 0:
                return <KurseManager
                    userRole={userRole}

                    userLastname={userLastname}
                    setHeaderTitle={setHeaderTitle}

                    allCourses={allCourses} setAllCourses={setAllCourses}
                    allPodcastSerien={allPodcastSerien}

                    allReferenten={allReferenten}
                    zertifikatVorlagen={zertifikatVorlagen}
                />

            case 1:
                return <ReferentenManager
                    allReferenten={allReferenten} setAllReferenten={setAllReferenten}
                />

            case 2:
                return <TeilnehmerManager
                    restartApp={restartApp}

                    allRelations={allRelations}
                    allTeilnehmer={allTeilnehmer} setAllTeilnehmer={setAllTeilnehmer}

                />

            case 3:
                return <SponsorenManager />

            case 4:
                return <Fachgebiete />

            case 5:
                return <KursteilnahmeManager
                    restartApp={restartApp}

                    allCourses={allCourses.filter(course => course.id === "CME_Kurse" || course.id === "GCP" || course.id === "Live")}

                    allRelations={allRelations}
                    allTeilnehmer={allTeilnehmer} setAllTeilnehmer={setAllTeilnehmer}
                    setHeaderTitle={setHeaderTitle}
                />

            case 6:
                return <StatisticManager />

            case 7:
                return <Links />

            case 8:
                return <GcpManager
                    allGcp={allGcp} setAllGcp={setAllGcp}
                    allGcpModules={allCourses.filter(course => course.id === "GCP")[0].data}

                    allTeilnehmer={allTeilnehmer}
                    zertifikatVorlagen={zertifikatVorlagen}
                    setHeaderTitle={setHeaderTitle}
                />

            case 9:
                return <ZertifikateManager
                    zertifikatVorlagen={zertifikatVorlagen}
                    setHeaderTitle={setHeaderTitle}
                />

            case 10:
                return <PodcastSerienManager
                    allPodcastSerien={allPodcastSerien} setAllPodcastSerien={setAllPodcastSerien}
                />

            case 11:
                return <ShuttleInformation />

            case 12:
                return <UserManager />

            case 13:
                return <Werkzeuge
                    userRole={userRole}
                />


            default:
                return <h3 className='basic-container'>FEHLER</h3>
        }
    }



    return (
        <div className='main-container'>

            <Header
                headerTitle={headerTitle}
                goToHome={() => reloadMain(activeMenu)}
                toggleMenu={() => setBurgerMenu(!burgerMenu)}
                logoutUser={logoutUser}
                refresh={restartApp}
            />

            <BurgerMenu
                burgerMenu={burgerMenu}
                activeMenu={activeMenu}
                userRole={userRole}
                reloadMain={reloadMain}
            />

            {/* active menu */}
            <div id="sections-container">
                {initialized ?
                    renderActiveMenu()
                    :
                    <div><Loader /><p style={{ width: "100%", textAlign: "center" }}>Lade Informationen aus Datenbank</p></div>
                }
            </div>

            <StatusBar />

        </div>
    )
}


export default Overview
import { useState, useEffect, useContext } from 'react'
import { TokenContext } from '../Main.js';
import { SiWorldhealthorganization } from 'react-icons/si'

import { sendAllToEiv } from '../../rest/eiv.js';
import { sortByDate } from './../../functions/helper.js';

import TeilnehmerList from './TeilnehmerList';
import Loader from './../Loader'
import KursTable from '../Kurse/KurseTable.js';


const KursteilnahmeManager = ({ setHeaderTitle, restartApp, allCourses, allRelations, allTeilnehmer, setAllTeilnehmer }) => {

    // access-token
    const [tokenObject, setTokenObject] = useContext(TokenContext);

    // -- fill object with all course types
    var allCourseTypes = [];
    for (let i = 0; i < allCourses.length; i++) {
        const element = allCourses[i];
        allCourseTypes.push(...element.data);
    }
    allCourseTypes = sortByDate(allCourseTypes);


    const [initialized, setInitialized] = useState(false);

    // -- state if course gets selected
    const [selectedCourse, setSelectedCourse] = useState();

    // -- which kind of course to be displayed
    const [activeTable, setActiveTable] = useState("Alle");

    // -- init
    useEffect(() => {
        initKursteilnahmeManager();
    }, []);
    const initKursteilnahmeManager = async () => {
        setInitialized(true);
    }



    if (initialized && !selectedCourse)
        return (
            <div className='basic-container'>


                {/* top section */}
                <div className='info-container'>

                    <div className='info-container-left'>

                        <h1>Kursteilnahmen</h1>

                        {/* select gcp or standard-course */}
                        <div>
                            <div className='checkbox-inline-container'>
                                <input type="radio" id="list-selection-alle" name="list-kind-selection" value="Alle" checked={activeTable === "Alle"} onChange={(e) => setActiveTable(e.currentTarget.value)} className="square-checkbox" />
                                <label htmlFor="list-selection-alle">Alle</label>
                            </div>
                            {allCourses.map((entry, index) => (
                                <div key={`course-type-selection-${index}`} className='checkbox-inline-container'>
                                    <input type="radio" id={`list-selection-${entry.id}`} name="list-kind-selection" value={entry.name} checked={activeTable === entry.name} onChange={(e) => setActiveTable(e.currentTarget.value)} className="square-checkbox" />
                                    <label htmlFor={`list-selection-${entry.id}`}>{entry.name}</label>
                                </div>
                            ))}
                        </div>
                        <div className='seperator'></div>
                    </div>


                    {/* button-container on right side */}
                    <div className='info-container-right'>

                        {/* send all to eiv-button */}
                        <div className='basic-small-btn right ' onClick={async () => { await sendAllToEiv(tokenObject, setTokenObject); restartApp() }}><SiWorldhealthorganization />Alle verbleibenden Teilnehmer an EIV melden</div>

                    </div>

                </div>


                <div className='seperator'></div>

                {/* course-table */}
                {activeTable === "Alle" ?
                    <KursTable
                        title={"Alle"} courses={allCourseTypes}
                        setSelectedCourse={setSelectedCourse}
                        allRelations={allRelations}
                        validation={false}
                    />
                    :
                    allCourses.map((entry, index) => {
                        if (activeTable === entry.name)
                            return (
                                <div key={`kursteilnahmemanager-table-key-${index}`}>
                                    <KursTable
                                        title={entry.name} courses={entry.data}
                                        setSelectedCourse={setSelectedCourse}
                                        allRelations={allRelations}
                                        validation={false}
                                    />
                                </div>
                            )
                    })
                }


            </div>
        )
    else if (selectedCourse)
        return (
            <TeilnehmerList
                selectedCourse={selectedCourse}
                allTeilnehmer={allTeilnehmer} setAllTeilnehmer={setAllTeilnehmer}

                restartApp={restartApp}
                setHeaderTitle={setHeaderTitle}

                // function on back-button click
                goBack={() => { setSelectedCourse() }}
            />
        )
    else
        return (
            <Loader />
        )
}

export default KursteilnahmeManager
import { useState, useEffect, useContext } from "react";
import { TokenContext } from "./Main";
import { AiOutlineDown } from "react-icons/ai";
import { FiTrash2 } from 'react-icons/fi'

import { sendPatchRequest, sendRequest } from "../rest/requests";
import { toggleTable } from "../functions/helper";
import { printInput } from "./InputComponents";

import Loader from "./Loader";

const Interessensgebiete = ({ }) => {

    // access-token
    const [tokenObject, setTokenObject] = useContext(TokenContext);

    // init
    const [initialized, setInitialized] = useState(false);

    // interessensgebiete
    const [interessensgebiete, setInteressensgebiete] = useState();


    // initialize component
    useEffect(() => { init() }, []);
    const init = async () => {
        // GET INTERESSENSGEBIETE FROM DATABASE
        const response = await sendRequest(tokenObject, setTokenObject, 'GET', '/interessensgebiete', 'Lade Interessensgebiete', 'Interessensgebiete geladen');
        if (response.success) {
            setInteressensgebiete(response.data);
            setInitialized(true);
        }
    }


    // update 
    const update = async (newName, index) => {
        // set updated array
        const newInteressensgebiete = [...interessensgebiete];
        newInteressensgebiete[index].Name = newName;

        // update state
        setInteressensgebiete(newInteressensgebiete);

        // UPDATE INTERESSENSGEBIETE IN DATABASE
        const response = await sendPatchRequest(tokenObject, setTokenObject, `/interessensgebiete`, newInteressensgebiete[index], `Speichere Interessensgebiet`, `Interessensgebiet gespeichert`);
        if (!response.success) setInitialized(false);
    }

    // add
    const add = async () => {
        const newValue = window.prompt("Bitte trage den Names des Interessensgebietes ein");

        if (newValue) {
            setInitialized(false);

            // ADD INTERESSENSGEBIET TO DATABASE
            const response = await sendRequest(tokenObject, setTokenObject, 'POST', '/interessensgebiete', 'Erstelle Interessensgebiet', 'Interessensgebiet erstellt', { "Name": newValue });
            if (response.success) init();
        }
    }

    // delete
    const deleteInteressensgebiet = async (thisInteressensgebiet) => {
        const confirm = window.confirm(`Interessensgebiet "${thisInteressensgebiet.Name}" wirklich löschen?`);

        if (confirm) {
            setInitialized(false);

            // DELETE INTERESSENSGEBIET FROM DATABASE
            const response = await sendRequest(tokenObject, setTokenObject, 'DELETE', '/interessensgebiete', `Lösche ${thisInteressensgebiet.Name}`, `${thisInteressensgebiet.Name} gelöscht`, thisInteressensgebiet);
            if (response.success) init();
        }
    }


    if (initialized) {
        return (
            <div className="basic-container">
                <h3>Interessensgebiete</h3>

                <div className="table-container form-container" active="false">
                    <div className="table-header" onClick={toggleTable}>
                        <AiOutlineDown />
                        {interessensgebiete.length} Interessensgebiete
                    </div>

                    <table>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* map interessensgebiete */}
                            {interessensgebiete.map((thisInteressensgebiet, index) => {

                                {/* name */ }
                                return (
                                    <tr key={`interessensgebiete-list-key-${index}`}>
                                        <td className="column-icon">{thisInteressensgebiet.INTERESSENSGEBIET_ID}</td>
                                        <td className="column-input">{printInput((e) => update(e.currentTarget.value, index), `single-interessensgebiet-name-${index}`, '', thisInteressensgebiet.Name)}</td>
                                        <td className="column-icon delete pointer" onClick={() => deleteInteressensgebiet(thisInteressensgebiet)}><FiTrash2 /></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>

                {/* add */}
                <div className="basic-btn" onClick={add}>Interessensgebiet hinzufügen</div>
            </div >
        )
    }
    else {
        return <Loader />
    }
}

export default Interessensgebiete
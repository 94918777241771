const debug = window.location.host === 'localhost:3000' || window.location.host === 'localhost:3001' ? true : false;
const backendUrl = !debug ? 'https://cmeassist.academy2.de/backend/api/v2' : 'http://localhost:8080/api/v2';

if (debug) console.log('%cDEBUG MODE', 'color: #ff0000');
else console.log('%cPRODUCTION-MODE', 'color: #00ff00');

// -- set status message
const setStatus = (status, loading, isError = false) => {
    var message = document.querySelector('#status-message');
    var spinner = document.querySelector('#status-spinner-container');
    var checked = document.querySelector('#status-check-container');
    var history = document.querySelector('#status-history');

    // set status-message
    message.innerHTML = status;

    // set history
    var historyElem = document.createElement('p');
    historyElem.innerHTML = status + '<br/>';

    if (loading) {
        // process active
        spinner.setAttribute("active", "");
        checked.removeAttribute("active");
        historyElem.style.color = "cornflowerblue";
    }
    else {
        // no process active
        spinner.removeAttribute("active");
        checked.setAttribute("active", "");
        historyElem.style.color = "green";
    }

    // if there is a error 
    if (isError) historyElem.style.color = "red";

    // prepend to history element
    if (history && !historyElem.innerHTML.includes("Lädt")) history.prepend(historyElem);
}


export { backendUrl, setStatus }
import { useState, useEffect, useContext } from 'react'
import { TokenContext } from '../Main'

import { FiTrash2 } from 'react-icons/fi'
import { AiOutlineFilePdf, AiOutlineDown, AiOutlineDownload } from 'react-icons/ai'
import { BiMailSend, BiCheckboxMinus, BiCheckbox } from "react-icons/bi"

import { backendUrl } from '../../rest/config'

import { setStatus } from '../../rest/config';
import { printCheckbox, printInput, printSelect, printTextArea } from '../InputComponents.js';
import { formatDateToString, checkedRelationsChange, sendCertificateToCheckedRelations, downloadCertificateFromCheckedRelations, toggleTable, uncheckAll, checkAll } from './../../functions/helper.js'
import { sendPatchRequest, sendRequest } from '../../rest/requests'
import { defaultHeaderTitle } from '../../functions/global_variables.js'
import { getGcpFromDatabase } from '../../rest/requests_get_all'

import TeilnehmerSearchAndCreate from './../Teilnehmer/TeilnehmerSearchAndCreate'
import Loader from '../Loader';
import Search from '../Search'


const GcpForm = ({ setHeaderTitle, selectedGcpKey, allGcpModules, setAllGcp, allTeilnehmer, zertifikatVorlagen }) => {

    // access-token
    const [tokenObject, setTokenObject] = useContext(TokenContext);

    const [initialized, setInitialized] = useState(false);

    // -- this gcp
    const [thisGcp, setThisGcp] = useState();
    const [modulesOfGcp, setModulesOfGcp] = useState();

    // -- search-results
    const [searchResults, setSearchResults] = useState([]);


    // -- this gcp-teilnehmer
    const [gcpTeilnehmer, setGcpTeilnehmer] = useState([]);
    const [filteredTeilnehmer, setFilteredTeilnehmer] = useState([]);

    // -- array containing checked teilnehmer
    const [checkedRelations, setCheckedRelations] = useState([]);


    // -- init
    useEffect(() => {
        setHeaderTitle(selectedGcpKey);

        // scroll to top when starting this form
        document.querySelector('#sections-container').scrollTop = 0;
        initGcpForm();

        return () => setHeaderTitle(defaultHeaderTitle)
    }, []);
    const initGcpForm = async () => {
        uncheckAll(setCheckedRelations);

        // GET THIS GCP FROM DATABASE
        const gcpResponse = await sendRequest(tokenObject, setTokenObject, 'GET', `/gcp/${selectedGcpKey}`, `Lade GCP-Kurs "${selectedGcpKey}"`, `GCP-Kurs "${selectedGcpKey}" geladen`);


        // GET MODULES OF GCP
        const modulesResponse = await sendRequest(tokenObject, setTokenObject, 'POST', '/gcp/relations/getModulesOfGcp', `Lade Module von "${selectedGcpKey}"`, `Module geladen`, { "GCPKey": selectedGcpKey });

        // GET TEILNEHMER OF THIS GCP
        const teilnehmerResponse = await sendRequest(tokenObject, setTokenObject, 'POST', '/gcp/relations/getTeilnehmerOfGcp', `Lade Teilnehmer des Kurses "${selectedGcpKey}"`, `Teilnehmer für Kurs "${selectedGcpKey}" geladen`, { "GCPKey": selectedGcpKey });


        if (gcpResponse.success && modulesResponse.success && teilnehmerResponse.success) {
            // set states
            setThisGcp(gcpResponse.data[0]);
            setModulesOfGcp(modulesResponse.data);
            setGcpTeilnehmer(teilnehmerResponse.data);
            setInitialized(true);
        }
    }




    // update state
    const updateThisGcp = async (e, key) => {
        var value;
        if (e.currentTarget.type === "checkbox") value = e.currentTarget.checked ? 1 : 0;
        else value = e.currentTarget.value;

        setThisGcp((prevState) => ({
            ...prevState,
            [key]: value
        }))
    }
    // UPDATE THIS GCP IN DATABASE
    useEffect(async () => {
        if (initialized) {
            // UPDATE GCP IN DATABASE
            await sendPatchRequest(tokenObject, setTokenObject, `/gcp/${thisGcp.GCPKey}`, thisGcp, `Speichere GCP-Kurs "${thisGcp.GCPKey}`, `Kurs "${thisGcp.GCPKey}" gespeichert`);
            // update gcp locally
            setAllGcp(await getGcpFromDatabase(tokenObject, setTokenObject));
        }
    }, [thisGcp])




    // REMOVE A GCP-MODULE-RELATION
    const deleteThisModule = async (relation) => {
        const confirmation = window.confirm(`Sicher, dass du das Modul "${relation.KursTitel}" löschen möchtest?`);

        if (confirmation) {
            // delete this module
            const response = await sendRequest(tokenObject, setTokenObject, 'DELETE', '/gcp/relations/deleteGcpModuleRelation', `Lösche Modul "${relation.GCP_MODUL_ID}"`, `Modul erfolgreich gelöscht`, { "GCP_MODUL_ID": relation.GCP_MODUL_ID });

            // success
            if (response.success) initGcpForm();
            // error
            else setStatus("Fehler beim Löschen des Moduls", false, true);
        }
    }

    // ADD NEW GCP-MODULE-RELATION
    const addGcpModuleRelationToDb = async (kurskey) => {

        // add this module
        const response = await sendRequest(tokenObject, setTokenObject, 'POST', '/gcp/relations/createGcpModuleRelation', 'Füge Modul hinzu', 'Modul erfolgreich hinzugefügt', { "GCPKey": thisGcp.GCPKey, "KursKey": kurskey });

        // success
        if (response.success) initGcpForm();
        // error
        else setStatus("Fehler beim Hinzufügen des Moduls", false, true);
    }


    // ADD GCP-TEILNEHMER-RELATION
    const addTeilnehmerToCourse = async (e, relation) => {
        e.stopPropagation();

        // add this teilnehmer
        const response = await sendRequest(tokenObject, setTokenObject, 'POST', '/gcp/relations/createGcpTeilnRelation', `Füge Teilnehmer hinzu`, `Teilnehmer erfolgreich hinzugefügt`, { "GCPKey": thisGcp.GCPKey, "EFN": relation.EFN });

        // success
        if (response.success) initGcpForm();
        // error
        else setStatus("Fehler beim hinzufügen eines Teilnehmers", false, true);
    }

    // DELETE GCP-TEILNEHMER-RELATION
    const deleteTeilnehmerFromCourse = async (e, relation) => {
        e.stopPropagation();

        const confirmation = window.confirm(`Sicher, dass du den Teilnehmer "${relation.Nachname}" von Kurs "${relation.GCPKey}" löschen möchtest`);

        if (confirmation) {
            // delete this teilnehmer
            const response = await sendRequest(tokenObject, setTokenObject, 'DELETE', '/gcp/relations/deleteGcpTeilnRelation', `Lösche Teilnehmer`, `Teilnehmer erfolgreich gelöscht`, { "GCP_TEILNEHMER_ID": relation.GCP_TEILNEHMER_ID })

            // success
            if (response.success) initGcpForm();
            // error
            else setStatus("Fehler beim löschen einer Teilnahme", false, true);
        }
    }




    if (initialized && thisGcp) {
        return (

            <div className='basic-container'>
                <div className='form-container'>
                    <h3>{thisGcp.GCPKey}</h3>
                    <form>
                        {printInput(() => { }, "gcp-form-id-input", "ID: ", thisGcp.GCP_ID, true, "Die ID wird für den Namen der Zertifikat-Vorlage benötigt.", "text", "", true)}
                        {printInput(() => { }, "gcp-form-key-input", "Name: ", thisGcp.GCPKey, true, undefined, "text", "", true)}
                        {printCheckbox((e) => updateThisGcp(e, "Status"), "gcp-form-status-input", "Aktiv: ", thisGcp.Status)}<br />
                        {printInput((e) => updateThisGcp(e, "Laufzeit_Start"), "gcp-form-laufzeit-start-input", "Laufzeit Start: ", thisGcp.Laufzeit_Start.split('T')[0], true, undefined, "date")}
                        {printInput((e) => updateThisGcp(e, "Laufzeit_Ende"), "gcp-form-laufzeit-ende-input", "Laufzeit Ende: ", thisGcp.Laufzeit_Ende.split('T')[0], true, undefined, "date")}
                        {printSelect((e) => updateThisGcp(e, "Typ"), "gcp-form-typ-input", "Typ: ", thisGcp.Typ, ["GCP-Auffrischungskurs", "GCP-Updatekurs", "MPDG-Auffrischungskurs"])}
                        {printSelect((e) => updateThisGcp(e, "Zertifikat_Vorlage"), 'gcp-zertifikat-vorlage-input', 'Zertifikat Vorlage', thisGcp.Zertifikat_Vorlage, zertifikatVorlagen.map(elem => elem.Name))}
                        {printTextArea((e) => updateThisGcp(e, "Bestanden_Mail"), "gcp-form-mail-input", "EMail-Text: ", thisGcp.Bestanden_Mail)}
                    </form>
                    <div className='seperator'></div>

                    {/* modules of this course */}
                    <div>

                        <h3>Module</h3>

                        {/* table with modules of this gcp */}
                        <div className='table-container'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>VNR</th>
                                        <th>Modul</th>
                                        <th className='column-icon'></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {/* loop through gcp-modules */}
                                    {modulesOfGcp.map((relation, index) =>
                                        <tr key={`teilnehmerform-finished-relation-key-${index}`}>
                                            <td>{relation.VNR}</td>
                                            <td>{relation.KursKey}</td>
                                            <td className='column-icon delete underline' onClick={() => deleteThisModule(relation)}><FiTrash2 /></td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>



                        {/* add modules */}
                        <div>
                            <h6>Modul hinzufügen</h6>

                            <Search input={allGcpModules} callback={setSearchResults} searchKeys={["AerztlicherLeiter", "Sponsor_Name", "KursKey", "VNR", "KursTitel", "KursUntertitel", "Referenten"]} showAll={false} focus={false} />

                            {searchResults.length > 0 ?
                                <>
                                    {/* table with search-results */}
                                    <div className='table-container' active="true">
                                        <div className='table-header' onClick={toggleTable}>
                                            <AiOutlineDown />
                                            {searchResults.length} Kurse
                                        </div>

                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Kurstitel</th>
                                                    <th>KursKey</th>
                                                    <th>VNR</th>
                                                    <th></th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {/* loop through search-results */}
                                                {searchResults.map((course, index) => {

                                                    // check if this course is a gcp-module already
                                                    var isModuleAlready = false;
                                                    for (let i = 0; i < modulesOfGcp.length; i++) {
                                                        const module = modulesOfGcp[i];
                                                        if (module.KursKey === course.KursKey) {
                                                            isModuleAlready = true;
                                                            break;
                                                        }
                                                    }

                                                    return (
                                                        <tr key={`modules-row-${index}`}>
                                                            <td>{course.KursTitel}</td>
                                                            <td>{course.KursKey}</td>
                                                            <td>{course.VNR}</td>

                                                            {isModuleAlready ?
                                                                <td>Modul dieses Kurses</td>
                                                                :
                                                                <td className='underline' style={{ color: "green" }} onClick={() => addGcpModuleRelationToDb(course.KursKey)}>
                                                                    Modul hinzufügen
                                                                </td>
                                                            }

                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                                :
                                <></>}

                        </div>
                    </div>
                    <div className='seperator'></div>


                    {/* teilnehmer participated in this course */}
                    <div>
                        <h3>Teilnehmer</h3>

                        {/* buttons for opening/sending/downloading */}
                        {checkedRelations.length > 0 ?
                            <>
                                <div
                                    className='basic-small-btn inline'
                                    onClick={() => {
                                        checkedRelations.forEach(teilnehmer => {
                                            window.open(`${backendUrl}/zertifikate/gcp/${thisGcp.GCP_ID}/${teilnehmer.EFN}`);
                                        })
                                    }}
                                    target='_blank'>
                                    <AiOutlineFilePdf />Öffne Zertifikate
                                </div>
                                <div className='basic-small-btn inline' onClick={() => downloadCertificateFromCheckedRelations(tokenObject, setTokenObject, checkedRelations)}><AiOutlineDownload />Download Zertifikate</div>
                                <div className='basic-small-btn inline' onClick={() => sendCertificateToCheckedRelations(tokenObject, setTokenObject, checkedRelations)}><BiMailSend />Sende Zertifikat</div>
                            </>
                            :
                            <></>
                        }


                        {/* gcp-teilnehmer-table */}
                        <div className='table-container'>
                            <table>
                                {/* headline */}
                                <thead>
                                    <tr>
                                        <th className='column-icon'
                                            onClick={checkedRelations.length > 0 ? () => uncheckAll(setCheckedRelations) : () => checkAll(gcpTeilnehmer, setCheckedRelations)}>
                                            {checkedRelations.length > 0 ? <BiCheckboxMinus /> : <BiCheckbox />}
                                        </th>
                                        <th>Nachname</th>
                                        <th>Vorname</th>
                                        <th>EFN</th>
                                        <th>Teilnahmedatum</th>
                                        <th className='column-icon'></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {gcpTeilnehmer.map((relation, index) =>
                                        <tr key={`teilnehmer-row-${index}`}>
                                            <td className='column-icon'>
                                                <input type="checkbox" className="relation-checkbox" onClick={(e) => checkedRelationsChange(e, relation, checkedRelations, setCheckedRelations, "GCP_TEILNEHMER_ID")} />
                                            </td>
                                            <td>{relation.Nachname}</td>
                                            <td>{relation.Vorname}</td>
                                            <td>{relation.EFN}</td>

                                            <td>{formatDateToString(relation.Teilnahmedatum)}</td>

                                            <td className='column-icon underline delete' onClick={(e) => deleteTeilnehmerFromCourse(e, relation)}><FiTrash2 /></td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>


                        {/* add Teilnehmer */}
                        <div>
                            <h6>Teilnehmer hinzufügen</h6>

                            <TeilnehmerSearchAndCreate
                                allTeilnehmer={allTeilnehmer}
                                filteredTeilnehmer={filteredTeilnehmer}
                                setFilteredTeilnehmer={setFilteredTeilnehmer}
                                createFallback={(teilnehmer) => initGcpForm()}
                            />

                            {/* found-teilnehmer */}
                            {filteredTeilnehmer.length > 0 ?
                                <div className='table-container' active="true">

                                    <div className='table-header' onClick={toggleTable}>
                                        <AiOutlineDown />
                                        {filteredTeilnehmer.length} Teilnehmer gefunden
                                    </div>

                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Nachname</th>
                                                <th>Vorname</th>
                                                <th>EFN</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {filteredTeilnehmer.map((teilnehmer, index) => {
                                                var teilnehmerPassedCourse = false;
                                                gcpTeilnehmer.forEach(relation => { if (relation.EFN === teilnehmer.EFN) teilnehmerPassedCourse = true; });

                                                return (
                                                    <tr key={`teilnehmer-row-${index}`}>
                                                        <td>{teilnehmer.Nachname}</td>
                                                        <td>{teilnehmer.Vorname}</td>
                                                        <td>{teilnehmer.EFN}</td>
                                                        {teilnehmerPassedCourse ?
                                                            <td>Kurs absolviert</td>
                                                            :
                                                            <td className='underline' style={{ color: "green" }}
                                                                onClick={(e) => addTeilnehmerToCourse(e, teilnehmer)}>
                                                                Zu Kurs hinzufügen
                                                            </td>
                                                        }
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                : <></>
                            }
                        </div>
                    </div>
                    <div className='seperator'></div>



                </div>
            </div >
        )
    }
    else {
        return (
            <Loader />
        )
    }
}

export default GcpForm